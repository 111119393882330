import React,{useEffect, useState} from "react";
import confirmacionLogo from '../styles/img/confirmado.png'
import preparacionLogo from '../styles/img/preparacion.png'
import solicitadoLogo from '../styles/img/solicitado.png'
import realizadoLogo from '../styles/img/realizado.png'

/**
 * Styles
 */
import '../styles/table.css'

 /**
 * Components
 */
//Componente que muestra la información de los requerimientos del médico tratante para la cirugía
import DetallesModal from "./detallesModal";

const AgendaVista = ({matrizAgenda,setEventoSeleccionado,eventoSeleccionado,events,finalDay,initialDay}) => {

    const [isA,setIsA] = useState(false)
    const [isB,setIsB] = useState(false)
    const [isC,setIsC] = useState(false)
    const [isD,setIsD] = useState(false)
    const [isE,setIsE] = useState(false)
    const [isF,setIsF] = useState(false)
    const [isG,setIsG] = useState(false)
    const [isH,setIsH] = useState(false)
    const [isI,setIsI] = useState(false)
    const [isJ,setIsJ] = useState(false)
    const [isK,setIsK] = useState(false)
    const [isL,setIsL] = useState(false)
    const [isM,setIsM] = useState(false)
    const [isN,setIsN] = useState(false)
    const [isO,setIsO] = useState(false)
    const [isP,setIsP] = useState(false)
    const [isQ,setIsQ] = useState(false)
    const [isR,setIsR] = useState(false)
    const [isS,setIsS] = useState(false)
    const [isT,setIsT] = useState(false)
    const [elementos,setElemento] = useState([])
    const [fecha,setFecha] = useState("")
    const [styleObject, setStyleObject] = useState("HeadingHora")

    useEffect(()=>{
        if(isT){
            setStyleObject("HeadingHoraT")
        }
        else{
            if(isS){
                setStyleObject("HeadingHoraS")
            }
            else{
                if(isR){
                    setStyleObject("HeadingHoraR")
                }
                else{
                    if(isQ){
                        setStyleObject("HeadingHoraQ")
                    }
                    else{
                        if(isP){
                            setStyleObject("HeadingHoraP")
                        }
                        else{
                            if(isP){
                                setStyleObject("HeadingHoraO")
                            }
                            else{
                                if(isN){
                                    setStyleObject("HeadingHoraN")
                                }
                                else{
                                    if(isM){
                                        setStyleObject("HeadingHoraM")
                                    }
                                    else{
                                        if(isL){
                                            setStyleObject("HeadingHoraL")
                                        }
                                        else{
                                            if(isK){
                                                setStyleObject("HeadingHoraK")
                                            }
                                            else{
                                                if(isJ){
                                                    setStyleObject("HeadingHoraJ")
                                                }
                                                else{
                                                    if(isI){
                                                        setStyleObject("HeadingHoraI")
                                                    }
                                                    else{
                                                        if(isH){
                                                            setStyleObject("HeadingHoraH")
                                                        }
                                                        else{
                                                            if(isG){
                                                                setStyleObject("HeadingHoraG")
                                                            }
                                                            else{
                                                                if(isF){
                                                                    setStyleObject("HeadingHoraF")
                                                                }
                                                                else{
                                                                    if(isE){
                                                                        setStyleObject("HeadingHoraE")
                                                                    }
                                                                    else{
                                                                        if(isD){
                                                                            setStyleObject("HeadingHoraD")
                                                                        }
                                                                        else{
                                                                            if(isC){
                                                                                setStyleObject("HeadingHoraC")
                                                                            }
                                                                            else{
                                                                                if(isB){
                                                                                    setStyleObject("HeadingHoraB")
                                                                                }
                                                                                else{
                                                                                    if(isA){
                                                                                        setStyleObject("HeadingHoraA")
                                                                                    }
                                                                                    else{
                                                                                        setStyleObject("HeadingHora")
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },[isT,isS,isR,isQ,isP,isO,isN,isM,isL,isK,isJ,isI,isH,isG,isF,isE,isD,isC,isB,isA])

    useEffect(()=>{
        console.log("Agenda")
        console.log(matrizAgenda)
        if(matrizAgenda){
            if(matrizAgenda.length > 0){
                if(matrizAgenda[0].A){
                    setIsA(true)
                }
                else{
                    setIsA(false)
                }

                if(matrizAgenda[0].B){
                    setIsB(true)
                }
                else{
                    setIsB(false)
                }

                if(matrizAgenda[0].C){
                    setIsC(true)
                }
                else{
                    setIsC(false)
                }

                if(matrizAgenda[0].D){
                    setIsD(true)
                }
                else{
                    setIsD(false)
                }

                if(matrizAgenda[0].E){
                    setIsE(true)
                }
                else{
                    setIsE(false)
                }

                if(matrizAgenda[0].F){
                    setIsF(true)
                }
                else{
                    setIsF(false)
                }

                if(matrizAgenda[0].G){
                    setIsG(true)
                }
                else{
                    setIsG(false)
                }

                if(matrizAgenda[0].H){
                    setIsH(true)
                }
                else{
                    setIsH(false)
                }

                if(matrizAgenda[0].I){
                    setIsI(true)
                }
                else{
                    setIsI(false)
                }

                if(matrizAgenda[0].J){
                    setIsJ(true)
                }
                else{
                    setIsJ(false)
                }

                if(matrizAgenda[0].K){
                    setIsK(true)
                }
                else{
                    setIsK(false)
                }

                if(matrizAgenda[0].L){
                    setIsL(true)
                }
                else{
                    setIsL(false)
                }

                if(matrizAgenda[0].M){
                    setIsM(true)
                }
                else{
                    setIsM(false)
                }

                if(matrizAgenda[0].N){
                    setIsN(true)
                }
                else{
                    setIsN(false)
                }

                if(matrizAgenda[0].O){
                    setIsO(true)
                }
                else{
                    setIsO(false)
                }

                if(matrizAgenda[0].P){
                    setIsP(true)
                }
                else{
                    setIsP(false)
                }

                if(matrizAgenda[0].Q){
                    setIsQ(true)
                }
                else{
                    setIsQ(false)
                }

                if(matrizAgenda[0].R){
                    setIsR(true)
                }
                else{
                    setIsR(false)
                }

                if(matrizAgenda[0].S){
                    setIsS(true)
                }
                else{
                    setIsS(false)
                }

                if(matrizAgenda[0].T){
                    setIsT(true)
                }
                else{
                    setIsT(false)
                }

                let elementosShowed = []
                for(let i=1;i<matrizAgenda.length;i++){
                    elementosShowed.push(matrizAgenda[i])
                }
                setElemento(elementosShowed)
            }
            else{
                setIsA(false)
                setIsB(false)
                setIsC(false)
                setIsD(false)
                setIsE(false)
                setIsF(false)
                setIsG(false)
                setIsH(false)
            }
        }
        else{
            setIsA(false)
            setIsB(false)
            setIsC(false)
            setIsD(false)
            setIsE(false)
            setIsF(false)
            setIsG(false)
            setIsH(false)
        }
    },[matrizAgenda])

    useEffect(()=>{
        let fechaAtratar = new Date()
        if(finalDay){
            fechaAtratar = new Date(finalDay)
        }
        else{
            if(initialDay){
                fechaAtratar = new Date(initialDay)   
            }
            else{
                fechaAtratar = new Date()
            }
        }

        let fechaAMostrar = ""

            switch(fechaAtratar.getDay()){
                case 1:
                    fechaAMostrar += "Lunes "
                    break
                case 2:
                    fechaAMostrar += "Martes "
                    break
                case 3:
                    fechaAMostrar += "Miércoles  "
                    break
                case 4:
                    fechaAMostrar += "Jueves "
                    break
                case 5:
                    fechaAMostrar += "Viernes "
                    break
                case 6:
                    fechaAMostrar += "Sábado  "
                    break
                case 7:
                    fechaAMostrar += "Domingo "
                    break
                default:
                    break
            }

            fechaAMostrar += fechaAtratar.getDate().toString().padStart(2,"0")
            fechaAMostrar += " "
            switch(fechaAtratar.getMonth()){
                case 0:
                    fechaAMostrar +="de Enero "
                    break
                case 1:
                    fechaAMostrar +="de Febrero "
                    break
                case 2:
                    fechaAMostrar +="de Marzo "
                    break
                case 3:
                    fechaAMostrar +="de Abril "
                    break
                case 4:
                    fechaAMostrar +="de Mayo "
                    break
                case 5:
                    fechaAMostrar +="de Junio "
                    break
                case 6:
                    fechaAMostrar +="de Julio "
                    break
                case 7:
                    fechaAMostrar +="de Agosto "
                    break
                case 8:
                    fechaAMostrar +="de Septiembre "
                    break
                case 9:
                    fechaAMostrar +="de Octubre "
                    break
                case 10:
                    fechaAMostrar +="de Noviembre "
                    break
                case 11:
                    fechaAMostrar +="de Diciembre "
                    break
                default:
                    break
            }
            fechaAMostrar += "del " + fechaAtratar.getFullYear()
            setFecha(fechaAMostrar)
    },[initialDay,finalDay])

    const getEvent = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        if(resultado){
            setEventoSeleccionado(resultado)
            if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
                document.getElementById('modal').style.display='block'
            }
        }
    }

    return (
        <>
            <div className="Table">
                <div className={styleObject}>
                    <div className="HoraEncabezado">
                        <p></p>
                    </div>
                    <div className="CellHora">
                        <p>Agenda para {fecha}</p>
                    </div>
                </div>
                <div className="HeadingAgenda" id="encabezadoSectores">
                    <div className="HoraEncabezado">
                        <p></p>
                    </div>
                    {
                        isA
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].A}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isB
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].B}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isC
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].C}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isD
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].D}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isE
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].E}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isF
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].F}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isG
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].G}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isH
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].H}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isI
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].I}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isJ
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].J}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isK
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].K}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isL
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].L}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isM
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].M}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isN
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].N}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isO
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].O}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isP
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].P}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isQ
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].Q}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isR
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].R}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isS
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].S}</p>
                                </div>
                            :
                                null
                    }
                    {
                        isT
                            ?
                                <div className="CellAgendaHeader">
                                    <p>{matrizAgenda[0].T}</p>
                                </div>
                            :
                                null
                    }
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {
                    elementos.map(event => 
                        <div className="Row" key={event.id}>
                            <div className="Hora">
                                <p>{event.Hora}</p>
                            </div>
                            {
                                isA
                                    ?
                                        <div className={event.A.Class}>
                                            {
                                                event.A.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.A)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.A.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.A.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.A)}>
                                                                {
                                                                        event.A.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.A.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.A.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.A.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.A)}>
                                                                    {
                                                                        event.A.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.A.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.A.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.A)}>
                                                                    {
                                                                        event.A.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.A.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.A.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isB
                                    ?
                                        <div className={event.B.Class}>
                                            {
                                                event.B.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.B)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.B.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.B.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.B)}>
                                                                {
                                                                        event.B.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.B.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.B.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.B.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.B)}>
                                                                    {
                                                                        event.B.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.B.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.B.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.B)}>
                                                                    {
                                                                        event.B.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.B.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.B.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isC
                                    ?
                                        <div className={event.C.Class}>
                                            {
                                                event.C.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.C)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.C.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.C.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.C)}>
                                                                {
                                                                        event.C.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.C.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.C.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.C.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.C)}>
                                                                    {
                                                                        event.C.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.C.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.C.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.C)}>
                                                                    {
                                                                        event.C.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.C.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.C.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isD
                                    ?
                                        <div className={event.D.Class}>
                                            {
                                                event.D.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.D)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.D.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.D.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.D)}>
                                                                {
                                                                        event.D.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.D.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.D.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.D.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.D)}>
                                                                    {
                                                                        event.D.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.D.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.D.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.D)}>
                                                                    {
                                                                        event.D.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.D.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.D.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isE
                                    ?
                                        <div className={event.E.Class}>
                                            {
                                                event.E.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.E)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.E.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.E.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.E)}>
                                                                {
                                                                        event.E.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.E.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.E.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.E.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.E)}>
                                                                    {
                                                                        event.E.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.E.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.E.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.E)}>
                                                                    {
                                                                        event.E.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.E.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.E.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isF
                                    ?
                                        <div className={event.F.Class}>
                                            {
                                                event.F.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.F)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.F.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.F.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.F)}>
                                                                {
                                                                        event.F.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.F.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.F.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.F.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.F)}>
                                                                    {
                                                                        event.F.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.F.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.F.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.F)}>
                                                                    {
                                                                        event.F.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.F.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.F.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isG
                                    ?
                                        <div className={event.G.Class}>
                                            {
                                                event.G.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.G)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.G.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.G.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.G)}>
                                                                {
                                                                        event.G.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.G.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.G.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.G.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.G)}>
                                                                    {
                                                                        event.G.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.G.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.G.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.G)}>
                                                                    {
                                                                        event.G.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.G.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.G.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isH
                                    ?
                                        <div className={event.H.Class}>
                                            {
                                                event.H.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.H)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.H.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.H)}>
                                                                {
                                                                        event.H.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.H.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.H.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.H.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.H)}>
                                                                    {
                                                                        event.H.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.H.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.H.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.H)}>
                                                                    {
                                                                        event.H.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.H.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.H.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isI
                                    ?
                                        <div className={event.I.Class}>
                                            {
                                                event.I.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.I)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.I.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.I)}>
                                                                {
                                                                        event.I.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.I.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.I.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.I.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.I)}>
                                                                    {
                                                                        event.I.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.I.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.I.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.I)}>
                                                                    {
                                                                        event.I.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.I.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.I.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isJ
                                    ?
                                        <div className={event.J.Class}>
                                            {
                                                event.I.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.J)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.J.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.J)}>
                                                                {
                                                                        event.J.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.J.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.J.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.J.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.J)}>
                                                                    {
                                                                        event.J.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.J.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.J.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.J)}>
                                                                    {
                                                                        event.J.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.J.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.J.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isK
                                    ?
                                        <div className={event.K.Class}>
                                            {
                                                event.K.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.K)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.K.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.K)}>
                                                                {
                                                                        event.K.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.K.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.K.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.K.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.K)}>
                                                                    {
                                                                        event.K.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.K.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.K.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.K)}>
                                                                    {
                                                                        event.K.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.K.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.K.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isL
                                    ?
                                        <div className={event.L.Class}>
                                            {
                                                event.L.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.L)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.L.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.L)}>
                                                                {
                                                                        event.L.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.L.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.L.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.L.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.L)}>
                                                                    {
                                                                        event.L.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.L.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.L.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.L)}>
                                                                    {
                                                                        event.L.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.L.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.L.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isM
                                    ?
                                        <div className={event.M.Class}>
                                            {
                                                event.M.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.M)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.M.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.M)}>
                                                                {
                                                                        event.M.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.M.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.M.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.M.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.M)}>
                                                                    {
                                                                        event.M.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.M.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.M.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.M)}>
                                                                    {
                                                                        event.M.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.M.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.M.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isN
                                    ?
                                        <div className={event.N.Class}>
                                            {
                                                event.N.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.N)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.N.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.N)}>
                                                                {
                                                                        event.N.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.N.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.N.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.N.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.N)}>
                                                                    {
                                                                        event.N.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.N.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.N.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.N)}>
                                                                    {
                                                                        event.N.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.N.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.N.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isO
                                    ?
                                        <div className={event.O.Class}>
                                            {
                                                event.O.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.O)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.O.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.O)}>
                                                                {
                                                                        event.O.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.O.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.O.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.O.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.O)}>
                                                                    {
                                                                        event.O.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.O.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.O.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.O)}>
                                                                    {
                                                                        event.O.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.O.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.O.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isP
                                    ?
                                        <div className={event.P.Class}>
                                            {
                                                event.P.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.P)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.P.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.P)}>
                                                                {
                                                                        event.P.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.P.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.P.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.P.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.P)}>
                                                                    {
                                                                        event.P.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.P.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.P.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.P)}>
                                                                    {
                                                                        event.P.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.P.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.P.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isQ
                                    ?
                                        <div className={event.Q.Class}>
                                            {
                                                event.Q.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.Q)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.Q.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.Q)}>
                                                                {
                                                                        event.Q.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.Q.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.Q.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.Q.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.Q)}>
                                                                    {
                                                                        event.Q.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.Q.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.Q.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.Q)}>
                                                                    {
                                                                        event.Q.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.Q.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.Q.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isR
                                    ?
                                        <div className={event.R.Class}>
                                            {
                                                event.R.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.R)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.R.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.R)}>
                                                                {
                                                                        event.R.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.R.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.R.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.R.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.R)}>
                                                                    {
                                                                        event.R.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.R.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.R.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.R)}>
                                                                    {
                                                                        event.R.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.R.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.R.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isS
                                    ?
                                        <div className={event.S.Class}>
                                            {
                                                event.S.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.S)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.S.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.S)}>
                                                                {
                                                                        event.S.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.S.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.S.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.S.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.S)}>
                                                                    {
                                                                        event.S.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.S.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.S.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.S)}>
                                                                    {
                                                                        event.S.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.S.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.S.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                isT
                                    ?
                                        <div className={event.T.Class}>
                                            {
                                                event.T.IsPreparacion
                                                    ?
                                                        <div onClick={getEvent(event.T)}>
                                                            <img src={preparacionLogo} className="ConfirmadoImg" alt="Cirugia Solicitada"/>
                                                            {/* <p>{event.H.Medico}</p> */}
                                                        </div>
                                                    :
                                                        event.T.IsConfirmado
                                                        ?
                                                            <div onClick={getEvent(event.T)}>
                                                                {
                                                                        event.T.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={confirmacionLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.T.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.T.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                }
                                                            </div>
                                                        :
                                                            event.T.IsPendiente
                                                            ?
                                                                <div onClick={getEvent(event.T)}>
                                                                    {
                                                                        event.T.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={solicitadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.T.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.T.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                            :
                                                                <div onClick={getEvent(event.T)}>
                                                                    {
                                                                        event.T.isMedia
                                                                            ?
                                                                                <>
                                                                                    <img src={realizadoLogo} className="ConfirmadoImg" alt="Cirugia Confirmada"/>
                                                                                    <p className="MedicoNombre">{event.T.Medico}</p>
                                                                                    <p className="MedicoNombre">{event.T.Procedimiento.toString().toUpperCase()}</p>
                                                                                </>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                            }
                                        </div>
                                    :
                                        null
                            }
                        </div>
                    )
                }
            </div>
            <DetallesModal eventoSeleccionado={eventoSeleccionado}/>
        </>
    )
}

export default AgendaVista