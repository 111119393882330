/**
 * Librearías
 */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import moment from 'moment';
import { AiFillCaretDown,AiFillCaretUp } from "react-icons/ai";

/**
 * Styles
 */
import '../styles/pacientesPendientes.css'

/**
 * Utilerías
 */
//Objetos y colecciones de la base de datos
import { buildingCollection, pacientesStore, resourcesCollection } from "../utils/firebase";
//Funciones necesarias en la lógica del negocio
import { compararInformación } from "../utils/config";

/**
 * Componentes
 */
//Componente asignado al muestro de información de una unidad hospitalaria
import BuildingElement from "./buildingElement";
//Componente asignado al muestreo de la información de un evento
import EventCard from "./eventCard";
//Componente que muestra el formulario para la edición de un evento
import EditarEvento from "./editarEvento";
//Componente que muestra el listado de eventos realizados en forma de tabla
import HistoricoTable from "./historicoTable";
//Componente que muestra que se está procesando información
import Loader from "./loader";
//Componente que muestra la administración de medicos
import MedicosSection from "./medicosSection";
//Componente que muestra el listado de eventos que estan por realizarse en forma de tabla
import PendienteTable from "./pendienteTable";
//Componente asignado al muestro de información de un quirófano
import ResourceElement from "./resourceElement";
//Componente que muestra que no hay información encontrada con los parámetros solicitados
import SinEventos from "./sinEventos";
//Componente que muestra el encabezado de la sección
import TituloSection from "./tituloSection";
//Componente que muestras la administración de usuarios
import UsuarioSection from "./usuarioSection";
import CancelEventCard from "./cancelEvent";
import AgendaVista from "./agendaVista";
import RegitrarEvento from "./registrarEvento";
import IndicadoresTable from "./indicadoresTable";


/**
 * Componente que muestra la información y acciones del calendario
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los eventos de calendarios
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const CalendarSection = ({tituloInicial,tituloFinal,cantidad,imgSource,isLoading,isEmpty,events,finalDay,initialDay,
    isMostrarRealizado,setIsMostrarRealizado,isMostrarEnProceso,setIsMostrarEnProceso,isMostrarEnPendiente,setIsMostrarEnPendiente,
    cantidadRealizada,setCantidadRealizada,
    cantidadProceso,setCantidadProceso,
    cantidadPendiente,setCantidadPendiente,uhList,
    isMostrarQuirofanos,setIsMostrarQuirofanos,
    isMostrarUsuarios,setIsMostrarUsuarios,userLevel,nomHospital,
    isMostrarEditar,setIsMostrarEditar,userName,cancelEvents,cantidadCanceladas,setCantidadCanceladas,
    isMostrarMedicos,setIsMostrarMedicos,isReadOnly,setIsMostrarAgendaVista,isMostrarAgendaVista,editEvents,isCorporativo,
    setuserNomHospital,isMostrarAgendar,setIsMostrarAgendar,hospitalSeleccionado,
    pendientesUnidad,pendientesUnidadEventFormat,
    agendadosUnidad,agendadoUnidadEventFormat,
    realizadosUnidad,realizadosUnidadEventFormat,canceladosUnidad,
    generalUnidad,isMostrarIndicadores,setIsMostrarIndicadores,editEventsUnidad}) => {

    //Estado que almacena la lista de unidades hospitalarias disponibles
    const [buildingList, setBuildingList] = useState([])
    //Estado que almacena la unidad hospitalaria seleccionada
    const [buildingSelected ,setBuildingSelected] = useState("-- Seleccione la unidad hospitalaria --")
    //Estado que indica si esta desplegada la lista de unidades hospitalarias
    const [clicBuildingSelect,setClicBuildingSelect] = useState(false)
    //Estado que indica si esta desplegada la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
    const [clicResourcesSelect,setResourcesClicSelect] = useState(false)
    //Estado que almacena el evento seleccionado
    const [eventoSeleccionado,setEventoSeleccionado] = useState({})
    //Estado que almacena el listado de eventos por realizar confirmados
    const [eventosConfirmados,setEventosConfirmados] = useState([])
    //Estado que alamcena el listado de eventos realizados
    const [eventosHistoricos,setEventosHistoricos] = useState([])
    //Estado que almacena el listado de eventos por realizar pendientes de confirmación
    const [eventosPendientes,setEventosPendientes] = useState([])
    //Estado que almacena el listado de los eventos que se exportarán en formato csv
    const [exportedEventList, setExportedEvenList] = useState([])
    //Estado que indica si se cuenta con eventos que correspondan al rango de fecha y opción seleccionada
    const [hadValidateEvents, setHadValidateEvents] = useState(false)
    //Estado que indica que si se requiere ver la vista de tipo tarjetas
    const [isTarjetasChecked,setIsTarjetasChecked] = useState(true)
    //Estado que almacena la lista de quirófanos disponibles para la unidad hospitalaria seleccionada
    const [resourcesList, setResourcesList] = useState([])
    //Estado que almacena el quirófano requerido para la cirugía
    const [resourcesSelected ,setResourcesSelected] = useState("-- Seleccione el quirófano deseado --")
    //Estado que almacena la vista seleccionada por el usuario
    const [vista,setVista] = useState("Tarjetas")
    //Estado que indica el tipo de reporte deseado
    const [tipoReporte, setTipoReporte] = useState("")
    const [cancelados, setCancelados] = useState([])
    const [matrizAgenda,setMatrizAgenda] = useState([])
    const [eventosEditados, setEventosEditados] = useState([])
    const [hadEventosEditados, setHadEventosEditados] = useState(false)

    const [eventosVista, setEventosVista] = useState([])
    const [indicadorUnidad, setIndicadorUnidad] = useState(false)
    const [indicadoresList, setIndicadoresList] = useState([])

    function getEventObjectResumen(event,estatus,style){
        let unidadHospitalaria = "SR"
        let quirofano = "SR"
        let medico = "SR"
        let telefonoMedico = "SR"
        let nombrePaciente = "SR"
        let telefonoPaciente = "SR"
        let fechaNacimiento = "SR"
        let edad = "SR"
        let sexo = "SR"
        let tipoCliente = "SR"
        let diagnostico = "SR"
        let procedimiento = "SR"
        let ladoProcedimiento = "SR"
        let anestesiologo = "SR"
        let tipoAnestesia = "SR"
        let fecha = "SR"
        let horaInicio = "SR"
        let horaFin = "SR"
        let duracion = "SR"
        let materiales = "SR"
        let extras = "SR"
        let ultimaSolicitud = "SR"
        let solicito = "SR"
        let start = ""
        let end = ""
        let tipoReasignacion = ""
        let materialesEspeciales = ""
        let hemoderivados = ""
        let concepto = ""
    
        if(event.description){
            let description = event.description.toString().split('|')
            if(description.length > 1){
                unidadHospitalaria = description[8].split('=')[1]
                quirofano = description[0].split('=')[1]
                medico = description[1].split('=')[1]
                telefonoMedico = description[6].split('=')[1]
                nombrePaciente = description[2].split('=')[1]
                telefonoPaciente = description[7].split('=')[1]
                procedimiento = description[3].split('=')[1]
                if(event.start.dateTime){
                    fecha = event.start.dateTime.split('T')[0]
                }
                else{
                    fecha = event.start.split('T')[0]
                }
    
                if(event.start.dateTime){
                    horaInicio = event.start.dateTime.split('T')[1].split('-')[0]
                }
                else{
                    horaInicio = event.start.split('T')[1].split('-')[0]
                }
                
                if(event.end.dateTime){
                    horaFin = event.end.dateTime.split('T')[1].split('-')[0]
                }
                else{
                    horaFin = event.end.split('T')[1].split('-')[0]
                }
                
                materiales = description[4].split('=')[1]
                extras = description[5].split('=')[1]
                if(description[9]){
                    fechaNacimiento = description[13].split('=')[1]
                    edad = description[14].split('=')[1]
                    sexo = description[15].split('=')[1]
                    tipoCliente = description[16].split('=')[1]
                    diagnostico = description[17].split('=')[1]
                    ladoProcedimiento = description[10].split('=')[1]
                    anestesiologo = description[11].split('=')[1]
                    tipoAnestesia = description[12].split('=')[1]
                    duracion = description[9].split('=')[1] + "Hr."
                    ultimaSolicitud = description[19].split('=')[1]
                    solicito = description[18].split('=')[1]
                    concepto = description[19].split('=')[1]
                    
                    
    
                    if(description.length === 24){
                        if(description[23]){
                            tipoReasignacion = description[23].split('=')[1]
                        }
                        else{
                            tipoReasignacion = "SR"
                        }
                        materialesEspeciales = "SR"
                        hemoderivados = "SR"
                    }
                    else{
                        if(description.length === 26){
                            tipoReasignacion = description[25].split('=')[1]
                            materialesEspeciales = description[23].split('=')[1]
                            hemoderivados = description[24].split('=')[1]
                        }
                        else{
                            
                        }
                    }
    
                    
                }
            }
            else{
                quirofano = description[0]
            }
    
            if(event.start.dateTime){
                start = event.start.dateTime
                end = event.end.dateTime
            }
            else{
                start = event.start
                end = event.end
            }
            
        }
        else{
            unidadHospitalaria = event.UnidadHospitalaria
            quirofano = event.Sala
            medico = event.Medico
            telefonoMedico = event.TelefonoMedico
            nombrePaciente = event.Paciente
            telefonoPaciente = event.TelefonoPaciente
            fechaNacimiento = event.FechaNacimiento
            edad = event.Edad
            sexo = event.Sexo
            tipoCliente = event.TipoCliente
            diagnostico = event.Diagnostico
            procedimiento = event.Procedimiento
            ladoProcedimiento = event.LadoProcedimiento
            anestesiologo = event.Anestesiologo
            tipoAnestesia = event.TipoAnestesia
            fecha = event.Fecha
            horaInicio = event.HoraInicio
            horaFin = event.HoraFin
            duracion = event.Duracion
            materiales = event.Materiales
            extras = event.Extras
            ultimaSolicitud = event.UltimaSolicitud
            solicito = event.Realizo
            start = event.start
            end = event.end
            tipoReasignacion = "SR"
            concepto = ultimaSolicitud
        }
    
        
        if(!event.id_evento){
            console.log(event)
        }
    
        return {
            'Estatus' : estatus,
            'UnidadHospitalaria' : unidadHospitalaria,
            'Sala': quirofano,
            'Medico': medico,
            'TelefonoMedico' : telefonoMedico,
            'Paciente': nombrePaciente,
            'TelefonoPaciente':telefonoPaciente,
            'FechaNacimiento':fechaNacimiento,
            'Edad':edad,
            'Sexo':sexo,
            'TipoCliente':tipoCliente,
            'Diagnostico':diagnostico,
            'Procedimiento':procedimiento,
            'LadoProcedimiento':ladoProcedimiento,
            'Anestesiologo':anestesiologo,
            'TipoAnestesia':tipoAnestesia,
            'Fecha':fecha,
            'HoraInicio':horaInicio,
            'HoraFin':horaFin,
            'Duracion':duracion,
            'Materiales':materiales,
            'Extras':extras,
            'UltimaSolicitud':ultimaSolicitud,
            'Realizo': solicito,
            'start':start,
            'end':end,
            'created':event.created,
            'type':style,
            'id_evento' : event.id_evento,
            'TipoReasignacion' : tipoReasignacion,
            'MaterialesEspeciales' : materialesEspeciales,
            'Hemoderivados' : hemoderivados,
            'Concepto' : concepto,
            'ID' : event.id
        }
        
    }

    useEffect(()=>{
        if(editEventsUnidad){
            if(editEventsUnidad.length > 0){
                setHadEventosEditados(true)
            }
            else{
                setHadEventosEditados(false)
            }
        }
        else{
            setHadEventosEditados(false)
        }
    },[editEventsUnidad])

    useEffect(()=>{
        setCancelados(canceladosUnidad)
    },[canceladosUnidad])


    /**
     * Mover este estado
     */
    useEffect(()=>{

        if(nomHospital !== "Corporativo"){
            const UN_DIA_EN_MILISEGUNDOS = 1000 * 60 * 60 * 24;
            const INTERVALO = UN_DIA_EN_MILISEGUNDOS * 1; // Cada semana
            const formateadorFecha = new Intl.DateTimeFormat('es-MX', { dateStyle: 'medium', });

            let reporteFecha = [{
                fecha : "FECHA",
                solicitadas : "",
                agendadas : "",
                realizadas : "",
                cancelados : "",
                totales : ""
            }]

            let totalSolicitadas = 0
            let totalAgendadas = 0
            let totalRealizadas = 0
            let totalCancelados = 0

            for (let i = initialDay; i <= finalDay; i = new Date(i.getTime() + INTERVALO)) {
                //2024-10-24
                let fecha = i.getFullYear() + "-" + (i.getMonth() + 1).toString().padStart(2,"0") + "-" + i.getDate().toString().padStart(2,"0")
                let solicitadas = 0
                let agendadas = 0
                let realizadas = 0
                let canceladas = 0

                for(let j = 0;j<pendientesUnidad.length;j++){
                    if(pendientesUnidad[j].Fecha === fecha){
                        solicitadas = solicitadas + 1
                        totalSolicitadas = totalSolicitadas + 1
                    }
                }

                for(let j = 0;j<agendadosUnidad.length;j++){
                    if(agendadosUnidad[j].Fecha === fecha){
                        agendadas = agendadas + 1
                        totalAgendadas = totalAgendadas + 1
                    }
                }

                for(let j = 0;j<realizadosUnidad.length;j++){
                    if(realizadosUnidad[j].Fecha === fecha){
                        realizadas = realizadas + 1
                        totalRealizadas = totalRealizadas + 1
                    }
                }

                for(let j = 0;j<canceladosUnidad.length;j++){
                    if(canceladosUnidad[j].Fecha === fecha){
                        canceladas = canceladas + 1
                        totalCancelados = totalCancelados + 1
                    }
                }

                reporteFecha.push({
                    fecha : formateadorFecha.format(i),
                    solicitadas : solicitadas,
                    agendadas : agendadas,
                    realizadas : realizadas,
                    canceladas : canceladas,
                    totales : solicitadas + agendadas + realizadas + canceladas
                })
                //console.log(formateadorFecha.format(i));
            }

            reporteFecha.push({
                fecha : "",
                solicitadas : "",
                agendadas : "",
                realizadas : "",
                canceladas : "Total",
                totales : totalSolicitadas + totalAgendadas + totalRealizadas + totalCancelados
            })

            setIndicadoresList(reporteFecha)
            setIndicadorUnidad(true)
        }
        else{
            let unidadesReporte = [
                // {
                //     "id": "999",
                //     "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                //     "activo": true,
                //     "hospital": "MAC Integración Procesos Clínicos",
                //     "mnemonicos": "IPC",
                //     "num_asociado": "0",
                //     "email_calidad": ""
                // },
                {
                    "id": "20",
                    "hospital": "HMAS Los Cabos",
                    "num_asociado": "0",
                    "mnemonicos": "LCA",
                    "activo": true,
                    "calendar": "Hospital-HMAS-Los Cabos"
                },
                {
                    "id": "21",
                    "hospital": "HMAS Querétaro",
                    "num_asociado": "0",
                    "mnemonicos": "QRO",
                    "activo": true,
                    "calendar": "Hospital-HMAS-Querétaro"
                },
                {
                    "id": "11",
                    "calendar": "Hospital-MAC-Aguascalientes Norte",
                    "activo": true,
                    "hospital": "MAC Aguascalientes Norte",
                    "mnemonicos": "IRA",
                    "num_asociado": "23",
                    "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                },
                {
                    "id": "4",
                    "calendar": "Hospital-MAC-Aguascalientes Sur",
                    "activo": true,
                    "hospital": "MAC Aguascalientes Sur",
                    "mnemonicos": "IRA",
                    "num_asociado": "23",
                    "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                },
                {
                    "id": "1",
                    "activo": true,
                    "mnemonicos": "CEL",
                    "num_asociado": 3,
                    "email_calidad": "jose.campos@hospitalesmac.com",
                    "hospital": "MAC Celaya",
                    "calendar": "Hospital-MAC-Celaya"
                },
                {
                    "id": "3",
                    "calendar": "Hospital-MAC-Guadalajara",
                    "activo": true,
                    "hospital": "MAC Guadalajara",
                    "mnemonicos": "GDL",
                    "num_asociado": "0",
                    "email_calidad": "calidad.irapuato@hospitalesmac.com"
                },
                {
                    "id": "2",
                    "calendar": "Hospital-MAC-Irapuato",
                    "activo": true,
                    "hospital": "MAC Irapuato",
                    "mnemonicos": "IRA",
                    "num_asociado": "23",
                    "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                },
                {
                    "id": "22",
                    "mnemonicos": "LVG",
                    "activo": true,
                    "num_asociado": 51,
                    "hospital": "MAC La Viga",
                    "calendar": "Hospital-MAC-La Viga",
                    "email_calidad": ""
                },
                {
                    "id": "18",
                    "num_asociado": 35,
                    "activo": true,
                    "mnemonicos": "LEO",
                    "hospital": "MAC León",
                    "calendar": "Hospital-MAC-León"
                },
                {
                    "id": "17",
                    "mnemonicos": "MOC",
                    "calendar": "Hospital-MAC-Los Mochis",
                    "num_asociado": 27,
                    "activo": true,
                    "hospital": "MAC Los Mochis"
                },
                {
                    "id": "19",
                    "calendar": "Hospital-MAC-Perisur",
                    "activo": true,
                    "hospital": "MAC Perisur",
                    "mnemonicos": "IRA",
                    "num_asociado": "23",
                    "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                },
                {
                    "id": "7",
                    "calendar": "Hospital-MAC-Puebla",
                    "activo": true,
                    "hospital": "MAC Puebla",
                    "mnemonicos": "PUE",
                    "num_asociado": "1",
                    "email_calidad": "julieta.jaquez@hospitalesmac.com"
                },
                {
                    "id": "6",
                    "calendar": "Hospital-MAC-San Miguel de Allende",
                    "activo": true,
                    "hospital": "MAC San Miguel de Allende",
                    "mnemonicos": "SMA",
                    "num_asociado": "0",
                    "email_calidad": "calidad.sma@hospitalesmac.com"
                },
                {
                    "id": "15",
                    "calendar": "Hospital-MAC-Boca del Río",
                    "activo": true,
                    "hospital": "MediMAC Boca del Rio",
                    "mnemonicos": "BDR",
                    "num_asociado": "23",
                    "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                },
                {
                    "id": "44",
                    "num_asociado": 1,
                    "mnemonicos": "CUE",
                    "activo": true,
                    "calendar": "Hospital-MAC-Cuemanco",
                    "hospital": "MediMAC Cuemanco"
                },
                {
                    "id": "45",
                    "calendar": "Hospital-MAC-Ecatepec",
                    "activo": true,
                    "hospital": "MediMAC Ecatepec",
                    "mnemonicos": "ECA",
                    "num_asociado": "18",
                    "email_calidad": ""
                },
                {
                    "id": "16",
                    "calendar": "Hospital-MAC-Guanajuato",
                    "activo": true,
                    "hospital": "MediMAC Guanajuato",
                    "mnemonicos": "GTO",
                    "num_asociado": "0",
                    "email_calidad": ""
                },
                {
                    "id": "42",
                    "calendar": "Hospital-MAC-Merida",
                    "activo": true,
                    "hospital": "MediMAC Merida",
                    "mnemonicos": "MER",
                    "num_asociado": "12",
                    "email_calidad": "angel.adrian@merida.medimac.com"
                },
                {
                    "id": "8",
                    "calendar": "Hospital-MAC-Mexicali",
                    "activo": true,
                    "hospital": "MAC Mexicali",
                    "mnemonicos": "MEX",
                    "num_asociado": "0",
                    "email_calidad": ""
                },
                {
                    "id": "13",
                    "calendar": "Hospital-MAC-Tampico",
                    "activo": true,
                    "hospital": "MAC Tampico",
                    "mnemonicos": "TMP",
                    "num_asociado": "34",
                    "email_calidad": ""
                },
                {
                    "id": "14",
                    "hospital": "MediMAC Tlalnepantla",
                    "num_asociado": "0",
                    "mnemonicos": "TLA",
                    "activo": true,
                    "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                    "identificador": "warrEGfxJRVLrzcRZNsR",
                    "calendar": "Hospital-MAC-Tlalnepantla"
                }
            ]

            // unidadesReporte = [
            //     {
            //         "id": "999",
            //         "calendar": "Hospital-MAC-Integración Procesos Clínicos",
            //         "activo": true,
            //         "hospital": "MAC Integración Procesos Clínicos",
            //         "mnemonicos": "IPC",
            //         "num_asociado": "0",
            //         "email_calidad": ""
            //     },
            // ]

            let reporteFecha = [{
                fecha : "UNIDAD",
                solicitadas : "",
                agendadas : "",
                realizadas : "",
                cancelados : "",
                totales : ""
            }]

            let totalSolicitadas = 0
            let totalAgendadas = 0
            let totalRealizadas = 0
            let totalCancelados = 0

            for (let i = 0; i < unidadesReporte.length; i++){
                let solicitadas = 0
                let agendadas = 0
                let realizadas = 0
                let canceladas = 0

                for(let j = 0;j<pendientesUnidad.length;j++){
                    if(pendientesUnidad[j].UnidadHospitalaria === unidadesReporte[i].calendar){
                        solicitadas = solicitadas + 1
                        totalSolicitadas = totalSolicitadas + 1
                    }
                }

                for(let j = 0;j<agendadosUnidad.length;j++){
                    if(agendadosUnidad[j].UnidadHospitalaria === unidadesReporte[i].calendar){
                        agendadas = agendadas + 1
                        totalAgendadas = totalAgendadas + 1
                    }
                }

                for(let j = 0;j<realizadosUnidad.length;j++){
                    if(realizadosUnidad[j].UnidadHospitalaria === unidadesReporte[i].calendar){
                        realizadas = realizadas + 1
                        totalRealizadas = totalRealizadas + 1
                    }
                }

                for(let j = 0;j<canceladosUnidad.length;j++){
                    if(canceladosUnidad[j].UnidadHospitalaria === unidadesReporte[i].calendar){
                        canceladas = canceladas + 1
                        totalCancelados = totalCancelados + 1
                    }
                }

                reporteFecha.push({
                    fecha : unidadesReporte[i].calendar,
                    solicitadas : solicitadas,
                    agendadas : agendadas,
                    realizadas : realizadas,
                    canceladas : canceladas,
                    totales : solicitadas + agendadas + realizadas + canceladas
                })
            }
            reporteFecha.push({
                fecha : "",
                solicitadas : "",
                agendadas : "",
                realizadas : "",
                canceladas : "Total",
                totales : totalSolicitadas + totalAgendadas + totalRealizadas + totalCancelados
            })
            //console.log(reporteFecha)
            setIndicadoresList(reporteFecha)
            setIndicadorUnidad(false)
        }
    },[initialDay,finalDay,pendientesUnidad,agendadosUnidad,realizadosUnidad,canceladosUnidad,nomHospital])

    // useEffect(()=>{
    //     let unidadesReport = [
    //         {
    //             unidad : "Hospital-HMAS-Los Cabos",
    //             hospitalNameOne : "HMAS Los Cabos",
    //             hospitalNameTwo : "Los Cabos",
    //             eventosPendientes : [],
    //             totalEventosPendientes : 0,
    //             eventosAgendados : [],
    //             totalEventosAgendados : 0,
    //             eventosRealizadosConfirmados : [],
    //             totalEventosRealizadosConfirmados : 0,
    //             eventosRealizadosUrgencia : [],
    //             totalEventosRealizadosUrgencia : 0,
    //             eventosRealizadosSinConfirmacion : [],
    //             totalEventosRealizadosSinConfirmacion : 0,
    //             eventosCancelados : [],
    //             totalEventosCancelados : 0,
    //             totalEventosRealizados : 0
    //         },
    //         {
    //             unidad : "Hospital-MAC-Celaya",
    //             hospitalNameOne : "MAC Celaya",
    //             hospitalNameTwo : "Celaya",
    //             eventosPendientes : [],
    //             totalEventosPendientes : 0,
    //             eventosAgendados : [],
    //             totalEventosAgendados : 0,
    //             eventosRealizadosConfirmados : [],
    //             totalEventosRealizadosConfirmados : 0,
    //             eventosRealizadosUrgencia : [],
    //             totalEventosRealizadosUrgencia : 0,
    //             eventosRealizadosSinConfirmacion : [],
    //             totalEventosRealizadosSinConfirmacion : 0,
    //             eventosCancelados : [],
    //             totalEventosCancelados : 0,
    //             totalEventosRealizados : 0
    //         }
    //     ]

    //     let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    //     let dayFinal =  new Date(moment(finalDay).format('MM/DD/YYYY'))

    //    for(let i=0; i<unidadesReport.length;i++){
    //         let eventosAgendados = []
    //         let eventosPendientes = []
    //         let eventosRealizadosConfirmados = []
    //         let eventosRealizadosUrgencia = []
    //         let eventosRealizadosSinConfirmacion = []
    //         let eventosCancelados = []

    //         events.forEach(
    //             event =>{
    //                 let dayEvento = null
    //                 let timeEvento = null
    //                 if(event.start.dateTime){
    //                     dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
    //                     timeEvento = event.start.dateTime
    //                 }
    //                 else{
    //                     dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
    //                     timeEvento = event.start
    //                 }

    //                 if(Date.parse(dayEvento) >= dayInicial && Date.parse(dayFinal) >= dayEvento){
    //                     let description = event.description.toString().split('|')
    //                     let unidadEvento = description[8].split('=')[1]
    //                     if(unidadesReport[i].unidad === unidadEvento || unidadesReport[i].hospitalNameOne === unidadEvento|| unidadesReport[i].hospitalNameTwo === unidadEvento){
                            
    //                         let diferencia = moment(timeEvento).diff(moment().subtract(2, 'hours'),"minutes")
    //                         if(diferencia > 0){//por realizarse
    //                             if(event.summary === "Confirmada"){
    //                                 eventosAgendados.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía pendiente confirmada","Row") })
    //                             }
    //                             else{
    //                                 eventosPendientes.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía pendiente sin confirmación","Row") })
    //                             }
    //                         }
    //                         else{//realizados
    //                             if(event.summary === "Confirmada"){
    //                                 eventosRealizadosConfirmados.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía realizada confirmada","Row") })
    //                             }
    //                             else{
    //                                 let concepto = description[19].split('=')[1]
    //                                 if(concepto === "Cirugía de urgencia"){
    //                                     eventosRealizadosUrgencia.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía realizada de urgencía","Row") })
    //                                 }
    //                                 else{
    //                                     eventosRealizadosSinConfirmacion.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía realizada sin confirmación","Row") })
    //                                 }
    //                             }
    //                         }
    //                     }   
    //                 }
    //             }
    //         )

    //         cancelEvents.forEach(
    //             event => {
    //                 //let dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
    //                 let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    //                 let dayFinal =  new Date(moment(finalDay).format('MM/DD/YYYY'))
    
    //                 let eventoAgendadoElDia = ""
    //                 if(event.afterEvent.start.dateTime){
    //                     eventoAgendadoElDia = event.afterEvent.start.dateTime.split("T")[0]
    //                 }
    //                 else{
    //                     eventoAgendadoElDia = event.afterEvent.start.split("T")[0]
    //                 }
    
    //                 let dayEvento = new Date(moment(eventoAgendadoElDia).format('MM/DD/YYYY'))

    //                 if(Date.parse(dayEvento) >= dayInicial && Date.parse(dayFinal) >= dayEvento){
    //                     if(event.afterEvent){
    //                         let description = event.afterEvent.description.toString().split('|')
    //                         let unidadEvento = description[8].split('=')[1]
    //                         if(unidadesReport[i].unidad === unidadEvento || unidadesReport[i].hospitalNameOne === unidadEvento|| unidadesReport[i].hospitalNameTwo === unidadEvento){
    //                             eventosCancelados.push({ id: event.id, ...getEventObjectResumen(event,"Cirugía cancelada","Row") })
    //                         }
    //                     }
    //                 }
    //             }
    //         )

    //         unidadesReport[i].eventosPendientes = eventosPendientes
    //         unidadesReport[i].totalEventosPendientes = eventosPendientes.length

    //         unidadesReport[i].eventosAgendados = eventosAgendados
    //         unidadesReport[i].totalEventosAgendados = eventosAgendados.length

    //         unidadesReport[i].eventosRealizadosConfirmados = eventosRealizadosConfirmados
    //         unidadesReport[i].totalEventosRealizadosConfirmados = eventosRealizadosConfirmados.length

    //         unidadesReport[i].eventosRealizadosUrgencia = eventosRealizadosUrgencia
    //         unidadesReport[i].totalEventosRealizadosUrgencia = eventosRealizadosUrgencia.length

    //         unidadesReport[i].eventosRealizadosSinConfirmacion = eventosRealizadosSinConfirmacion
    //         unidadesReport[i].totalEventosRealizadosSinConfirmacion = eventosRealizadosSinConfirmacion.length

    //         unidadesReport[i].eventosCancelados = eventosCancelados
    //         unidadesReport[i].totalEventosCancelados = eventosCancelados.length

    //         unidadesReport[i].totalEventosRealizados = eventosRealizadosConfirmados.length + eventosRealizadosUrgencia.length + eventosRealizadosSinConfirmacion.length
    //    }

    //    console.log(unidadesReport)
    // },[events,cancelEvents,finalDay,initialDay])

    useEffect(()=>{
        let eventosTotales = []

        for(let i = 0;i<eventosPendientes.length;i++){
            let horaInicial = parseInt(eventosPendientes[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosPendientes[i].start.split("T")[1].split(":")[1])
            eventosTotales.push({
                "id": eventosPendientes[i].id,
                "Estatus": eventosPendientes[i].Estatus,
                "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                "Sala": eventosPendientes[i].Sala,
                "Medico": eventosPendientes[i].Medico,
                "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                "Paciente": eventosPendientes[i].Paciente,
                "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                "Edad": eventosPendientes[i].Edad,
                "Sexo": eventosPendientes[i].Sexo,
                "TipoCliente": eventosPendientes[i].TipoCliente,
                "Diagnostico": eventosPendientes[i].Diagnostico,
                "Procedimiento": eventosPendientes[i].Procedimiento,
                "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                "Anestesiologo": eventosPendientes[i].Anestesiologo,
                "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                "Fecha": eventosPendientes[i].Fecha,
                "HoraInicio": eventosPendientes[i].HoraInicio,
                "HoraFin": eventosPendientes[i].HoraFin,
                "Duracion": eventosPendientes[i].Duracion,
                "Materiales": eventosPendientes[i].Materiales,
                "Extras": eventosPendientes[i].Extras,
                "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                "Realizo": eventosPendientes[i].Realizo,
                "start": eventosPendientes[i].start,
                "end": eventosPendientes[i].end,
                "created": eventosPendientes[i].created,
                "type": eventosPendientes[i].type,
                "id_evento": eventosPendientes[i].id_evento,
                "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })
            let isInicioCero = 0
            if(minutoInicial === 0){
                eventosTotales.push({
                    "id": eventosPendientes[i].id,
                    "Estatus": eventosPendientes[i].Estatus,
                    "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                    "Sala": eventosPendientes[i].Sala,
                    "Medico": eventosPendientes[i].Medico,
                    "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                    "Paciente": eventosPendientes[i].Paciente,
                    "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                    "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                    "Edad": eventosPendientes[i].Edad,
                    "Sexo": eventosPendientes[i].Sexo,
                    "TipoCliente": eventosPendientes[i].TipoCliente,
                    "Diagnostico": eventosPendientes[i].Diagnostico,
                    "Procedimiento": eventosPendientes[i].Procedimiento,
                    "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                    "Anestesiologo": eventosPendientes[i].Anestesiologo,
                    "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                    "Fecha": eventosPendientes[i].Fecha,
                    "HoraInicio": eventosPendientes[i].HoraInicio,
                    "HoraFin": eventosPendientes[i].HoraFin,
                    "Duracion": eventosPendientes[i].Duracion,
                    "Materiales": eventosPendientes[i].Materiales,
                    "Extras": eventosPendientes[i].Extras,
                    "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                    "Realizo": eventosPendientes[i].Realizo,
                    "start": eventosPendientes[i].start,
                    "end": eventosPendientes[i].end,
                    "created": eventosPendientes[i].created,
                    "type": eventosPendientes[i].type,
                    "id_evento": eventosPendientes[i].id_evento,
                    "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "PendienteInicio",
                    "IsPreparacion" : false
                })
                isInicioCero = 1
            }
            let horaFinal = parseInt(eventosPendientes[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosPendientes[i].end.split("T")[1].split(":")[1])

            let cantidadCasillas = 0
            cantidadCasillas = (horaFinal - horaInicial)
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2
            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1 + isInicioCero
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Pendiente"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "PendienteInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "PendienteFin"
                    }
                    else{
                        clase = "Pendiente"
                    }
                }

                eventosTotales.push({
                    "id": eventosPendientes[i].id,
                    "Estatus": eventosPendientes[i].Estatus,
                    "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                    "Sala": eventosPendientes[i].Sala,
                    "Medico": eventosPendientes[i].Medico,
                    "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                    "Paciente": eventosPendientes[i].Paciente,
                    "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                    "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                    "Edad": eventosPendientes[i].Edad,
                    "Sexo": eventosPendientes[i].Sexo,
                    "TipoCliente": eventosPendientes[i].TipoCliente,
                    "Diagnostico": eventosPendientes[i].Diagnostico,
                    "Procedimiento": eventosPendientes[i].Procedimiento,
                    "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                    "Anestesiologo": eventosPendientes[i].Anestesiologo,
                    "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                    "Fecha": eventosPendientes[i].Fecha,
                    "HoraInicio": eventosPendientes[i].HoraInicio,
                    "HoraFin": eventosPendientes[i].HoraFin,
                    "Duracion": eventosPendientes[i].Duracion,
                    "Materiales": eventosPendientes[i].Materiales,
                    "Extras": eventosPendientes[i].Extras,
                    "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                    "Realizo": eventosPendientes[i].Realizo,
                    "start": eventosPendientes[i].start,
                    "end": eventosPendientes[i].end,
                    "created": eventosPendientes[i].created,
                    "type": eventosPendientes[i].type,
                    "id_evento": eventosPendientes[i].id_evento,
                    "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsPendiente" : true,
                    "isMedia" : isMedia
                })

                casilla = casilla + 1
                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "PendienteInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "PendienteFin"
                        }
                        else{
                            clase = "Pendiente"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsPendiente" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Pendiente"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "PendienteInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "PendienteFin"
                        }
                        else{
                            clase = "Pendiente"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsPendiente" : true,
                        "isMedia" : isMedia
                    })
    
                    casilla = casilla + 1
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            clase = "PendienteInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "PendienteFin"
                            }
                            else{
                                clase = "Pendiente"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosPendientes[i].id,
                            "Estatus": eventosPendientes[i].Estatus,
                            "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                            "Sala": eventosPendientes[i].Sala,
                            "Medico": eventosPendientes[i].Medico,
                            "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                            "Paciente": eventosPendientes[i].Paciente,
                            "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                            "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                            "Edad": eventosPendientes[i].Edad,
                            "Sexo": eventosPendientes[i].Sexo,
                            "TipoCliente": eventosPendientes[i].TipoCliente,
                            "Diagnostico": eventosPendientes[i].Diagnostico,
                            "Procedimiento": eventosPendientes[i].Procedimiento,
                            "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                            "Anestesiologo": eventosPendientes[i].Anestesiologo,
                            "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                            "Fecha": eventosPendientes[i].Fecha,
                            "HoraInicio": eventosPendientes[i].HoraInicio,
                            "HoraFin": eventosPendientes[i].HoraFin,
                            "Duracion": eventosPendientes[i].Duracion,
                            "Materiales": eventosPendientes[i].Materiales,
                            "Extras": eventosPendientes[i].Extras,
                            "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                            "Realizo": eventosPendientes[i].Realizo,
                            "start": eventosPendientes[i].start,
                            "end": eventosPendientes[i].end,
                            "created": eventosPendientes[i].created,
                            "type": eventosPendientes[i].type,
                            "id_evento": eventosPendientes[i].id_evento,
                            "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsPendiente" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                //La fecha Final es mayor...
                if(finalDay){
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
                
             }
            
             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if(fechaBusqueda === eventosPendientes[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosPendientes[i].id,
                        "Estatus": eventosPendientes[i].Estatus,
                        "UnidadHospitalaria": eventosPendientes[i].UnidadHospitalaria,
                        "Sala": eventosPendientes[i].Sala,
                        "Medico": eventosPendientes[i].Medico,
                        "TelefonoMedico": eventosPendientes[i].TelefonoMedico,
                        "Paciente": eventosPendientes[i].Paciente,
                        "TelefonoPaciente": eventosPendientes[i].TelefonoPaciente,
                        "FechaNacimiento": eventosPendientes[i].FechaNacimiento,
                        "Edad": eventosPendientes[i].Edad,
                        "Sexo": eventosPendientes[i].Sexo,
                        "TipoCliente": eventosPendientes[i].TipoCliente,
                        "Diagnostico": eventosPendientes[i].Diagnostico,
                        "Procedimiento": eventosPendientes[i].Procedimiento,
                        "LadoProcedimiento": eventosPendientes[i].LadoProcedimiento,
                        "Anestesiologo": eventosPendientes[i].Anestesiologo,
                        "TipoAnestesia": eventosPendientes[i].TipoAnestesia,
                        "Fecha": eventosPendientes[i].Fecha,
                        "HoraInicio": eventosPendientes[i].HoraInicio,
                        "HoraFin": eventosPendientes[i].HoraFin,
                        "Duracion": eventosPendientes[i].Duracion,
                        "Materiales": eventosPendientes[i].Materiales,
                        "Extras": eventosPendientes[i].Extras,
                        "UltimaSolicitud": eventosPendientes[i].UltimaSolicitud,
                        "Realizo": eventosPendientes[i].Realizo,
                        "start": eventosPendientes[i].start,
                        "end": eventosPendientes[i].end,
                        "created": eventosPendientes[i].created,
                        "type": eventosPendientes[i].type,
                        "id_evento": eventosPendientes[i].id_evento,
                        "TipoReasignacion": eventosPendientes[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
             }
        }

        for(let i = 0;i<eventosConfirmados.length;i++){
            let horaInicial = parseInt(eventosConfirmados[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosConfirmados[i].start.split("T")[1].split(":")[1])
            eventosTotales.push({
                "id": eventosConfirmados[i].id,
                "Estatus": eventosConfirmados[i].Estatus,
                "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                "Sala": eventosConfirmados[i].Sala,
                "Medico": eventosConfirmados[i].Medico,
                "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                "Paciente": eventosConfirmados[i].Paciente,
                "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                "Edad": eventosConfirmados[i].Edad,
                "Sexo": eventosConfirmados[i].Sexo,
                "TipoCliente": eventosConfirmados[i].TipoCliente,
                "Diagnostico": eventosConfirmados[i].Diagnostico,
                "Procedimiento": eventosConfirmados[i].Procedimiento,
                "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                "Fecha": eventosConfirmados[i].Fecha,
                "HoraInicio": eventosConfirmados[i].HoraInicio,
                "HoraFin": eventosConfirmados[i].HoraFin,
                "Duracion": eventosConfirmados[i].Duracion,
                "Materiales": eventosConfirmados[i].Materiales,
                "Extras": eventosConfirmados[i].Extras,
                "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                "Realizo": eventosConfirmados[i].Realizo,
                "start": eventosConfirmados[i].start,
                "end": eventosConfirmados[i].end,
                "created": eventosConfirmados[i].created,
                "type": eventosConfirmados[i].type,
                "id_evento": eventosConfirmados[i].id_evento,
                "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })

            let isInicioCero = 0
            if(minutoInicial === 0){
                eventosTotales.push({
                    "id": eventosConfirmados[i].id,
                    "Estatus": eventosConfirmados[i].Estatus,
                    "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                    "Sala": eventosConfirmados[i].Sala,
                    "Medico": eventosConfirmados[i].Medico,
                    "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                    "Paciente": eventosConfirmados[i].Paciente,
                    "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                    "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                    "Edad": eventosConfirmados[i].Edad,
                    "Sexo": eventosConfirmados[i].Sexo,
                    "TipoCliente": eventosConfirmados[i].TipoCliente,
                    "Diagnostico": eventosConfirmados[i].Diagnostico,
                    "Procedimiento": eventosConfirmados[i].Procedimiento,
                    "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                    "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                    "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                    "Fecha": eventosConfirmados[i].Fecha,
                    "HoraInicio": eventosConfirmados[i].HoraInicio,
                    "HoraFin": eventosConfirmados[i].HoraFin,
                    "Duracion": eventosConfirmados[i].Duracion,
                    "Materiales": eventosConfirmados[i].Materiales,
                    "Extras": eventosConfirmados[i].Extras,
                    "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                    "Realizo": eventosConfirmados[i].Realizo,
                    "start": eventosConfirmados[i].start,
                    "end": eventosConfirmados[i].end,
                    "created": eventosConfirmados[i].created,
                    "type": eventosConfirmados[i].type,
                    "id_evento": eventosConfirmados[i].id_evento,
                    "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "ConfirmadosInicio",
                    "IsPreparacion" : false
                })
                isInicioCero = 1
            }
            let horaFinal = parseInt(eventosConfirmados[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosConfirmados[i].end.split("T")[1].split(":")[1])
            

            let cantidadCasillas = 0
            cantidadCasillas = (horaFinal - horaInicial)
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2

            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1 + isInicioCero
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Confirmados"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "ConfirmadosInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "ConfirmadosFin"
                    }
                    else{
                        clase = "Confirmados"
                    }
                }



                eventosTotales.push({
                    "id": eventosConfirmados[i].id,
                    "Estatus": eventosConfirmados[i].Estatus,
                    "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                    "Sala": eventosConfirmados[i].Sala,
                    "Medico": eventosConfirmados[i].Medico,
                    "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                    "Paciente": eventosConfirmados[i].Paciente,
                    "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                    "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                    "Edad": eventosConfirmados[i].Edad,
                    "Sexo": eventosConfirmados[i].Sexo,
                    "TipoCliente": eventosConfirmados[i].TipoCliente,
                    "Diagnostico": eventosConfirmados[i].Diagnostico,
                    "Procedimiento": eventosConfirmados[i].Procedimiento,
                    "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                    "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                    "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                    "Fecha": eventosConfirmados[i].Fecha,
                    "HoraInicio": eventosConfirmados[i].HoraInicio,
                    "HoraFin": eventosConfirmados[i].HoraFin,
                    "Duracion": eventosConfirmados[i].Duracion,
                    "Materiales": eventosConfirmados[i].Materiales,
                    "Extras": eventosConfirmados[i].Extras,
                    "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                    "Realizo": eventosConfirmados[i].Realizo,
                    "start": eventosConfirmados[i].start,
                    "end": eventosConfirmados[i].end,
                    "created": eventosConfirmados[i].created,
                    "type": eventosConfirmados[i].type,
                    "id_evento": eventosConfirmados[i].id_evento,
                    "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsConfirmado" : true,
                    "isMedia" : isMedia
                })
                casilla = casilla + 1

                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "ConfirmadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "ConfirmadosFin"
                        }
                        else{
                            clase = "Confirmados"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsConfirmado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Confirmados"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "ConfirmadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "ConfirmadosFin"
                        }
                        else{
                            clase = "Confirmados"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsConfirmado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
    
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            clase = "ConfirmadosInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "ConfirmadosFin"
                            }
                            else{
                                clase = "Confirmados"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosConfirmados[i].id,
                            "Estatus": eventosConfirmados[i].Estatus,
                            "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                            "Sala": eventosConfirmados[i].Sala,
                            "Medico": eventosConfirmados[i].Medico,
                            "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                            "Paciente": eventosConfirmados[i].Paciente,
                            "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                            "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                            "Edad": eventosConfirmados[i].Edad,
                            "Sexo": eventosConfirmados[i].Sexo,
                            "TipoCliente": eventosConfirmados[i].TipoCliente,
                            "Diagnostico": eventosConfirmados[i].Diagnostico,
                            "Procedimiento": eventosConfirmados[i].Procedimiento,
                            "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                            "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                            "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                            "Fecha": eventosConfirmados[i].Fecha,
                            "HoraInicio": eventosConfirmados[i].HoraInicio,
                            "HoraFin": eventosConfirmados[i].HoraFin,
                            "Duracion": eventosConfirmados[i].Duracion,
                            "Materiales": eventosConfirmados[i].Materiales,
                            "Extras": eventosConfirmados[i].Extras,
                            "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                            "Realizo": eventosConfirmados[i].Realizo,
                            "start": eventosConfirmados[i].start,
                            "end": eventosConfirmados[i].end,
                            "created": eventosConfirmados[i].created,
                            "type": eventosConfirmados[i].type,
                            "id_evento": eventosConfirmados[i].id_evento,
                            "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsConfirmado" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                //La fecha Final es mayor...
                if(finalDay){
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
                
             }

             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if(fechaBusqueda === eventosConfirmados[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosConfirmados[i].id,
                        "Estatus": eventosConfirmados[i].Estatus,
                        "UnidadHospitalaria": eventosConfirmados[i].UnidadHospitalaria,
                        "Sala": eventosConfirmados[i].Sala,
                        "Medico": eventosConfirmados[i].Medico,
                        "TelefonoMedico": eventosConfirmados[i].TelefonoMedico,
                        "Paciente": eventosConfirmados[i].Paciente,
                        "TelefonoPaciente": eventosConfirmados[i].TelefonoPaciente,
                        "FechaNacimiento": eventosConfirmados[i].FechaNacimiento,
                        "Edad": eventosConfirmados[i].Edad,
                        "Sexo": eventosConfirmados[i].Sexo,
                        "TipoCliente": eventosConfirmados[i].TipoCliente,
                        "Diagnostico": eventosConfirmados[i].Diagnostico,
                        "Procedimiento": eventosConfirmados[i].Procedimiento,
                        "LadoProcedimiento": eventosConfirmados[i].LadoProcedimiento,
                        "Anestesiologo": eventosConfirmados[i].Anestesiologo,
                        "TipoAnestesia": eventosConfirmados[i].TipoAnestesia,
                        "Fecha": eventosConfirmados[i].Fecha,
                        "HoraInicio": eventosConfirmados[i].HoraInicio,
                        "HoraFin": eventosConfirmados[i].HoraFin,
                        "Duracion": eventosConfirmados[i].Duracion,
                        "Materiales": eventosConfirmados[i].Materiales,
                        "Extras": eventosConfirmados[i].Extras,
                        "UltimaSolicitud": eventosConfirmados[i].UltimaSolicitud,
                        "Realizo": eventosConfirmados[i].Realizo,
                        "start": eventosConfirmados[i].start,
                        "end": eventosConfirmados[i].end,
                        "created": eventosConfirmados[i].created,
                        "type": eventosConfirmados[i].type,
                        "id_evento": eventosConfirmados[i].id_evento,
                        "TipoReasignacion": eventosConfirmados[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
            }
        }

        for(let i = 0;i<eventosHistoricos.length;i++){
            let horaInicial = parseInt(eventosHistoricos[i].start.split("T")[1].split(":")[0])
            let minutoInicial = parseInt(eventosHistoricos[i].start.split("T")[1].split(":")[1])

            eventosTotales.push({
                "id": eventosHistoricos[i].id,
                "Estatus": eventosHistoricos[i].Estatus,
                "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                "Sala": eventosHistoricos[i].Sala,
                "Medico": eventosHistoricos[i].Medico,
                "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                "Paciente": eventosHistoricos[i].Paciente,
                "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                "Edad": eventosHistoricos[i].Edad,
                "Sexo": eventosHistoricos[i].Sexo,
                "TipoCliente": eventosHistoricos[i].TipoCliente,
                "Diagnostico": eventosHistoricos[i].Diagnostico,
                "Procedimiento": eventosHistoricos[i].Procedimiento,
                "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                "Fecha": eventosHistoricos[i].Fecha,
                "HoraInicio": eventosHistoricos[i].HoraInicio,
                "HoraFin": eventosHistoricos[i].HoraFin,
                "Duracion": eventosHistoricos[i].Duracion,
                "Materiales": eventosHistoricos[i].Materiales,
                "Extras": eventosHistoricos[i].Extras,
                "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                "Realizo": eventosHistoricos[i].Realizo,
                "start": eventosHistoricos[i].start,
                "end": eventosHistoricos[i].end,
                "created": eventosHistoricos[i].created,
                "type": eventosHistoricos[i].type,
                "id_evento": eventosHistoricos[i].id_evento,
                "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                "Hora" : horaInicial.toString().padStart(2,"0") + ":" + minutoInicial.toString().padStart(2,"0"),
                "Class" : "Preparacion",
                "IsPreparacion" : true
            })
            let horaFinal = parseInt(eventosHistoricos[i].end.split("T")[1].split(":")[0])
            let minutoFinal = parseInt(eventosHistoricos[i].end.split("T")[1].split(":")[1])



            let cantidadCasillas = 0
            let rangoInicial = horaInicial
            if(minutoInicial !== 0){
                rangoInicial = rangoInicial + 0.5
            }
            let rangoFinal = horaFinal
            if(minutoFinal !== 0){
                rangoFinal = rangoFinal + 0.5
            }
            cantidadCasillas = (horaFinal - horaInicial)
            //Propuesta
            cantidadCasillas = (rangoFinal - rangoInicial)

            if(horaInicial === 7){
                console.log("Revisar")
                console.log(horaInicial)
                console.log(minutoInicial)
                console.log(horaFinal)
                console.log(minutoFinal)
                console.log("Casillas " + cantidadCasillas)
                console.log("Propuesta " + (rangoFinal - rangoInicial))
            }

            
        
            //nuevo
            if(cantidadCasillas < 0){
                cantidadCasillas = cantidadCasillas + 12
            }
            cantidadCasillas = (cantidadCasillas - 1) * 2
            let numeroCasillaMedia = Math.round((cantidadCasillas) / 2)
            let casilla = 1
            if(cantidadCasillas % 2 > 0){
                cantidadCasillas = cantidadCasillas + 1
            }

            let isInicioCero = 0
            if(minutoInicial === 0){
                
                eventosTotales.push({
                    "id": eventosHistoricos[i].id,
                    "Estatus": eventosHistoricos[i].Estatus,
                    "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                    "Sala": eventosHistoricos[i].Sala,
                    "Medico": eventosHistoricos[i].Medico,
                    "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                    "Paciente": eventosHistoricos[i].Paciente,
                    "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                    "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                    "Edad": eventosHistoricos[i].Edad,
                    "Sexo": eventosHistoricos[i].Sexo,
                    "TipoCliente": eventosHistoricos[i].TipoCliente,
                    "Diagnostico": eventosHistoricos[i].Diagnostico,
                    "Procedimiento": eventosHistoricos[i].Procedimiento,
                    "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                    "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                    "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                    "Fecha": eventosHistoricos[i].Fecha,
                    "HoraInicio": eventosHistoricos[i].HoraInicio,
                    "HoraFin": eventosHistoricos[i].HoraFin,
                    "Duracion": eventosHistoricos[i].Duracion,
                    "Materiales": eventosHistoricos[i].Materiales,
                    "Extras": eventosHistoricos[i].Extras,
                    "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                    "Realizo": eventosHistoricos[i].Realizo,
                    "start": eventosHistoricos[i].start,
                    "end": eventosHistoricos[i].end,
                    "created": eventosHistoricos[i].created,
                    "type": eventosHistoricos[i].type,
                    "id_evento": eventosHistoricos[i].id_evento,
                    "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                    "Hora" : horaInicial.toString().padStart(2,"0") + ":30",
                    "Class" : "RealizadosInicio",
                    "IsRealizado" : false
                })
                isInicioCero = 1
                //revisar
                casilla = casilla + 1
            }
            for(let j=horaInicial+1;j<horaFinal;j++){
                let isMedia = false
                let clase = "Realizados"
                if(casilla === numeroCasillaMedia){
                    isMedia = true

                }
                else{
                    if(cantidadCasillas === 2){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }
                }

                if(casilla === 1){
                    clase = "RealizadosInicio"
                }
                else{
                    if(casilla === cantidadCasillas){
                        clase = "RealizadosFin"
                    }
                    else{
                        clase = "Realizados"
                    }
                }

                eventosTotales.push({
                    "id": eventosHistoricos[i].id,
                    "Estatus": eventosHistoricos[i].Estatus,
                    "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                    "Sala": eventosHistoricos[i].Sala,
                    "Medico": eventosHistoricos[i].Medico,
                    "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                    "Paciente": eventosHistoricos[i].Paciente,
                    "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                    "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                    "Edad": eventosHistoricos[i].Edad,
                    "Sexo": eventosHistoricos[i].Sexo,
                    "TipoCliente": eventosHistoricos[i].TipoCliente,
                    "Diagnostico": eventosHistoricos[i].Diagnostico,
                    "Procedimiento": eventosHistoricos[i].Procedimiento,
                    "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                    "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                    "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                    "Fecha": eventosHistoricos[i].Fecha,
                    "HoraInicio": eventosHistoricos[i].HoraInicio,
                    "HoraFin": eventosHistoricos[i].HoraFin,
                    "Duracion": eventosHistoricos[i].Duracion,
                    "Materiales": eventosHistoricos[i].Materiales,
                    "Extras": eventosHistoricos[i].Extras,
                    "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                    "Realizo": eventosHistoricos[i].Realizo,
                    "start": eventosHistoricos[i].start,
                    "end": eventosHistoricos[i].end,
                    "created": eventosHistoricos[i].created,
                    "type": eventosHistoricos[i].type,
                    "id_evento": eventosHistoricos[i].id_evento,
                    "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                    "Hora" : j.toString().padStart(2,"0") + ":00",
                    "Class" : clase,
                    "IsRealizado" : true,
                    "isMedia" : isMedia
                })
                casilla = casilla + 1

                if(minutoFinal === 30 || j!== (horaFinal - 1)){
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
                    }
                    else{
                        isMedia = false
                    }

                    if(casilla === 1){
                        clase = "RealizadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "RealizadosFin"
                        }
                        else{
                            clase = "Realizados"
                        }
                    }

                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":30",
                        "Class" : clase,
                        "IsRealizado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
                }
            }

            //nuevo
            if((horaFinal - horaInicial) < 0){
                for(let j=horaInicial+1;j<24;j++){
                    let isMedia = false
                    let clase = "Realizados"
                    if(casilla === numeroCasillaMedia){
                        isMedia = true
    
                    }
                    else{
                        isMedia = false
                    }
    
                    if(casilla === 1){
                        clase = "RealizadosInicio"
                    }
                    else{
                        if(casilla === cantidadCasillas){
                            clase = "RealizadosFin"
                        }
                        else{
                            clase = "Realizados"
                        }
                    }
    
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : j.toString().padStart(2,"0") + ":00",
                        "Class" : clase,
                        "IsRealizado" : true,
                        "isMedia" : isMedia
                    })
                    casilla = casilla + 1
    
                    if(minutoFinal === 30 || j!== (horaFinal - 1)){
                        if(casilla === numeroCasillaMedia){
                            isMedia = true
                        }
                        else{
                            isMedia = false
                        }
    
                        if(casilla === 1){
                            console.log("A")
                            clase = "RealizadosInicio"
                        }
                        else{
                            if(casilla === cantidadCasillas){
                                clase = "RealizadosFin"
                            }
                            else{
                                clase = "Realizados"
                            }
                        }
    
                        eventosTotales.push({
                            "id": eventosHistoricos[i].id,
                            "Estatus": eventosHistoricos[i].Estatus,
                            "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                            "Sala": eventosHistoricos[i].Sala,
                            "Medico": eventosHistoricos[i].Medico,
                            "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                            "Paciente": eventosHistoricos[i].Paciente,
                            "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                            "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                            "Edad": eventosHistoricos[i].Edad,
                            "Sexo": eventosHistoricos[i].Sexo,
                            "TipoCliente": eventosHistoricos[i].TipoCliente,
                            "Diagnostico": eventosHistoricos[i].Diagnostico,
                            "Procedimiento": eventosHistoricos[i].Procedimiento,
                            "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                            "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                            "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                            "Fecha": eventosHistoricos[i].Fecha,
                            "HoraInicio": eventosHistoricos[i].HoraInicio,
                            "HoraFin": eventosHistoricos[i].HoraFin,
                            "Duracion": eventosHistoricos[i].Duracion,
                            "Materiales": eventosHistoricos[i].Materiales,
                            "Extras": eventosHistoricos[i].Extras,
                            "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                            "Realizo": eventosHistoricos[i].Realizo,
                            "start": eventosHistoricos[i].start,
                            "end": eventosHistoricos[i].end,
                            "created": eventosHistoricos[i].created,
                            "type": eventosHistoricos[i].type,
                            "id_evento": eventosHistoricos[i].id_evento,
                            "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                            "Hora" : j.toString().padStart(2,"0") + ":30",
                            "Class" : clase,
                            "IsRealizado" : true,
                            "isMedia" : isMedia
                        })
                        casilla = casilla + 1
                    }
                }
            }

            let fecha = new Date()
            if(Date.parse(finalDay) < Date.parse(initialDay)){
                //La fecha final es menor que la inicial
                fecha = new Date(initialDay)
             }else{
                if(finalDay){
                    //La fecha Final es mayor...
                    fecha = new Date(finalDay)
                }
                else{
                    fecha = new Date(initialDay)
                }
             }

             let fechaBusqueda = fecha.getFullYear()+"-"+(fecha.getMonth()+1).toString().padStart(2,"0")+"-"+fecha.getDate().toString().padStart(2,"0")
             if( fechaBusqueda === eventosHistoricos[i].end.split("T")[0]){
                if(minutoFinal === 30){
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : horaFinal.toString().padStart(2,"0") + ":00",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
                else{
                    eventosTotales.push({
                        "id": eventosHistoricos[i].id,
                        "Estatus": eventosHistoricos[i].Estatus,
                        "UnidadHospitalaria": eventosHistoricos[i].UnidadHospitalaria,
                        "Sala": eventosHistoricos[i].Sala,
                        "Medico": eventosHistoricos[i].Medico,
                        "TelefonoMedico": eventosHistoricos[i].TelefonoMedico,
                        "Paciente": eventosHistoricos[i].Paciente,
                        "TelefonoPaciente": eventosHistoricos[i].TelefonoPaciente,
                        "FechaNacimiento": eventosHistoricos[i].FechaNacimiento,
                        "Edad": eventosHistoricos[i].Edad,
                        "Sexo": eventosHistoricos[i].Sexo,
                        "TipoCliente": eventosHistoricos[i].TipoCliente,
                        "Diagnostico": eventosHistoricos[i].Diagnostico,
                        "Procedimiento": eventosHistoricos[i].Procedimiento,
                        "LadoProcedimiento": eventosHistoricos[i].LadoProcedimiento,
                        "Anestesiologo": eventosHistoricos[i].Anestesiologo,
                        "TipoAnestesia": eventosHistoricos[i].TipoAnestesia,
                        "Fecha": eventosHistoricos[i].Fecha,
                        "HoraInicio": eventosHistoricos[i].HoraInicio,
                        "HoraFin": eventosHistoricos[i].HoraFin,
                        "Duracion": eventosHistoricos[i].Duracion,
                        "Materiales": eventosHistoricos[i].Materiales,
                        "Extras": eventosHistoricos[i].Extras,
                        "UltimaSolicitud": eventosHistoricos[i].UltimaSolicitud,
                        "Realizo": eventosHistoricos[i].Realizo,
                        "start": eventosHistoricos[i].start,
                        "end": eventosHistoricos[i].end,
                        "created": eventosHistoricos[i].created,
                        "type": eventosHistoricos[i].type,
                        "id_evento": eventosHistoricos[i].id_evento,
                        "TipoReasignacion": eventosHistoricos[i].TipoReasignacion,
                        "Hora" : (horaFinal-1).toString().padStart(2,"0") + ":30",
                        "Class" : "PreparacionFin",
                        "IsPreparacion" : true
                    })
                }
             }
        }


        let calendarioVista = []
        if(resourcesList){
            if(resourcesList[0]){
                calendarioVista.push(getEncabezadoObject(resourcesList))
                for(let i=0;i<24;i++){
                    let objeto = {}
                    let horaUno = i.toString().padStart(2,"0") + ":00"
                    let horaDos = i.toString().padStart(2,"0") + ":30"

                    let aUno = {}//Sala 1 hora completa
                    let aDos = {}//Sala 1 media hora
                    if(resourcesList[0]){//Sala 1
                        aUno = buscarEvento(horaUno,resourcesList[0].Resource_Name,eventosTotales)
                        aDos = buscarEvento(horaDos,resourcesList[0].Resource_Name,eventosTotales)
                    }

                    let bUno = {}//Sala 2 hora completa
                    let bDos = {}//Sala 2 media hora
                    if(resourcesList[1]){//Sala 2
                        bUno = buscarEvento(horaUno,resourcesList[1].Resource_Name,eventosTotales)
                        bDos = buscarEvento(horaDos,resourcesList[1].Resource_Name,eventosTotales)
                    }

                    let cUno = {}//Sala 3 hora completa
                    let cDos = {}//Sala 3 media hora
                    if(resourcesList[2]){//Sala 3
                        cUno = buscarEvento(horaUno,resourcesList[2].Resource_Name,eventosTotales)
                        cDos = buscarEvento(horaDos,resourcesList[2].Resource_Name,eventosTotales)
                    }

                    let dUno = {}//Sala 4 hora completa
                    let dDos = {}//Sala 4 media hora
                    if(resourcesList[3]){//Sala 4
                        dUno = buscarEvento(horaUno,resourcesList[3].Resource_Name,eventosTotales)
                        dDos = buscarEvento(horaDos,resourcesList[3].Resource_Name,eventosTotales)
                    }

                    let eUno = {}//Sala 5 hora completa
                    let eDos = {}//Sala 5 media hora
                    if(resourcesList[4]){//Sala 5
                        eUno = buscarEvento(horaUno,resourcesList[4].Resource_Name,eventosTotales)
                        eDos = buscarEvento(horaDos,resourcesList[4].Resource_Name,eventosTotales)
                    }

                    let fUno = {}//Sala 6 hora completa
                    let fDos = {}//Sala 6 media hora
                    if(resourcesList[5]){//Sala 6
                        fUno = buscarEvento(horaUno,resourcesList[5].Resource_Name,eventosTotales)
                        fDos = buscarEvento(horaDos,resourcesList[5].Resource_Name,eventosTotales)
                    }

                    let gUno = {}//Sala 7 hora completa
                    let gDos = {}//Sala 7 media hora
                    if(resourcesList[6]){//Sala 7
                        gUno = buscarEvento(horaUno,resourcesList[6].Resource_Name,eventosTotales)
                        gDos = buscarEvento(horaDos,resourcesList[6].Resource_Name,eventosTotales)
                    }

                    let hUno = {}//Sala 8 hora completa
                    let hDos = {}//Sala 8 media hora
                    if(resourcesList[7]){//Sala 8
                        hUno = buscarEvento(horaUno,resourcesList[7].Resource_Name,eventosTotales)
                        hDos = buscarEvento(horaDos,resourcesList[7].Resource_Name,eventosTotales)
                    }

                    let iUno = {}//Sala 9 hora completa
                    let iDos = {}//Sala 9 media hora
                    if(resourcesList[8]){//Sala 9
                        iUno = buscarEvento(horaUno,resourcesList[8].Resource_Name,eventosTotales)
                        iDos = buscarEvento(horaDos,resourcesList[8].Resource_Name,eventosTotales)
                    }

                    let jUno = {}//Sala 10 hora completa
                    let jDos = {}//Sala 10 media hora
                    if(resourcesList[9]){//Sala 10
                        jUno = buscarEvento(horaUno,resourcesList[9].Resource_Name,eventosTotales)
                        jDos = buscarEvento(horaDos,resourcesList[9].Resource_Name,eventosTotales)
                    }

                    let kUno = {}//Sala 11 hora completa
                    let kDos = {}//Sala 11 media hora
                    if(resourcesList[10]){//Sala 11
                        kUno = buscarEvento(horaUno,resourcesList[10].Resource_Name,eventosTotales)
                        kDos = buscarEvento(horaDos,resourcesList[10].Resource_Name,eventosTotales)
                    }

                    let lUno = {}//Sala 12 hora completa
                    let lDos = {}//Sala 12 media hora
                    if(resourcesList[11]){//Sala 12
                        lUno = buscarEvento(horaUno,resourcesList[11].Resource_Name,eventosTotales)
                        lDos = buscarEvento(horaDos,resourcesList[11].Resource_Name,eventosTotales)
                    }

                    let mUno = {}//Sala 13 hora completa
                    let mDos = {}//Sala 13 media hora
                    if(resourcesList[12]){//Sala 13
                        mUno = buscarEvento(horaUno,resourcesList[12].Resource_Name,eventosTotales)
                        mDos = buscarEvento(horaDos,resourcesList[12].Resource_Name,eventosTotales)
                    }

                    let nUno = {}//Sala 14 hora completa
                    let nDos = {}//Sala 14 media hora
                    if(resourcesList[13]){//Sala 14
                        nUno = buscarEvento(horaUno,resourcesList[13].Resource_Name,eventosTotales)
                        nDos = buscarEvento(horaDos,resourcesList[13].Resource_Name,eventosTotales)
                    }

                    let oUno = {}//Sala 15 hora completa
                    let oDos = {}//Sala 15 media hora
                    if(resourcesList[14]){//Sala 15
                        oUno = buscarEvento(horaUno,resourcesList[14].Resource_Name,eventosTotales)
                        oDos = buscarEvento(horaDos,resourcesList[14].Resource_Name,eventosTotales)
                    }

                    let pUno = {}//Sala 16 hora completa
                    let pDos = {}//Sala 16 media hora
                    if(resourcesList[15]){//Sala 16
                        pUno = buscarEvento(horaUno,resourcesList[15].Resource_Name,eventosTotales)
                        pDos = buscarEvento(horaDos,resourcesList[15].Resource_Name,eventosTotales)
                    }

                    let qUno = {}//Sala 17 hora completa
                    let qDos = {}//Sala 17 media hora
                    if(resourcesList[16]){//Sala 17
                        qUno = buscarEvento(horaUno,resourcesList[16].Resource_Name,eventosTotales)
                        qDos = buscarEvento(horaDos,resourcesList[16].Resource_Name,eventosTotales)
                    }

                    let rUno = {}//Sala 18 hora completa
                    let rDos = {}//Sala 18 media hora
                    if(resourcesList[17]){//Sala 18
                        rUno = buscarEvento(horaUno,resourcesList[17].Resource_Name,eventosTotales)
                        rDos = buscarEvento(horaDos,resourcesList[17].Resource_Name,eventosTotales)
                    }

                    let sUno = {}//Sala 19 hora completa
                    let sDos = {}//Sala 19 media hora
                    if(resourcesList[18]){//Sala 19
                        sUno = buscarEvento(horaUno,resourcesList[18].Resource_Name,eventosTotales)
                        sDos = buscarEvento(horaDos,resourcesList[18].Resource_Name,eventosTotales)
                    }

                    let tUno = {}//Sala 20 hora completa
                    let tDos = {}//Sala 20 media hora
                    if(resourcesList[19]){//Sala 20
                        tUno = buscarEvento(horaUno,resourcesList[19].Resource_Name,eventosTotales)
                        tDos = buscarEvento(horaDos,resourcesList[19].Resource_Name,eventosTotales)
                    }

                    objeto = {
                        "Hora": horaUno,
                        "A": aUno,
                        "B": bUno,
                        "C": cUno,
                        "D": dUno,
                        "E": eUno,
                        "F": fUno,
                        "G": gUno,
                        "H": hUno,
                        "I": iUno,
                        "J": jUno,
                        "K": kUno,
                        "L": lUno,
                        "M": mUno,
                        "N": nUno,
                        "O": oUno,
                        "P": pUno,
                        "Q": qUno,
                        "R": rUno,
                        "S": sUno,
                        "T": tUno,
                    }
                    calendarioVista.push(objeto)

                    objeto = {
                        "Hora": horaDos,
                        "A": aDos,
                        "B": bDos,
                        "C": cDos,
                        "D": dDos,
                        "E": eDos,
                        "F": fDos,
                        "G": gDos,
                        "H": hDos,
                        "I": iDos,
                        "J": jDos,
                        "K": kDos,
                        "L": lDos,
                        "M": mDos,
                        "N": nDos,
                        "O": oDos,
                        "P": pDos,
                        "Q": qDos,
                        "R": rDos,
                        "S": sDos,
                        "T": tDos,
                    }
                    calendarioVista.push(objeto)
                }

                setMatrizAgenda(calendarioVista)
            }
            else{
                setMatrizAgenda([])
            }
        }
        else{
            setMatrizAgenda([])
        }


        function getEncabezadoObject(resources){
            let dimension = resources.length
            let objeto = {}
            switch (dimension){
                case 1:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": "",
                        "C": "",
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 2:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": "",
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 3:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": "",
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 4:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": "",
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 5:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": "",
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 6:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": "",
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 7:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": "",
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 8:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": "",
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 9:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": "",
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 10:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": "",
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 11:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": "",
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 12:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": "",
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 13:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": "",
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 14:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": "",
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 15:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": "",
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 16:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": "",
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 17:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": "",
                        "S": "",
                        "T": "",
                    }
                    break
                case 18:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": "",
                        "T": "",
                    }
                    break
                case 19:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": resourcesList[18].Resource_Name,
                        "T": "",
                    }
                    break
                case 20:
                    objeto = {
                        "Hora": "",
                        "A": resourcesList[0].Resource_Name,
                        "B": resourcesList[1].Resource_Name,
                        "C": resourcesList[2].Resource_Name,
                        "D": resourcesList[3].Resource_Name,
                        "E": resourcesList[4].Resource_Name,
                        "F": resourcesList[5].Resource_Name,
                        "G": resourcesList[6].Resource_Name,
                        "H": resourcesList[7].Resource_Name,
                        "I": resourcesList[8].Resource_Name,
                        "J": resourcesList[9].Resource_Name,
                        "K": resourcesList[10].Resource_Name,
                        "L": resourcesList[11].Resource_Name,
                        "M": resourcesList[12].Resource_Name,
                        "N": resourcesList[13].Resource_Name,
                        "O": resourcesList[14].Resource_Name,
                        "P": resourcesList[15].Resource_Name,
                        "Q": resourcesList[16].Resource_Name,
                        "R": resourcesList[17].Resource_Name,
                        "S": resourcesList[18].Resource_Name,
                        "T": resourcesList[19].Resource_Name,
                    }
                    break
                default:
                    objeto = {}
                    break
            }
            return objeto
        }

        function buscarEvento(horaBuscada,sala,eventosTotales){
            let objeto = {
                "id": "0",
                "Class": "Vacio"
            }


            for(let i = 0; i < eventosTotales.length; i++){
                if(eventosTotales[i].Hora.toString().trim() === horaBuscada && eventosTotales[i].Sala.toString().trim() === sala.toString().trim()){
                    let fechaAtratar = new Date()
                    let fechaEvento = eventosTotales[i].Fecha
                    if(finalDay){
                        fechaAtratar = new Date(finalDay)
                    }
                    else{
                        if(initialDay){
                            fechaAtratar = new Date(initialDay)   
                        }
                        else{
                            fechaAtratar = new Date()
                        }
                    }
                    if(fechaAtratar.getFullYear()+"-"+(fechaAtratar.getMonth()+1)+"-"+fechaAtratar.getDate() === fechaEvento.split('-')[0]+"-"+parseInt(fechaEvento.split('-')[1])+"-"+parseInt(fechaEvento.split('-')[2])){
                        objeto = eventosTotales[i]
                    }

                }
            }
            return objeto
        }
    },[eventosHistoricos,eventosPendientes,eventosConfirmados,resourcesList,finalDay,initialDay])

    useEffect(()=>{
        if(buildingList){
            if(buildingList.length > 0){
                if(buildingList[0].Building_Id){
                    setBuildingSelected(buildingList[0].Building_Id)
                }
            }
        }
    },[buildingList])

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica que se haya seleccionado una unidad hospitalaria
            /**
             * Se obtiene la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
             */
            pacientesStore.collection(resourcesCollection).where('Building_Id',"==",buildingSelected)
            .onSnapshot(snap =>{
                const resultList = []
                snap.forEach(doc => {
                    resultList.push({ id: doc.id, ...doc.data() })
                })
                resultList.sort(compararInformación('Resource_Name'))
                setResourcesList(resultList)
                setResourcesSelected("-- Seleccione el quirófano deseado --")
            })
        }
        else{//Se limpia la lista de quirofanos
            setResourcesList([])
        }
    },[buildingSelected])


    //revisar
    useEffect(()=>{
        setEventosHistoricos(realizadosUnidad)
        setEventosConfirmados(agendadosUnidad)
        setEventosPendientes(pendientesUnidad)
        if(isMostrarRealizado){
            let historicos = []
            for(let i=0;i<realizadosUnidad.length;i++){
                historicos.push(realizadosUnidad[i])
            }

            for(let i=0;i<canceladosUnidad.length;i++){

                historicos.push(canceladosUnidad[i])
            }

            setExportedEvenList(historicos)
            setTipoReporte('Histórico')
            setEventosVista(realizadosUnidadEventFormat)
            
        }
        else{
            if(isMostrarEnProceso){
                setExportedEvenList(agendadosUnidad)
                setTipoReporte('Agendadas')
                setEventosVista(agendadoUnidadEventFormat)
                
            }
            else{
                if(isMostrarEnPendiente){
                    setExportedEvenList(pendientesUnidad)
                    setTipoReporte('Pendientes')
                    setEventosVista(pendientesUnidadEventFormat)
                    
                }
                else{
                    setExportedEvenList(generalUnidad)
                    setTipoReporte('General')
                }
            }
        }

        let validos = pendientesUnidad.length + agendadosUnidad.length + realizadosUnidad.length

        if(validos > 0){//Se verifica que se hayan encontrado eventos validos dentor del rango de fechas seleccionados
            //Se asignan las cantidades a mostrar en la sección de encabezado
            setCantidadRealizada(realizadosUnidad.length + canceladosUnidad.length)
            setCantidadProceso(agendadosUnidad.length)
            setCantidadPendiente(pendientesUnidad.length)

            if(isMostrarEnProceso){//Se verifica si la opción seleccionada es En Proceso
                if(cantidadProceso > 0){//Se verifica si hay eventos en proceso
                    //Se indica que hay eventos validos
                    setHadValidateEvents(true)
                }
                else{//Se inidca que no hay eventos validos
                    setHadValidateEvents(false)
                }
            }
            else{
                if(isMostrarEnPendiente){//Se verifica si la opción seleccionada es Pendientes
                    if(pendientesUnidad.length > 0){//Se verifica si hay eventos pendientes
                        //Se indica que hay eventos validos
                        setHadValidateEvents(true)
                    }
                    else{//Se indica que no hay eventos validos
                        setHadValidateEvents(false)
                    }
                }
                else{
                    if(isMostrarRealizado){//Se verifica si la opción seleccionada es Realizados
                        if(realizadosUnidad.length > 0 || cancelados.length > 0){//Se verifica si hay eventos realizados
                            //Se indica que hay eventos validos
                            setHadValidateEvents(true)
                        }
                        else{//Se indica que no hay eventos validos
                            setHadValidateEvents(false)
                        }
                    }
                }
            }
        }
        else{//No se han encontrado eventos validos dentro del rango seleccionado
            setHadValidateEvents(false)
            setCantidadRealizada(cantidadCanceladas)
            setCantidadProceso(0)
            setCantidadPendiente(0)
        }
    },[pendientesUnidad,agendadosUnidad,realizadosUnidad,generalUnidad,agendadoUnidadEventFormat,
        cantidadCanceladas,cantidadProceso,isMostrarEnPendiente,isMostrarEnProceso,isMostrarRealizado,
        pendientesUnidadEventFormat,realizadosUnidadEventFormat,setCantidadPendiente,setCantidadProceso,setCantidadRealizada,canceladosUnidad
    ])

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Si se cuenta con una unidad hospitalaria seleccionada se despliega la lista de quirófanos
            setResourcesClicSelect(true)
        }
    },[resourcesList,buildingSelected])

    useEffect(()=>{
        if(resourcesList){
            let horasOcupadas = []
            events.forEach(
                event => {
                    let dayEvento = null
                    if(event.start.dateTime){
                        dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                    }
                    else{
                        dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
                    }
                    //new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                    let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
                    
                    // let isConfirmed = false
                    // if(event.summary === "Confirmada"){
                    //     isConfirmed = true
                    // }
                    if(dayEvento.getFullYear() + "-" + dayEvento.getMonth() + "-" + dayEvento.getDate() === dayInicial.getFullYear() + "-" + dayInicial.getMonth() + "-" + dayInicial.getDate()){
                        //let tiempoInicial = event.start.dateTime.toString().split('T')
                        //let horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        let tiempoInicial = ""
                        let horaInicial = ""
                        if(event.start.dateTime){
                            tiempoInicial = event.start.dateTime.toString().split('T')
                            horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        }
                        else{
                            tiempoInicial = event.start.toString().split('T')
                            horaInicial = parseInt(tiempoInicial[1].split(':')[0])
                        }

                        //let tiempoFinal = event.end.dateTime.toString().split('T')
                        //let horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        let tiempoFinal = ""
                        let horaFinal = ""

                        if( event.end.dateTime){
                            tiempoFinal = event.end.dateTime.toString().split('T')
                            horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        }
                        else{
                            tiempoFinal = event.end.toString().split('T')
                            horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                        }

                        let description = event.description.toString().split('|')
                        let AppointmentType = ""
                        if(description.length > 1){
                            AppointmentType = description[0].split('=')[1]
                        }
                        else{
                            AppointmentType = description[0]
                        }

                        let horaReservadaObject = {
                            'Recurso' : AppointmentType,
                            'Hora' : horaInicial,
                            'Identificador':"Inicio",
                            'id':event.id,
                            'medico':description[1].split('=')[1]
                        }
                        horasOcupadas.push(horaReservadaObject)
                        for(let i=horaInicial + 1;i<horaFinal;i++){
                            let horaReservadaObject = {
                                'Recurso' : AppointmentType,
                                'Hora' : i,
                                'Identificador':"Intermedio",
                                'id':event.id,
                                'medico':description[1].split('=')[1]
                            }
                            horasOcupadas.push(horaReservadaObject)
                        }
                    }
                }
            )

            let agendaArray = []
            for(let i=0;i<resourcesList.length;i++){
                let cadenaGeneral = ""
                let id = 0
                let medico=""
                cadenaGeneral += resourcesList[i].Resource_Name
                for(let j=0;j<24;j++){
                    let encontrada = false
                    for(let h=0;h<horasOcupadas.length;h++){
                        if(resourcesList[i].Resource_Name === horasOcupadas[h].Recurso && parseInt(j) === parseInt(horasOcupadas[h].Hora)){
                            encontrada = true
                            id = horasOcupadas[h].id
                            medico = horasOcupadas[h].medico
                        }
                    }

                    if(encontrada){
                        cadenaGeneral += "°"+medico+"|" + id
                    }
                    else{
                        cadenaGeneral += "°Libre"
                    }
                }
                let reserveObject = {
                    'Sala' : cadenaGeneral.split('°')[0],
                    'h0' : cadenaGeneral.split('°')[1],
                    'h1' : cadenaGeneral.split('°')[2],
                    'h2' : cadenaGeneral.split('°')[3],
                    'h3' : cadenaGeneral.split('°')[4],
                    'h4' : cadenaGeneral.split('°')[5],
                    'h5' : cadenaGeneral.split('°')[6],
                    'h6' : cadenaGeneral.split('°')[7],
                    'h7' : cadenaGeneral.split('°')[8],
                    'h8' : cadenaGeneral.split('°')[9],
                    'h9' : cadenaGeneral.split('°')[10],
                    'h10' : cadenaGeneral.split('°')[11],
                    'h11' : cadenaGeneral.split('°')[12],
                    'h12' : cadenaGeneral.split('°')[13],
                    'h13' : cadenaGeneral.split('°')[14],
                    'h14' : cadenaGeneral.split('°')[15],
                    'h15' : cadenaGeneral.split('°')[16],
                    'h16' : cadenaGeneral.split('°')[17],
                    'h17' : cadenaGeneral.split('°')[18],
                    'h18' : cadenaGeneral.split('°')[19],
                    'h19' : cadenaGeneral.split('°')[20],
                    'h20' : cadenaGeneral.split('°')[21],
                    'h21' : cadenaGeneral.split('°')[22],
                    'h22' : cadenaGeneral.split('°')[23],
                    'h23' : cadenaGeneral.split('°')[24],
                }
                agendaArray.push(reserveObject)
            }
        }
    },[resourcesList,events,initialDay,eventosHistoricos,eventosConfirmados])

    useEffect(()=>{
        /**
         * Se obtiene la lista de unidades hospitalarias
         */
        pacientesStore.collection(buildingCollection)
        .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                if(userLevel !== "superuser"){
                    let data = doc.data()
                    const resultado = uhList.find( uh => uh.calendar === data.Building_Id );
                    if(resultado){
                        if(nomHospital === resultado.hospital){
                            resultList.push({ id: doc.id, ...doc.data() })
                        }
                    }
                }
                else{
                    resultList.push({ id: doc.id, ...doc.data() })
                }
            })
            resultList.sort(compararInformación('Building_Id'))
            setBuildingList(resultList)
        },(error)=>{ 
            setBuildingList([]) 
            Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
            })
        })
    },[uhList,nomHospital,userLevel])
            
    useEffect(()=>{
        if(vista === "Lista"){
            setIsTarjetasChecked(false)
        }
        else{
            setIsTarjetasChecked(true)
        }
    },[vista])
    
    /**
     * Función que cambio de estado la visualización de la lista de unidades hospitalarias (visible o invisible)
     */
     function mostrarOcultarBuilding(){ 
        if(!clicBuildingSelect){
            setClicBuildingSelect(true)
        } else{
            setClicBuildingSelect(false)
        }   
    }

    /**
     * Función que cambio de estado la visualización de la lista de quirófano disponibles (visible o invisible)
     */
     const mostrarOcultarResources = () => {
        if(!clicResourcesSelect){
            setResourcesClicSelect(true)
        } else{
            setResourcesClicSelect(false)
        }   
    }

    /**
     * Función que atrapa la selección de un genero
     * @param {Event} vista Recibe el evento disparado en la selección
     */
     const onChangeVista = (vista) => {
        setVista(vista.target.value)
    };

    return (
        <>
            <section className='seccion_pacientes_pendientes'>
                <div id="cuerpoEncabezado" className='cuerpoEncabezado'>
                    <TituloSection tituloInicial={tituloInicial} tituloFinal={tituloFinal} cantidad={cantidad} imgSource={imgSource}
                    isMostrarRealizado={isMostrarRealizado} setIsMostrarRealizado={setIsMostrarRealizado}
                    isMostrarEnProceso={isMostrarEnProceso} setIsMostrarEnProceso={setIsMostrarEnProceso}
                    isMostrarEnPendiente={isMostrarEnPendiente}  setIsMostrarEnPendiente={setIsMostrarEnPendiente}
                    cantidadRealizada={cantidadRealizada} cantidadProceso={cantidadProceso} cantidadPendiente={cantidadPendiente}
                    isMostrarQuirofanos={isMostrarQuirofanos} setIsMostrarQuirofanos={setIsMostrarQuirofanos}
                    setIsMostrarUsuarios={setIsMostrarUsuarios} userLevel={userLevel}
                    setIsMostrarEditar={setIsMostrarEditar} exportedEventList={exportedEventList} nomHospital={nomHospital} 
                    isMostrarUsuarios={isMostrarUsuarios} cantidadCanceladas={cantidadCanceladas}
                    setIsMostrarMedicos={setIsMostrarMedicos} isMostrarMedicos={isMostrarMedicos}
                    tipoReporte={tipoReporte} setIsMostrarAgendaVista={setIsMostrarAgendaVista} isMostrarAgendaVista={isMostrarAgendaVista}
                    eventosEditados = {editEventsUnidad} hadEventosEditados={hadEventosEditados} setuserNomHospital={setuserNomHospital}
                    isCorporativo={isCorporativo} 
                    setIsMostrarAgendar={setIsMostrarAgendar} isMostrarAgendar={isMostrarAgendar}
                    setIsMostrarIndicadores={setIsMostrarIndicadores} isMostrarIndicadores={isMostrarIndicadores}
                    />
                </div>
                <div className='itemservespCont'>
                    {
                        (isMostrarEnPendiente || isMostrarEnProceso || isMostrarRealizado) && !isMostrarEditar
                        ?
                            <div className='firstfiltercont'>
                                <div className='ftconfliter'>
                                        <div className="datos">
                                            <input type="radio" value="Tarjetas" name="gender" checked={isTarjetasChecked} onChange={onChangeVista}/> Tarjetas
                                            <input type="radio" value="Lista" name="gender" checked={!isTarjetasChecked} onChange={onChangeVista}/> Listado
                                        </div>
                                </div>
                            </div>
                        :
                            null
                    }
                    {
                        isMostrarEditar
                        ?
                            <EditarEvento eventoSeleccionado={eventoSeleccionado} setIsMostrarEditar={setIsMostrarEditar} 
                            uhList={uhList} nomHospital={nomHospital} userLevel={userLevel} userName={userName} isMostrarEnPendiente={isMostrarEnPendiente}/>
                        :
                        isMostrarUsuarios
                        ?
                            <UsuarioSection uhList={uhList} userLevel={userLevel} nomHospital={nomHospital} hospitalSeleccionado={hospitalSeleccionado}/>
                        :
                        isMostrarEnPendiente || isMostrarEnProceso || isMostrarRealizado
                            ?isLoading 
                                ? <Loader />
                                : hadValidateEvents
                                    ?
                                        isTarjetasChecked
                                            ?
                                                <>
                                                    {
                                                        eventosVista.map(
                                                            event => <EventCard 
                                                                        key={event.id} 
                                                                        event={event} 
                                                                        finalDay={finalDay} 
                                                                        initialDay={initialDay}
                                                                        isMostrarRealizado={isMostrarRealizado}
                                                                        isMostrarEnProceso={isMostrarEnProceso}
                                                                        isMostrarEnPendiente={isMostrarEnPendiente}
                                                                        uhList={uhList}
                                                                        eventoSeleccionado={eventoSeleccionado}
                                                                        setEventoSeleccionado={setEventoSeleccionado}
                                                                        setIsMostrarEditar={setIsMostrarEditar}
                                                                        nomHospital={nomHospital}
                                                                        userLevel={userLevel}
                                                                        userName={userName}
                                                                        isReadOnly={isReadOnly}/>
                                                        )
                                                    }
                                                    {
                                                        isMostrarRealizado
                                                            ?
                                                                cancelados.map(
                                                                    event => <CancelEventCard 
                                                                                key={event.id} 
                                                                                event={event} 
                                                                                finalDay={finalDay} 
                                                                                initialDay={initialDay}
                                                                                isMostrarRealizado={isMostrarRealizado}
                                                                                isMostrarEnProceso={isMostrarEnProceso}
                                                                                isMostrarEnPendiente={isMostrarEnPendiente}
                                                                                uhList={uhList}
                                                                                eventoSeleccionado={eventoSeleccionado}
                                                                                setEventoSeleccionado={setEventoSeleccionado}
                                                                                setIsMostrarEditar={setIsMostrarEditar}
                                                                                nomHospital={nomHospital}
                                                                                userLevel={userLevel}
                                                                                userName={userName}
                                                                                isReadOnly={isReadOnly}
                                                                                events={events} 
                                                                                />
                                                                )
                                                            :
                                                                null
                                                    }
                                                </>
                                            :
                                                isMostrarRealizado
                                                    ?
                                                        <HistoricoTable eventos={realizadosUnidad} uhList={uhList} events={events} 
                                                        setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                        userLevel={userLevel} userName={userName} cancelados={cancelados} setIsMostrarEditar={setIsMostrarEditar}/>
                                                    :
                                                        isMostrarEnPendiente
                                                            ?
                                                                <PendienteTable eventos={pendientesUnidad} uhList={uhList} events={events} 
                                                                setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                                setIsMostrarEditar={setIsMostrarEditar} userName={userName} isMostrarEnPendiente={isMostrarEnPendiente}
                                                                userLevel={userLevel}/>
                                                            :
                                                                <PendienteTable eventos={agendadosUnidad} uhList={uhList} events={events} 
                                                                setEventoSeleccionado={setEventoSeleccionado} eventoSeleccionado={eventoSeleccionado} 
                                                                setIsMostrarEditar={setIsMostrarEditar} userName={userName} 
                                                                isMostrarEnPendiente={isMostrarEnPendiente} userLevel={userLevel}/>

                                    :
                                        <SinEventos />
                            :
                                isMostrarQuirofanos
                                    ?
                                        <div className="reserve_form">
                                            <p className="reserve_title">Administración de Quirófanos</p>
                                            <div className={"content"} id="contenedorBuilding">
                                                <div className="contenedorEncabezado">
                                                    Seleccione la Unidad Hospitalaria
                                                </div>
                                                <div className="contenido">
                                                    <div id="buildingSelected" className="contenedor_item_show" onClick={mostrarOcultarBuilding}>
                                                        {buildingSelected}
                                                    </div>
                                                    {
                                                        clicBuildingSelect
                                                            ?
                                                                <>
                                                                    <span id="buildingDownArrow" className="arrow" onClick={mostrarOcultarBuilding}><AiFillCaretUp/></span>
                                                                    <div>
                                                                        {
                                                                            buildingList.map(
                                                                                build => <BuildingElement key={build.id} 
                                                                                build={build}
                                                                                buildingSelected={buildingSelected}
                                                                                setBuildingSelected={setBuildingSelected}
                                                                                mostrarOcultarBuilding={mostrarOcultarBuilding}/>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                <span id="buildingDownArrow" className="arrow" onClick={mostrarOcultarBuilding}><AiFillCaretDown /></span>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"content"} id="contenedorResources">
                                                <div className="contenedorEncabezado">
                                                    Quirófanos Disponibles
                                                </div>
                                                <div className="contenido">
                                                    {
                                                        clicResourcesSelect
                                                            ?
                                                                <>
                                                                    <div>
                                                                        {
                                                                            resourcesList.map(
                                                                                resource => <ResourceElement key={resource.id}
                                                                                resource={resource}
                                                                                resourcesSelected={resourcesSelected}
                                                                                setResourcesSelected={setResourcesSelected}
                                                                                mostrarOcultarResources={mostrarOcultarResources} isReadOnly={isReadOnly}/>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            :
                                                                <span id="resourcesDownArrow" className="arrow"></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        isMostrarAgendaVista
                                                    ?
                                                        <AgendaVista matrizAgenda={matrizAgenda} setEventoSeleccionado={setEventoSeleccionado} 
                                                        eventoSeleccionado={eventoSeleccionado} events={events} finalDay={finalDay} initialDay={initialDay}/>
                                                    :
                                                        isMostrarAgendar
                                                            ? 
                                                                <RegitrarEvento setIsMostrarRealizado={setIsMostrarRealizado} setIsMostrarAgendar={setIsMostrarAgendar}
                                                                nomHospital={nomHospital} uhList={uhList} userLevel={userLevel} userName={userName}/>
                                                            :
                                                                isMostrarIndicadores
                                                                    ?
                                                                        <IndicadoresTable indicadorUnidad={indicadorUnidad} indicadoresList={indicadoresList} 
                                                                            nomHospital={nomHospital} initialDay={initialDay} finalDay={finalDay}/>
                                                                    :
                                                                        <MedicosSection uhList={uhList} userLevel={userLevel} nomHospital={nomHospital} isTarjetasChecked={isTarjetasChecked} setIsTarjetasChecked={setIsTarjetasChecked} vista={vista} setVista={setVista} isMostrarMedicos={isMostrarMedicos}
                                                                            eventosPendientes={eventosPendientes} eventosConfirmados={eventosConfirmados} events={events} userName={userName} isReadOnly={isReadOnly}/>
                    }
                </div>
            </section>
        </>
    )
}

export default CalendarSection;