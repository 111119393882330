/**
 * Librerias
 */
import React,{useEffect, useState} from "react";
import ExcelJS from 'exceljs';
import { HiSave } from "react-icons/hi";

/**
 * Styles
 */
import '../styles/table.css'


const IndicadoresTable = ({indicadorUnidad,indicadoresList,nomHospital,initialDay,finalDay}) =>{

    const [reportList, setReportList] = useState([])
    const styleActive = { color: "white", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

    useEffect(()=>{
        let report = []

        for(let i=1;i<indicadoresList.length;i++){
            report.push(indicadoresList[i])
        }
        setReportList(report)
    },[indicadoresList])

    const guardarEquipo = () => (event) => {
        event.preventDefault()

        let dayStart = initialDay.getDate().toString().padStart(2,"0") + "-" + (parseInt(initialDay.getMonth()) + 1).toString().padStart(2,"0") + "-" + initialDay.getFullYear()
        let dayEnd = finalDay.getDate().toString().padStart(2,"0") + "-" + (parseInt(finalDay.getMonth()) + 1).toString().padStart(2,"0") + "-" + finalDay.getFullYear()
        

        let encabezado = "FECHA"
        let title = ""

        let unidad = ""
        if(nomHospital === "Corporativo"){
            unidad = "Global"
        }
        else{
            unidad = nomHospital
        }

        if(indicadorUnidad){
            encabezado = "FECHA"
            title = "Cirugías programadas por rango de fecha Detalle"
        }
        else{
            encabezado = "UNIDAD"
            title = "Cirugías programadas por rango de fecha"
        }
    
        let columnsArray = []
        columnsArray.push({
          header: encabezado,
          key: "fecha",
          width: 20,
        })
    
        columnsArray.push({
          header: "Solicitudes",
          key: "solicitadas",
          width: 20,
        })
    
        columnsArray.push({
          header: "Agendadas",
          key: "agendadas",
          width: 20,
        })
    
        columnsArray.push({
          header: "Realizadas",
          key: "realizadas",
          width: 20,
        })
    
        columnsArray.push({
          header: "Canceladas",
          key: "canceladas",
          width: 20,
        })
    
        columnsArray.push({
          header: "Total",
          key: "totales",
          width: 20,
        })
    
    
        var Workbook = new ExcelJS.Workbook()
        var Sheet = Workbook.addWorksheet("Reporte de Qx (" + unidad +")")
        //Sheet.properties.defaultRowHeight = 80
    
        // Sheet.getRow(1).border = {
        //   top: { style: "thick", color: "black" },
        //   left: { style: "thick", color: "black" },
        //   bottom: { style: "thick", color: "black" },
        //   right: { style: "thick", color: "black" },
        // };
    
        // Sheet.getRow(1).fill = {
        //   type: "pattern",
        //   pattern: "darkVertical",
        //   fgColor: { argb: "FFFF00" },
        // };
    
        Sheet.getRow(1).font = {
          //name: "Comic Sans MS",
          // family: 4,
          // size: 12,
          bold: true,
        };

        
    
        Sheet.columns = [
          {
            header: encabezado,
            key: "fecha",
            width: 20,
          },
          {
            header: "Solicitudes",
            key: "solicitadas",
            width: 20,
          }
        ];
    
        Sheet.columns = columnsArray;
    
        for(let i=0;i<reportList.length;i++){
          Sheet.addRow({
            fecha: reportList[i].fecha,
            solicitadas : reportList[i].solicitadas,
            agendadas : reportList[i].agendadas,
            realizadas : reportList[i].realizadas,
            canceladas : reportList[i].canceladas,
            totales : reportList[i].totales
          });
        }
    
        var rowValues = [];
        

        rowValues[1] = "Reporte de Qx (" + unidad +")";
        // insert new row and return as row object
        const insertedRow = Sheet.insertRow(1, rowValues);
    
        rowValues = [];
        rowValues[1] = title;
        // insert new row and return as row object
        const insertedRowSubtitle = Sheet.insertRow(2, rowValues);
    
        let today = new Date()
        let day = today.getDate().toString().padStart(2,"0") + "-" + (parseInt(today.getMonth()) + 1).toString().padStart(2,"0") + "-" + today.getFullYear()
        let time = today.getHours().toString().padStart(2,"0") + ":" + today.getMinutes().toString().padStart(2,"0") + ":" + today.getSeconds().toString().padStart(2,"0")
    
        var rowFechaValues = [];
        const insertedRowVaciasUno = Sheet.insertRow(3, rowFechaValues);
        rowFechaValues[1] = "Fecha inicial: " + dayStart;
        rowFechaValues[3] = "Fecha  final: " + dayEnd;
        rowFechaValues[5] = "Fecha  Consulta: " + day + " " + time
        // insert new row and return as row object
        const insertedRowFechas = Sheet.insertRow(3, rowFechaValues);
        rowFechaValues = [];
        const insertedRowVacias = Sheet.insertRow(3, rowFechaValues);
    
    
        Sheet.mergeCells(1,1,1,(6));
        Sheet.mergeCells("A2:F2");
    
        let rowIndex = 1;
        for (rowIndex; rowIndex <= Sheet.rowCount; rowIndex++) {
          Sheet.getRow(rowIndex).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          if(rowIndex > 3 && rowIndex !== 5 && rowIndex < Sheet.rowCount){
            createOuterBorder(
              Sheet,
              ({ row: rowIndex, col: 1 }),
              ({ row: rowIndex, col: 6 })
            );
          }
        }
    
        Sheet.getRow(1).font = {
          size: 18,
          bold: true,
        };
    
        Sheet.getRow(2).font = {
          size: 14,
          bold: true,
        };
    
        
    
        // var rowMontsValues = [];
        // let contador = 2
        // for(let i=0;i<mesesValidos.length;i++){
        //   console.log(mesesValidos[i].month)
          
        //   rowMontsValues[contador] = getMonthNomber(mesesValidos[i].month);
        //   contador = contador + mesesValidos[i].cantidad
        // }
        // const insertedMonthRow = Sheet.insertRow(2, rowMontsValues);
    
        // contador = 2
        // for(let i=0;i<mesesValidos.length;i++){
        //   console.log(contador + " a "+ (parseInt(mesesValidos[i].cantidad)+parseInt(contador - 1)))
        //   Sheet.mergeCells(2,contador,2,(parseInt(mesesValidos[i].cantidad)+parseInt(contador - 1)));
        //   contador = parseInt(contador) + parseInt(mesesValidos[i].cantidad)
        // }
    
        // Sheet.mergeCells(2,2,2,8);
    
        Workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
    
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "Reporte de Qx (" + unidad +").xlsx";
          anchor.click();
          //window.URL.revokeObjectURL(url);
        });
        //alert("Descarga finalizada")
    }
    
    const createOuterBorder = (worksheet, start = {row: 1, col: 1}, end = {row: 1, col: 1}, borderWidth = 'medium') => {
    
      const borderStyle = {
          style: borderWidth
      };
      for (let i = start.row; i <= end.row; i++) {
          const leftBorderCell = worksheet.getCell(i, start.col);
          const rightBorderCell = worksheet.getCell(i, end.col);
          leftBorderCell.border = {
              ...leftBorderCell.border,
              left: borderStyle
          };
          rightBorderCell.border = {
              ...rightBorderCell.border,
              right: borderStyle
          };
      }
    
      for (let i = start.col; i <= end.col; i++) {
          const topBorderCell = worksheet.getCell(start.row, i);
          const bottomBorderCell = worksheet.getCell(end.row, i);
          topBorderCell.border = {
              ...topBorderCell.border,
              top: borderStyle
          };
          bottomBorderCell.border = {
              ...bottomBorderCell.border,
              bottom: borderStyle
          };
      }
    };

    return(
        <>
            <div className="Table">
                        <div className="Heading">
                            <div className="Cell">
                                {
                                    indicadorUnidad
                                        ?
                                            <p>Fecha</p>
                                        :
                                            <p>Unidad</p>
                                }
                            </div>
                            <div className="Cell">
                                <p>Pendientes</p>
                            </div>
                            <div className="Cell">
                                <p>Agendadas</p>
                            </div>
                            <div className="Cell">
                                <p>Realizadas</p>
                            </div>
                            <div className="Cell">
                                <p>Canceladas</p>
                            </div>
                            <div className="Cell">
                                <p>Total</p>
                            </div>
                        </div>
                        {
                            reportList.map(event => 
                                <div className="Row" key={event.fecha}>
                                    <div className="Cell">
                                        <p>{event.fecha}</p>
                                    </div>
                                    <div className="Cell">
                                        <p>{event.solicitadas}</p>
                                    </div>
                                    <div className="Cell">
                                        <p>{event.agendadas}</p>
                                    </div>
                                    <div className="Cell">
                                        <p>{event.realizadas}</p>
                                    </div>
                                    <div className="Cell">
                                        <p>{event.canceladas}</p>
                                    </div>
                                    <div className="Cell">
                                        <p>{event.totales}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <span onClick={guardarEquipo()} className="btn-flotanteExport"><HiSave style={styleActive}/></span>
        </>
    )
}

export default IndicadoresTable