/**
 * Librerías
 */
 import React, { useEffect, useState } from 'react';
 import {FaUser} from 'react-icons/fa';
 import Swal from "sweetalert2"

 /**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Objetos
 */
//Importación de las funcionalidades del objeto de tipo unidadHospitalaria
import { getUnidadHospitalariaName } from '../objects/unidadHospitalaria';
import { updateMedico, deletePaciente,deleteMedico } from '../objects/medico';
import { createCalendarLog } from '../objects/calendarLog';

/**
 * Componente que muestra la información de un médico
 * @param {Object} param0 Recibe los elementos necesarios para mostrar la información actual del médico
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const MedicoCard = ({user,uhList,userLevel,nomHospitalUser,isMostrarMedicos,isCalidad,
    events,userName,isReadOnly}) => {

    //Estado que almacena si el usuario corresponde a la unidad hospitalaria del usuario logeado
    const [correspondeHospital,setCorrespondeHospital] = useState(false)
    //Estilo asignado al icono de la tarjeta para usuarios activos
    const styleActive = { color: "black", margin: "5px auto 5px", height: "35px", fontSize:"2.5em"}

    useEffect(()=>{
        if(userLevel === "superuser"){//Se verifica si el user loegado tiene acceso total
            setCorrespondeHospital(true)
        }
        else{
            let unidadName = getUnidadHospitalariaName(nomHospitalUser,uhList)
            if(unidadName === "MediMAC Merida"){
                unidadName = "Merida"
            }

            let hospitalName = getUnidadHospitalariaName(user.Hospital,uhList)
            if(user.Hospital === "MediMAC Merida" || user.Hospital === "Merida"){
                hospitalName = "Merida"
            }

            if(unidadName === hospitalName){
                    setCorrespondeHospital(true)
                }
                else{
                    setCorrespondeHospital(false)
                }
        }
    },[user,nomHospitalUser,uhList,userLevel])

    /**
     * Función que atrapa el evento para mostrar la información de un usuario
     * @param {Object} user Recibe un objeto de tipo user que contiene la información del usuario
     * @returns Devuelve el estado del objeto
     */
     const handleMostrarInformaciónClick = (user) => (event) => {
        event.preventDefault()
        if(!isReadOnly){
            let cedula = ""
            if(user.Cedula){
                cedula = user.Cedula
            }

            let cedulaEspecialidad = ""
            if(user.CedulaEspecialidad){
                cedulaEspecialidad = user.CedulaEspecialidad
            }
            else{
                if(user.inputCedulaEspecialidad){
                    cedulaEspecialidad = user.inputCedulaEspecialidad
                }
            }

            let cedulaSubEspecialidad = ""
            if(user.cedulaSubEspecialidad){
                cedulaSubEspecialidad = user.cedulaSubEspecialidad
            }
            else{
                if(user.inputCedulaSubEspecialidad){
                    cedulaSubEspecialidad = user.inputCedulaSubEspecialidad
                }
            }


            let rfc = ""
            if(user.RFC){
                rfc = user.RFC
            }

            let telefono = ""
            if(user.NumeroCelular){
                telefono = user.NumeroCelular
            }

            let correoElectronico = ""
            if(user.CorreoElectronico){
                correoElectronico = user.CorreoElectronico
            }

            if(!user.inputCedulaEspecialidad){
                user.inputCedulaEspecialidad = ""
            }

            if(!user.inputCedulaSubEspecialidad){
                user.inputCedulaSubEspecialidad = ""
            }

            if(isCalidad){
                if(isMostrarMedicos){
                    Swal.fire({
                        customClass:'modalRegistro',
                        html:`<div class="form_wrapper">
                            <div class="form_container">
                                <div class="row clearfix">
                                    <div class="">
                                        <form autocomplete="off" method="post">
                                            <h2 class="modalTitle">Información del médico</h2>
                                            <br />
                                            <div class="input_field">
                                                <p>Nombre del médico</p>
                                                <input type="text" name="inputNombre" id="inputNombre" 
                                                placeholder="Ingrese el nombre del usuario" required  class="inputText" autocomplete="off" 
                                                value="`+user.Nombre+`" 
                                                oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Medicina General</p>
                                                <input type="text" name="inputCedula" id="inputCedula" 
                                                placeholder="Ingrese la cedula de Medicina General" required class="inputText" autocomplete="off" 
                                                value="`+cedula+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaEspecialidad" 
                                                placeholder="Ingrese la cedula de Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaEspecialidad+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Sub Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaSubEspecialidad" 
                                                placeholder="Ingrese la Cedula de Sub Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaSubEspecialidad+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>RFC</p>
                                                <input type="text" name="inputCedula" id="inputRFC" 
                                                placeholder="Ingrese el RFC" required class="inputText" autocomplete="off" 
                                                value="`+rfc+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Teléfono del médico</p>
                                                <input type="text" name="inputTelefono" id="inputTelefono" placeholder="Ingrese el teléfono del médico" 
                                                required class="inputText" autocomplete="off" value="`+telefono+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Correo electrónico del médico</p>
                                                <input type="text" name="inputCorreo" id="inputCorreo" placeholder="Ingrese correo electrónico del médico" 
                                                required class="inputText" autocomplete="off" value="`+correoElectronico+`"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>`,
                        showDenyButton: true,
                        showCancelButton: true,
                        denyButtonText: "Eliminar",
                        cancelButtonText: "Cancelar",
                        denyButtonColor: "red",
                        cancelButtonColor: "grey",
                        confirmButtonText: "Actualizar",
                        confirmButtonColor: "#04afaa",
                        scrollbarPadding:false,
                        width:'500px',
                        heightAuto: false,
                        focusConfirm: false,
                        showCloseButton:true,
                        preConfirm: () => {
                            if (document.getElementById('inputNombre').value) {//Se verifica que se haya proporcionado un nombre de usuario valido
                                if(document.getElementById('inputNombre').value !== user.Nombre
                                || document.getElementById('inputCedula').value !== cedula
                                || document.getElementById('inputTelefono').value !== telefono
                                || document.getElementById('inputCorreo').value !== correoElectronico
                                || document.getElementById('inputCedulaEspecialidad').value !== cedulaEspecialidad
                                || document.getElementById('inputCedulaSubEspecialidad').value !== cedulaSubEspecialidad
                                || document.getElementById('inputRFC').value !== rfc){
                                    user.Nombre = document.getElementById('inputNombre').value
                                    user.Cedula = document.getElementById('inputCedula').value
                                    user.NumeroCelular = document.getElementById('inputTelefono').value
                                    user.CorreoElectronico = document.getElementById('inputCorreo').value
                                    user.CedulaEspecialidad = document.getElementById('inputCedulaEspecialidad').value
                                    user.CedulaSubEspecialidad = document.getElementById('inputCedulaSubEspecialidad').value
                                    user.RFC = document.getElementById('inputRFC').value
                                    
                                    user.Validate = 1
                                    solicitarActualizarUser()
                                    async function solicitarActualizarUser(){
                                        user.Descripcion = ""
                                        user.idTab_DirectorioMedico  = ""
                                        user.Extension = ""
                                        user.SitioWeb  = ""
                                        user.Especialidad2 = ""
                                        user.Subespecialidad = ""
                                        user.Especialidad  = ""
                                        user.NumeroTelefonico = ""
                                        user.Consultorio = ""

                                        // const response = await deleteMedico(user.id)
                                        // alert("Medico actualizado de manera exitosa")
                                        const response = await updateMedico(user.id,user)
                                        
                                            if(response === "success"){//Actualización de usuario exitosa
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Actualización de médico',
                                                    text: 'Medico actualizado de manera exitosa',
                                                    confirmButtonText: "Aceptar",
                                                    confirmButtonColor: "#04afaa",
                                                })
                                            }
                                            else{//Error en la actualización de usuario
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: response,
                                                })
                                            }
                                    }
                                }
                                else{
                                    Swal.showValidationMessage('No ha realizado cambios')
                                }
                            } else {//Nombre de usuario invalido
                            Swal.showValidationMessage('Proporcione el nombre del usuario')   
                            }
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isDenied) {
                            user.Nombre = document.getElementById('inputNombre').value
                            user.Cedula = document.getElementById('inputCedula').value
                            user.NumeroCelular = document.getElementById('inputTelefono').value
                            user.CorreoElectronico = document.getElementById('inputCorreo').value
                            user.CedulaEspecialidad = document.getElementById('inputCedulaEspecialidad').value
                            user.CedulaSubEspecialidad = document.getElementById('inputCedulaSubEspecialidad').value
                            user.RFC = document.getElementById('inputRFC').value
                            user.Validate = 1
                            solicitarActualizarUser()
                                    async function solicitarActualizarUser(){
                                        user.Descripcion = ""
                                        user.idTab_DirectorioMedico  = ""
                                        user.Extension = ""
                                        user.SitioWeb  = ""
                                        user.Especialidad2 = ""
                                        user.Subespecialidad = ""
                                        user.Especialidad  = ""
                                        user.NumeroTelefonico = ""
                                        user.Consultorio = ""
                                        user.IsEliminated = true

                                        // const response = await deleteMedico(user.id)
                                        // alert("Medico actualizado de manera exitosa")
                                        const response = await updateMedico(user.id,user)
                                        
                                            if(response === "success"){//Actualización de usuario exitosa
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Actualización de médico',
                                                    text: 'Médico eliminado de manera exitosa',
                                                    confirmButtonText: "Aceptar",
                                                    confirmButtonColor: "#04afaa",
                                                })
                                            }
                                            else{//Error en la actualización de usuario
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: response,
                                                })
                                            }
                                    }
                        }
                    })
                }
                else{
                    Swal.fire({
                        customClass:'modalRegistro',
                        html:`<div class="form_wrapper">
                            <div class="form_container">
                                <div class="row clearfix">
                                    <div class="">
                                        <form autocomplete="off" method="post">
                                            <h2 class="modalTitle">Información del médico</h2>
                                            <br />
                                            <div class="input_field">
                                                <p>Nombre del médico</p>
                                                <input type="text" name="inputNombre" id="inputNombre" 
                                                placeholder="Ingrese el nombre del usuario" required  class="inputText" autocomplete="off" 
                                                value="`+user.Nombre+`" 
                                                oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Medicina General</p>
                                                <input type="text" name="inputCedula" id="inputCedula" 
                                                placeholder="Ingrese la cedula de Medicina General" required class="inputText" autocomplete="off" 
                                                value="`+cedula+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaEspecialidad" 
                                                placeholder="Ingrese la cedula de Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaEspecialidad+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Cedula de Sub Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaSubEspecialidad" 
                                                placeholder="Ingrese la Cedula de Sub Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaSubEspecialidad+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>RFC</p>
                                                <input type="text" name="inputCedula" id="inputRFC" 
                                                placeholder="Ingrese el RFC" required class="inputText" autocomplete="off" 
                                                value="`+rfc+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Teléfono del médico</p>
                                                <input type="text" name="inputTelefono" id="inputTelefono" placeholder="Ingrese el teléfono del médico" 
                                                required class="inputText" autocomplete="off" value="`+telefono+`"/>
                                            </div>
                                            <div class="input_field">
                                                <p>Correo electrónico del médico</p>
                                                <input type="text" name="inputCorreo" id="inputCorreo" placeholder="Ingrese correo electrónico del médico" 
                                                required class="inputText" autocomplete="off" value="`+correoElectronico+`"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>`,
                        showDenyButton: true,
                        showCancelButton: true,
                        denyButtonText: "Denegar",
                        cancelButtonText: "Editar",
                        denyButtonColor: "red",
                        cancelButtonColor: "#0099FF",
                        confirmButtonText: "Validar",
                        confirmButtonColor: "#04afaa",
                        scrollbarPadding:false,
                        width:'500px',
                        heightAuto: false,
                        focusConfirm: false,
                        showCloseButton:true,
                        preConfirm: () => {
                            if (document.getElementById('inputNombre').value) {//Se verifica que se haya proporcionado un nombre de usuario valido
                                if(document.getElementById('inputCedula').value){
                                    if(document.getElementById('inputTelefono').value){
                                        if(document.getElementById('inputCorreo').value){
                                            user.Nombre = document.getElementById('inputNombre').value
                                            user.Cedula = document.getElementById('inputCedula').value
                                            user.NumeroCelular = document.getElementById('inputTelefono').value
                                            user.CorreoElectronico = document.getElementById('inputCorreo').value
                                            user.Validate = 1
                                            user.CedulaEspecialidad = document.getElementById('inputCedulaEspecialidad').value
                                            user.CedulaSubEspecialidad = document.getElementById('inputCedulaSubEspecialidad').value
                                            user.RFC = document.getElementById('inputRFC').value
                                            user.Descripcion = ""
                                            user.idTab_DirectorioMedico  = ""
                                            user.Extension = ""
                                            user.SitioWeb  = ""
                                            user.Especialidad2 = ""
                                            user.Subespecialidad = ""
                                            user.Especialidad  = ""
                                            user.NumeroTelefonico = ""
                                            user.Consultorio = ""
                                            //console.log(user)
                                    solicitarActualizarUser()
                                    async function solicitarActualizarUser(){

                                        // const response = await deleteMedico(user.id)
                                        // alert("Medico actualizado de manera exitosa")
                                        const response = await updateMedico(user.id,user)
                                        
                                            if(response === "success"){//Actualización de usuario exitosa
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Actualización de médico',
                                                    text: 'Medico validado de manera exitosa',
                                                    confirmButtonText: "Aceptar",
                                                    confirmButtonColor: "#04afaa",
                                                })
                                            }
                                            else{//Error en la actualización de usuario
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: response,
                                                })
                                            }
                                    }
                                        }
                                        else{
                                            Swal.showValidationMessage('Proporcione el correo electrónico del médico')   
                                        }
                                    }
                                    else{
                                        Swal.showValidationMessage('Proporcione el teléfono del médico')   
                                    }
                                }
                                else{
                                    Swal.showValidationMessage('Proporcione la cedula del médico')   
                                }
                            } else {//Nombre de usuario invalido
                            Swal.showValidationMessage('Proporcione el nombre del médico')   
                            }
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isDenied) {
                            user.Nombre = document.getElementById('inputNombre').value
                            user.Cedula = document.getElementById('inputCedula').value
                            user.NumeroCelular = document.getElementById('inputTelefono').value
                            user.CorreoElectronico = document.getElementById('inputCorreo').value
                            user.CedulaEspecialidad = document.getElementById('inputCedulaEspecialidad').value
                            user.CedulaSubEspecialidad = document.getElementById('inputCedulaSubEspecialidad').value
                            user.RFC = document.getElementById('inputRFC').value
                            user.Descripcion = ""
                            user.idTab_DirectorioMedico  = ""
                            user.Extension = ""
                            user.SitioWeb  = ""
                            user.Especialidad2 = ""
                            user.Subespecialidad = ""
                            user.Especialidad  = ""
                            user.NumeroTelefonico = ""
                            user.Consultorio = ""
                        let eventosACancelar = []
                        events.forEach(
                                event => {
                                    let description = event.description.toString().split('|')
                                    if(description.length > 1){
                                        if(description[8]){
                                            let medicoName = description[1].split('=')[1]
                                            if(medicoName.toUpperCase().includes(user.Nombre.toUpperCase())){
                                                //eventosACancelar.push(event)
                                            }  
                                        }
                                    }
                                
                                }
                            )

                            if(eventosACancelar.length > 0){
                                let posicion = 0
                                eliminarEventos(posicion)
                                function eliminarEventos(posicion){
                                    let date = new Date()
                                    solicitarUpdateEvent()
                                    async function solicitarUpdateEvent(){
                                        const response = await createCalendarLog(eventosACancelar[posicion].id,"cancel","Medico sin credencializar",eventosACancelar[posicion],eventosACancelar[posicion].description,userName,date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                                        date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds())
                                        if(response === "success"){//Registro de usuario exitoso
                                            posicion = posicion + 1
                                            if(posicion < eventosACancelar.length){
                                                eliminarEventos(posicion)
                                            }
                                            else{
                                                solicitarActualizarUser()
                                                async function solicitarActualizarUser(){
                        
                                                    const response = await deletePaciente(user)
                                                    
                                                        if(response === "success"){//Actualización de usuario exitosa
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: 'Actualización de médico',
                                                                text: 'Médico denegado de manera exitosa',
                                                                confirmButtonText: "Aceptar",
                                                                confirmButtonColor: "#04afaa",
                                                            })
                                                        }
                                                        else{//Error en la actualización de usuario
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: response,
                                                            })
                                                        }
                                                }
                                            }
                                        }
                                        else{//Error en el registro de usuario
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: response,
                                            })
                                        }
                                    }
                                }
                            }
                            else{
                                solicitarActualizarUser()
                                                async function solicitarActualizarUser(){
                        
                                                    const response = await deletePaciente(user)
                                                    
                                                        if(response === "success"){//Actualización de usuario exitosa
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: 'Actualización de médico',
                                                                text: 'Médico denegado de manera exitosa',
                                                                confirmButtonText: "Aceptar",
                                                                confirmButtonColor: "#04afaa",
                                                            })
                                                        }
                                                        else{//Error en la actualización de usuario
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: response,
                                                            })
                                                        }
                                                }
                            }
                        }
                        else{
                            if (result.isDismissed){
                                if(user.Nombre !== document.getElementById('inputNombre').value
                                || user.Cedula !== document.getElementById('inputCedula').value
                                || user.NumeroCelular !== document.getElementById('inputTelefono').value
                                || user.CorreoElectronico !== document.getElementById('inputCorreo').value
                                || (user.CedulaEspecialidad !== document.getElementById('inputCedulaEspecialidad').value && document.getElementById('inputCedulaEspecialidad').value.length > 0)
                                || (user.CedulaSubEspecialidad !== document.getElementById('inputCedulaSubEspecialidad').value && document.getElementById('inputCedulaSubEspecialidad').value.length > 0)
                                || user.RFC !== document.getElementById('inputRFC').value){
                                    user.Nombre = document.getElementById('inputNombre').value
                                    user.Cedula = document.getElementById('inputCedula').value
                                    user.NumeroCelular = document.getElementById('inputTelefono').value
                                    user.CorreoElectronico = document.getElementById('inputCorreo').value
                                    user.CedulaEspecialidad = document.getElementById('inputCedulaEspecialidad').value
                                    user.CedulaSubEspecialidad = document.getElementById('inputCedulaSubEspecialidad').value
                                    user.RFC = document.getElementById('inputRFC').value
                                    user.Descripcion = ""
                                    user.idTab_DirectorioMedico  = ""
                                    user.Extension = ""
                                    user.SitioWeb  = ""
                                    user.Especialidad2 = ""
                                    user.Subespecialidad = ""
                                    user.Especialidad  = ""
                                    user.NumeroTelefonico = ""
                                    user.Consultorio = ""
                                    solicitarActualizarUser()
                                    async function solicitarActualizarUser(){

                                        // const response = await deleteMedico(user.id)
                                        // alert("Medico actualizado de manera exitosa")
                                        const response = await updateMedico(user.id,user)
                                        
                                            if(response === "success"){//Actualización de usuario exitosa
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: 'Actualización de médico',
                                                    text: 'Médico actualizado de manera exitosa',
                                                    confirmButtonText: "Aceptar",
                                                    confirmButtonColor: "#04afaa",
                                                })
                                            }
                                            else{//Error en la actualización de usuario
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Error',
                                                    text: response,
                                                })
                                            }
                                    }
                                }
                                
                            }
                        }
                    })
                }
            }
            else{
                Swal.fire({
                    customClass:'modalRegistro',
                    html:`<div class="form_wrapper">
                        <div class="form_container">
                            <div class="row clearfix">
                                <div class="">
                                    <form autocomplete="off" method="post">
                                        <h2 class="modalTitle">Información del médico</h2>
                                        <br />
                                        <div class="input_field">
                                            <p>Nombre del médico</p>
                                            <input type="text" name="inputNombre" id="inputNombre" 
                                            placeholder="Ingrese el nombre del usuario" required  class="inputText" autocomplete="off" 
                                            value="`+user.Nombre+`" 
                                            oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                        </div>
                                        <div class="input_field">
                                                <p>Cedula de Medicina General</p>
                                                <input type="text" name="inputCedula" id="inputCedula" 
                                                placeholder="Ingrese la cedula de Medicina General" required class="inputText" autocomplete="off" 
                                                value="`+cedula+`"/>
                                        </div>
                                        <div class="input_field">
                                                <p>Cedula de Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaEspecialidad" 
                                                placeholder="Ingrese la cedula de Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaEspecialidad+`"/>
                                        </div>
                                        <div class="input_field">
                                                <p>Cedula de Sub Especialidad</p>
                                                <input type="text" name="inputCedula" id="inputCedulaEspecialidad" 
                                                placeholder="Ingrese la Cedula de Sub Especialidad" required class="inputText" autocomplete="off" 
                                                value="`+cedulaSubEspecialidad+`"/>
                                        </div>
                                        <div class="input_field">
                                                <p>RFC</p>
                                                <input type="text" name="inputCedula" id="inputRFC" 
                                                placeholder="Ingrese el RFC" required class="inputText" autocomplete="off" 
                                                value="`+rfc+`"/>
                                        </div>
                                        <div class="input_field">
                                            <p>Teléfono del médico</p>
                                            <input type="text" name="inputTelefono" id="inputTelefono" placeholder="Ingrese el teléfono del médico" 
                                            required class="inputText" autocomplete="off" value="`+telefono+`"/>
                                        </div>
                                        <div class="input_field">
                                            <p>Correo electrónico del médico</p>
                                            <input type="text" name="inputCorreo" id="inputCorreo" placeholder="Ingrese correo electrónico del médico" 
                                            required class="inputText" autocomplete="off" value="`+correoElectronico+`"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>`,
                    showDenyButton: true,
                    denyButtonText: "Cancelar",
                    denyButtonColor: "grey",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#04afaa",
                    scrollbarPadding:false,
                    width:'500px',
                    heightAuto: false,
                    focusConfirm: false,
                    showCloseButton:true
                })
            }
        }
        
    }

    return(
        <>
            {
                correspondeHospital ? 
                (
                    <div className="itemuser" onClick={handleMostrarInformaciónClick(user)}>
                        <div>
                            {/* <img src="http://hospitalesmac.com/img/icono_9.png" title="Pendiente de salida" alt="Pendiente de salida" height="48px" className='imgservi'/> */}
                            <FaUser style={styleActive}/>
                            <h1 className='info_item_hospital_name'>{getUnidadHospitalariaName(user.Hospital,uhList)}</h1>       
                            <h1 className='itemblogTitle'>{user.Nombre}</h1>                 
                            <h1 className='info_itemblog'>{user.Cedula}</h1>
                            <h1 className='info_itemblog'>{user.RFC}</h1>
                            <h1 className='info_itemblog'>{user.NumeroCelular}</h1>
                        </div>
                    </div>
                )
                : null
            }
        </>
    )
}

export default MedicoCard;