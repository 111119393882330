/**
 * Librerías
 */
import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2"
//import axios from 'axios';

/**
 * Styles
 */
import './App.css';

/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore y las colecciones necesarias
import { pacientesStore,  uhCollection, usersCollection, sessions, systems, ipwhitelist } from './utils/firebase';
//Importar las funciones requeridas para la lógica del sistema
import { compararInformación } from './utils/config';


/**
 * Componentes
 */
//Page asignada a la visualización del calendario de eventos reservados
import CalendarPage from './views/calendarPage';
//Page asignada a la visualización del login de acceso
import Login from './views/login';


const App = () => {

  //Estado que almacena si se puede accesar
  const [access,setAccess] = useState(false)
  //Estado que almacena el listado de sesiones activas
  const [sessionList, setSessionList] = useState([])
  //Estado que almacena si hay una sesión guardada
  const [userIsLogged,setUserIslogged] = useState(sessionStorage.getItem('logged'))
  //Estado que almacena el nivel de permiso asignado al usuario logeado
  const [userLevel,setUserLevel] = useState(sessionStorage.getItem('level'))
  //Estado que almacena el nombre del usuario logeado
  const [userName,setUserName] = useState(sessionStorage.getItem('name'))
  //Estado que almacena el nombre del hospital asignado al usuario logeado
  const [userNomHospital,setuserNomHospital] = useState(sessionStorage.getItem('nomHospital'))
  //Estado que almacena las unidades hospitalarias obtenidos desde la base de datos en una lista
  const [uhList,setuhList] = useState([])
  //Estado que almacena la version del sistema
  const [version] = useState("1.0.1")
  const [isIpValida, setIsIpValida] = useState(true)
  const [ipList, setIpList] = useState([])
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [isCorporativo, setIsCorporativo] = useState(false)
  const [hospitalSeleccionado, setHospitalSeleccionado] = useState("")

  useEffect(()=>{
    // const date = '2024-01-02T9:00';
    // const timePrueba = new Date(date.split('T')[0])
    // const horario = date.split('T')[1].split('-')[0]
    // timePrueba.setHours(horario.split(":")[0])
    // timePrueba.setHours(timePrueba.getHours() + 5)
    // timePrueba.setMinutes(horario.split(":")[1])
    // console.log(timePrueba)

    pacientesStore.collection("calendarIntent").where("Building_Id","==","Hospital-HMAS-Los Cabos")
        .onSnapshot(snap => {
            const sesiones = []
            snap.forEach(doc => {
              sesiones.push({ id: doc.id, ...doc.data() })
            })
            console.log("Hola")
            console.log(sesiones)
        },(error)=>{  
        })


    pacientesStore.collection(sessions)
        .onSnapshot(snap => {
            const sesiones = []
            snap.forEach(doc => {
              sesiones.push({ id: doc.id, ...doc.data() })
            })
            setSessionList(sesiones)
        },(error)=>{  
          setuhList([])
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
          })
        })
    
    pacientesStore.collection(ipwhitelist)
        .onSnapshot(snap => {
            const ips = []
            snap.forEach(doc => {
              ips.push({ id: doc.id, ...doc.data() })
            })
            setIpList(ips)
        },(error)=>{  
          setuhList([])
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
          })
        })
  },[])

  useEffect(()=>{
    if(version){
      pacientesStore.collection(systems).where('id','==',"2")
        .onSnapshot(snap => {
            const systems = []
            snap.forEach(doc => {
              systems.push({ id: doc.id, ...doc.data() })
            })

            if(systems.length > 0){
              if(systems[0].version !== version){
                Swal.fire({
                  icon: 'warning',
                  title: 'Nueva versión disponible',
                  text: 'Se recargará la página para obtener la versión más reciente.',
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#04afaa",
                  allowOutsideClick:false
                }).then((result) => {
                  if (result.isConfirmed) {
                      //Se solicita el registro del evento
                      window.location.reload()
                  }
                })
              }
            }
        },(error)=>{  
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
          })
        })
    }
  },[version])

  useEffect(()=>{
    if(access){
      let resultado = null
      if(userLevel !== "calidad"){
        resultado = sessionList.find( session => session.uh === userNomHospital && session.system === 2 && session.level !== "calidad");
      }
      else{
        resultado = sessionList.find( session => session.uh === userNomHospital && session.system === 2 && session.level === userLevel);
      }

      if(resultado){
        if(resultado.id){
          if(resultado.token !== sessionStorage.getItem('token')){
            // Swal.fire({
            //   icon: 'warning',
            //   title: 'Sesión Caducada',
            //   text: 'Otro dispositivo ha iniciado sesión en el sistema.',
            //   confirmButtonText: "Aceptar",
            //   confirmButtonColor: "#04afaa",
            //   allowOutsideClick:false
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //       //Se solicita el registro del evento
            //       sessionStorage.clear()
            //       window.location.reload()
            //   }
            // })
          }
        }
      }
    }
  },[sessionList,access,userLevel,userNomHospital])

  useEffect(()=>{

    if(userIsLogged){
      setAccess(true)
      pacientesStore.collection(uhCollection)
        .onSnapshot(snap => {
            const unidadesHospitalarias = []
            snap.forEach(doc => {
              unidadesHospitalarias.push({ id: doc.id, ...doc.data() })
            })

            unidadesHospitalarias.sort(compararInformación('hospital'))
            setuhList(unidadesHospitalarias)
        },(error)=>{  
          setuhList([])
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error,
          })
        })

      pacientesStore.collection(usersCollection)
            .onSnapshot(snap => {
                const users = []
                snap.forEach(doc => {
                  users.push({ id: doc.id, ...doc.data() })
                })
                
                for(let i=0;i<users.length;i++){//Se recorren los usuarios disponibles
                  let unidadHospitalaria = users[i].UnidadHospitalaria
                  if(users[i].id === sessionStorage.getItem('id')){//Se verifica que se este obteniendo la información del usuario loegado
                    if(users[i].Activo){//Se verifica que el usuario loegado este activo
                        //Se actualiza la información del usuario logeado (útil por si se le hacen actualziaciones mientras se encuentra logeado)
                        sessionStorage.setItem('id',users[i].id)
                        sessionStorage.setItem('nomHospital',unidadHospitalaria.hospital)
                        setuserNomHospital(unidadHospitalaria.hospital)
                        sessionStorage.setItem('name',users[i].Nombre)
                        setUserName(users[i].Nombre)
                        sessionStorage.setItem('level',users[i].Permiso)
                        setUserLevel(users[i].Permiso)
                        sessionStorage.setItem('logged',true)
                        setUserIslogged(true)
                        if(users[i].Permiso === 'tablero'){
                          setIsReadOnly(true)
                        }
                        else{
                          setIsReadOnly(false)
                        }
                    }
                    else{//Se borran los datos de la sesión y se recarga el sitio
                        sessionStorage.setItem('nomHospital','')
                        setuserNomHospital('')
                        sessionStorage.setItem('name','')
                        setUserName('')
                        sessionStorage.setItem('level','')
                        setUserLevel('')
                        sessionStorage.setItem('logged',false)
                        setUserIslogged(false)
                    }
                  }
                }
            },(error) =>{
              sessionStorage.setItem('nomHospital','')
              setuserNomHospital('')
              sessionStorage.setItem('name','')
              setUserName('')
              sessionStorage.setItem('level','')
              setUserLevel('')
              sessionStorage.setItem('logged',false)
              setUserIslogged(false)
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
              })
            })
      
      
    }
    else{
      setAccess(false)
    }
  },[userIsLogged])

  useEffect(()=>{
    if(ipList){
      if(ipList.length > 0){
        validateIps()
      }
      else{
        setIsIpValida(true)
      } 
    }
    else{
      setIsIpValida(true)
    }

    async function validateIps(){
      const response = await geIpAddress()
      const resultado = ipList.find( ip => ip.ip === response.ip)
      if(resultado){
        if(resultado.id){
          setIsIpValida(true)
        }
        else{
          setIsIpValida(false)
        }
      }
      else{
        setIsIpValida(false)
      }
    }
  },[ipList])

  const geIpAddress = async () => {
    const response = await fetch('https://ipapi.co/json/')
    const json = await response.json()
    return json
  }

  return (
    <div className="App">
      {
        isIpValida || !isIpValida
        ?
          access && userNomHospital && userName && userLevel
          ?
            <CalendarPage userName={userName} userNomHospital={userNomHospital} userLevel={userLevel} uhList={uhList} access={access} 
            isReadOnly={isReadOnly} isCorporativo={isCorporativo} setuserNomHospital={setuserNomHospital} 
            setHospitalSeleccionado={setHospitalSeleccionado} hospitalSeleccionado={hospitalSeleccionado}/>
          :
            <Login setuserNomHospital={setuserNomHospital} setUserName={setUserName} 
            setUserIslogged={setUserIslogged} setUserLevel={setUserLevel} userIsLogged={userIsLogged} sessionList={sessionList}
            setIsCorporativo={setIsCorporativo}/>
        :
          <div>
            <h1>Forbbiden Access</h1>
          </div>
      }
    </div>
  );
}

export default App;
