import React,{useEffect, useState} from "react";
import moment from 'moment';
import Swal from "sweetalert2"
import { HiSearch } from "react-icons/hi";
import {MdOutlineDoDisturb,MdOutlineEditCalendar} from 'react-icons/md';

/**
 * Styles
 */
 import '../styles/table.css'

//Importar las funcionalidades para la lógica del negocio
//import { compararInformación, compararInformaciónDesc } from '../utils/functions'

 /**
 * Components
 */
//Componente que muestra la información de los requerimientos del médico tratante para la cirugía
import DetallesModal from "./detallesModal";

 /**
 * Objects
 */
//Contiene la interacción a la base de datos del objeto de tipo calendarLog
import { createCalendarLog } from "../objects/calendarLog";

/**
 * Componente que muestra el listado de eventos realizados en forma de tabla
 * @param {Object} param0 Recibe los elementos necesarios para mostrar el listado de eventos realizados
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const HistoricoTable = ({eventos,uhList,events,setEventoSeleccionado,eventoSeleccionado,userLevel,userName,cancelados,setIsMostrarEditar}) => {

    const [isPacific, setIsPacific] = useState(false)
    const [optionsVisible,setOptionsVisible] = useState(true)

    useEffect(()=>{
        var MyDate = new Date();
        if(MyDate.toString().includes("GMT-0700")){
        setIsPacific(true)
        }
        else{
        setIsPacific(false)
        }
    },[])

    useEffect(()=>{
        if(userLevel === "superuser" || userLevel === "agenda"){
            setOptionsVisible(true)
        }
        else{
            setOptionsVisible(false)
        }
    },[userLevel])

    useEffect(()=>{
        console.log(cancelados)
    },[cancelados])


    /**
     * Función que muestra la información de un evento seleccionado
     * @param {Object} evento Recibe el evento seleccionado
     * @returns Devuelve el estado de la solicitud
     */
    const getEvent = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        if(resultado){
            setEventoSeleccionado(resultado)
            if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
                document.getElementById('modal').style.display='block'
            }
        }
    }

    /**
     * Función que obtiene el nombre de la unidad hospitalaria a la que pertenece el evento
     * @param {String} UnidadHospitalaria Recibe la unidad hospitalaria a buscar
     * @returns String Devuelve el nombre encontrado
     */
    function getUH(UnidadHospitalaria){
        const resultado = uhList.find( uh => uh.calendar === UnidadHospitalaria )
        if(resultado){
            return resultado.hospital
        }
        else{
            return UnidadHospitalaria
        }
    }

    /**
     * Función que atrapa el evento click de cancelar y dispara la solicitud de cancelación de evento
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleCancelarClick = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        let mensaje = ""
        if(isPacific){
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')
        }
        else{
            mensaje = getUH(evento.UnidadHospitalaria).toUpperCase() + " " + evento.Paciente.toUpperCase() + " " + evento.Procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }

        let motivoOpciones = ""
        motivoOpciones+="<option value='cero' selected>Seleccione el motivo de la cancelación</option>"
        motivoOpciones+="<option value='Cancelada por el médico'>Cancelada por el médico</option>"
        motivoOpciones+="<option value='Cancelada por el Paciente'>Cancelada por el Paciente</option>"
        motivoOpciones+="<option value='Error de Captura'>Error de Captura</option>"
        motivoOpciones+="<option value='Expediente'>Expediente</option>"
        motivoOpciones+="<option value='Hospital'>Hospital</option>"
        motivoOpciones+="<option value='Aseguradora'>Aseguradora</option>"
        
        Swal.fire({
            title: "Se cancelará la reservación del quirófano:",
            html:`<div class="form_wrapper">
                                                                            <div class="form_container">
                                                                                <div class="row clearfix">
                                                                                    <div class="">
                                                                                        <form autocomplete="off" method="post">
                                                                                            <h3>`+mensaje+`</h3>
                                                                                            <br />
                                                                                            <div class="input_field select_option">
                                                                                                <select id="selectMotivo">
                                                                                                    `+motivoOpciones+`
                                                                                                </select>
                                                                                                <div class="select_arrow"></div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>`,
            text: mensaje,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#14988f',
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
            cancelButtonText:`Cancelar`,
            customClass: 'swal-confirm',
            preConfirm: () => {
                if(document.getElementById('selectMotivo').value !== "cero"){
                    let date = new Date()
                    //Se solicita el registro del evento
                    solicitarUpdateEvent()
                    async function solicitarUpdateEvent(){
                        const response = await createCalendarLog(evento.id,"cancel",document.getElementById('selectMotivo').value,evento,resultado.description,userName,date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                        date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds())
                        if(response === "success"){//Registro de usuario exitoso
                            Swal.fire({
                                icon: 'success',
                                title: 'Actualización de evento',
                                text: 'Cirugía cancelada de manera exitosa',
                                confirmButtonText: "Aceptar",
                                confirmButtonColor: "#04afaa",
                            })
                        }
                        else{//Error en el registro de usuario
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response,
                            })
                        }
                    }
                }
                else{
                    Swal.showValidationMessage('Seleccione el motivo de la reasignación')   
                } 
            }
        })
    }

    const handleShowRequerimientosClick = (evento) => (event) => {
        event.preventDefault()
        let eventoObtenido = {
            "organizer": {
                "email": "c_oepg1ijckdt9pq2k77p2gap6ug@group.calendar.google.com",
                "displayName": "Calendario Quirófanos Hospital MAC Celaya",
                "self": true
            },
            "eventType": "default",
            "creator": {
                "email": "surgeryroomagendahospitalmacce@auto-factura-erps.iam.gserviceaccount.com"
            },
            "created": "2023-09-27T01:06:27.000Z",
            "reminders": {
                "useDefault": true
            },
            "description": "AppointmentType="+evento.Quirofano+"|Medico="+evento.Medico+"|Paciente="+evento.Paciente+"|Procedimiento="+evento.Procedimiento+"|Equipo Quirúrgico="+evento.Materiales+"|Extras="+evento.Extras+"|Telefono Medico=6681507387|Telefono Paciente=6871079122|Unidad Hospitalaroa=Hospital-MAC-Los Mochis|Duracion="+evento.Duracion+"|LadoProcedimiento="+evento.LadoProcedimiento+"|Anestesiologo="+evento.Anestesiologo+"|TipoAnestesia="+evento.TipoAnestesia+"|FechaNacimientoPaciente="+evento.FechaNacimiento+"|EdadPaciente="+evento.Edad+"|SexoPaciente="+evento.Sexo+"|TipoCliente="+evento.TipoCliente+"|Diagnostico="+evento.Diagnostico+"|Solicitado="+evento.Realizo+"|Concepto="+evento.UltimaSolicitud+"",
            "etag": "\"3391553575454000\"",
            "iCalUID": "kftg7icjrl1j7u4729manfentg@google.com",
            "updated": "2023-09-27T01:06:27.727Z",
            "summary": "Confirmada",
            "id": "kftg7icjrl1j7u4729manfentg",
            "start": {
                "timeZone": "UTC",
                "dateTime": evento.start
            },
            "status": "confirmed",
            "htmlLink": "https://www.google.com/calendar/event?eid=a2Z0ZzdpY2pybDFqN3U0NzI5bWFuZmVudGcgY19vZXBnMWlqY2tkdDlwcTJrNzdwMmdhcDZ1Z0Bn",
            "id_evento": "ZwOxuK2YAVr64c3H0rL2",
            "end": {
                "timeZone": "UTC",
                "dateTime": evento.end
            },
            "kind": "calendar#event",
            "sequence": 0
        }
        setEventoSeleccionado(eventoObtenido)
        if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
            document.getElementById('modal').style.display='block'
        }
    }

    const handleShowEditeFormClick = (evento) => (event) => {
        event.preventDefault()
        const resultado = events.find( uh => uh.id === evento.id )
        if(resultado){
            setEventoSeleccionado(resultado)
            setIsMostrarEditar(true)
        }
    }

    return (
        <>
        <div className="Table">
            <div className="Heading">
                <div className="Cell">
                    <p>Unidad Hospitalaria</p>
                </div>
                <div className="Cell">
                    <p>Solicitado</p>
                </div>
                <div className="Cell">
                    <p>Estatus</p>
                </div>
                <div className="Cell">
                    <p>Quirófano</p>
                </div>
                <div className="Cell">
                    <p>Médico</p>
                </div>
                <div className="Cell">
                    <p>Tel. Médico</p>
                </div>
                <div className="Cell">
                    <p>Paciente</p>
                </div>
                <div className="Cell">
                    <p>Tel. Paciente</p>
                </div>
                <div className="Cell">
                    <p>Procedimiento</p>
                </div>
                <div className="Cell">
                    <p>Inicio</p>
                </div>
                <div className="Cell">
                    <p>Fin</p>
                </div>
                <div className="Cell">
                    <p></p>
                </div>
                {
                    optionsVisible
                        ?
                            <>
                                <div className="Cell">
                                    <p></p>
                                </div>
                                <div className="Cell">
                                    <p></p>
                                </div>
                            </>

                        :
                            null
                }
            </div>
            {
                eventos.map(event => 
                    <div className={"Row"} key={event.id}>
                        <div className="Cell">
                            <p>{getUH(event.UnidadHospitalaria).toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{moment(event.created).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')}</p>
                                    :
                                        <p>{moment(event.created).format('DD-MM-YYYY hh:mm:ss a')}</p>
                            }
                        </div>
                        <div className="Cell">
                            <p>{event.Estatus.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Sala.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Medico.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoMedico}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Paciente.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoPaciente}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Procedimiento.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.start.split('T')[0].split('-')[2]+ '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0]+ ' ' + event.start.split('T')[1].split(':')[0] + ":" + event.start.split('T')[1].split(':')[1]}</p>
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                                    :
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                            }
                        </div>
                        {
                            optionsVisible
                                ?
                                    <>
                                        <div className="Cell">
                                            <h1 className='info_itemblogEditar'onClick={handleShowEditeFormClick(event)}><MdOutlineEditCalendar /> { "Editar".toUpperCase()}</h1> 
                                        </div>
                                        <div className="Cell">
                                            <h1 className='info_itemblogCancelar'onClick={handleCancelarClick(event)}><MdOutlineDoDisturb /> { "Cancelar".toUpperCase()}</h1> 
                                        </div>
                                    </>
                                :
                                    null
                        }
                        <div className="Cell">
                            <h1 className='info_itemblogRequerimientos' onClick={getEvent(event)}><HiSearch /> { "Detalles".toUpperCase()}</h1> 
                        </div>
                    </div>
                )
            }
            {
                cancelados.map(event => 
                    <div className={"RowRed"} key={event.id}>
                        <div className="Cell">
                            <p>{getUH(event.UnidadHospitalaria).toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{moment(event.created).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')}</p>
                                    :
                                        <p>{moment(event.created).format('DD-MM-YYYY hh:mm:ss a')}</p>
                            }
                        </div>
                        <div className="Cell">
                            <p>{event.Estatus.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Sala.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Medico.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoMedico}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Paciente.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.TelefonoPaciente}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.Procedimiento.toUpperCase()}</p>
                        </div>
                        <div className="Cell">
                            <p>{event.start.split('T')[0].split('-')[2]+ '-' + event.start.split('T')[0].split('-')[1] + '-' + event.start.split('T')[0].split('-')[0]+ ' ' + event.start.split('T')[1].split(':')[0] + ":" + event.start.split('T')[1].split(':')[1]}</p>
                        </div>
                        <div className="Cell">
                            {
                                isPacific
                                    ?
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                                    :
                                        <p>{event.end.split('T')[0].split('-')[2]+ '-' + event.end.split('T')[0].split('-')[1] + '-' + event.end.split('T')[0].split('-')[0]+ ' ' + event.end.split('T')[1].split(':')[0] + ":" + event.end.split('T')[1].split(':')[1]}</p>
                            }
                        </div>
                        {
                            optionsVisible
                                ?
                                    <>
                                        <div className="Cell">
                                            <h1 className='info_itemblogEditar'onClick={handleShowEditeFormClick(event)}><MdOutlineEditCalendar /> { "Editar".toUpperCase()}</h1> 
                                        </div>
                                        <div className="Cell">
                                            <h1 className='info_itemblogCancelar'onClick={handleCancelarClick(event)}><MdOutlineDoDisturb /> { "Cancelar".toUpperCase()}</h1> 
                                        </div>
                                    </>
                                :
                                    null
                        }
                        <div className="Cell">
                            <h1 className='info_itemblogRequerimientos' onClick={handleShowRequerimientosClick(event)}><HiSearch /> { "Detalles".toUpperCase()}</h1> 
                        </div>
                    </div>
                )
            }
        </div>
        <DetallesModal eventoSeleccionado={eventoSeleccionado}/>
        </>
    )
 }

 export default HistoricoTable;