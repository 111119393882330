import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import { HiSave } from "react-icons/hi";
import { CSVLink } from "react-csv";

/**
 * Styles
 */
 import '../styles/pacientesPendientes.css'

 /**
 * Componentes
 */
//Componente que muestra al usuario que se esta procesando la información del servicio
import Loader from './loader';
//Componente que muestra la información de un medico
import MedicoCard from "./medicoCard";
//Componente que muestra el listado de medicos en formato de tabla
import MedicoTable from "./medicoTable";
//Componente que indica al usuario que no se obtuvo información con sus parámetros de búsqueda
import SinEventos from './sinEventos';


/**
 * Objects
 */
//Importación de las funcionalidades del objeto de tipo unidadHospitalaria
import { getUnidadHospitalariaName } from '../objects/unidadHospitalaria';
import { createMedico } from "../objects/medico";

/**
 * Servicios
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore, doctordirectory } from "../utils/firebase";
//Importar las funcionalidades para la lógica del negocio
import { compararInformación, quitarAcentos } from '../utils/functions'


/**
 * Componente que muestra la información y acciones de los medicos
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los médicos
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada
 */
const MedicosSection = ({uhList,userLevel,nomHospital,isTarjetasChecked,setIsTarjetasChecked,vista,setVista,
    isMostrarMedicos,eventosPendientes,eventosConfirmados,events,userName,isReadOnly}) => {

    //Estado que indica si se hay usuarios que mostrar
    const [hayResultados, setHayResultados] = useState(false)
    //Estado que indica si el usuario logeado tiene permisos de calidad
    const [isCalidad, setIsCalidad] = useState(true)
    //Estado que indica que se esta solicitando información al servicio
    const [isLoader,setIsLoader] = useState(false)
    //Estado que indica si se muestra la opción para limpiar la búsqueda
    const [seMuestra,setSeMuestra] = useState(true)
    //Estado que almacena los resultados de la búsqueda de usuarios según el parámetros requerido por el usuario
    const [usersAmostrarList,setUsersAmostrarList] = useState([])
    //Estado que almacena los usuarios obtenidos desde la base de datos en una lista
    const [usersList,setUsersList] = useState([])
    //Estado que almacena el parametro de búsqueda para el muestreo de usuarios (si se encuentra vacio se consideran toda la lista de pacientes)
    const [valorBusqueda,setValorBusqueda] = useState("")

    const styleActive = { color: "white", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

    //Encabezado de las columnas que contendrá el reporte exportado
    const headers = [
        { label: "Unidad Hospitalaria", key: "Hospital"},
        { label: "Nombre", key: "Nombre"},
        { label: "Cedula Medicina General", key: "Cedula"},
        { label: "RFC", key: "RFC"},
        { label: "Celular", key: "NumeroCelular"},
        // { label: "Teléfono", key: "NumeroTelefonico"},
        // { label: "Extension", key: "Extension"},
        // { label: "Consultorio", key: "Consultorio"},
        { label: "Cedula Especialidad", key: "inputCedulaEspecialidad" },
        // { label: "Especialidad2", key: "Especialidad2"},
        { label: "Cedula Sub Especialidad", key: "inputCedulaSubEspecialidad"},
        { label: "Email", key: "CorreoElectronico"},
        // { label: "Sitio Web", key: "SitioWeb"},
        // { label: "Descripcion", key: "Descripcion"},
        // { label: "idTab_DirectorioMedico", key: "idTab_DirectorioMedico"},
      ];


    //Definición del contenido del reporte a exportar en CSV
    const csvreport = {
        data: usersAmostrarList,
        headers: headers,
        filename: 'Médicos Hospital ' + nomHospital  + '.csv',
    };

    useEffect(()=>{
        setValorBusqueda("")
        setIsLoader(true)
        pacientesStore.collection(doctordirectory)
        .onSnapshot(snap => {
            const users = []
            snap.forEach(doc => {
                let data = doc.data()
                try{
                    if(isMostrarMedicos){
                        if(data.doctorInfo.Validate !== 0){
                            if(!data.doctorInfo.IsEliminated){
                                users.push({ 
                                    id: doc.id,
                                    Descripcion : data.doctorInfo.Descripcion,
                                    idTab_DirectorioMedico : data.doctorInfo.idTab_DirectorioMedico,
                                    NumeroTelefonico : data.doctorInfo.NumeroTelefonico,
                                    NumeroCelular : data.doctorInfo.NumeroCelular,
                                    Extension : data.doctorInfo.Extension,
                                    SitioWeb: data.doctorInfo.SitioWeb,
                                    Especialidad : data.doctorInfo.Especialidad,
                                    Nombre : data.doctorInfo.Nombre,
                                    Especialidad2 : data.doctorInfo.Especialidad2,
                                    Subespecialidad : data.doctorInfo.Subespecialidad,
                                    Hospital : data.doctorInfo.Hospital,
                                    CorreoElectronico : data.doctorInfo.CorreoElectronico,
                                    Consultorio : data.doctorInfo.Consultorio,
                                    Cedula : data.doctorInfo.Cedula,
                                    RFC : data.doctorInfo.RFC,
                                    Validate: data.doctorInfo.Validate,
                                    CedulaEspecialidad : data.doctorInfo.CedulaEspecialidad,
                                    CedulaSubEspecialidad : data.doctorInfo.CedulaSubEspecialidad,
                                    inputCedulaEspecialidad : data.doctorInfo.inputCedulaEspecialidad,
                                    inputCedulaSubEspecialidad : data.doctorInfo.inputCedulaSubEspecialidad,
                                })
                            }
                        }
                    }
                    else{
                        if(data.doctorInfo.Validate === 0){
                            users.push({ 
                                id: doc.id,
                                Descripcion : data.doctorInfo.Descripcion,
                                idTab_DirectorioMedico : data.doctorInfo.idTab_DirectorioMedico,
                                NumeroTelefonico : data.doctorInfo.NumeroTelefonico,
                                NumeroCelular : data.doctorInfo.NumeroCelular,
                                Extension : data.doctorInfo.Extension,
                                SitioWeb: data.doctorInfo.SitioWeb,
                                Especialidad : data.doctorInfo.Especialidad,
                                Nombre : data.doctorInfo.Nombre,
                                Especialidad2 : data.doctorInfo.Especialidad2,
                                Subespecialidad : data.doctorInfo.Subespecialidad,
                                Hospital : data.doctorInfo.Hospital,
                                CorreoElectronico : data.doctorInfo.CorreoElectronico,
                                Consultorio : data.doctorInfo.Consultorio,
                                Cedula : data.doctorInfo.Cedula,
                                RFC : data.doctorInfo.RFC,
                                Validate: data.doctorInfo.Validate,
                                CedulaEspecialidad : data.doctorInfo.CedulaEspecialidad,
                                CedulaSubEspecialidad : data.doctorInfo.CedulaSubEspecialidad
                            })
                        }
                    }
                }
                catch{
                    
                }
            })

            setIsLoader(false)
            setUsersList(users)
        },(error)=>{
            setIsLoader(false)
            setUsersList([])
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
              })
        })
    },[isMostrarMedicos])

    useEffect(()=>{
        if(userLevel === "superuser" || userLevel === "calidad"){
            setIsCalidad(true)
        }
        else{
            setIsCalidad(false)
        }
    },[userLevel])

    useEffect(()=>{
        if(usersList.length > 0){//Verificar si existen usuarios
            if(valorBusqueda){//Se desea realizar búsqueda de un usuario específico
                usersList.sort(compararInformación('Nombre'))
                let usuariosValidos = 0
                let usuariosPermitidos = []
                usersList.forEach(
                    user => {
                        let cedula = user.Cedula
                                    let rfc = user.RFC
                                    let especialidad = user.Especialidad
                                    if(cedula){
                                        try{
                                            cedula = quitarAcentos(cedula).toUpperCase()
                                        }
                                        catch{
                                            cedula = cedula
                                        }
                                        
                                    }
                                    else{
                                        cedula = 'SR'
                                    }

                                    if(rfc){
                                        rfc = quitarAcentos(rfc).toUpperCase()
                                    }
                                    else{
                                        rfc = 'SR'
                                    }

                                    if(especialidad){
                                        especialidad = quitarAcentos(especialidad).toUpperCase()
                                    }
                                    else{
                                        especialidad = 'SR'
                                    }
                        if(userLevel !== "superuser"){//Se verifica si el usuario loegado tiene permisos de tipo administrador
                            let unidadName = getUnidadHospitalariaName(nomHospital,uhList)
                            if(unidadName === "MediMAC Merida"){
                                unidadName = "Merida"
                            }

                            let hospitalName = getUnidadHospitalariaName(user.Hospital,uhList)
                            if(user.Hospital === "MediMAC Merida" || user.Hospital === "Merida"){
                                hospitalName = "Merida"
                            }
                            
                            if(unidadName === hospitalName){//Se verifica si el usuario pertenece a la misma unidad hospitalaria que el user logeado                        
                                    if(quitarAcentos(user.Nombre).toUpperCase().includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || quitarAcentos(user.Hospital).toUpperCase().includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || especialidad.includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || cedula.toString().includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || rfc.includes(quitarAcentos(valorBusqueda).toUpperCase())){
                                        //Se designa como usuario disponible para vista
                                        usuariosValidos = usuariosValidos + 1
                                        usuariosPermitidos.push(user)
                                    }
                            }
                        }
                        else{//User logeado con acceso total
                            if(quitarAcentos(user.Nombre).toUpperCase().includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || quitarAcentos(user.Hospital).toUpperCase().includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || especialidad.includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || cedula.includes(quitarAcentos(valorBusqueda).toUpperCase())
                                    || rfc.includes(quitarAcentos(valorBusqueda).toUpperCase())){
                                        //Se designa como usuario disponible para vista
                                        usuariosValidos = usuariosValidos + 1
                                        usuariosPermitidos.push(user)
                                    }
                        }
                    }
                )

                if(usuariosValidos > 0){//Se verifica si hay usuario disponles para vista
                    //Se asigna la lista de usuarios disponibles para vista
                    setUsersAmostrarList(usuariosPermitidos)
                    setHayResultados(true)
                }
                else{//Se muestra la tarjeta de sin resultados
                    setUsersAmostrarList([])
                    setHayResultados(false)
                }
            }
            else{//Se muestran todos los usuarios disponibles
                usersList.sort(compararInformación('Nombre'))
                let usuariosValidos = 0
                let usuariosPermitidos = []
                usersList.forEach(
                    user => {
                        if(userLevel !== "superuser"){//Se verifica si el usuario loegado tiene permisos de tipo administrador
                            
                            let unidadName = getUnidadHospitalariaName(nomHospital,uhList)
                            if(unidadName === "MediMAC Merida"){
                                unidadName = "Merida"
                            }

                            let hospitalName = getUnidadHospitalariaName(user.Hospital,uhList)
                            if(user.Hospital === "MediMAC Merida" || user.Hospital === "Merida"){
                                hospitalName = "Merida"
                            }
                                                       
                            if(hospitalName === unidadName){//Se verifica si el usuario pertenece a la misma unidad hospitalaria que el user logeado
                                    //Se designa como usuario disponible para vista
                                    usuariosValidos = usuariosValidos + 1
                                    usuariosPermitidos.push(user)
                            }
                        }
                        else{//User logeado con acceso total
                            //Se dedgina como usuario disponible para vista
                            usuariosValidos = usuariosValidos + 1
                            usuariosPermitidos.push(user)
                        }
                    }
                )

                if(usuariosValidos > 0){//Se verifica si hay usuario disponles para vista
                    //Se asigna la lista de usuarios disponibles para vista
                    setUsersAmostrarList(usuariosPermitidos)
                    setHayResultados(true)
                }
                else{//Se muestra la tarjeta de sin resultados
                    setUsersAmostrarList([])
                    setHayResultados(false)
                }
            }
        }
        else{//No hay usuarios por mostrar
            setUsersAmostrarList([])
            setHayResultados(false)
        }
    },[usersList,valorBusqueda,uhList,nomHospital,userLevel])

    useEffect(()=>{
        if(valorBusqueda){
            setSeMuestra(true)
        }
        else{
            setSeMuestra(false)
        }
    },[valorBusqueda])

    useEffect(()=>{
        if(vista === "Lista"){
            setIsTarjetasChecked(false)
        }
        else{
            setIsTarjetasChecked(true)
        }
    },[vista,setIsTarjetasChecked])

    /**
     * Función que atrapa el evento para agregar un usuario
     * @returns Devuelve el estado del objeto
     */
     const handleAgregar = () => (event) => {
        event.preventDefault()
        let uhOpciones = ""
        if(userLevel === "superuser"){//Se verifica si el usuario logeado tiene acceso total
            //Se muestran en el select de unidades hospitalarias todas las unidades disponibles
            uhOpciones += "<option value="+0+">-- Seleccione la unidad hospitalaria --</option>"
            uhList.forEach(uh => {
                uhOpciones+="<option value="+uh.hospital+">"+uh.hospital+"</option>"
            })
        }else{//Usuario con acceso de tipo administrador
            //Se muestra en el select de unidad hospitalaria solo a la que pertenene el usuario logeado
            uhList.forEach(uh => {
                if(nomHospital === uh.hospital){
                    uhOpciones+="<option value="+uh.hospital+">"+uh.hospital+"</option>"
                }
            })
        }

        Swal.fire({
            customClass:'modalRegistro',
            html:`<div class="form_wrapper">
                <div class="form_container">
                    <div class="row clearfix">
                        <div class="">
                            <form autocomplete="off" method="post">
                                <h2 class="modalTitle">Médico Nuevo</h2>
                                <br />
                                <div class="input_field">
                                    <p>Nombre del médico</p>
                                    <input type="text" name="inputNombre" id="inputNombre" placeholder="Ingrese el nombre del médico" 
                                    required  class="inputText" autocomplete="off"
                                    oninput="this.value=value.replace(/[^a-zA-Z ñÑÁÉÍÓÚáéíóú]/g,'')"/>
                                </div>
                                <div class="input_field select_option">
                                    <p>Unidad Hospitalaria</p>
                                    <select id="selectUH">
                                        `+uhOpciones+`
                                    </select>
                                    <div class="select_arrow"></div>
                                </div>
                                <div class="input_field">
                                    <p>Cedula de Medicina General</p>
                                    <input type="text" name="inputCedulaEspecialidad" id="inputCedulaEspecialidad" placeholder="Ingrese la cedula de Medicina General" 
                                    required class="inputText" autocomplete="off"/>
                                </div>
                                <div class="input_field">
                                    <p>Cedula de Especialidad</p>
                                    <input type="text" name="inputCedulaEspecialidad2" id="inputCedulaEspecialidad2" placeholder="Ingrese la cedula de Especialidad" 
                                    required class="inputText" autocomplete="off"/>
                                </div>
                                <div class="input_field">
                                    <p>Cedula de Sub Especialidad</p>
                                    <input type="text" name="inputCedulaEspecialidad3" id="inputCedulaEspecialidad3" placeholder="Ingrese la cedula de Sub Especialidad" 
                                    required class="inputText" autocomplete="off"/>
                                </div>
                                <div class="input_field">
                                    <p>RFC</p>
                                    <input type="text" name="inputRFC" id="inputRFC" placeholder="Ingrese el RFC del médico" 
                                    required class="inputText" autocomplete="off"/>
                                </div>
                                <div class="input_field">
                                    <p>Teléfono del médico</p>
                                    <input type="text" name="inputTelefono" id="inputTelefono" placeholder="Ingrese el teléfono del médico" 
                                    required class="inputText" autocomplete="off"
                                    oninput="this.value=value.replace(/[' ']/g,'')"/>
                                </div>
                                <div class="input_field">
                                    <p>Correo electrónico del médico</p>
                                    <input type="text" name="inputCorreo" id="inputCorreo" placeholder="Ingrese correo electrónico del médico" 
                                    required class="inputText" autocomplete="off"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>`,
            showDenyButton: true,
            denyButtonText: "Cancelar",
            denyButtonColor: "grey",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#04afaa",
            scrollbarPadding:false,
            allowOutsideClick:false,
            width:'500px',
            heightAuto: false,
            focusConfirm: false,
            showCloseButton:true,
            preConfirm: () => {
                if (document.getElementById('inputNombre').value) {//Se verifica que el campo nombre sea valido
                    if (document.getElementById('inputCorreo').value || true){
                        if (document.getElementById('inputTelefono').value || true){
                            if (document.getElementById('inputTelefono').value.length === 10 || true){
                                if (document.getElementById('inputCedulaEspecialidad').value || document.getElementById('inputCedulaEspecialidad2').value || document.getElementById('inputCedulaEspecialidad3').value){
                                    var combo = document.getElementById("selectUH");
                                    var selected = combo.options[combo.selectedIndex].text;
                                    let unidad = ""
                                    if(selected === "HMAS Querétaro"){
                                        unidad = selected
                                    }
                                    else{
                                        if(selected === "HMAS Los Cabos"){
                                            unidad = selected
                                        }
                                        else{
                                            unidad = selected.split('MAC')[1].trim()
                                        }
                                    }

                                    let medicoObject = {
                                        "Descripcion": "",
                                        "idTab_DirectorioMedico": "",
                                        "NumeroTelefonico": "",
                                        "NumeroCelular": document.getElementById('inputTelefono').value,
                                        "Extension": "",
                                        "SitioWeb": "",
                                        "Especialidad": "",
                                        "Nombre": document.getElementById('inputNombre').value,
                                        "Especialidad2": "",
                                        "Subespecialidad": "",
                                        "Hospital": unidad,
                                        "CorreoElectronico": document.getElementById('inputCorreo').value,
                                        "Consultorio": "",
                                        "Cedula": document.getElementById('inputCedulaEspecialidad').value,
                                        "RFC": document.getElementById('inputRFC').value,
                                        "CedulaMedicinaGeneral":"",
                                        "inputCedulaEspecialidad" : document.getElementById('inputCedulaEspecialidad2').value,
                                        "inputCedulaSubEspecialidad" : document.getElementById('inputCedulaEspecialidad3').value
                                    }
                                    //console.log(medicoObject)

                                    let medicos = []
                                        
                                        
                                    //Proceso para carga de medicos
                                    for(let i=0;i<medicos.length;i++){
                                        let celularNuevo = ""
                                        if(medicos[i].NumeroCelular){
                                            celularNuevo = medicos[i].NumeroCelular
                                        }
                                        
                                        let cedulaEspecialidadNuevo = ""
                                        if(medicos[i].inputCedulaEspecialidad){
                                            cedulaEspecialidadNuevo = medicos[i].inputCedulaEspecialidad
                                        }

                                        let nombreNuevo = ""
                                        if(medicos[i].Nombre){
                                            nombreNuevo = medicos[i].Nombre
                                        }

                                        let correoNuevo = ""
                                        if(medicos[i].CorreoElectronico){
                                            correoNuevo = medicos[i].CorreoElectronico
                                        }

                                        let cedulaNuevo = ""
                                        if(medicos[i].Cedula){
                                            cedulaNuevo = medicos[i].Cedula
                                        }

                                        let rfcNuevo = ""
                                        if(medicos[i].RFC){
                                            rfcNuevo = medicos[i].RFC
                                        }

                                        let cedulaEspecialidadInput = ""
                                        if(medicos[i].inputCedulaEspecialidad){
                                            cedulaEspecialidadInput = medicos[i].inputCedulaEspecialidad
                                        }

                                        medicoObject = {
                                            "Descripcion": "",
                                            "idTab_DirectorioMedico": "",
                                            "NumeroTelefonico": "",
                                            "NumeroCelular": celularNuevo,
                                            "Extension": "",
                                            "SitioWeb": "",
                                            "CedulaEspecialidad": cedulaEspecialidadNuevo,
                                            "Nombre": nombreNuevo,
                                            "Especialidad2": "",
                                            "Subespecialidad": "",
                                            "Hospital": "Tlalnepantla",
                                            "CorreoElectronico": correoNuevo,
                                            "Consultorio": "",
                                            "Cedula": cedulaNuevo,
                                            "RFC": rfcNuevo,
                                            "CedulaMedicinaGeneral":"",
                                            "inputCedulaEspecialidad" : cedulaEspecialidadInput,
                                            "inputCedulaSubEspecialidad" : ""
                                        }

                                        //solicitarCreateUser()
                                    }
                                        

                                    solicitarCreateUser()
                                                    async function solicitarCreateUser(){
                                                        const response = await createMedico(medicoObject)
                                                        if(response === "success"){//Registro de usuario exitoso
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: 'Registro de médico',
                                                                text: 'Médico registrado de manera exitosa',
                                                                confirmButtonText: "Aceptar",
                                                                confirmButtonColor: "#04afaa",
                                                            })
                                                        }
                                                        else{//Error en el registro de usuario
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title: 'Error',
                                                                text: response,
                                                            })
                                                        }
                                                    }
                                }
                                else{
                                    Swal.showValidationMessage('Proporcione la cedula de la especialidad') 
                                }
                            }
                            else{
                                Swal.showValidationMessage('Proporcione el teléfono del médico')   
                            }
                        }
                        else{
                            Swal.showValidationMessage('Proporcione el teléfono del médico')   
                        }
                    }
                    else{
                        Swal.showValidationMessage('Proporcione la dirección de correo')   
                    }
                 } else {//Nombre de usuario invalido
                   Swal.showValidationMessage('Proporcione el nombre del usuario')   
                 }
              }
        })
    }

    /**
     * Función que atrapa el evento de limpiar la búsqueda
     * @returns Devuelve el estado del objeto
     */
     const handleCancelarClick = () => (event) => {
        event.preventDefault()
        setValorBusqueda("")
    }

    /**
     * Función que atrapa la selección de un genero
     * @param {Event} vista Recibe el evento disparado en la selección
     */
     const onChangeVista = (vista) => {
        setVista(vista.target.value)
    };

    return (
        <>
            <div className='firstfiltercont'>
                    <div className='ftconfliter'>
                        <div className="text-container">
                            <input type="text" 
                                name="valorBusqueda" 
                                value={valorBusqueda} 
                                className='inputbuscardirectorio' 
                                placeholder="Búsqueda abierta aquí..."
                                onChange={(event) => setValorBusqueda(event.target.value)}
                            />
                            {
                                seMuestra ? <span className='clearBtn' onClick={handleCancelarClick()}>X</span>
                                : null
                            } 
                        </div>
                        <div className="datos">
                                <input type="radio" value="Tarjetas" name="gender" checked={isTarjetasChecked} onChange={onChangeVista}/> Tarjetas
                                <input type="radio" value="Lista" name="gender" checked={!isTarjetasChecked} onChange={onChangeVista}/> Listado
                        </div>
                    </div>
            </div>
            {
                isLoader 
                    ?
                        <Loader/>
                    :
                        hayResultados ?
                            isTarjetasChecked
                            ?
                                usersAmostrarList.map(user => 
                                    <MedicoCard key={user.id} user={user} uhList={uhList} userLevel={userLevel} nomHospitalUser={nomHospital} isMostrarMedicos={isMostrarMedicos} 
                                    isCalidad={isCalidad} eventosPendientes={eventosPendientes} 
                                    eventosConfirmados={eventosConfirmados} events={events} userName={userName} isReadOnly={isReadOnly}/>
                                )
                            :
                                <MedicoTable usersAmostrarList={usersAmostrarList} isMostrarMedicos={isMostrarMedicos} isCalidad={isCalidad}
                                eventosPendientes={eventosPendientes} eventosConfirmados={eventosConfirmados} events={events} userName={userName} isReadOnly={isReadOnly}/>
                    : 
                        <SinEventos/>
            }
            {
                isCalidad
                    ?
                        <button className="btn-flotante" title="Agregar médico" alt="Agregar" onClick={handleAgregar()}>+</button>
                    :
                        null
            }
            {
                hayResultados
                    ?
                    <CSVLink {...csvreport} className="btn-flotanteExportMedicos" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
                    :
                        null
            }
        </>
    )
}

export default MedicosSection;