/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"
import {BsFillCalendarPlusFill} from 'react-icons/bs';
import { HiSearch } from "react-icons/hi";
import moment from 'moment';

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'

/**
 * Objects
 */
//Contiene la interacción a la base de datos del objeto de tipo calendarLog
import { createCalendarLog } from "../objects/calendarLog";

/**
 * Components
 */
//Componente que muestra la información de los requerimientos del médico tratante para la cirugía
import DetallesModal from "./detallesModal";

/**
 * Componente asignado al muestreo de la información de un evento
 * @param {Object} param0 Recibe los elementos necesarios para mostrar los detalles de un evento específico
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const EventCard = ({event,finalDay,initialDay,isMostrarRealizado,isMostrarEnProceso,isMostrarEnPendiente,uhList,
    eventoSeleccionado,setEventoSeleccionado,setIsMostrarEditar,nomHospital,userLevel,
    userName,isReadOnly}) => {

    //Estado que indica si el evento tiene requerimientos
    const [hadRequerimientos, setHadRequerimientos] = useState(false)
    //Estado que indica si el evento ha sido confirmado
    const [isConfirmed, setIsConfirmed] = useState(false)
    //Estado que indica si el evento corresponde al rango de fechas y sección seleccionada
    const [isDateValid,setIsDateValid] = useState(false)
    //Estado que indica si el evento ya ha sido realizado
    const [isExpired,setIsExpired] = useState(false)
    //Estado que indica si el evento ya ha pasado su tiempo y no fue confirmado
    const [isIgnored,setIsIgnored] = useState(false)
    //Estado que indica si el evento esta en proceso
    const [isNow,setIsNow] = useState(false)
    //Estado que indica si el evento esta por realizarse
    const [isPending,setIsPending] = useState(false)
    //Estado que almacena la referencia del médico tratante
    const [medico,setMedico] = useState("")
    //Estado que indica si el usuario tiene permitido ver las opciones de actualización de evento
    const [optionsVisible,setOptionsVisible] = useState(true)
    //Estado que almacena el nombre del paciente
    const [paciente,setPaciente] = useState("")
    //Estado que almacena el procedimiento a realizar
    const [procedimiento,setProcedimiento] = useState("")
    //Estado que almacena el quirófano solicitado
    const [quirofano,setQuirofano] = useState("")
    //Estado que almacena el teléfono de contacto del médico tratante
    const [telefonoMedico,setTelefonoMedico] = useState("")
    //Estado que almacena el teléfono de contacto del paciente
    const [telefonoPaciente,setTelefonoPaciente] = useState("")
    //Estado que almacena el nombre de la unidad hospitalaria en la que se reserva el evento
    const [unidadHospitalaria,setUnidadHospitalaria] = useState("")
    
    const [isPacific, setIsPacific] = useState(false)
    const [fechaInicio, setFechaInicio] = useState("")
    const [fechaFinal, setFechaFinal] = useState("")

    //Estilo asignado al icono de la tarjeta para pacientes en quirófano
    const styleActive = { color: "#84c404", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}
    //Estilo asignado al icono de la tarjeta para pacientes en quirófano
    const styleAltaAdministrativa = { color: "#04afaa", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}
    //Estilo asignado al icono de la tarjeta para pacientes en quirófano
    //const styleComplicacion = { color: "#e12f3b", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}
    //Estilo asignado al icono de la tarjeta para pacientes en recuperación
    const styleRecuperacion = { color: "#FFBF00", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}


    useEffect(()=>{
        var MyDate = new Date();
        if(MyDate.toString().includes("GMT-0700")){
        setIsPacific(true)
        }
        else{
        setIsPacific(false)
        }

        let inicio = initialDay
        let final = initialDay
        if(finalDay){
            final = finalDay
        }

        let dayEvento = null
        if(event.start.dateTime){
            dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
        }
        else{
            new Date(moment(event.start).format('MM/DD/YYYY'))
        }
        let dayInicial =  new Date(moment(inicio).format('MM/DD/YYYY'))
        let dayFinal =  new Date(moment(final).format('MM/DD/YYYY'))

        let tiempoInicial = ""
                            if(event.start.dateTime){
                                tiempoInicial = event.start.dateTime
                            }
                            else{
                                tiempoInicial = event.start
                            }
                            let tiempoFinal = ""
                            if(event.start.dateTime){
                                tiempoFinal = event.end.dateTime
                            }
                            else{
                                tiempoFinal = event.end
                            }
                            if(parseInt(tiempoInicial.split('T')[1].split(':')[0]) < 12){
                                setFechaInicio(tiempoInicial.split('T')[0].split('-')[2] + '-' + tiempoInicial.split('T')[0].split('-')[1] + '-' + tiempoInicial.split('T')[0].split('-')[0] + ' ' + tiempoInicial.split('T')[1].split(':')[0] + ":" + tiempoInicial.split('T')[1].split(':')[1] + " am")
                            }
                            else{
                                let hora = parseInt(tiempoInicial.split('T')[1].split(':')[0])
                                hora = hora - 12
                                setFechaInicio(tiempoInicial.split('T')[0].split('-')[2] + '-' + tiempoInicial.split('T')[0].split('-')[1] + '-' + tiempoInicial.split('T')[0].split('-')[0] + ' ' + hora.toString().padStart(2,'0') + ":" + tiempoInicial.split('T')[1].split(':')[1] + " pm")
                            }

                            if(parseInt(tiempoFinal.split('T')[1].split(':')[0]) < 12){
                                setFechaFinal(tiempoFinal.split('T')[0].split('-')[2] + '-' + tiempoFinal.split('T')[0].split('-')[1] + '-' + tiempoFinal.split('T')[0].split('-')[0] + ' ' + tiempoFinal.split('T')[1].split(':')[0] + ":" + tiempoFinal.split('T')[1].split(':')[1] + " am")
                            }
                            else{
                                let hora = parseInt(tiempoFinal.split('T')[1].split(':')[0])
                                hora = hora - 12
                                setFechaFinal(tiempoFinal.split('T')[0].split('-')[2] + '-' + tiempoFinal.split('T')[0].split('-')[1] + '-' + tiempoFinal.split('T')[0].split('-')[0] + ' ' + hora.toString().padStart(2,'0') + ":" + tiempoFinal.split('T')[1].split(':')[1] + " pm")
                            }

        

        let diferencia = moment(tiempoInicial).diff(moment().subtract(2, 'hours'),"minutes")
        
        let fechaEventoDos = new Date(tiempoInicial.split("T")[0] + " " + tiempoInicial.split("T")[1])
        let hoy = new Date()
        let restaHoy = (fechaEventoDos.getTime() - hoy.getTime())/1000
        restaHoy /= 60;
        let diferenciaHoy = Math.round(restaHoy)


        // if(diferenciaHoy < 0){
        //     console.log("A")
        //     console.log(tiempoInicial)
        //     console.log(diferencia)
        //     setIsExpired(false)
        //     setIsIgnored(false)
        //     if(event.summary === "Confirmada"){
        //         setIsNow(true)
        //         setIsPending(false)
        //     }
        //     else{
        //         setIsNow(false)
        //         setIsPending(true)
        //     }
        // }
        // else{
        //     console.log("B")
        //     console.log(tiempoInicial)
        //     console.log(event.start.dateTime)
        //     console.log(diferencia)
        //     setIsNow(false)
        //     setIsPending(false)
        //     setIsIgnored(false)
        //     setIsExpired(true)
        // }

        if(isMostrarEnPendiente){
            setIsPending(true)
            setIsNow(false)
            setIsExpired(false)
        }
        else{
            if(isMostrarEnProceso){
                setIsPending(false)
                setIsNow(true)
                setIsExpired(false)
            }
            else{
                if(isMostrarRealizado){
                    setIsPending(false)
                    setIsNow(false)
                    setIsExpired(true)
                }
            }
        }
        setIsDateValid(true)

        let description = event.description.toString().split('|')

        if(event.summary === "Confirmada"){
            setIsConfirmed(true)
            setIsPending(false)
        }
        else{
            setIsConfirmed(false)
            setIsPending(true)
        }

        let unidadHospitalariaEventoName = ""
        if(description.length > 1){
            setQuirofano(description[0].split('=')[1])
            setMedico(description[1].split('=')[1])
            setPaciente(description[2].split('=')[1])
            setProcedimiento(description[3].split('=')[1])
            setHadRequerimientos(true)
            if(description[6]){
                setTelefonoMedico(description[6].split('=')[1])
            }
            else{
                setTelefonoMedico("SR")
            }
            if(description[7]){
                if(description[7].split('=')[1]){
                    setTelefonoPaciente(description[7].split('=')[1])
                }
                else{
                    setTelefonoPaciente("SR")
                }
            }
            else{
                setTelefonoPaciente("SR")
            }

            if(description[8]){
                const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                if(resultado){
                    unidadHospitalariaEventoName = resultado.hospital
                    setUnidadHospitalaria(resultado.hospital)
                }
                else{
                    unidadHospitalariaEventoName = getUhName(event.organizer.displayName)
                    setUnidadHospitalaria(getUhName(event.organizer.displayName))
                }
                
            }
            else{
                unidadHospitalariaEventoName = getUhName(event.organizer.displayName)
                setUnidadHospitalaria(getUhName(event.organizer.displayName))
            }
        }
        else{
            setQuirofano(description[0])
            setMedico("SR")
            setPaciente("SR")
            setProcedimiento("SR")
            setTelefonoMedico("SR")
            setTelefonoPaciente("SR")
            setHadRequerimientos(false)
            setUnidadHospitalaria(getUhName(event.organizer.displayName))
            unidadHospitalariaEventoName = getUhName(event.organizer.displayName)
            
        }

        if(userLevel !== "superuser"){
            if(unidadHospitalariaEventoName !== nomHospital){
                if(nomHospital !== "Corporativo"){
                    setIsDateValid(false)
                }
                
            }
            else{
                
            }

        }

        /**
         * Función que busca el nombre de la unidad hospitalaría a la que pertenece el evento
         * @param {String} organizer Recibe el nombre del organizador en el calendario
         * @returns String Devuelve el nombre de la unidad hospitalaria encontrada
         */
        function getUhName(organizer){
            try{
                //Se toma el nombre del organizador a partir de donde se encuentre la palabra MAC
                let unidadABuscar= organizer.toUpperCase().split('MAC')[1]
                
                //Se realiza la búsqueda en la lista de unidad hospitalarias disponibles
                let busqueda = []
                uhList.forEach(
                    uh => {
                        if(uh.hospital.toUpperCase().includes(unidadABuscar.toUpperCase())){
                            busqueda.push(uh.hospital.toUpperCase())
                        }
                    }
                )

                if(busqueda.length > 0){//Se verifica si hay resultados en la búsqueda y se devuelve el valor localizado
                    return busqueda
                }
                else{//Se devuelve el nombre del organizador del calendario
                    return organizer
                }
            }
            catch{//Errores inesperados se devuelve el nombre del organizador del calendario
                return organizer
            }
        }
        
    },[event,initialDay,finalDay,isMostrarRealizado,isMostrarEnProceso,isMostrarEnPendiente,uhList,userLevel,nomHospital])

    useEffect(()=>{
        if(userLevel === "superuser"  || userLevel === "agenda"){
            setOptionsVisible(true)
        }
        else{
            setOptionsVisible(false)
        }
    },[userLevel])

    /**
     * Función que atrapa el evento click de cancelar y dispara la solicitud de cancelación de evento
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleCancelarClick = (evento) => (event) => {
        event.preventDefault()
        let mensaje = ""
        if(isPacific){
            mensaje = unidadHospitalaria + " " + paciente.toUpperCase() + " " + procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')
        }
        else{
            mensaje = unidadHospitalaria + " " + paciente.toUpperCase() + " " + procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            + " al " + moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }
        
        let motivoOpciones = ""
        motivoOpciones+="<option value='cero' selected>Seleccione el motivo de la cancelación</option>"
        motivoOpciones+="<option value='Cancelada por el médico'>Cancelada por el médico</option>"
        motivoOpciones+="<option value='Cancelada por el Paciente'>Cancelada por el Paciente</option>"
        motivoOpciones+="<option value='Error de Captura'>Error de Captura</option>"
        motivoOpciones+="<option value='Expediente'>Expediente</option>"
        motivoOpciones+="<option value='Hospital'>Hospital</option>"
        motivoOpciones+="<option value='Aseguradora'>Aseguradora</option>"
        
        Swal.fire({
            title: "Se cancelará la reservación del quirófano:",
            html:`<div class="form_wrapper">
                                                                            <div class="form_container">
                                                                                <div class="row clearfix">
                                                                                    <div class="">
                                                                                        <form autocomplete="off" method="post">
                                                                                            <h3>`+mensaje+`</h3>
                                                                                            <br />
                                                                                            <div class="input_field select_option">
                                                                                                <select id="selectMotivo">
                                                                                                    `+motivoOpciones+`
                                                                                                </select>
                                                                                                <div class="select_arrow"></div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>`,
            text: mensaje,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#14988f',
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
            cancelButtonText:`Cancelar`,
            customClass: 'swal-confirm',
            preConfirm: () => {
                if(document.getElementById('selectMotivo').value !== "cero"){
                    let date = new Date()
                    //Se solicita el registro del evento
                    solicitarUpdateEvent()
                    async function solicitarUpdateEvent(){
                        const response = await createCalendarLog(evento.id,"cancel",document.getElementById('selectMotivo').value,evento,
                        evento.description,userName,date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                        date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds(),evento.id_evento)
                        if(response === "success"){//Registro de usuario exitoso
                            Swal.fire({
                                icon: 'success',
                                title: 'Actualización de evento',
                                text: 'Cirugía cancelada de manera exitosa',
                                confirmButtonText: "Aceptar",
                                confirmButtonColor: "#04afaa",
                            })
                        }
                        else{//Error en el registro de usuario
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response,
                            })
                        }
                    }
                }
                else{
                    Swal.showValidationMessage('Seleccione el motivo de la reasignación')   
                } 
            }
        })

    }

    /**
     * Función que atrapa el evento click de confirmar y dispara la solicitud de confirmación del evento
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleConfirmarClick = (evento) => (event) => {
        event.preventDefault()

        let horaInicial = ""
        if(moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a') === "Invalid date"){
            if(evento.start.dateTime){
                horaInicial = moment(evento.start.dateTime.split("T")[0]+"T0"+evento.start.dateTime.split("T")[1]).format('DD-MM-YYYY hh:mm:ss a')
            }
        }
        else{
            horaInicial = moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }

        let horaFinal = ""
        if(moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a') === "Invalid date"){
            if(evento.end.dateTime){
                horaFinal = moment(evento.end.dateTime.split("T")[0]+"T0"+evento.end.dateTime.split("T")[1]).format('DD-MM-YYYY hh:mm:ss a')
            }
        }
        else{
            horaFinal = moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }


        let mensaje = ""
        if(isPacific){
            mensaje = unidadHospitalaria + " " + paciente.toUpperCase() + " " + procedimiento.toUpperCase() + " del " + horaInicial
            + " al " + moment(evento.end.dateTime).format('DD-MM-YYYY hh:mm:ss a')
        }
        else{
            // mensaje = unidadHospitalaria + " " + paciente.toUpperCase() + " " + procedimiento.toUpperCase() + " del " + moment(evento.start.dateTime).format('DD-MM-YYYY hh:mm:ss a')
            // + " al " + moment(evento.end.dateTime).add(1,'hours').format('DD-MM-YYYY hh:mm:ss a')
            mensaje = unidadHospitalaria + " " + paciente.toUpperCase() + " " + procedimiento.toUpperCase() + " del " + horaInicial
            + " al " + horaFinal
        }
        Swal.fire({
            title: "Se confirmará la reservación del quirófano:",
            text: mensaje,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonColor: '#14988f',
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
            cancelButtonText:`Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                //Se solicita el registro del evento
                let description = evento.description.toString().split('|')
                let requetsBody = ""
                requetsBody += description[0] + "|"
                requetsBody += description[1] + "|"
                requetsBody += description[2] + "|"
                requetsBody += description[3] + "|"
                requetsBody += description[4] + "|"
                requetsBody += description[5] + "|"
                requetsBody += description[6] + "|"
                requetsBody += description[7] + "|"
                requetsBody += description[8] + "|"
                requetsBody += description[9] + "|"
                requetsBody += description[10] + "|"
                requetsBody += description[11] + "|"
                requetsBody += description[12] + "|"
                requetsBody += description[13] + "|"
                requetsBody += description[14] + "|"
                requetsBody += description[15] + "|"
                requetsBody += description[16] + "|"
                requetsBody += description[17] + "|"
                requetsBody += "Solicitado=" + userName
                requetsBody += "|Concepto=Confirmación"
                if(description[20]){
                    requetsBody += description[20] + "|"
                }
                else{
                    requetsBody += "|EstudiosTransoperatorios=NA"
                }
                if(description[21]){
                    requetsBody += description[21] + "|"
                }
                else{
                    requetsBody += "|TipoClienteOpcion=NA"
                }
                if(description[22]){
                    requetsBody += description[22] + "|"
                }
                else{
                    requetsBody += "|TipoAnestesiologo=NA"
                }
                if(description[23]){
                    requetsBody += description[32] + "|"
                }
                else{
                    requetsBody += "|TipoCambio=NA"
                }
                let date = new Date()

                solicitarUpdateEvent()
                async function solicitarUpdateEvent(){
                    const response = await createCalendarLog(evento.id,"confirmar","confirm",evento,requetsBody,userName,
                    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                    date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds(),evento.id_evento)
                    if(response === "success"){//Registro de usuario exitoso
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización de evento',
                            text: 'Cirugía confirmada de manera exitosa',
                            confirmButtonText: "Aceptar",
                            confirmButtonColor: "#04afaa",
                          })
                    }
                    else{//Error en el registro de usuario
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response,
                          })
                    }
                }
            }
        })
    }

    /**
     * Función que atrapa el evento click para editar un evento y disparara el muestreo de la sección de edición
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleShowEditeFormClick = (evento) => (event) => {
        event.preventDefault()
        setEventoSeleccionado(evento)
        setIsMostrarEditar(true)
    }

    /**
     * Función que atrapa el evento click para mostrar los requerimientos y dispara el muestro del modal
     * @param {Object} evento Recibe un objeto de tipo evento
     * @returns Devuelve el estado del objeto
     */
    const handleShowRequerimientosClick = (evento) => (event) => {
        event.preventDefault()
        setEventoSeleccionado(evento)
        if(document.getElementById('modal').style.display === "none" || !document.getElementById('modal').style.display){
            document.getElementById('modal').style.display='block'
        }
    }

    return (
        <>
            {
                isDateValid 
                ? <div className={"itemblog"}>
                        <div>
                            {
                                isExpired 
                                    ? <BsFillCalendarPlusFill style={styleAltaAdministrativa}/>
                                    : isPending 
                                        ? <BsFillCalendarPlusFill style={styleRecuperacion}/>
                                        : isNow
                                            ? <BsFillCalendarPlusFill style={styleActive}/>
                                            : isIgnored
                                                ? <BsFillCalendarPlusFill style={styleAltaAdministrativa}/>
                                                : null
                            }
                            {
                                isExpired || isIgnored
                                    ?   
                                        <h1 className='itemblogTitleEstatus'>{"Cirugía realizada"}</h1>
                                    :
                                        null
                            }
                            <h1 className='itemblogTitle'>{quirofano.toUpperCase()}</h1>        
                            <h1 className='info_item_hospital_name'>{unidadHospitalaria}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Solicitado el ".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{moment(event.created).format('DD-MM-YYYY hh:mm:ss a')}</h1>           
                            <h1 className='info_itemblogSubtitle'>{"Médico".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{medico.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Teléfono Médico".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{telefonoMedico}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Paciente".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{paciente.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Teléfono Paciente".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{telefonoPaciente}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Procedimiento".toUpperCase()}</h1>
                            <h1 className='info_itemblog'>{procedimiento.toUpperCase()}</h1>
                            <h1 className='info_itemblogSubtitle'>{"Inicio".toUpperCase()}</h1>
                            {
                                isPacific
                                    ?
                                        <h1 className='info_itemblog'>{fechaInicio}</h1>
                                    :
                                        <h1 className='info_itemblog'>{fechaInicio}</h1>
                            }
                            <h1 className='info_itemblogSubtitle'>{"Fin".toUpperCase()}</h1>
                            {
                                isPacific
                                    ?
                                        <h1 className='info_itemblog'>{fechaFinal}</h1>
                                    :
                                        <h1 className='info_itemblog'>{fechaFinal}</h1>
                            }                 
                            {
                                hadRequerimientos 
                                    ?
                                        <h1 className='info_itemblogRequerimientos' onClick={handleShowRequerimientosClick(event)}><HiSearch /> { "Detalles".toUpperCase()}</h1> 
                                    :
                                        null
                            }
                            <br />
                            {
                                optionsVisible
                                    ?
                                        isPending
                                        ?
                                            <>
                                                {
                                                    !isConfirmed && !isExpired
                                                        ?
                                                            <p className='boton_confirmar' onClick={handleConfirmarClick(event)}>Confirmar</p>
                                                        :
                                                            null
                                                }
                                                <p className='boton_editar' onClick={handleShowEditeFormClick(event)}>Editar</p>
                                                <p className='boton_cancel' onClick={handleCancelarClick(event)}> Cancelar</p>
                                            </>
                                        : 
                                            isNow
                                                ?
                                                    <>
                                                        <p className='boton_editar' onClick={handleShowEditeFormClick(event)}>Editar</p>
                                                        <p className='boton_cancel' onClick={handleCancelarClick(event)}>Cancelar</p>
                                                    </>
                                                :
                                                    <>
                                                        <p className='boton_editar' onClick={handleShowEditeFormClick(event)}>Editar</p>
                                                        <p className='boton_cancel' onClick={handleCancelarClick(event)}>Cancelar</p>
                                                    </>
                                    :
                                        null
                            }
                        </div>
                    </div>
                : null
            }
            {
                <DetallesModal eventoSeleccionado={eventoSeleccionado}/>
            }
        </>
    )
}

export default EventCard;