/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore
import { pacientesStore } from '../utils/firebase';

/**
 * Función que solicita el registro de un nuevo evento a la colección de calendarIntent
 * @param {String} AppointmentType Recibe el identificador del recurso solicitado
 * @param {String} date Recibe la fecha inicial de la reservación
 * @param {String} platform Recibe la plataforma desde la cual se hizo la petición
 * @param {String} created Recibe la fecha en que se realiza la petición
 * @param {String} Building_Id Recibe el identificador de la unidad hospitalaria que solicita la reservación
 * @param {String} Resource_Id Recibe el identificador del quirófano que se desea reservar
 * @param {String} Resource_Name Recibe el nombre del quirófano que se desea reservar
 * @param {String} dateFinal Recibe la fecha final de la reservación
 * @returns String Devuelve el resultado de la solicitud
 */
export const createEventIntent = async (AppointmentType, date, platform, created, Building_Id,Resource_Id,Resource_Name,dateFinal) => {
  let timeStartPrueba = ""
        let dateInicio =  date.split('T')[0]
        let timeInicio = date.split('T')[1]

        if(timeInicio.split(':')[1].toString() === "30"){
            timeStartPrueba = dateInicio+"T"+timeInicio.split(':')[0]+":00"
        }
        else{
            timeStartPrueba = dateInicio+"T"+(parseInt(timeInicio.split(':')[0]) - 1)+":30"
        }

        let timeEndPrueba = ""
        let dateTermino =  dateFinal.split('T')[0]
        let timeTermino = dateFinal.split('T')[1]

        if(timeTermino.split(':')[1].toString() === "30"){
            timeEndPrueba = dateTermino+"T"+(parseInt(timeTermino.split(':')[0]) + 1)+":00"
        }
        else{
            timeEndPrueba = dateTermino+"T"+(timeTermino.split(':')[0])+":30"
        }  
  
        let eventObject = {
          AppointmentType:    AppointmentType,
          date:               date,
          platform:           platform,
          created:            created,
          Building_Id:        Building_Id,
          Resource_Id:        Resource_Id,
          Resource_Name:      Resource_Name,
          dateFinal:          dateFinal,
          dateLimpieza : timeStartPrueba,
          dateFinalLimpieza: timeEndPrueba
        }

    try {
        const response = await fetch('https://us-central1-auto-factura-erps.cloudfunctions.net/createCalendarEvent', {
          method: 'POST',
          body: JSON.stringify(eventObject),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        return "success"
      } catch (error) {
        console.error('Error:', error);
        return error
      }
}