/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import moment from 'moment';
import Swal from "sweetalert2"
// import {Notifications} from 'react-push-notification'
// import addNotification  from 'react-push-notification'

/**
 * Utilerías
 */
//Importar el espacio de almacenamiento de base de datos firestore y las colecciones necesarias
import { pacientesStore, surgerycalendarrequest,calendarLog, doctordirectory, usersCollection } from "../utils/firebase";

/**
 * Objetos
 */
//Importar las funcionalidades del objeto de tipo UnidadHospitalaria
import { getUnidadHospitalariaName } from "../objects/unidadHospitalaria";

/**
 * Componentes
 */
//Componente que muestra la información y acciones del calendario
import CalendarSection from "../components/calendarSection";
//Componente que muestra el elemento que permite la selección de fechas
import DateModal from "../components/dateModal";
//Componente que muestra los elementos relacionados con el Footer
import Footer from '../components/footer';
//Componente que muestra los elementos relacionados con el Header
import Header from "../components/header";

/**
 * Page asignada a la visualización del calendario de eventos reservados
 * @returns Component Devuelve el sitio con los elementos y funcionalidad asignada
 */
const CalendarPage = ({userName,userNomHospital,userLevel,uhList,access,isReadOnly,isCorporativo,
  setuserNomHospital,setHospitalSeleccionado,hospitalSeleccionado}) => {

    //Estado que almacena la cantidad de eventos cancelados
    const [cantidadCanceladas, setCantidadCanceladas] = useState(0)
    //Estado que almacena la cantidad de eventos pendientes de realizarse
    const [cantidadPendiente,setCantidadPendiente] = useState(0)
    //Estado que almacena la cantidad de eventos que se están realizando
    const [cantidadProceso,setCantidadProceso] = useState(0)
    //Estado que almacena la cantidad de eventos realizados
    const [cantidadRealizada,setCantidadRealizada] = useState(0)
    //Estado que almacena la lista de eventos obtenidos en un rango de fecha
    const [events,setEvents] = useState([])
    //Estado que almacena la lista de medicos
    const [medicos, setMedicos] = useState([])
    //Estado que almacena la lista de eventos cancelados en un rango de fecha
    const [cancelEvents,setCancelEvents] = useState([])
    //Estado que indica la fecha final del rango deseado
    const [finalDay, setFinalDay] = useState(new Date())
    //Estado que indica la fecha inicial del rango deseado
    const [initialDay,setInitialDay] = useState(new Date())
    //Estado que indica si no se obtuvieron eventos en el rango deseado
    const [isEmpty, setIsEmpty] = useState(false)
    //Estado que indica si esta cargando la lista de eventos
    const [isLoading, setIsLoading] = useState(true)
    //Estado que indica si se ha seleccionado la opción de editar un evento
    const [isMostrarEditar, setIsMostrarEditar] = useState(false)
    const [isMostrarAgendar, setIsMostrarAgendar] = useState(false)
    //Estado que indica si se tiene seleccionada la opción de eventos pendientes
    const [isMostrarEnPendiente,setIsMostrarEnPendiente] = useState(true)
    //Estado que indica si se tiene seleccionada la opción de eventos en proceso
    const [isMostrarEnProceso,setIsMostrarEnProceso] = useState(false)
    //Estado que indica si se mostrará la opción de administración de quirófanos
    const [isMostrarQuirofanos, setIsMostrarQuirofanos] = useState(false)
    //Estado que indica si se tiene seleccionada la opción de eventos realizados
    const [isMostrarRealizado,setIsMostrarRealizado] = useState(false)
    //Estado que infica si se mostrará la opción de administración de usuarios
    const [isMostrarUsuarios, setIsMostrarUsuarios] = useState(false)
    //Estado que indica el rango de fechas seleccionadas
    const [rangeMessage, setRangeMessage] = useState("")
    //Estado que indica si se mostrará la opción de médicos activos
    const [isMostrarMedicos, setIsMostrarMedicos] = useState(false)
    const [editEvents,setEditEvents] = useState([])

    const [isMostrarAgendaVista, setIsMostrarAgendaVista] = useState(false)

    const [pendientesUnidad, setPendientesUnidad] = useState([])
    const [pendientesUnidadEventFormat, setPendientesUnidadEventFormat] = useState([])
    const [agendadosUnidad, setAgendadosUnidad] = useState([])
    const [agendadoUnidadEventFormat, setAgendadosUnidadEventFormat] = useState([])
    const [realizadosUnidad, setRealizadosUnidad] = useState([])
    const [realizadosUnidadEventFormat, setRealizadosUnidadEventFormat] = useState([])

    const [canceladosUnidad, setCanceladosUnidad] = useState([])
    const [canceladosUnidadEventFormat, setCanceladosUnidadEventFormat] = useState([])

    const [generalUnidad, setGeneralUnidad] = useState([])

    const [isMostrarIndicadores, setIsMostrarIndicadores] = useState(false)
    const [editEventsUnidad, setEditEventsUnidad] = useState([])



    /**
     * Función que solicita la petición del listado de eventos de calendario
     */
    function getCalendarRequest(){
      try{
        pacientesStore
        .collection(surgerycalendarrequest)
        .add({
            created:       new Date()
        })
      }
      catch{
          //console.log('error')
      }
    }

    function getEventObject(event,estatus,style){
      let unidadHospitalaria = "SR"//
      let quirofano = "SR"//
      let medico = "SR"//
      let telefonoMedico = "SR"//
      let nombrePaciente = "SR"//
      let telefonoPaciente = "SR"//
      let fechaNacimiento = "SR"//
      let edad = "SR"//
      let sexo = "SR"//
      let tipoCliente = "SR"//
      let diagnostico = "SR"//
      let procedimiento = "SR"//
      let ladoProcedimiento = "SR"//
      let anestesiologo = "SR"//
      let tipoAnestesia = "SR"//
      let fecha = "SR"//
      let horaInicio = "SR"//
      let horaFin = "SR"//
      let duracion = "SR"//
      let materiales = "SR"//
      let extras = "SR"//
      let ultimaSolicitud = "SR"//
      let solicito = "SR"//
      let start = ""//
      let end = ""//
      let tipoReasignacion = ""//
      let materialesEspeciales = ""//
      let hemoderivados = ""//

      if(event.description){
          let description = event.description.toString().split('|')
          if(description.length > 1){
              if(event.start.dateTime){
                  fecha = event.start.dateTime.split('T')[0]
              }
              else{
                  fecha = event.start.split('T')[0]
              }

              if(event.start.dateTime){
                  horaInicio = event.start.dateTime.split('T')[1].split('-')[0]
              }
              else{
                  horaInicio = event.start.split('T')[1].split('-')[0]
              }
              
              if(event.end.dateTime){
                  horaFin = event.end.dateTime.split('T')[1].split('-')[0]
              }
              else{
                  horaFin = event.end.split('T')[1].split('-')[0]
              }


              for(let n =0;n<description.length;n++){
                  let evaluar = description[n].split('=')[0]

                  if(evaluar === "AppointmentType"){//0
                      quirofano = description[n].split('=')[1]
                  }

                  if(evaluar === "Medico"){//1
                      medico = description[n].split('=')[1]
                  }

                  if(evaluar === "Paciente"){//2
                      nombrePaciente = description[n].split('=')[1]
                  }

                  if(evaluar === "Procedimiento"){//3
                      procedimiento = description[n].split('=')[1]
                  }

                  if(evaluar === "Equipo Quirúrgico"){//4
                      materiales = description[n].split('=')[1]
                  }

                  if(evaluar === "Extras"){//5
                      extras = description[n].split('=')[1]
                  }

                  if(evaluar === "Telefono Medico"){//6
                      telefonoMedico = description[n].split('=')[1]
                  }

                  if(evaluar === "Telefono Paciente"){//7
                      telefonoPaciente = description[n].split('=')[1]
                  }

                  if(evaluar === "Unidad Hospitalaroa"){//8
                      unidadHospitalaria = description[n].split('=')[1]
                  }

                  if(evaluar === "Duracion"){//9
                      duracion = description[n].split('=')[1]
                  }

                  if(evaluar === "LadoProcedimiento"){//10
                      ladoProcedimiento = description[n].split('=')[1]
                  }

                  if(evaluar === "Anestesiologo"){//11
                      anestesiologo = description[n].split('=')[1]
                  }

                  if(evaluar === "TipoAnestesia"){//12
                      tipoAnestesia = description[n].split('=')[1]
                  }

                  if(evaluar === "FechaNacimientoPaciente"){//13
                      fechaNacimiento = description[n].split('=')[1]
                  }

                  if(evaluar === "EdadPaciente"){//14
                      edad = description[n].split('=')[1]
                  }

                  if(evaluar === "SexoPaciente"){//15
                      sexo = description[n].split('=')[1]
                  }

                  if(evaluar === "TipoCliente"){//16
                      tipoCliente = description[n].split('=')[1]
                  }

                  if(evaluar === "Diagnostico"){//17
                      diagnostico = description[n].split('=')[1]
                  }

                  if(evaluar === "Solicitado"){//18
                      solicito = description[n].split('=')[1]
                  }

                  if(evaluar === "Concepto"){//19
                      ultimaSolicitud = description[n].split('=')[1]
                  }

                  if(evaluar === "EquipoEspecial"){//23
                      materialesEspeciales = description[n].split('=')[1]
                  }

                  if(evaluar === "Hemoderivados"){//24
                      hemoderivados = description[n].split('=')[1]
                  }

                  if(evaluar === "TipoCambio"){//25
                      tipoReasignacion = description[n].split('=')[1]
                  }
              }
              
              
          }
          else{
              quirofano = description[0]
          }

          if(event.start.dateTime){
              start = event.start.dateTime
              end = event.end.dateTime
          }
          else{
              start = event.start
              end = event.end
          }
          
      }
      else{
          unidadHospitalaria = event.UnidadHospitalaria
          quirofano = event.Sala
          medico = event.Medico
          telefonoMedico = event.TelefonoMedico
          nombrePaciente = event.Paciente
          telefonoPaciente = event.TelefonoPaciente
          fechaNacimiento = event.FechaNacimiento
          edad = event.Edad
          sexo = event.Sexo
          tipoCliente = event.TipoCliente
          diagnostico = event.Diagnostico
          procedimiento = event.Procedimiento
          ladoProcedimiento = event.LadoProcedimiento
          anestesiologo = event.Anestesiologo
          tipoAnestesia = event.TipoAnestesia
          fecha = event.Fecha
          horaInicio = event.HoraInicio
          horaFin = event.HoraFin
          duracion = event.Duracion
          materiales = event.Materiales
          extras = event.Extras
          ultimaSolicitud = event.UltimaSolicitud
          solicito = event.Realizo
          start = event.start
          end = event.end
          tipoReasignacion = "SR"
      }

      return {
          'Estatus' : estatus,
          'UnidadHospitalaria' : unidadHospitalaria,
          'Sala': quirofano,
          'Medico': medico,
          'TelefonoMedico' : telefonoMedico,
          'Paciente': nombrePaciente,
          'TelefonoPaciente':telefonoPaciente,
          'FechaNacimiento':fechaNacimiento,
          'Edad':edad,
          'Sexo':sexo,
          'TipoCliente':tipoCliente,
          'Diagnostico':diagnostico,
          'Procedimiento':procedimiento,
          'LadoProcedimiento':ladoProcedimiento,
          'Anestesiologo':anestesiologo,
          'TipoAnestesia':tipoAnestesia,
          'Fecha':fecha,
          'HoraInicio':horaInicio,
          'HoraFin':horaFin,
          'Duracion':duracion,
          'Materiales':materiales,
          'Extras':extras,
          'UltimaSolicitud':ultimaSolicitud,
          'Realizo': solicito,
          'start':start,
          'end':end,
          'created':event.created,
          'type':style,
          'id_evento' : event.id_evento,
          'TipoReasignacion' : tipoReasignacion,
          'MaterialesEspeciales' : materialesEspeciales,
          'Hemoderivados' : hemoderivados
      }
      
  }

  useEffect(()=>{
    let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))

    let dayFinal =  new Date(moment(finalDay).format('MM/DD/YYYY'))
    if(!finalDay){
      dayFinal =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    }
    

    let eventosGeneral = []

    let eventosPendientesDeConfirmar = []
    let eventPending = []

    let eventosRealizados = []
    let eventRealizado = []

    let eventosDefinidos = []
    let eventDefinido = []

    let eventosRevisar = []

    let unidad = userNomHospital
    let hospitalNameOne = ""
    let hospitalNameTwo = ""

    switch(unidad){
      case "MAC León":
        hospitalNameOne = "Hospital-MAC-León"
        hospitalNameTwo = "León"
        break
      case "HMAS Los Cabos":
        hospitalNameOne = "Hospital-HMAS-Los Cabos"
        hospitalNameTwo = "Los Cabos"
        break
      case "HMAS Querétaro":
        hospitalNameOne = "Hospital-HMAS-Querétaro"
        hospitalNameTwo = "Querétaro"
        break
      case "MAC Aguascalientes Norte":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Norte"
        hospitalNameTwo = "Aguascalientes Norte"
        break
      case "MAC Aguascalientes Sur":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Sur"
        hospitalNameTwo = "Aguascalientes Sur"
        break
      case "MAC Celaya":
        hospitalNameOne = "Hospital-MAC-Celaya"
        hospitalNameTwo = "Celaya"
        break
      case "MAC Guadalajara":
        hospitalNameOne = "Hospital-MAC-Guadalajara"
        hospitalNameTwo = "Guadalajara"
        break
      case "MAC Integración Procesos Clínicos":
        hospitalNameOne = "Hospital-MAC-Integración Procesos Clínicos"
        hospitalNameTwo = "Integración Procesos Clínicos"
        break
      case "MAC Irapuato":
        hospitalNameOne = "Hospital-MAC-Irapuato"
        hospitalNameTwo = "Irapuato"
        break
      case "MAC La Viga":
        hospitalNameOne = "Hospital-MAC-La Viga"
        hospitalNameTwo = "La Viga"
        break
      case "MAC Los Mochis":
        hospitalNameOne = "Hospital-MAC-Los Mochis"
        hospitalNameTwo = "Los Mochis"
        break
      case "MAC Mexicali":
        hospitalNameOne = "Hospital-MAC-Mexicali"
        hospitalNameTwo = "Mexicali"
        break
      case "MAC Perisur":
        hospitalNameOne = "Hospital-MAC-Perisur"
        hospitalNameTwo = "Perisur"
        break
      case "MAC Puebla":
        hospitalNameOne = "Hospital-MAC-Puebla"
        hospitalNameTwo = "Puebla"
        break
      case "MAC San Miguel de Allende":
        hospitalNameOne = "Hospital-MAC-San Miguel de Allende"
        hospitalNameTwo = "San Miguel de Allende"
        break
      case "MAC Tampico":
        hospitalNameOne = "Hospital-MAC-Tampico"
        hospitalNameTwo = "Tampico"
        break
      case "MediMAC Boca del Rio":
        hospitalNameOne = "Hospital-MAC-Boca del Río"
        hospitalNameTwo = "Boca del Rio"
        break
      case "MediMAC Cuemanco":
        hospitalNameOne = "Hospital-MAC-Cuemanco"
        hospitalNameTwo = "Cuemanco"
        break
      case "MediMAC Ecatepec":
        hospitalNameOne = "Hospital-MAC-Ecatepec"
        hospitalNameTwo = "Ecatepec"
        break
      case "MediMAC Guanajuato":
        hospitalNameOne = "Hospital-MAC-Guanajuato"
        hospitalNameTwo = "Guanajuato"
        break
      case "MediMAC Merida":
        hospitalNameOne = "Hospital-MAC-Merida"
        hospitalNameTwo = "Merida"
        break
      case "MediMAC Tlalnepantla":
        hospitalNameOne = "Hospital-MAC-Tlalnepantla"
        hospitalNameTwo = "Tlalnepantla"
        break
      default :
        break
    }


      if(userNomHospital !== "Corporativo"){
        //new
        events.forEach(event => {
          let dayEvento = null
          let timeEvento = null
          let timeEventoEnd = null

          if(event.start.dateTime){
            dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
            timeEvento = event.start.dateTime
          }
          else{
            dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
            timeEvento = event.start
          }

          if(event.end.dateTime){
            timeEventoEnd = event.end.dateTime
          }
          else{
            timeEventoEnd = event.end
          }
          
          let description = event.description.toString().split('|')
          let unidadEvento = description[8].split('=')[1]

          


          if(unidad === unidadEvento || hospitalNameOne === unidadEvento|| hospitalNameTwo === unidadEvento){
            //"2024-10-28 16:10:58"
            
            
            let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
            
            let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
            let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
            

            let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
            let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


            let fechaEventoDos = new Date(timeEvento.split("T")[0] + " " + timeEvento.split("T")[1])
            let hoy = new Date()
            let restaHoy = (fechaEventoDos.getTime() - hoy.getTime())/1000
            restaHoy /= 60;
            let diferenciaHoy = Math.round(restaHoy)

            

            
            if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
              let fechaEventoTres = new Date(timeEventoEnd.split("T")[0] + " " + timeEventoEnd.split("T")[1])
              let restaValidacion = (fechaEventoTres.getTime() - fechaEventoDos.getTime())/1000
              restaValidacion /= 60;
              let diferenciaValidacion = Math.round(restaValidacion)

              if(diferenciaValidacion <= 0){
                eventosRevisar.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
              }

              let diferencia = moment(timeEvento).diff(moment().subtract(2, 'hours'),"minutes")
              if(diferenciaHoy > 0){
                if(event.summary === "Confirmada"){
                  eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                  eventosGeneral.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                  eventDefinido.push(event)
                }
                else{
                  if(diferenciaHoy < 0){
                    let concepto = description[19].split('=')[1]
                    if(concepto === "Cirugía de urgencia"){
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventRealizado.push(event)
                    }
                    else{
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventRealizado.push(event)
                    }
                  }
                  else{
                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                    eventPending.push(event)
                  }
                  
                }
                
              }
              else{
                if(diferenciaHoy > 0){
                  if(event.summary === "Confirmada"){
                    eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                    eventosGeneral.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                    eventDefinido.push(event)
                  }
                  else{
                    //console.log(timeEvento)
                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                    eventPending.push(event)
                  }
                }
                else{
                  if(event.summary === "Confirmada"){
                    eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                    eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                    eventRealizado.push(event)
                  }
                  else{
                    let concepto = description[19].split('=')[1]
                    if(concepto === "Cirugía de urgencia"){
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventRealizado.push(event)
                    }
                    else{
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventRealizado.push(event)
                    }
                  }
                }

                
              }
            }
            else{
              if(event.summary !== "Confirmada"){//Cirugias pendientes
                let diferencia = moment(timeEvento).diff(moment().subtract(2, 'hours'),"minutes")

                if(diferenciaHoy >= 0){
                  eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                  eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                  eventPending.push(event)
                }
              }
            }
          }

        });      
      }
      else{
        events.forEach(event => {
          let dayEvento = null
          let timeEvento = null
          let timeEventoEnd = null

          if(event.start.dateTime){
            dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
            timeEvento = event.start.dateTime
          }
          else{
            dayEvento = new Date(moment(event.start).format('MM/DD/YYYY'))
            timeEvento = event.start
          }

          if(event.end.dateTime){
            timeEventoEnd = event.end.dateTime
          }
          else{
            timeEventoEnd = event.end
          }
          
          let description = event.description.toString().split('|')
          let unidadEvento = description[8].split('=')[1]

          if("Hospital-MAC-Integración Procesos Clínicos" !== unidadEvento){
            let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
            
            let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
            let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
            

            let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
            let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


            let fechaEventoDos = new Date(timeEvento.split("T")[0] + " " + timeEvento.split("T")[1])
            let hoy = new Date()
            let restaHoy = (fechaEventoDos.getTime() - hoy.getTime())/1000
            restaHoy /= 60;
            let diferenciaHoy = Math.round(restaHoy)

            

            
            if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
              let fechaEventoTres = new Date(timeEventoEnd.split("T")[0] + " " + timeEventoEnd.split("T")[1])
              let restaValidacion = (fechaEventoTres.getTime() - fechaEventoDos.getTime())/1000
              restaValidacion /= 60;
              let diferenciaValidacion = Math.round(restaValidacion)

              if(diferenciaValidacion <= 0){
                eventosRevisar.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
              }

              let diferencia = moment(timeEvento).diff(moment().subtract(2, 'hours'),"minutes")
              if(diferenciaHoy > 0){
                if(event.summary === "Confirmada"){
                  eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                  eventosGeneral.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                  eventDefinido.push(event)
                }
                else{
                  if(diferenciaHoy < 0){
                    let concepto = description[19].split('=')[1]
                    if(concepto === "Cirugía de urgencia"){
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventRealizado.push(event)
                    }
                    else{
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventRealizado.push(event)
                    }
                  }
                  else{
                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                    eventPending.push(event)
                  }
                  
                }
                
              }
              else{
                if(diferenciaHoy > 0){
                  if(event.summary === "Confirmada"){
                    eventosDefinidos.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                    eventosGeneral.push({ id: event.id, ...getEventObject(event,"Reservada","Row") })
                    eventDefinido.push(event)
                  }
                  else{
                    //console.log(timeEvento)
                    eventosGeneral.push(getEventObject(event,"Pendiente","Row"))
                    eventosPendientesDeConfirmar.push({ id: event.id, ...getEventObject(event,"Pendiente","Row") })
                    eventPending.push(event)
                  }
                }
                else{
                  if(event.summary === "Confirmada"){
                    eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                    eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                    eventRealizado.push(event)
                  }
                  else{
                    let concepto = description[19].split('=')[1]
                    if(concepto === "Cirugía de urgencia"){
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Cirugía realizada","Row") })
                      eventRealizado.push(event)
                    }
                    else{
                      eventosRealizados.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventosGeneral.push({ id: event.id, ...getEventObject(event,"Sin confirmación","RowRed") })
                      eventRealizado.push(event)
                    }
                  }
                }

                
              }
            }
          }
          

        });  
      }

      setPendientesUnidad(eventosPendientesDeConfirmar)
      setPendientesUnidadEventFormat(eventPending)

      setAgendadosUnidad(eventosDefinidos)
      setAgendadosUnidadEventFormat(eventDefinido)

      setRealizadosUnidad(eventosRealizados)
      setRealizadosUnidadEventFormat(eventRealizado)
      console.log("Eventos general")
      console.log(eventosGeneral)
      setGeneralUnidad(eventosGeneral)

  },[events,initialDay,finalDay,userNomHospital])

  useEffect(()=>{
    let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    let dayFinal =  new Date(moment(finalDay).format('MM/DD/YYYY'))

    if(!finalDay){
      dayFinal =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    }

    let unidad = userNomHospital
    let hospitalNameOne = ""
    let hospitalNameTwo = ""

    let eventosCancelados = []
    let eventCancelado = []

    switch(unidad){
      case "MAC León":
        hospitalNameOne = "Hospital-MAC-León"
        hospitalNameTwo = "León"
        break
      case "HMAS Los Cabos":
        hospitalNameOne = "Hospital-HMAS-Los Cabos"
        hospitalNameTwo = "Los Cabos"
        break
      case "HMAS Querétaro":
        hospitalNameOne = "Hospital-HMAS-Querétaro"
        hospitalNameTwo = "Querétaro"
        break
      case "MAC Aguascalientes Norte":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Norte"
        hospitalNameTwo = "Aguascalientes Norte"
        break
      case "MAC Aguascalientes Sur":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Sur"
        hospitalNameTwo = "Aguascalientes Sur"
        break
      case "MAC Celaya":
        hospitalNameOne = "Hospital-MAC-Celaya"
        hospitalNameTwo = "Celaya"
        break
      case "MAC Guadalajara":
        hospitalNameOne = "Hospital-MAC-Guadalajara"
        hospitalNameTwo = "Guadalajara"
        break
      case "MAC Integración Procesos Clínicos":
        hospitalNameOne = "Hospital-MAC-Integración Procesos Clínicos"
        hospitalNameTwo = "Integración Procesos Clínicos"
        break
      case "MAC Irapuato":
        hospitalNameOne = "Hospital-MAC-Irapuato"
        hospitalNameTwo = "Irapuato"
        break
      case "MAC La Viga":
        hospitalNameOne = "Hospital-MAC-La Viga"
        hospitalNameTwo = "La Viga"
        break
      case "MAC Los Mochis":
        hospitalNameOne = "Hospital-MAC-Los Mochis"
        hospitalNameTwo = "Los Mochis"
        break
      case "MAC Mexicali":
        hospitalNameOne = "Hospital-MAC-Mexicali"
        hospitalNameTwo = "Mexicali"
        break
      case "MAC Perisur":
        hospitalNameOne = "Hospital-MAC-Perisur"
        hospitalNameTwo = "Perisur"
        break
      case "MAC Puebla":
        hospitalNameOne = "Hospital-MAC-Puebla"
        hospitalNameTwo = "Puebla"
        break
      case "MAC San Miguel de Allende":
        hospitalNameOne = "Hospital-MAC-San Miguel de Allende"
        hospitalNameTwo = "San Miguel de Allende"
        break
      case "MAC Tampico":
        hospitalNameOne = "Hospital-MAC-Tampico"
        hospitalNameTwo = "Tampico"
        break
      case "MediMAC Boca del Rio":
        hospitalNameOne = "Hospital-MAC-Boca del Río"
        hospitalNameTwo = "Boca del Rio"
        break
      case "MediMAC Cuemanco":
        hospitalNameOne = "Hospital-MAC-Cuemanco"
        hospitalNameTwo = "Cuemanco"
        break
      case "MediMAC Ecatepec":
        hospitalNameOne = "Hospital-MAC-Ecatepec"
        hospitalNameTwo = "Ecatepec"
        break
      case "MediMAC Guanajuato":
        hospitalNameOne = "Hospital-MAC-Guanajuato"
        hospitalNameTwo = "Guanajuato"
        break
      case "MediMAC Merida":
        hospitalNameOne = "Hospital-MAC-Merida"
        hospitalNameTwo = "Merida"
        break
      case "MediMAC Tlalnepantla":
        hospitalNameOne = "Hospital-MAC-Tlalnepantla"
        hospitalNameTwo = "Tlalnepantla"
        break
      default :
        break
    }

    cancelEvents.forEach(event =>{

      if(userNomHospital !== "Corporativo"){
        let dayEvento = null
        let timeEvento = null
        if(event.afterEvent.start.dateTime){
          dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start.dateTime
        }
        else{
          dayEvento = new Date(moment(event.afterEvent.start).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start
        }
        
        let description = ""
        let unidadEvento = ""
        if(event.afterEvent.description){
          description = event.afterEvent.description.toString().split('|')
          unidadEvento = description[8].split('=')[1]
        }
        else{
          unidadEvento = event.afterEvent.UnidadHospitalaria
        }
        
        if(unidad === unidadEvento || hospitalNameOne === unidadEvento|| hospitalNameTwo === unidadEvento){
          let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
              
              let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
              let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
              

              let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
              let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


          if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
            
            if(description){
              eventosCancelados.push({ id: event.id, ...getEventObject(event.afterEvent,"Cancelado","RowRed") })
              eventCancelado.push(event)
            }
          }
        }
      }
      else{
        let dayEvento = null
        let timeEvento = null
        if(event.afterEvent.start.dateTime){
          dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start.dateTime
        }
        else{
          dayEvento = new Date(moment(event.afterEvent.start).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start
        }
        
        let description = ""
        let unidadEvento = ""
        if(event.afterEvent.description){
          description = event.afterEvent.description.toString().split('|')
          unidadEvento = description[8].split('=')[1]
        }
        else{
          unidadEvento = event.afterEvent.UnidadHospitalaria
        }
        
        if("Hospital-MAC-Integración Procesos Clínicos" !== unidadEvento){
          let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
              
              let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
              let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
              

              let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
              let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


          if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
            
            if(description){
              eventosCancelados.push({ id: event.id, ...getEventObject(event.afterEvent,"Cancelado","RowRed") })
              eventCancelado.push(event)
            }
          }
        }
      }
      
      
    })

    setCanceladosUnidad(eventosCancelados)

    

  },[cancelEvents,initialDay,finalDay,userNomHospital])

  useEffect(()=>{
    let eventosCancelados = []
    let eventCancelado = []

    let dayInicial =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    let dayFinal =  new Date(moment(finalDay).format('MM/DD/YYYY'))

    if(!finalDay){
      dayFinal =  new Date(moment(initialDay).format('MM/DD/YYYY'))
    }

    let unidad = userNomHospital
    let hospitalNameOne = ""
    let hospitalNameTwo = ""

    switch(unidad){
      case "MAC León":
        hospitalNameOne = "Hospital-MAC-León"
        hospitalNameTwo = "León"
        break
      case "HMAS Los Cabos":
        hospitalNameOne = "Hospital-HMAS-Los Cabos"
        hospitalNameTwo = "Los Cabos"
        break
      case "HMAS Querétaro":
        hospitalNameOne = "Hospital-HMAS-Querétaro"
        hospitalNameTwo = "Querétaro"
        break
      case "MAC Aguascalientes Norte":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Norte"
        hospitalNameTwo = "Aguascalientes Norte"
        break
      case "MAC Aguascalientes Sur":
        hospitalNameOne = "Hospital-MAC-Aguascalientes Sur"
        hospitalNameTwo = "Aguascalientes Sur"
        break
      case "MAC Celaya":
        hospitalNameOne = "Hospital-MAC-Celaya"
        hospitalNameTwo = "Celaya"
        break
      case "MAC Guadalajara":
        hospitalNameOne = "Hospital-MAC-Guadalajara"
        hospitalNameTwo = "Guadalajara"
        break
      case "MAC Integración Procesos Clínicos":
        hospitalNameOne = "Hospital-MAC-Integración Procesos Clínicos"
        hospitalNameTwo = "Integración Procesos Clínicos"
        break
      case "MAC Irapuato":
        hospitalNameOne = "Hospital-MAC-Irapuato"
        hospitalNameTwo = "Irapuato"
        break
      case "MAC La Viga":
        hospitalNameOne = "Hospital-MAC-La Viga"
        hospitalNameTwo = "La Viga"
        break
      case "MAC Los Mochis":
        hospitalNameOne = "Hospital-MAC-Los Mochis"
        hospitalNameTwo = "Los Mochis"
        break
      case "MAC Mexicali":
        hospitalNameOne = "Hospital-MAC-Mexicali"
        hospitalNameTwo = "Mexicali"
        break
      case "MAC Perisur":
        hospitalNameOne = "Hospital-MAC-Perisur"
        hospitalNameTwo = "Perisur"
        break
      case "MAC Puebla":
        hospitalNameOne = "Hospital-MAC-Puebla"
        hospitalNameTwo = "Puebla"
        break
      case "MAC San Miguel de Allende":
        hospitalNameOne = "Hospital-MAC-San Miguel de Allende"
        hospitalNameTwo = "San Miguel de Allende"
        break
      case "MAC Tampico":
        hospitalNameOne = "Hospital-MAC-Tampico"
        hospitalNameTwo = "Tampico"
        break
      case "MediMAC Boca del Rio":
        hospitalNameOne = "Hospital-MAC-Boca del Río"
        hospitalNameTwo = "Boca del Rio"
        break
      case "MediMAC Cuemanco":
        hospitalNameOne = "Hospital-MAC-Cuemanco"
        hospitalNameTwo = "Cuemanco"
        break
      case "MediMAC Ecatepec":
        hospitalNameOne = "Hospital-MAC-Ecatepec"
        hospitalNameTwo = "Ecatepec"
        break
      case "MediMAC Guanajuato":
        hospitalNameOne = "Hospital-MAC-Guanajuato"
        hospitalNameTwo = "Guanajuato"
        break
      case "MediMAC Merida":
        hospitalNameOne = "Hospital-MAC-Merida"
        hospitalNameTwo = "Merida"
        break
      case "MediMAC Tlalnepantla":
        hospitalNameOne = "Hospital-MAC-Tlalnepantla"
        hospitalNameTwo = "Tlalnepantla"
        break
      default :
        break
    }


    editEvents.forEach(event =>{

      if(userNomHospital !== "Corporativo"){
        let dayEvento = null
        let timeEvento = null
        if(event.afterEvent.start.dateTime){
          dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start.dateTime
        }
        else{
          dayEvento = new Date(moment(event.afterEvent.start).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start
        }
        
        let description = ""
        let unidadEvento = ""
        if(event.afterEvent.description){
          description = event.afterEvent.description.toString().split('|')
          unidadEvento = description[8].split('=')[1]
        }
        else{
          unidadEvento = event.afterEvent.UnidadHospitalaria
        }
        
        if(unidad === unidadEvento || hospitalNameOne === unidadEvento|| hospitalNameTwo === unidadEvento){
          let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
              
              let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
              let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
              

              let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
              let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


          if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
            if(description){
              eventosCancelados.push({ id: event.id, ...getEventObject(event.afterEvent,"Editado","RowRed") })
              eventCancelado.push(event)
            }
          }
        }
      }
      else{
        let dayEvento = null
        let timeEvento = null
        if(event.afterEvent.start.dateTime){
          dayEvento = new Date(moment(event.afterEvent.start.dateTime).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start.dateTime
        }
        else{
          dayEvento = new Date(moment(event.afterEvent.start).format('MM/DD/YYYY'))
          timeEvento = event.afterEvent.start
        }
        
        let description = ""
        let unidadEvento = ""
        if(event.afterEvent.description){
          description = event.afterEvent.description.toString().split('|')
          unidadEvento = description[8].split('=')[1]
        }
        else{
          unidadEvento = event.afterEvent.UnidadHospitalaria
        }
        
        if("Hospital-MAC-Integración Procesos Clínicos" !== unidadEvento){
          let fechaEvento = new Date(timeEvento.split("T")[0] + " 00:00:00");
              
              let restaInferior = fechaEvento.getTime() - dayInicial.getTime()//si la fecha del evento es mayor a cero esta por suceder
              let restaSuperior = dayFinal.getTime() - fechaEvento.getTime()//si la fecha final es mayor a cero esta
              

              let diferenciaInferior = Math.round(restaInferior/ (1000*60*60*24))
              let diferenciaSuperior = Math.round(restaSuperior/ (1000*60*60*24))


          if(diferenciaInferior >= 0 && diferenciaSuperior >= 0){
            
            if(description){
              eventosCancelados.push({ id: event.id, ...getEventObject(event.afterEvent,"Cancelado","RowRed") })
              eventCancelado.push(event)
            }
          }
        }
      }
      
      
    })

    setEditEventsUnidad(eventosCancelados)
  },[editEvents,finalDay,initialDay,userNomHospital])

    useEffect(()=>{
        //Obtención del listado de eventos
        pacientesStore.collection("eventos")
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push({ id_evento: doc.id, ...doc.data() })
            })

            let resultados = []
            for(let i=0;i<resultList.length;i++){
              if(resultList[i].event){
                let prueba = resultList[i].event
                prueba.id_evento = resultList[i].id_evento
                //resultados.push(resultList[i].event)
                resultados.push(prueba)
              }
            }
            
            resultados.sort(function(x, y) {
              var firstDate = new Date(x.start.dateTime),
                SecondDate = new Date(y.start.dateTime);
                
              if (firstDate > SecondDate) return -1;
              if (firstDate < SecondDate) return 1;
              return 0;
            });
            
            setEvents(resultados)
        })
        //Obtención del listado de eventos cancelados
        pacientesStore.collection(calendarLog).where('peticion','==','cancel')
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                let data = doc.data()

                resultList.push(doc.data())
            })

            setCancelEvents(resultList)
        })

        pacientesStore.collection(calendarLog).where('peticion','==','update')
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push(doc.data())
            })
            setEditEvents(resultList)
        })


        pacientesStore.collection(calendarLog).where('create','==','2024-11-11').where('peticion','==','cancel')
          .onSnapshot(snap =>{
            const resultList = []

            snap.forEach(doc => {
              let data = doc.data()
              if(data.status !== "Cancelada por el médico" && data.status !== "Error de Captura"){


                let unidadEvento = data.AppointmentType.split('|')[8].split("=")[1]
                let unidadUsuario = ""

                let usuarios = [
                  {
                      "Permiso": "credencializacion",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "mac2023",
                      "Nombre": "DULCE MARIA REYES CAMPOS",
                      "UnidadHospitalaria": {
                          "mnemonicos": "AGS",
                          "num_asociado": "0",
                          "identificador": "PV3bUNDCpVnCq9rdGzUd",
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "hospital": "MAC Aguascalientes Sur",
                          "activo": true,
                          "id": "4",
                          "calendar": "Hospital-MAC-Aguascalientes Sur"
                      },
                      "Nick": "dulce.ags"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "elizabethRosas",
                      "Nick": "elizabeth.rosas",
                      "Activo": true,
                      "Nombre": "Elizabeth Rosas",
                      "Permiso": "mesa",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 8,
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "id": "9"
                      }
                  },
                  {
                      "Pass": "programacionqx2",
                      "UnidadHospitalaria": {
                          "id": "15",
                          "calendar": "Hospital-MAC-Boca del Río",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "mnemonicos": "BDR",
                          "activo": true,
                          "hospital": "MediMAC Boca del Rio",
                          "num_asociado": 23
                      },
                      "Activo": true,
                      "Nombre": "Maria Guadalupe Gonzalez Velasco",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "MGGONZALEZV",
                      "Permiso": "administrador"
                  },
                  {
                      "Pass": "RECUQRO",
                      "Activo": true,
                      "Nick": "RECUQRO",
                      "Nombre": "RECUPERACIÓN QRO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Permiso": "recuperación",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "id": "21",
                          "activo": true,
                          "mnemonicos": "QRO",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "hospital": "HMAS Querétaro"
                      }
                  },
                  {
                      "Pass": "EnseñanzaSur",
                      "Permiso": "tablero",
                      "Nombre": "Haydee Medrano Diaz",
                      "Nick": "HMEDRANOD",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "PER",
                          "hospital": "MAC Perisur",
                          "num_asociado": 60,
                          "id": "19",
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Nombre": "Quirofano Leon",
                      "Nick": "leon",
                      "Activo": true,
                      "Pass": "quirofano",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LEO",
                          "activo": true,
                          "hospital": "MAC León",
                          "calendar": "Hospital-MAC-León",
                          "num_asociado": "0",
                          "id": "18"
                      },
                      "Permiso": "quirofano"
                  },
                  {
                      "Nick": "MSRUEDAE",
                      "Nombre": "Miriam Sugey Rueda Escamilla",
                      "UnidadHospitalaria": {
                          "num_asociado": 34,
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "activo": true,
                          "calendar": "Hospital-MAC-Tampico",
                          "id": "13",
                          "hospital": "MAC Tampico",
                          "mnemonicos": "TMP"
                      },
                      "Activo": true,
                      "Permiso": "calidad",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "Agendacalidad2"
                  },
                  {
                      "Nombre": "Responsiva jefe de enfermería",
                      "UnidadHospitalaria": {
                          "id": "6",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "num_asociado": "0",
                          "mnemonicos": "SMA",
                          "hospital": "MAC San Miguel de Allende",
                          "activo": true,
                          "calendar": "Hospital-MAC-San Miguel de Allende"
                      },
                      "Pass": "quirofano5",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda",
                      "Nick": "SANMIGUEL",
                      "Activo": true
                  },
                  {
                      "Permiso": "administrador",
                      "Nick": "JZARAGOZAG",
                      "Pass": "JZARAGOZAG",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "José de Jesús Zaragoza Galván",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "hospital": "HMAS Querétaro",
                          "id": "21",
                          "mnemonicos": "QRO",
                          "activo": true
                      },
                      "Activo": true
                  },
                  {
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "KIOSORIOF",
                      "Pass": "KIOSORIOF",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LVG",
                          "activo": true,
                          "num_asociado": 40,
                          "calendar": "Hospital-MAC-La Viga",
                          "email_calidad": "",
                          "hospital": "MAC La Viga",
                          "id": "22"
                      },
                      "Activo": false,
                      "Nombre": "Karen Isabel Osorio Fernández"
                  },
                  {
                      "Activo": true,
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MER",
                          "num_asociado": 12,
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "activo": true,
                          "id": "42",
                          "calendar": "Hospital-MAC-Merida",
                          "hospital": "MediMAC Merida"
                      },
                      "Nombre": "José Guadalupe Chin Victorin",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "JGCHINV",
                      "Pass": "agenda123"
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "id": "11",
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "num_asociado": 1,
                          "mnemonicos": "AGN",
                          "hospital": "MAC Aguascalientes Norte",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "activo": true
                      },
                      "Permiso": "administrador",
                      "Nombre": "Adriana Berenice Campos Gonzalez",
                      "Pass": "EnfriaAgendaN",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "ABCAMPOSG"
                  },
                  {
                      "Activo": true,
                      "Nombre": "Alex Yankel Ibarra Ruiz",
                      "Permiso": "administrador",
                      "Pass": "agendaboca1",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AYIBARRAR",
                      "UnidadHospitalaria": {
                          "id": "15",
                          "calendar": "Hospital-MAC-Boca del Río",
                          "hospital": "MediMAC Boca del Rio",
                          "num_asociado": 23,
                          "mnemonicos": "BDR",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "activo": true
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "Nombre": "Leticia Berenice Ramirez",
                      "Permiso": "superuser",
                      "Nick": "berenice.ramirez",
                      "UnidadHospitalaria": {
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "hospital": "Corporativo",
                          "num_asociado": 9,
                          "activo": true,
                          "id": "9",
                          "mnemonicos": "COR"
                      },
                      "Pass": "Chilaquiles59"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          },
                          {
                              "active": true,
                              "id": 2
                          },
                          {
                              "active": true,
                              "id": 4
                          }
                      ],
                      "Nick": "sanchezhans",
                      "Pass": "hanssan",
                      "Nombre": "Hans Sánchez",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "hospital": "Corporativo",
                          "activo": true
                      },
                      "Permiso": "superuser"
                  },
                  {
                      "Pass": "Pato@43.",
                      "Nombre": "Franklin Tamayo",
                      "Permiso": "lectura",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "angel.adrian@merida.medicamac.com",
                          "id": "5",
                          "activo": true,
                          "mnemonicos": "MER",
                          "hospital": "MAC Merida",
                          "num_asociado": 3,
                          "calendar": "Hospital-MAC-Mérida",
                          "identificador": "6sT1GkURvGItbEgl8FDG"
                      },
                      "Nick": "franklin.tamayo"
                  },
                  {
                      "Permiso": "ciberseguridad",
                      "Pass": "santos",
                      "Activo": true,
                      "Email": "alejandro.santos@hospitalesmac.com",
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "activo": true,
                          "num_asociado": 7,
                          "id": "9",
                          "mnemonicos": "COR"
                      },
                      "Nick": "alejandro",
                      "Nombre": "Alejandro Santos",
                      "Systems": [
                          {
                              "id": "6",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Ana Gabriela Arcos Espinosa",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "45",
                          "hospital": "MediMAC Ecatepec",
                          "activo": true,
                          "num_asociado": 25,
                          "mnemonicos": "ECA",
                          "calendar": "Hospital-MAC-Ecatepec"
                      },
                      "Pass": "EcatepecAdm",
                      "Nick": "AGARCOSE",
                      "Activo": true
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "num_asociado": 25,
                          "mnemonicos": "LVG",
                          "id": "22",
                          "calendar": "Hospital-MAC-La Viga",
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "email_calidad": ""
                      },
                      "Nombre": "MEJORA CONTINUA LV",
                      "Activo": true,
                      "Nick": "MCONTINUALV",
                      "Pass": "MCONTINUALV"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Christian Josue Gaytan García",
                      "Permiso": "administrador",
                      "Pass": "MedAgenda2",
                      "Nick": "CJGAYTANG",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "19",
                          "hospital": "MAC Perisur",
                          "num_asociado": 60,
                          "calendar": "Hospital-MAC-Perisur",
                          "mnemonicos": "PER"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": false,
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "22",
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga",
                          "num_asociado": 87,
                          "mnemonicos": "LVG",
                          "email_calidad": ""
                      },
                      "Nick": "RodrigoS",
                      "Pass": "RodrigoS",
                      "Nombre": "Rodrigo Sandoval Vivanco"
                  },
                  {
                      "Nick": "DMREYESC",
                      "Pass": "CalidadAgenda",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "id": "4",
                          "num_asociado": 7,
                          "activo": true
                      },
                      "Nombre": "Dulce Maria Reyes Campos",
                      "Activo": true,
                      "Permiso": "calidad"
                  },
                  {
                      "Pass": "PAOADMINC",
                      "UnidadHospitalaria": {
                          "num_asociado": 10,
                          "id": "9",
                          "mnemonicos": "COR",
                          "activo": true,
                          "hospital": "Corporativo"
                      },
                      "Nick": "IPESCOBEDOM",
                      "Activo": true,
                      "Nombre": "IRIS PAOLA ESCOBEDO MARQUEZ",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador"
                  },
                  {
                      "Nombre": "KAREN LIZETH ARANO",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "identificador": "hwVGvefNSD6uZfjhdLoD",
                          "id": "18",
                          "num_asociado": "0",
                          "mnemonicos": "LEO",
                          "calendar": "Hospital-MAC-León",
                          "activo": true,
                          "hospital": "MAC León"
                      },
                      "Permiso": "credencializacion",
                      "Pass": "mac2023",
                      "Nick": "karen.leon",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "Nombre": "SERVICIOS GENERALES",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Celaya",
                          "mnemonicos": "CEL",
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "num_asociado": 0,
                          "id": "1",
                          "hospital": "MAC Celaya",
                          "activo": true
                      },
                      "Nick": "ARUIZ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "tablero",
                      "Permiso": "tablero"
                  },
                  {
                      "Activo": true,
                      "Nick": "DMILLANG",
                      "Pass": "admision12",
                      "UnidadHospitalaria": {
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "mnemonicos": "TLA",
                          "id": "14",
                          "activo": true,
                          "hospital": "MediMAC Tlalnepantla",
                          "num_asociado": 25
                      },
                      "Nombre": "Daniel Millan Guillen",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "tablero"
                  },
                  {
                      "Nombre": "Ediberto Domínguez Jiménez",
                      "Pass": "edibertoDominguez",
                      "Permiso": "mesa",
                      "Nick": "ediberto.dominguez",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "id": "9",
                          "activo": true,
                          "num_asociado": 8,
                          "hospital": "Corporativo"
                      }
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC León",
                          "calendar": "Hospital-MAC-León",
                          "id": "18",
                          "activo": true,
                          "num_asociado": 35,
                          "mnemonicos": "LEO"
                      },
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "ArchivoMAC",
                      "Nick": "ArchivoClinico",
                      "Nombre": "Archivo"
                  },
                  {
                      "Activo": true,
                      "Pass": "recuperacion",
                      "Nombre": "Recuperacion Leon",
                      "Permiso": "recuperación",
                      "Nick": "leon",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LEO",
                          "calendar": "Hospital-MAC-León",
                          "activo": true,
                          "num_asociado": "0",
                          "hospital": "MAC León",
                          "id": "18"
                      }
                  },
                  {
                      "Pass": "josecruzR",
                      "Permiso": "mesa",
                      "Nick": "jose.cruz",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "activo": true,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "num_asociado": 8
                      },
                      "Activo": true,
                      "Nombre": "José Cruz Rangel",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Permiso": "administrador",
                      "Nombre": "Yoana Rubio",
                      "Activo": true,
                      "Pass": "integracionAdmin",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "IPC",
                          "id": "999",
                          "hospital": "MAC Integración Procesos Clínicos",
                          "num_asociado": 0,
                          "activo": true,
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos"
                      },
                      "Nick": "yoana.rubio"
                  },
                  {
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "identificador": "PTiH3S8IoyjBVUUkTp59",
                          "activo": true,
                          "mnemonicos": "QRO",
                          "hospital": "HMAS Querétaro",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "id": "21"
                      },
                      "Activo": true,
                      "Pass": "hamburguesa9823",
                      "Nombre": "Juan Pablo Cambron Plata",
                      "Permiso": "credencializacion",
                      "Nick": "pablo.cambron"
                  },
                  {
                      "Pass": "calidad",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "calidad",
                      "Nombre": "Claudia Barrientos",
                      "UnidadHospitalaria": {
                          "id": "1",
                          "num_asociado": 0,
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "hospital": "MAC Celaya",
                          "calendar": "Hospital-MAC-Celaya",
                          "activo": true,
                          "mnemonicos": "CEL"
                      },
                      "Activo": true,
                      "Nick": "CBARRIENTOS"
                  },
                  {
                      "Pass": "QUIROFANOLMM",
                      "Nombre": "Quirófano Los Mochis",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MOC",
                          "calendar": "Hospital-MAC-Los Mochis",
                          "id": "17",
                          "activo": true,
                          "num_asociado": "0",
                          "hospital": "MAC Los Mochis"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Nick": "QUIROFANOLM",
                      "Permiso": "quirofano"
                  },
                  {
                      "Nick": "ismael.llamas",
                      "Pass": "mac2024",
                      "Permiso": "lectura",
                      "Nombre": "ismael",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "GDL",
                          "num_asociado": 43,
                          "calendar": "Hospital-MAC-Guadalajara",
                          "identificador": "Jae6nIRS5HGXtoVnXEXL",
                          "activo": true,
                          "id": "3",
                          "hospital": "MAC Guadalajara"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ]
                  },
                  {
                      "Nick": "JAHERNANDEZM",
                      "Nombre": "Jose Adrian Hernandez Mugia",
                      "Pass": "enfermeriaqx",
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "GDL",
                          "id": "3",
                          "activo": true,
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "hospital": "MAC Guadalajara"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda"
                  },
                  {
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nick": "diana.hdz",
                      "Activo": true,
                      "Permiso": "lectura",
                      "Pass": "mac2023",
                      "Nombre": "Diana Hernandez",
                      "UnidadHospitalaria": {
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "num_asociado": 9,
                          "mnemonicos": "COR",
                          "id": "9",
                          "hospital": "Corporativo",
                          "activo": true
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Los Cabos",
                          "activo": true,
                          "num_asociado": "0",
                          "mnemonicos": "LCA",
                          "id": "20",
                          "email_calidad": "credencializacion@hmas.mx",
                          "calendar": "Hospital-HMAS-Los Cabos"
                      },
                      "Nombre": "Carmen Beatriz López Mosco",
                      "Permiso": "tablero",
                      "Pass": "AgendaCabos5",
                      "Nick": "CBLOPEZM"
                  },
                  {
                      "Permiso": "calidad",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-HMAS-Querétaro",
                          "hospital": "HMAS Querétaro",
                          "activo": true,
                          "id": "21",
                          "num_asociado": "0",
                          "mnemonicos": "QRO"
                      },
                      "Activo": true,
                      "Pass": "CALIDADQRO",
                      "Nombre": "CALIDAD QUERÉTARO",
                      "Nick": "CALIDADQRO"
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "activo": true,
                          "num_asociado": 10,
                          "hospital": "Corporativo",
                          "id": "9"
                      },
                      "Nombre": "JORGE LUIS RIVERA TOVAR",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "JLRIVERAT",
                      "Pass": "ADMINJORLU",
                      "Activo": true,
                      "Permiso": "administrador"
                  },
                  {
                      "Permiso": "administrador",
                      "Nick": "JESUS.PAZ",
                      "Pass": "AgendaSur",
                      "UnidadHospitalaria": {
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "id": "4",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "num_asociado": 7,
                          "activo": true,
                          "hospital": "MAC Aguascalientes Sur"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Nombre": "Jesus De La Paz"
                  },
                  {
                      "Nombre": "Roxana Ulloa Urquidy",
                      "Nick": "RULLOAU",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MOC",
                          "num_asociado": 27,
                          "activo": true,
                          "id": "17",
                          "hospital": "MAC Los Mochis",
                          "calendar": "Hospital-MAC-Los Mochis"
                      },
                      "Pass": "Ulloa.calidad",
                      "Activo": false,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "calidad"
                  },
                  {
                      "Nombre": "Quirofano Cuemanco",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MediMAC Cuemanco",
                          "id": "44",
                          "num_asociado": 0,
                          "calendar": "Hospital-MAC-Cuemanco",
                          "mnemonicos": "CUE"
                      },
                      "Nick": "QXCMCO",
                      "Permiso": "quirofano",
                      "Pass": "QXCMCO"
                  },
                  {
                      "Nick": "diana.granados",
                      "UnidadHospitalaria": {
                          "id": "999",
                          "activo": true,
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "mnemonicos": "IPC",
                          "hospital": "MAC Integración Procesos Clínicos",
                          "num_asociado": 0
                      },
                      "Pass": "agendaProcesos",
                      "Activo": true,
                      "Nombre": "Diana Granados",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda"
                  },
                  {
                      "Pass": "agenda123",
                      "Permiso": "tablero",
                      "Activo": true,
                      "Nick": "LANTONIOM",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Guanajuato",
                          "hospital": "MediMAC Guanajuato",
                          "num_asociado": 11,
                          "id": "16",
                          "activo": true,
                          "mnemonicos": "GTO"
                      },
                      "Nombre": "Luis Antonio Martínez"
                  },
                  {
                      "Nombre": "Eduardo Nery Malagón Tapia",
                      "Permiso": "tablero",
                      "Activo": true,
                      "Pass": "VentaQx",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "calendar": "Hospital-MAC-Ecatepec",
                          "mnemonicos": "ECA",
                          "id": "45",
                          "hospital": "MediMAC Ecatepec",
                          "num_asociado": 23
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "ENMALAGONT"
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 7,
                          "mnemonicos": "AGS",
                          "hospital": "MAC Aguascalientes Sur",
                          "id": "4",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "activo": true
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Responsiva firmada por jefatura de enfermería",
                      "Nick": "AGUASCALIENTESS",
                      "Pass": "QuirofanoSur5",
                      "Permiso": "agenda"
                  },
                  {
                      "Nick": "MEXICALI",
                      "Activo": true,
                      "Pass": "Quirofanomx5",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Mexicali",
                          "calendar": "Hospital-MAC-Mexicali",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "mnemonicos": "MEX",
                          "activo": true,
                          "id": "8",
                          "num_asociado": "0"
                      },
                      "Permiso": "agenda",
                      "Nombre": "Responsiva Jefe de Enfermería",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Nombre": "PATOLOGIA",
                      "Activo": true,
                      "Pass": "PATOLOGIA",
                      "Nick": "PATOLOGIA",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "HMAS Querétaro",
                          "id": "21",
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "mnemonicos": "QRO"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "calidad"
                  },
                  {
                      "Pass": "AgendaSupervicion",
                      "UnidadHospitalaria": {
                          "id": "19",
                          "mnemonicos": "PER",
                          "activo": true,
                          "hospital": "MAC Perisur",
                          "num_asociado": 60,
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Nick": "MFCASTELLANOSR",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Maria Fernanda Castellanos Rodriguez",
                      "Permiso": "tablero",
                      "Activo": true
                  },
                  {
                      "Nick": "EPLOPEZJ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "num_asociado": 7,
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Sur",
                          "id": "4"
                      },
                      "Activo": true,
                      "Nombre": "Erika Paulina Lopez Jimenez",
                      "Pass": "ErikaPaulina"
                  },
                  {
                      "Nombre": "Nora Guzman",
                      "Nick": "NGUZMAN",
                      "Activo": true,
                      "Pass": "EnfermeriaTampico",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "hospital": "MAC Tampico",
                          "activo": true,
                          "id": "13",
                          "num_asociado": 34,
                          "mnemonicos": "TMP",
                          "calendar": "Hospital-MAC-Tampico"
                      }
                  },
                  {
                      "Activo": true,
                      "Nombre": "Recuperacion Cuemanco",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Cuemanco",
                          "num_asociado": 0,
                          "id": "44",
                          "mnemonicos": "CUE",
                          "hospital": "MediMAC Cuemanco",
                          "activo": true
                      },
                      "Nick": "RCUEMANCO",
                      "Permiso": "recuperación",
                      "Pass": "RCUEMANCO"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "id": "42",
                          "mnemonicos": "MER",
                          "calendar": "Hospital-MAC-Merida",
                          "activo": true,
                          "identificador": "gPqsBxshZAIcwc4lg0b3",
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "num_asociado": 18,
                          "hospital": "MediMAC Merida"
                      },
                      "Nombre": "Franklin tamayo",
                      "Pass": "mac2023",
                      "Activo": true,
                      "Permiso": "lectura",
                      "Nick": "franklin.merida"
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "AGN",
                          "num_asociado": 1,
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Norte",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "id": "11",
                          "identificador": "JQ7KGLLcBjLvqg1w1u6X"
                      },
                      "Nombre": "ADRIANA JEANNETTE ESQUIVEL LOPEZ",
                      "Activo": true,
                      "Permiso": "lectura",
                      "Pass": "mac2023",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nick": "adriana.agsnte"
                  },
                  {
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-León",
                          "activo": true,
                          "hospital": "MAC León",
                          "num_asociado": "0",
                          "id": "18",
                          "mnemonicos": "LEO"
                      },
                      "Nick": "calidad",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "calidad",
                      "Pass": "calidad.leon",
                      "Activo": true,
                      "Nombre": "Karen Arano Calidad"
                  },
                  {
                      "Nombre": "Sergio Ruiz",
                      "Activo": true,
                      "Permiso": "infraestructura",
                      "Email": "sergio.ruiz@hospitalesmac.com",
                      "Nick": "SergioRuiz",
                      "Systems": [
                          {
                              "id": "6",
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "activo": true,
                          "num_asociado": 7,
                          "mnemonicos": "COR",
                          "id": "9"
                      },
                      "Pass": "Corp2024*"
                  },
                  {
                      "Pass": "QuirofanoNorte",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AGUASCALIENTESN",
                      "Permiso": "agenda",
                      "Nombre": "Responsiva firmada por jefatura de enfermería",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "mnemonicos": "AGN",
                          "id": "11",
                          "activo": true,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "hospital": "MAC Aguascalientes Norte",
                          "calendar": "Hospital-MAC-Aguascalientes Norte"
                      }
                  },
                  {
                      "Pass": "ADMINGUER",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "GUSTAVO GUERCHON",
                      "Activo": true,
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "id": "9",
                          "activo": true,
                          "num_asociado": 9
                      },
                      "Nick": "GGUERCHON"
                  },
                  {
                      "Permiso": "tablero",
                      "Nick": "FVEGASE",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "BancoAgenda",
                      "Nombre": "Fernando Vegas Escobedo",
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Los Cabos",
                          "email_calidad": "credencializacion@hmas.mx",
                          "activo": true,
                          "mnemonicos": "LCA",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "num_asociado": "0",
                          "id": "20"
                      },
                      "Activo": true
                  },
                  {
                      "Nick": "AlejandroSantos",
                      "Email": "alejandro.santos@hospitalesmac.com",
                      "Pass": "CorpCSIS2024+",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "id": "9",
                          "num_asociado": 7
                      },
                      "Activo": true,
                      "Nombre": "Alejandro Santos Novoa",
                      "Permiso": "ciberseguridad",
                      "Systems": [
                          {
                              "active": true,
                              "id": "6"
                          }
                      ]
                  },
                  {
                      "Pass": "Osun.sg",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "activo": true,
                          "calendar": "Hospital-MAC-Los Mochis",
                          "id": "17",
                          "hospital": "MAC Los Mochis",
                          "mnemonicos": "MOC"
                      },
                      "Nombre": "Eddy Osuna Gil",
                      "Permiso": "tablero",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "EOSUNAG"
                  },
                  {
                      "Nombre": "Esther Laureano Primo",
                      "Activo": true,
                      "Nick": "ELAIRENOP",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Perisur",
                          "activo": true,
                          "hospital": "MAC Perisur",
                          "id": "19",
                          "mnemonicos": "PER",
                          "num_asociado": 60
                      },
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "AgendaEnfria"
                  },
                  {
                      "Permiso": "superuser",
                      "Pass": "Cafe@54",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nick": "ivan.escamilla",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "num_asociado": 9,
                          "id": "9",
                          "identificador": "MolsdOWdaka5W52m8e0I"
                      },
                      "Nombre": "Ivan Escamilla Martinez"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Nombre": "Quirófano Enfermería",
                      "Nick": "QuirofanoCelaya",
                      "Permiso": "quirofano",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "activo": true,
                          "id": "1"
                      },
                      "Pass": "123456"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "ADMISION",
                      "Pass": "HospitalMAC",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-León",
                          "id": "18",
                          "hospital": "MAC León",
                          "activo": true
                      },
                      "Nick": "VisualizadorA",
                      "Activo": true,
                      "Permiso": "calidad"
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "Quirofano5",
                      "Nick": "HCABOS",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "LCA",
                          "email_calidad": "credencializacion@hmas.mx",
                          "id": "20",
                          "num_asociado": "0",
                          "activo": true,
                          "hospital": "HMAS Los Cabos",
                          "calendar": "Hospital-HMAS-Los Cabos"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Resposiva firmada por Jefatura de Enfermería"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "administrador",
                      "Activo": true,
                      "Nombre": "Victor Alfaro",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "id": "1",
                          "num_asociado": 0,
                          "activo": true,
                          "mnemonicos": "CEL",
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Celaya"
                      },
                      "Nick": "VALFARO"
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "id": "7",
                          "calendar": "Hospital-MAC-Puebla",
                          "mnemonicos": "PUE",
                          "hospital": "MAC Puebla",
                          "activo": true
                      },
                      "Permiso": "calidad",
                      "Nick": "CAFLORESE",
                      "Nombre": "Cesar Arturo Flores Escalona",
                      "Pass": "quirofanocalidadp",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "Nick": "andresrdz",
                      "Nombre": "Dr Andrés Rdz",
                      "UnidadHospitalaria": {
                          "mnemonicos": "AGN",
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "num_asociado": 1,
                          "identificador": "JQ7KGLLcBjLvqg1w1u6X",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Norte",
                          "id": "11"
                      },
                      "Pass": "123456",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Permiso": "lectura"
                  },
                  {
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Nick": "ARMORALES",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "mnemonicos": "LCA",
                          "hospital": "HMAS Los Cabos",
                          "id": "20",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "email_calidad": "credencializacion@hmas.mx",
                          "activo": true
                      },
                      "Nombre": "Adilene Riqueño Morales",
                      "Pass": "AgendaCabos3"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Nick": "QUIROFANOLV",
                      "Activo": true,
                      "Pass": "QUIROFANOLV",
                      "Nombre": "Quirofano La Viga",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "id": "22",
                          "num_asociado": 25,
                          "mnemonicos": "LVG",
                          "email_calidad": "",
                          "calendar": "Hospital-MAC-La Viga"
                      },
                      "Permiso": "quirofano"
                  },
                  {
                      "UnidadHospitalaria": {
                          "id": "14",
                          "activo": true,
                          "hospital": "MediMAC Tlalnepantla",
                          "mnemonicos": "TLA",
                          "num_asociado": 25,
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com"
                      },
                      "Activo": true,
                      "Nombre": "Arely Ramírez Jiménez",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "tablero",
                      "Pass": "servicioquirofano",
                      "Nick": "ARAMIREZJ"
                  },
                  {
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Boca del Rio",
                          "mnemonicos": "BDR",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "id": "15",
                          "num_asociado": 23,
                          "activo": true,
                          "calendar": "Hospital-MAC-Boca del Río"
                      },
                      "Activo": true,
                      "Nick": "LEHERNANDEZ",
                      "Pass": "agendaquirofano5",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Luis Enrique Hernández"
                  },
                  {
                      "Nombre": "Karla Andrea Garcia Garcia",
                      "Nick": "KAGARCIAG",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "calendar": "Hospital-MAC-Puebla",
                          "id": "7",
                          "hospital": "MAC Puebla",
                          "activo": true,
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "mnemonicos": "PUE"
                      },
                      "Permiso": "administrador",
                      "Pass": "puebla1"
                  },
                  {
                      "UnidadHospitalaria": {
                          "email_calidad": "",
                          "num_asociado": 51,
                          "activo": true,
                          "mnemonicos": "LVG",
                          "calendar": "Hospital-MAC-La Viga",
                          "hospital": "MAC La Viga",
                          "id": "22"
                      },
                      "Nombre": "BRENDA OLIMPIA ROJAS CAMARGO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "BOROJASC",
                      "Pass": "BOROJASC",
                      "Permiso": "administrador",
                      "Activo": false
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "17",
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Los Mochis",
                          "hospital": "MAC Los Mochis",
                          "mnemonicos": "MOC",
                          "activo": true
                      },
                      "Nick": "ZNAVARRETEC",
                      "Nombre": "Zulema Cervantes Navarrete",
                      "Pass": "Nava.adm"
                  },
                  {
                      "Pass": "administracion",
                      "Nick": "capacitacion",
                      "Activo": true,
                      "Nombre": "Capacitación Administración",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "4",
                          "hospital": "MAC Aguascalientes Sur"
                      },
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "EnfermeriaSur",
                      "Nick": "EVAZQUEZM",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "mnemonicos": "PER",
                          "num_asociado": 60,
                          "activo": true,
                          "hospital": "MAC Perisur",
                          "calendar": "Hospital-MAC-Perisur",
                          "id": "19"
                      },
                      "Activo": true,
                      "Nombre": "Eduardo Vazquez Molina"
                  },
                  {
                      "Nick": "CJEZPIXC",
                      "Pass": "serviciosagenda4",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Cinthya Janet Ezpix De La Cruz",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 1,
                          "mnemonicos": "PUE",
                          "hospital": "MAC Puebla",
                          "calendar": "Hospital-MAC-Puebla",
                          "id": "7",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com"
                      },
                      "Activo": true,
                      "Permiso": "tablero"
                  },
                  {
                      "Nombre": "MEJORA CONTINUA TI",
                      "Pass": "MCONTINUALV",
                      "Permiso": "administrador",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC La Viga",
                          "id": "22",
                          "activo": true,
                          "email_calidad": "",
                          "num_asociado": 25,
                          "calendar": "Hospital-MAC-La Viga",
                          "mnemonicos": "LVG"
                      },
                      "Nick": "MCONTINUALV"
                  },
                  {
                      "Pass": "mac2023",
                      "Nick": "claudia.celaya",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "identificador": "hf0tnK3M7B3kmXMdHDLS",
                          "mnemonicos": "CEL",
                          "num_asociado": 2,
                          "activo": true,
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Celaya",
                          "hospital": "MAC Celaya",
                          "id": "1"
                      },
                      "Permiso": "credencializacion",
                      "Nombre": "CLAUDIA BARRIENTOS TORRES"
                  },
                  {
                      "Nombre": "David Emilio Mendoza",
                      "Nick": "DEMENDOZA",
                      "Pass": "guanajuato1",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Guanajuato",
                          "num_asociado": 11,
                          "id": "16",
                          "mnemonicos": "GTO",
                          "hospital": "MediMAC Guanajuato",
                          "activo": true
                      },
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "9",
                          "hospital": "Corporativo",
                          "num_asociado": 9,
                          "mnemonicos": "COR"
                      },
                      "Nick": "MBLUPERCIOV",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Pass": "CORPOADMN",
                      "Permiso": "administrador",
                      "Nombre": "MARIA BELEN LUPERCIO VELASCO"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "id": "4",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Sur"
                      },
                      "Nombre": "Capacitación Quirófano",
                      "Permiso": "quirofano",
                      "Pass": "quirofano",
                      "Nick": "capacitacion"
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "ECA",
                          "num_asociado": 18,
                          "calendar": "Hospital-MAC-Ecatepec",
                          "hospital": "MediMAC Ecatepec",
                          "activo": true,
                          "id": "45"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Pass": "AgendaQuirofano3",
                      "Permiso": "agenda",
                      "Nick": "ECATEPEC",
                      "Nombre": "Responsiva Jefe de Enfermería"
                  },
                  {
                      "Permiso": "lectura",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "activo": true,
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "hospital": "Corporativo",
                          "id": "9",
                          "num_asociado": 9
                      },
                      "Pass": "mac2023",
                      "Nombre": "Itcel Rodríguez",
                      "Activo": false,
                      "Nick": "itcel.rodriguez"
                  },
                  {
                      "Nombre": "Hayde Arellano",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "num_asociado": 0,
                          "id": "1",
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "activo": true,
                          "mnemonicos": "CEL",
                          "calendar": "Hospital-MAC-Celaya"
                      },
                      "Nick": "hayde",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          },
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "arellano",
                      "Permiso": "administrador"
                  },
                  {
                      "Nombre": "Responsiva jefe de enfermería",
                      "Pass": "quirofano5",
                      "Nick": "BOCARIO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Boca del Río",
                          "mnemonicos": "BDR",
                          "hospital": "MediMAC Boca del Rio",
                          "activo": true,
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "num_asociado": 23,
                          "id": "15"
                      },
                      "Permiso": "agenda"
                  },
                  {
                      "Pass": "mac2023",
                      "Nick": "janeth.guanajuato",
                      "Permiso": "credencializacion",
                      "UnidadHospitalaria": {
                          "id": "16",
                          "activo": true,
                          "hospital": "MediMAC Guanajuato",
                          "calendar": "Hospital-MAC-Guanajuato",
                          "num_asociado": "0",
                          "identificador": "XqOVRCpZ5fxbb1ZCOh2p",
                          "mnemonicos": "GTO"
                      },
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Nombre": "JANETH MONSERRAT GUTIERREZ MORADO"
                  },
                  {
                      "Pass": "QUIROFANOQRO",
                      "Nick": "QUIROFANOQRO",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "calendar": "Hospital-HMAS-Querétaro",
                          "mnemonicos": "QRO",
                          "num_asociado": "0",
                          "hospital": "HMAS Querétaro",
                          "id": "21"
                      },
                      "Permiso": "quirofano",
                      "Nombre": "QUIROFANO QRO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Pass": "CONSULTAQRO",
                      "Nombre": "CONSULTA QUERÉTARO",
                      "Activo": true,
                      "Nick": "CONSULTAQRO",
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Querétaro",
                          "num_asociado": "0",
                          "activo": true,
                          "calendar": "Hospital-HMAS-Querétaro",
                          "id": "21",
                          "mnemonicos": "QRO"
                      },
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Pass": "CCASTILLO",
                      "Nick": "CCASTILLO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "QRO",
                          "activo": true,
                          "calendar": "Hospital-HMAS-Querétaro",
                          "id": "21",
                          "num_asociado": "0",
                          "hospital": "HMAS Querétaro"
                      },
                      "Nombre": "CRISTIAN YAHIR CASTILLO",
                      "Permiso": "administrador",
                      "Activo": true
                  },
                  {
                      "Pass": "programacioncalidad",
                      "Nick": "JEVERAR",
                      "Permiso": "calidad",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "José Enrique Vera Ramirez",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "id": "3",
                          "hospital": "MAC Guadalajara",
                          "activo": true,
                          "calendar": "Hospital-MAC-Guadalajara",
                          "mnemonicos": "GDL"
                      }
                  },
                  {
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "17",
                          "mnemonicos": "MOC",
                          "hospital": "MAC Los Mochis",
                          "activo": true,
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Los Mochis"
                      },
                      "Pass": "Nava.adm",
                      "Activo": true,
                      "Nick": "ZNAVARRETEC",
                      "Nombre": "Zulema Cervantes Navarrete"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "tlalnepantla1",
                      "UnidadHospitalaria": {
                          "num_asociado": 25,
                          "activo": true,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "mnemonicos": "TLA",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "id": "14",
                          "hospital": "MediMAC Tlalnepantla"
                      },
                      "Nombre": "Rene Cabrera Lecona",
                      "Permiso": "administrador",
                      "Nick": "RCABRERAL"
                  },
                  {
                      "Permiso": "calidad",
                      "Activo": true,
                      "Nick": "AGJIMENEZO",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "LCA",
                          "num_asociado": "0",
                          "id": "20",
                          "hospital": "HMAS Los Cabos",
                          "email_calidad": "credencializacion@hmas.mx",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "activo": true
                      },
                      "Nombre": "Anna Guadalupe Jimenez Osuna",
                      "Pass": "CatalogoQuirofano"
                  },
                  {
                      "Permiso": "mesa",
                      "Pass": "hectorHerrera",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 8,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "activo": true,
                          "id": "9"
                      },
                      "Nombre": "Hector Herrera",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "hector.herrera"
                  },
                  {
                      "Permiso": "calidad",
                      "Pass": "CalidadAgenda3",
                      "Nombre": "Leonardo Erick Flores Vazquez",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Ecatepec",
                          "num_asociado": 18,
                          "id": "45",
                          "calendar": "Hospital-MAC-Ecatepec",
                          "mnemonicos": "ECA",
                          "activo": true
                      },
                      "Nick": "LEFLORESV",
                      "Activo": true
                  },
                  {
                      "Nick": "ROSA.BARRAGAN",
                      "Nombre": "Rosa Isaura Oseguerra Barragan",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "id": "19",
                          "num_asociado": 60,
                          "activo": true,
                          "mnemonicos": "PER",
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Pass": "CalidadQuirofano",
                      "Permiso": "calidad"
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": 27,
                          "id": "17",
                          "mnemonicos": "MOC",
                          "hospital": "MAC Los Mochis",
                          "identificador": "yOvFHZmXst7RPPOefQXT",
                          "calendar": "Hospital-MAC-Los Mochis",
                          "activo": true
                      },
                      "Activo": true,
                      "Pass": "mac2024",
                      "Permiso": "lectura",
                      "Nick": "jesus.mochis",
                      "Nombre": "Jesús Abraham Castro Berrelleza",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Pass": "RECUPERACIONLV",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LVG",
                          "num_asociado": 25,
                          "email_calidad": "",
                          "activo": true,
                          "id": "22",
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga"
                      },
                      "Nick": "RECUPERACIONLV",
                      "Nombre": "Recuperacion La Viga",
                      "Permiso": "recuperación",
                      "Activo": true
                  },
                  {
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "EMARTINEZX",
                      "UnidadHospitalaria": {
                          "num_asociado": 25,
                          "hospital": "MediMAC Tlalnepantla",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "mnemonicos": "TLA",
                          "activo": true,
                          "id": "14",
                          "calendar": "Hospital-MAC-Tlalnepantla"
                      },
                      "Activo": true,
                      "Nombre": "Edith Martínez Xochihua",
                      "Pass": "quirofanotlalnepantla"
                  },
                  {
                      "Nick": "AERODRIGUEZR",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "3",
                          "hospital": "MAC Guadalajara",
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "activo": true,
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "GDL"
                      },
                      "Pass": "agendaprogramacion",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Aurea Elizabeth Rodriguez Rodriguez",
                      "Activo": true
                  },
                  {
                      "Nick": "sergio.merida",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "mac2024",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Merida",
                          "mnemonicos": "MER",
                          "id": "42",
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "activo": true,
                          "identificador": "gPqsBxshZAIcwc4lg0b3",
                          "num_asociado": 12,
                          "hospital": "MediMAC Merida"
                      },
                      "Permiso": "credencializacion",
                      "Nombre": "Sergio Ignacio Ruz Piste"
                  },
                  {
                      "Nick": "fernanda.salazar",
                      "Systems": [
                          {
                              "id": "2",
                              "active": true
                          }
                      ],
                      "Pass": "fsalazar",
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "id": "9",
                          "mnemonicos": "COR",
                          "activo": true,
                          "num_asociado": 6
                      },
                      "Nombre": "Fernanda Salazar Hernandez",
                      "Permiso": "administrador",
                      "Activo": true
                  },
                  {
                      "Nombre": "Janine Ponce Galindo",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "GTO",
                          "calendar": "Hospital-MAC-Guanajuato",
                          "num_asociado": 11,
                          "hospital": "MediMAC Guanajuato",
                          "id": "16"
                      },
                      "Activo": true,
                      "Permiso": "tablero",
                      "Nick": "JPONCEG",
                      "Pass": "guanajuato2"
                  },
                  {
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "22",
                          "email_calidad": "",
                          "activo": true,
                          "calendar": "Hospital-MAC-La Viga",
                          "hospital": "MAC La Viga",
                          "mnemonicos": "LVG",
                          "num_asociado": 51
                      },
                      "Nombre": "JOSE ROBERTO BASTARRACHEA RIVERA",
                      "Nick": "JRBASTARRACHEAR",
                      "Pass": "JRBASTARRACHEAR",
                      "Activo": false
                  },
                  {
                      "Activo": true,
                      "Pass": "ANCHACON",
                      "Nombre": "Antonio Chacón",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Cuemanco",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "id": "44",
                          "num_asociado": 0,
                          "mnemonicos": "CUE",
                          "activo": true
                      },
                      "Nick": "ANCHACON",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador"
                  },
                  {
                      "Nombre": "Ruben Ayala",
                      "Activo": true,
                      "Pass": "LeonAyala1049.",
                      "Nick": "ruben_ayala",
                      "Permiso": "lectura",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-León",
                          "identificador": "hwVGvefNSD6uZfjhdLoD",
                          "id": "18",
                          "num_asociado": 91,
                          "hospital": "MAC León",
                          "activo": true,
                          "mnemonicos": "LEO"
                      },
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Nick": "arnulfo.veloz",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "num_asociado": 4,
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "activo": true
                      },
                      "Permiso": "superuser",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Pass": "arnulfo-veloz",
                      "Nombre": "Arnulfo Veloz Gutierrez",
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Querétaro",
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "mnemonicos": "QRO",
                          "id": "21",
                          "activo": true
                      },
                      "Activo": true,
                      "Nick": "CCARMONA",
                      "Pass": "CCARMONA",
                      "Permiso": "administrador",
                      "Nombre": "María del Carmen Carmona Ornelas"
                  },
                  {
                      "Pass": "Programacionqx2",
                      "Activo": true,
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MEX",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Mexicali",
                          "id": "8",
                          "hospital": "MAC Mexicali",
                          "activo": true,
                          "num_asociado": "0"
                      },
                      "Nombre": "Antonio Macías Chavez",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AMACIASC"
                  },
                  {
                      "Nick": "joselomeli",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "18",
                          "calendar": "Hospital-MAC-León",
                          "hospital": "MAC León"
                      },
                      "Activo": true,
                      "Permiso": "administrador",
                      "Nombre": "José Manuel Lomelí Teran",
                      "Pass": "manuel.teran#"
                  },
                  {
                      "Activo": true,
                      "Nick": "anette.narvaez",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 9,
                          "hospital": "Corporativo",
                          "id": "9",
                          "mnemonicos": "COR",
                          "identificador": "MolsdOWdaka5W52m8e0I"
                      },
                      "Pass": "Totopo94",
                      "Permiso": "superuser",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "Anette Narvaez"
                  },
                  {
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "BCOLULAC",
                      "Activo": true,
                      "Pass": "quirofanopuebla",
                      "UnidadHospitalaria": {
                          "mnemonicos": "PUE",
                          "calendar": "Hospital-MAC-Puebla",
                          "id": "7",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Puebla",
                          "num_asociado": 1
                      },
                      "Nombre": "Benjamin Colula Cortes"
                  },
                  {
                      "UnidadHospitalaria": {
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "activo": true,
                          "id": "1",
                          "num_asociado": 3,
                          "calendar": "Hospital-MAC-Celaya",
                          "hospital": "MAC Celaya",
                          "mnemonicos": "CEL"
                      },
                      "Activo": false,
                      "Permiso": "administrador",
                      "Nombre": "Dr Manuel Barrientos",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          },
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "MBARRIENTOS",
                      "Pass": "administrador"
                  },
                  {
                      "Pass": "AgendaCalidad2",
                      "Activo": true,
                      "Nick": "ALHERNANDEZ",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "IRA",
                          "activo": true,
                          "hospital": "MAC Irapuato",
                          "id": "2",
                          "num_asociado": 20,
                          "calendar": "Hospital-MAC-Irapuato"
                      },
                      "Permiso": "calidad",
                      "Nombre": "Aidee Lorena Herndandez"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Pedro Leonardo Montiel Ramirez",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "mnemonicos": "GTO",
                          "id": "16",
                          "calendar": "Hospital-MAC-Guanajuato",
                          "num_asociado": 11,
                          "hospital": "MediMAC Guanajuato",
                          "activo": true
                      },
                      "Nick": "PLMONTIELR",
                      "Activo": true,
                      "Pass": "agendaenfermeria"
                  },
                  {
                      "Nombre": "Jonathan Esquivel Cruz",
                      "Nick": "JESQUIVELC",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "mnemonicos": "PER",
                          "calendar": "Hospital-MAC-Perisur",
                          "num_asociado": 60,
                          "id": "19",
                          "activo": true
                      },
                      "Activo": true,
                      "Pass": "VinculacionQx",
                      "Permiso": "calidad"
                  },
                  {
                      "Activo": true,
                      "Nick": "VisualizadorG",
                      "Pass": "HospitalMAC",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LEO",
                          "num_asociado": "0",
                          "hospital": "MAC León",
                          "activo": true,
                          "calendar": "Hospital-MAC-León",
                          "id": "18"
                      },
                      "Nombre": "SERVICIOS GENERALES",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Pass": "CONYAMON",
                      "Nick": "MCONTRERASY",
                      "Activo": true,
                      "Permiso": "administrador",
                      "Nombre": "MONSERRAT CONTRERAS YAÑEZ",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "num_asociado": 10,
                          "id": "9",
                          "activo": true,
                          "hospital": "Corporativo"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "AgendaNorte",
                      "Activo": true,
                      "Nombre": "Marcela Arrioja",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Aguascalientes Norte",
                          "num_asociado": 1,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "id": "11",
                          "activo": true,
                          "mnemonicos": "AGN",
                          "calendar": "Hospital-MAC-Aguascalientes Norte"
                      },
                      "Nick": "MARRIOJA"
                  },
                  {
                      "Pass": "JHERRERAS",
                      "Nick": "JHERRERAS",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "id": "44",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "num_asociado": 0,
                          "mnemonicos": "CUE",
                          "hospital": "MediMAC Cuemanco",
                          "activo": true
                      },
                      "Permiso": "tablero",
                      "Nombre": "Juan Herrera SanJuan"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "num_asociado": 6,
                          "id": "9",
                          "activo": true,
                          "hospital": "Corporativo"
                      },
                      "Pass": "corporativo",
                      "Nick": "mejora",
                      "Permiso": "administrador",
                      "Nombre": "Mejora Continua Corporativo"
                  },
                  {
                      "Pass": "quirofano",
                      "Nombre": "Mejora Continua Quirofano",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "IRA",
                          "num_asociado": "0",
                          "id": "2",
                          "calendar": "Hospital-MAC-Irapuato",
                          "hospital": "MAC Irapuato",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com"
                      },
                      "Nick": "mejora",
                      "Activo": true,
                      "Permiso": "quirofano",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ]
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "QRO",
                          "id": "21",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "num_asociado": "0",
                          "hospital": "HMAS Querétaro",
                          "activo": true
                      },
                      "Nombre": "DAVID SAN MARCOS ROMERO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Nick": "DSANMARCOS",
                      "Permiso": "administrador",
                      "Pass": "DSANMARCOS"
                  },
                  {
                      "Permiso": "administrador",
                      "Activo": true,
                      "Pass": "ADMINROSA",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "num_asociado": 10,
                          "id": "9"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "JOEL RODRIGUEZ SANCHEZ",
                      "Nick": "JRODRIGUEZS"
                  },
                  {
                      "Activo": true,
                      "Nombre": "Karina Ortiz Martinez",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "activo": true,
                          "id": "19",
                          "calendar": "Hospital-MAC-Perisur",
                          "num_asociado": 60,
                          "mnemonicos": "PER"
                      },
                      "Permiso": "administrador",
                      "Nick": "KORTIZM",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "QuirofanoSur"
                  },
                  {
                      "Permiso": "superuser",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "Corporativo",
                          "num_asociado": 4,
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "id": "9",
                          "mnemonicos": "COR"
                      },
                      "Nombre": "Ivan Zavala",
                      "Pass": "ivan.zavala",
                      "Nick": "ivan_zavala",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "id": "9",
                          "num_asociado": 9,
                          "identificador": "MolsdOWdaka5W52m8e0I"
                      },
                      "Pass": "pejetariana",
                      "Permiso": "lectura",
                      "Nombre": "Ana Victoria Cuelllo",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nick": "anavic"
                  },
                  {
                      "Nick": "JMGUTIERREZM",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "GTO",
                          "num_asociado": 11,
                          "hospital": "MediMAC Guanajuato",
                          "id": "16",
                          "activo": true,
                          "calendar": "Hospital-MAC-Guanajuato"
                      },
                      "Pass": "calidadagenda",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "calidad",
                      "Nombre": "Janet Monserrat Gutierrez Morado"
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "hospital": "MAC Celaya",
                          "mnemonicos": "CEL",
                          "activo": true,
                          "id": "1",
                          "calendar": "Hospital-MAC-Celaya"
                      },
                      "Activo": true,
                      "Nombre": "CENDIS",
                      "Nick": "CENDISCLY",
                      "Permiso": "tablero",
                      "Pass": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Nick": "GCASTILLOG",
                      "Nombre": "Grissel Castillo Gonzalez",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "QuirofanoCabos",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "20",
                          "email_calidad": "credencializacion@hmas.mx",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "mnemonicos": "LCA",
                          "num_asociado": "0",
                          "hospital": "HMAS Los Cabos"
                      }
                  },
                  {
                      "Nick": "ZLGARCIAE",
                      "Nombre": "Zaira Lizbeth Garcia Estudillo",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "AGN",
                          "num_asociado": 1,
                          "hospital": "MAC Aguascalientes Norte",
                          "id": "11",
                          "activo": true,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Aguascalientes Norte"
                      },
                      "Pass": "ServiciosQuirofano",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "id": "1",
                          "hospital": "MAC Celaya",
                          "mnemonicos": "CEL",
                          "calendar": "Hospital-MAC-Celaya",
                          "activo": true
                      },
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "jenfcly",
                      "Nombre": "JEFA ENFERMERIA",
                      "Nick": "JENFCLY"
                  },
                  {
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Cuemanco",
                          "mnemonicos": "CUE",
                          "activo": true,
                          "id": "44",
                          "num_asociado": 0,
                          "hospital": "MediMAC Cuemanco"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Antonio Chacón",
                      "Activo": true,
                      "Permiso": "administrador",
                      "Pass": "ACHACON",
                      "Nick": "ACHACON"
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "AdmisionAgenda5",
                      "UnidadHospitalaria": {
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "hospital": "MAC Mexicali",
                          "activo": true,
                          "mnemonicos": "MEX",
                          "id": "8",
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Mexicali"
                      },
                      "Nombre": "Claudia Singh Ballesteros",
                      "Nick": "CSINGHB",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 12,
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "calendar": "Hospital-MAC-Merida",
                          "mnemonicos": "MER",
                          "activo": true,
                          "hospital": "MediMAC Merida",
                          "id": "42"
                      },
                      "Nombre": "Alaidee Jocelyn Ramirez González ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "AJRAMIREZG",
                      "Pass": "meridaagenda1",
                      "Permiso": "administrador"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "ECOTAC",
                      "Pass": "AgendaCabos7",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "mnemonicos": "LCA",
                          "hospital": "HMAS Los Cabos",
                          "email_calidad": "credencializacion@hmas.mx",
                          "num_asociado": "0",
                          "id": "20"
                      },
                      "Permiso": "tablero",
                      "Nombre": "Eleazar Cota Corral"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "SRIOSF",
                      "Nombre": "Sonia Rios Flores",
                      "Pass": "admision10",
                      "Permiso": "tablero",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC Puebla",
                          "calendar": "Hospital-MAC-Puebla",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "mnemonicos": "PUE",
                          "id": "7",
                          "num_asociado": 1,
                          "activo": true
                      }
                  },
                  {
                      "Nombre": "Miriam Lorena Bernal Cuautle",
                      "Nick": "MLBERNALC",
                      "Pass": "ServiciosQuirofano5",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "num_asociado": 60,
                          "mnemonicos": "PER",
                          "activo": true,
                          "id": "19",
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Nombre": "DIEGO ADAYR CORRO HERNANDEZ",
                      "Permiso": "credencializacion",
                      "Pass": "mac2023",
                      "Nick": "diego.boca",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "BDR",
                          "num_asociado": "0",
                          "id": "15",
                          "activo": true,
                          "hospital": "MediMAC Boca del Rio",
                          "calendar": "Hospital-MAC-Boca del Río",
                          "identificador": "jKG26506alTlGkxdV1YX",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com"
                      },
                      "Activo": true
                  },
                  {
                      "Pass": "enfermeriaqx2",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 25,
                          "id": "14",
                          "hospital": "MediMAC Tlalnepantla",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "mnemonicos": "TLA",
                          "activo": true
                      },
                      "Nick": "EBAILONR",
                      "Activo": true,
                      "Nombre": "Erika Bailon Rodriguez",
                      "Permiso": "agenda"
                  },
                  {
                      "Nombre": "Sandra Guadalupe Estrada Perez",
                      "Permiso": "tablero",
                      "Activo": true,
                      "Pass": "AdmisionEcatepec",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "SGESTRADAP",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Ecatepec",
                          "id": "45",
                          "num_asociado": 18,
                          "mnemonicos": "ECA",
                          "activo": true,
                          "hospital": "MediMAC Ecatepec"
                      }
                  },
                  {
                      "Nick": "belen.lupercio",
                      "Activo": true,
                      "Nombre": "Belén Lupercio",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Permiso": "lectura",
                      "Pass": "mac2023",
                      "UnidadHospitalaria": {
                          "num_asociado": 9,
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "activo": true,
                          "id": "9"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "raulMtz",
                      "Activo": true,
                      "Nick": "raul.martinez",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 8,
                          "hospital": "Corporativo",
                          "id": "9",
                          "mnemonicos": "COR"
                      },
                      "Permiso": "mesa",
                      "Nombre": "Raúl Martínez"
                  },
                  {
                      "Activo": true,
                      "Permiso": "administrador",
                      "Pass": "quirofano123",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "AMEJIAA",
                      "Nombre": "Adriana Mejía Aguilar",
                      "UnidadHospitalaria": {
                          "id": "6",
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "activo": true,
                          "hospital": "MAC San Miguel de Allende",
                          "mnemonicos": "SMA",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "num_asociado": "0"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "tablero",
                      "Pass": "AgendaImagen",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "LCA",
                          "email_calidad": "credencializacion@hmas.mx",
                          "id": "20",
                          "hospital": "HMAS Los Cabos",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "num_asociado": "0"
                      },
                      "Nombre": "Ramon Alfonso Sanchez Sanchez",
                      "Nick": "IMAGENQX"
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "identificador": "eknsJXoZouRw4SDFlARk",
                          "mnemonicos": "LCA",
                          "email_calidad": "credencializacion@hmas.mx",
                          "hospital": "HMAS Los Cabos",
                          "id": "20",
                          "num_asociado": "0"
                      },
                      "Activo": true,
                      "Permiso": "credencializacion",
                      "Nombre": "IDANY PAULINA ALOMIA CASTELO",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Pass": "mac2023",
                      "Nick": "idany.cabos"
                  },
                  {
                      "Nick": "MCQUERETARO",
                      "Pass": "MCQUERETARO",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "activo": true,
                          "mnemonicos": "QRO",
                          "id": "21",
                          "hospital": "HMAS Querétaro",
                          "calendar": "Hospital-HMAS-Querétaro"
                      },
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "MEJORA CONTINUA CORPORATIVO",
                      "Activo": true
                  },
                  {
                      "Nick": "CIRODRIGUEZP",
                      "Pass": "servicioquirofano2",
                      "Activo": true,
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Guanajuato",
                          "mnemonicos": "GTO",
                          "activo": true,
                          "calendar": "Hospital-MAC-Guanajuato",
                          "id": "16",
                          "num_asociado": 11
                      },
                      "Nombre": "Carlos Israel Rodriguez Palacios",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "Nick": "isaura.barragan",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "Isaura Barragan",
                      "Pass": "mac.2024",
                      "Permiso": "credencializacion",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "PER",
                          "num_asociado": "0",
                          "identificador": "eJwB0xpZe11uq3bkFi5s",
                          "calendar": "Hospital-MAC-Perisur",
                          "hospital": "MAC Perisur",
                          "id": "19"
                      }
                  },
                  {
                      "Permiso": "agenda",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "GDL",
                          "activo": true,
                          "num_asociado": "0",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "hospital": "MAC Guadalajara",
                          "id": "3"
                      },
                      "Nick": "GUADALAJARA",
                      "Activo": true,
                      "Nombre": "Responsiva jefe de enfermería",
                      "Pass": "quirofano5"
                  },
                  {
                      "Nombre": "Victor Esteban Cebreros Quevedo",
                      "Nick": "VECEBREROSQ",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Pass": "Cebre.admi",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MOC",
                          "num_asociado": "0",
                          "activo": true,
                          "calendar": "Hospital-MAC-Los Mochis",
                          "id": "17",
                          "hospital": "MAC Los Mochis"
                      },
                      "Permiso": "administrador"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Pass": "CLKAGENDA",
                      "Permiso": "agenda",
                      "Nick": "CKCMANRIQUES",
                      "Nombre": "CLEMENTINA KARLA CRISTINA MANRIQUE SANDOVAL",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Irapuato",
                          "calendar": "Hospital-MAC-Irapuato",
                          "activo": true,
                          "num_asociado": 20,
                          "id": "2",
                          "mnemonicos": "IRA",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com"
                      }
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "IRA",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "num_asociado": 20,
                          "hospital": "MAC Irapuato",
                          "calendar": "Hospital-MAC-Irapuato",
                          "id": "2"
                      },
                      "Nombre": "Flavio Josúe Martínez Núñez",
                      "Nick": "FJMARTINEZ",
                      "Pass": "AgendaSistemas",
                      "Permiso": "tablero",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "calidad",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "hospital": "MAC Aguascalientes Norte",
                          "activo": true,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "num_asociado": 1,
                          "id": "11",
                          "mnemonicos": "AGN"
                      },
                      "Pass": "AgendaCalidad",
                      "Activo": true,
                      "Nombre": "Christophet Alejandro Ariza Acero",
                      "Nick": "CAARIZAA"
                  },
                  {
                      "Permiso": "agenda",
                      "Nombre": "Responsiva jefe de enfermería",
                      "Activo": true,
                      "Pass": "quirofano5",
                      "Nick": "PUEBLA",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "PUE",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "num_asociado": 1,
                          "id": "7",
                          "activo": true,
                          "calendar": "Hospital-MAC-Puebla",
                          "hospital": "MAC Puebla"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AFONGH",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "activo": true,
                          "id": "20",
                          "num_asociado": "0",
                          "mnemonicos": "LCA",
                          "hospital": "HMAS Los Cabos",
                          "email_calidad": "credencializacion@hmas.mx"
                      },
                      "Activo": true,
                      "Pass": "AdmCabos",
                      "Nombre": "Arlett Fong Hilares"
                  },
                  {
                      "Permiso": "lectura",
                      "Nombre": "Bianca Granados",
                      "Nick": "bianca.granados",
                      "Pass": "DireccionMedica392",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "id": "9",
                          "num_asociado": 9,
                          "activo": true,
                          "hospital": "Corporativo",
                          "identificador": "MolsdOWdaka5W52m8e0I"
                      }
                  },
                  {
                      "Permiso": "agenda",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MOC",
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Los Mochis",
                          "activo": true,
                          "hospital": "MAC Los Mochis",
                          "id": "17"
                      },
                      "Activo": true,
                      "Nombre": "QUIROFANO LOS MOCHIS",
                      "Pass": "QUIROFANOLM",
                      "Nick": "QUIROFANOLM",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "Nick": "ONEVAREZ",
                      "UnidadHospitalaria": {
                          "mnemonicos": "QRO",
                          "activo": true,
                          "num_asociado": "0",
                          "hospital": "HMAS Querétaro",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "id": "21"
                      },
                      "Pass": "ONEVAREZ",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "OLIMPIA NEVAREZ GONZALEZ",
                      "Permiso": "administrador"
                  },
                  {
                      "Nick": "EVSANTOSZ",
                      "Nombre": "Elvira Veronica Santos Zavala",
                      "Pass": "AdmisionPeriferico",
                      "UnidadHospitalaria": {
                          "mnemonicos": "PER",
                          "num_asociado": 60,
                          "calendar": "Hospital-MAC-Perisur",
                          "activo": true,
                          "id": "19",
                          "hospital": "MAC Perisur"
                      },
                      "Permiso": "tablero",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Permiso": "calidad",
                      "Nick": "rosario.gallardo",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 0,
                          "hospital": "MAC Integración Procesos Clínicos",
                          "id": "999",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "mnemonicos": "IPC"
                      },
                      "Nombre": "Rosario Gallardo",
                      "Pass": "calidadIntegracion"
                  },
                  {
                      "Pass": "admision11",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "SMA",
                          "num_asociado": "0",
                          "id": "6",
                          "hospital": "MAC San Miguel de Allende",
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "activo": true,
                          "email_calidad": "calidad.sma@hospitalesmac.com"
                      },
                      "Nombre": "Daniela Escobedo",
                      "Nick": "DESCOBEDO",
                      "Permiso": "tablero"
                  },
                  {
                      "Nick": "MIRUEDAR",
                      "UnidadHospitalaria": {
                          "id": "22",
                          "mnemonicos": "LVG",
                          "calendar": "Hospital-MAC-La Viga",
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "num_asociado": 25,
                          "email_calidad": ""
                      },
                      "Activo": true,
                      "Permiso": "tablero",
                      "Nombre": "MIRIAM ILIANA RUEDA REAL",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "MIRUEDAR"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "9",
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "num_asociado": 8
                      },
                      "Pass": "martinMC",
                      "Nombre": "Martín Morales Carapia",
                      "Nick": "martin.morales",
                      "Permiso": "mesa"
                  },
                  {
                      "Permiso": "agenda",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "MER",
                          "calendar": "Hospital-MAC-Merida",
                          "num_asociado": 12,
                          "hospital": "MediMAC Merida",
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "id": "42",
                          "activo": true
                      },
                      "Nick": "YDOMINGUEZ",
                      "Pass": "enfermeriamerida",
                      "Nombre": "Yareli Domínguez",
                      "Activo": true
                  },
                  {
                      "Nombre": "Diana Granados",
                      "Activo": true,
                      "Nick": "diana",
                      "Pass": "credenciales",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "num_asociado": 9,
                          "id": "9",
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "hospital": "Corporativo",
                          "activo": true
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Permiso": "lectura"
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MAC Aguascalientes Sur",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "num_asociado": 7,
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "mnemonicos": "AGS",
                          "id": "4"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Claudia Angelica Moreno Duran",
                      "Activo": true,
                      "Permiso": "tablero",
                      "Nick": "CAMORENOD",
                      "Pass": "ServiciosAgenda"
                  },
                  {
                      "Activo": true,
                      "Nick": "SSHUERTAR",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Guadalajara",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "activo": true,
                          "id": "3",
                          "mnemonicos": "GDL",
                          "num_asociado": "0",
                          "hospital": "MAC Guadalajara"
                      },
                      "Nombre": "Susana Sarahi Huerta Ramirez",
                      "Permiso": "tablero",
                      "Pass": "admision15",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "agenda",
                      "Nick": "PERISUR",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "mnemonicos": "PER",
                          "id": "19",
                          "activo": true,
                          "num_asociado": 60,
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Activo": true,
                      "Pass": "Quirofanoperisur",
                      "Nombre": "Responsiva firmada por jefatura de enfermería"
                  },
                  {
                      "Permiso": "administrador",
                      "Pass": "Ecatepecqx1",
                      "Activo": true,
                      "Nick": "JMLAGOSV",
                      "Nombre": "Jill Marian Lagos Vargas",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 18,
                          "mnemonicos": "ECA",
                          "id": "45",
                          "activo": true,
                          "hospital": "MediMAC Ecatepec",
                          "calendar": "Hospital-MAC-Ecatepec"
                      }
                  },
                  {
                      "Nick": "MTGUTIERREZS",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "quirofanocalidad",
                      "Activo": true,
                      "Permiso": "calidad",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "num_asociado": 25,
                          "id": "14",
                          "activo": true,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "hospital": "MediMAC Tlalnepantla",
                          "mnemonicos": "TLA"
                      },
                      "Nombre": "Miriam Teresa Gutierrez Salcido"
                  },
                  {
                      "Permiso": "jefeti",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": "6",
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "num_asociado": 3,
                          "mnemonicos": "CEL",
                          "activo": true,
                          "hospital": "MAC Celaya",
                          "id": "1",
                          "calendar": "Hospital-MAC-Celaya"
                      },
                      "Pass": "molina",
                      "Nick": "alejandro",
                      "Email": "alejandro.molina@hospitalesmac.com",
                      "Nombre": "Alejandro Molina"
                  },
                  {
                      "Permiso": "calidad",
                      "Nombre": "NORMA PAOLA ESTRADA HERNANDEZ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "NORMACAL1",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "activo": true,
                          "num_asociado": 20,
                          "hospital": "MAC Irapuato",
                          "mnemonicos": "IRA",
                          "id": "2",
                          "calendar": "Hospital-MAC-Irapuato"
                      },
                      "Nick": "NPESTRADAH"
                  },
                  {
                      "Pass": "TampicoAgenda1",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Tampico",
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "num_asociado": 34,
                          "id": "13",
                          "activo": true,
                          "mnemonicos": "TMP",
                          "calendar": "Hospital-MAC-Tampico"
                      },
                      "Nombre": "Jilema Arteaga Torres",
                      "Permiso": "agenda",
                      "Nick": "JARTEAGAT",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Permiso": "agenda",
                      "Activo": true,
                      "Nombre": "Responsiva jefe de enfermería",
                      "Pass": "quirofano5",
                      "Nick": "MERIDA",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Merida",
                          "id": "42",
                          "num_asociado": 12,
                          "activo": true,
                          "mnemonicos": "MER",
                          "hospital": "MediMAC Merida",
                          "email_calidad": "angel.adrian@merida.medimac.com"
                      }
                  },
                  {
                      "Nombre": "Mariela Hernandez Contreras",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "num_asociado": 34,
                          "id": "13",
                          "hospital": "MAC Tampico",
                          "mnemonicos": "TMP",
                          "activo": true,
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Tampico"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "Quirofano5",
                      "Activo": true,
                      "Nick": "MHERNANDEZC"
                  },
                  {
                      "Permiso": "administrador",
                      "Nombre": "OLIMPIA NEVAREZ GONZALEZ",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-HMAS-Querétaro",
                          "activo": true,
                          "hospital": "HMAS Querétaro",
                          "id": "21",
                          "mnemonicos": "QRO",
                          "num_asociado": "0"
                      },
                      "Nick": "ONEVAREZ",
                      "Pass": "ONEVAREZ",
                      "Activo": true
                  },
                  {
                      "Activo": true,
                      "Pass": "MCONTINUACM",
                      "Nick": "MCONTINUACM",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          },
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Nombre": "MEJORA CONTINUA TI",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Cuemanco",
                          "num_asociado": 0,
                          "hospital": "MediMAC Cuemanco",
                          "mnemonicos": "CUE",
                          "id": "44",
                          "activo": true
                      },
                      "Permiso": "administrador"
                  },
                  {
                      "Permiso": "administrador",
                      "Pass": "quirofano5",
                      "Activo": true,
                      "Nick": "TLALNEPANTLA",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "TLA",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "activo": true,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "hospital": "MediMAC Tlalnepantla",
                          "id": "14",
                          "num_asociado": 25
                      },
                      "Nombre": "Responsiva jefe de enfermería"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "Nombre": "Ana Cagigas",
                      "Pass": "Perro45.",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "hospital": "Corporativo",
                          "activo": true,
                          "id": "9",
                          "num_asociado": 9
                      },
                      "Permiso": "lectura",
                      "Nick": "ana.cagigas"
                  },
                  {
                      "Nombre": "Arcelia Robledo",
                      "Permiso": "mesa",
                      "Pass": "arceliaRobledo",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "arcelia.robledo",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 8,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "id": "9"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "CatalogoQuirofano",
                      "Permiso": "calidad",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "activo": true,
                          "num_asociado": "0",
                          "id": "20",
                          "mnemonicos": "LCA",
                          "email_calidad": "credencializacion@hmas.mx",
                          "hospital": "HMAS Los Cabos"
                      },
                      "Nick": "AGJIMENEZO",
                      "Activo": true,
                      "Nombre": "Anna Guadalupe Jimenez Osuna"
                  },
                  {
                      "Nombre": "AGENDA QUERÉTARO",
                      "Activo": true,
                      "Nick": "AGENDAQRO",
                      "UnidadHospitalaria": {
                          "mnemonicos": "QRO",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "activo": true,
                          "id": "21",
                          "num_asociado": "0",
                          "hospital": "HMAS Querétaro"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda",
                      "Pass": "AGENDAQRO"
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "14",
                          "identificador": "warrEGfxJRVLrzcRZNsR",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "num_asociado": 25,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "hospital": "MediMAC Tlalnepantla",
                          "mnemonicos": "TLA"
                      },
                      "Activo": true,
                      "Permiso": "lectura",
                      "Pass": "mac2023",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nick": "maria.tlalnepantla",
                      "Nombre": "MARIA TERESA GUTIERRES SALCIDO"
                  },
                  {
                      "Activo": true,
                      "Pass": "tostada8453",
                      "Nick": "elizabeth.retana",
                      "UnidadHospitalaria": {
                          "identificador": "PTiH3S8IoyjBVUUkTp59",
                          "id": "21",
                          "activo": true,
                          "mnemonicos": "QRO",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "num_asociado": 1,
                          "hospital": "HMAS Querétaro"
                      },
                      "Nombre": "Elizabeth Rodriguez Retana",
                      "Permiso": "credencializacion",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Permiso": "lectura",
                      "Nick": "andresrrb",
                      "Activo": true,
                      "Pass": "andres23",
                      "Nombre": "Dr. Andrés Robin Rodríguez Balboa",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "COR",
                          "id": "9",
                          "hospital": "Corporativo",
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "num_asociado": 9
                      }
                  },
                  {
                      "Nick": "Fatima",
                      "Nombre": "Fatima Torres",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "9",
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "num_asociado": 7
                      },
                      "Pass": "Corp2024+",
                      "Systems": [
                          {
                              "active": true,
                              "id": "6"
                          }
                      ],
                      "Permiso": "infraestructura",
                      "Email": "fatima.torres@hospitalesmac.com"
                  },
                  {
                      "Permiso": "credencializacion",
                      "Nombre": "Capacitaciones Procesos",
                      "UnidadHospitalaria": {
                          "id": "999",
                          "hospital": "MAC Integración Procesos Clínicos",
                          "num_asociado": 0,
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "activo": true,
                          "mnemonicos": "IPC",
                          "identificador": "c76rEasKDO7Geq3AhMro"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nick": "capacitaciones",
                      "Pass": "hmac2024"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Mesa de Ayuda",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "activo": true,
                          "num_asociado": 7,
                          "id": "9",
                          "hospital": "Corporativo"
                      },
                      "Nick": "soporte",
                      "Pass": "hmac2024",
                      "Permiso": "mesa",
                      "Activo": true
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Sergio Ignacio Ruz Piste",
                      "Nick": "SIRUZP",
                      "Activo": true,
                      "Pass": "calidadmerida1",
                      "UnidadHospitalaria": {
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "num_asociado": 12,
                          "hospital": "MediMAC Merida",
                          "mnemonicos": "MER",
                          "activo": true,
                          "calendar": "Hospital-MAC-Merida",
                          "id": "42"
                      },
                      "Permiso": "calidad"
                  },
                  {
                      "Pass": "ADMISIONCM",
                      "Nick": "ADMISIONCM",
                      "Nombre": "Elizabeth Martínez Rodriguez",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Cuemanco",
                          "num_asociado": 0,
                          "activo": true,
                          "mnemonicos": "CUE",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "id": "44"
                      },
                      "Activo": true
                  },
                  {
                      "Nombre": "Daniel Baeza Aguilar",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "activo": true,
                          "mnemonicos": "GDL",
                          "id": "3",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "hospital": "MAC Guadalajara"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "DBAEZAA",
                      "Activo": true,
                      "Permiso": "tablero",
                      "Pass": "agenda123"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "LIA.SOTOR",
                      "UnidadHospitalaria": {
                          "num_asociado": 20,
                          "id": "2",
                          "activo": true,
                          "hospital": "MAC Irapuato",
                          "calendar": "Hospital-MAC-Irapuato",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "IRA"
                      },
                      "Pass": "IrapuatoQx",
                      "Nombre": "Lia Soto Rocha",
                      "Permiso": "agenda",
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Querétaro",
                          "mnemonicos": "QRO",
                          "identificador": "PTiH3S8IoyjBVUUkTp59",
                          "activo": true,
                          "id": "21",
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Querétaro"
                      },
                      "Pass": "mac2023",
                      "Permiso": "credencializacion",
                      "Activo": true,
                      "Nombre": "RAFAEL SALINAS JIMENEZ",
                      "Nick": "rafael.qro",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ]
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": 60,
                          "mnemonicos": "PER",
                          "calendar": "Hospital-MAC-Perisur",
                          "identificador": "eJwB0xpZe11uq3bkFi5s",
                          "id": "19",
                          "hospital": "MAC Perisur",
                          "activo": true
                      },
                      "Pass": "mac2023",
                      "Nick": "erika.perisur",
                      "Nombre": "ERIKA ESTRADA OLEA",
                      "Activo": false,
                      "Permiso": "lectura",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Pass": "mac2023",
                      "Permiso": "credencializacion",
                      "Nick": "aidee.irapuato",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "id": "2",
                          "hospital": "MAC Irapuato",
                          "num_asociado": "0",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "activo": true,
                          "mnemonicos": "IRA",
                          "calendar": "Hospital-MAC-Irapuato",
                          "identificador": "etApvBaeuDGpWqB1kd6L"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nombre": "AIDEE LORENA HERNANDEZ AZUELA"
                  },
                  {
                      "Permiso": "tablero",
                      "Nick": "LRDURANC",
                      "Activo": true,
                      "Nombre": "Luis Rubén Duran Camberos",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "hospital": "MAC Guadalajara",
                          "mnemonicos": "GDL",
                          "id": "3"
                      },
                      "Pass": "servicioquirofano3",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Nombre": "Mayra Leticia Juárez Cortes",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "id": "7",
                          "calendar": "Hospital-MAC-Puebla",
                          "activo": true,
                          "mnemonicos": "PUE",
                          "hospital": "MAC Puebla",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com"
                      },
                      "Nick": "MLJUAREZC",
                      "Permiso": "agenda",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "enfermeriaagenda"
                  },
                  {
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-Guadalajara",
                          "id": "3",
                          "hospital": "MAC Guadalajara",
                          "mnemonicos": "GDL"
                      },
                      "Nombre": "Juan Carlos Ayala Álvarez",
                      "Activo": true,
                      "Pass": "gdlagenda1",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "JCAYALAA"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "JEFEQUIROFANO",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 35,
                          "calendar": "Hospital-MAC-León",
                          "id": "18",
                          "mnemonicos": "LEO",
                          "hospital": "MAC León"
                      },
                      "Permiso": "agenda",
                      "Activo": true,
                      "Nombre": "Jefe Quirofano",
                      "Pass": "JEFEQUIROFANO.1"
                  },
                  {
                      "Permiso": "superuser",
                      "Activo": true,
                      "Nombre": "Melissa Frausto",
                      "Pass": "Tortas@53",
                      "UnidadHospitalaria": {
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "num_asociado": 1,
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "identificador": "JQ7KGLLcBjLvqg1w1u6X",
                          "mnemonicos": "AGN",
                          "activo": true,
                          "id": "11",
                          "hospital": "MAC Aguascalientes Norte"
                      },
                      "Nick": "melissa.frausto",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ]
                  },
                  {
                      "Pass": "joseGarcia",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "mesa",
                      "Activo": true,
                      "Nick": "jose.garcia",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "activo": true,
                          "num_asociado": 8,
                          "hospital": "Corporativo",
                          "id": "9"
                      },
                      "Nombre": "José García"
                  },
                  {
                      "Nombre": "Adrián Quiroga Contreras",
                      "Permiso": "administrador",
                      "Pass": "QuirofanoSur",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AQUIROGAC",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Aguascalientes Sur",
                          "num_asociado": 7,
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "id": "4",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "activo": true
                      },
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "id": "9",
                          "num_asociado": 5,
                          "activo": true
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Permiso": "superuser",
                      "Pass": "vincula345",
                      "Nombre": "Jaime Arriaga",
                      "Nick": "jaime_arriaga",
                      "Activo": true
                  },
                  {
                      "Permiso": "administrador",
                      "Pass": "JZARAGOZAG",
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Querétaro",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "id": "21",
                          "mnemonicos": "QRO",
                          "activo": true,
                          "num_asociado": "0"
                      },
                      "Nick": "JZARAGOZAG",
                      "Nombre": "José de Jesús Zaragoza Galván",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Nombre": "Luz Ma López Davalos",
                      "Pass": "Qxtlalnepantla",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "mnemonicos": "TLA",
                          "activo": true,
                          "id": "14",
                          "hospital": "MediMAC Tlalnepantla",
                          "num_asociado": 25,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "calendar": "Hospital-MAC-Tlalnepantla"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Nick": "Luz.lopez"
                  },
                  {
                      "Nick": "QXCELAYA",
                      "Pass": "agenda",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "1",
                          "calendar": "Hospital-MAC-Celaya",
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "hospital": "MAC Celaya",
                          "num_asociado": 0,
                          "mnemonicos": "CEL"
                      },
                      "Activo": true,
                      "Permiso": "agenda",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Enfermeria Qx"
                  },
                  {
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "mac2024",
                      "Nick": "miriam.tlalne",
                      "Nombre": "Miriam gutiérrez Salcido",
                      "Permiso": "credencializacion",
                      "UnidadHospitalaria": {
                          "id": "14",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "mnemonicos": "TLA",
                          "activo": true,
                          "num_asociado": "0",
                          "identificador": "warrEGfxJRVLrzcRZNsR",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "hospital": "MediMAC Tlalnepantla"
                      }
                  },
                  {
                      "Permiso": "lectura",
                      "Nick": "angel.merida",
                      "Pass": "mac2023",
                      "UnidadHospitalaria": {
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "activo": true,
                          "id": "42",
                          "identificador": "gPqsBxshZAIcwc4lg0b3",
                          "mnemonicos": "MER",
                          "calendar": "Hospital-MAC-Merida",
                          "num_asociado": 18,
                          "hospital": "MediMAC Merida"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nombre": "ANGEL IVAN ADRIAN PUERTO"
                  },
                  {
                      "Nombre": "Alexia Reyes Mateos",
                      "Pass": "alexiaRM",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "COR",
                          "num_asociado": 8,
                          "id": "9",
                          "hospital": "Corporativo"
                      },
                      "Permiso": "mesa",
                      "Nick": "alexia.reyes"
                  },
                  {
                      "Pass": "ServiciosAgenda5",
                      "Activo": true,
                      "Nombre": "Maria Teresa Campos Briseño",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Ecatepec",
                          "mnemonicos": "ECA",
                          "calendar": "Hospital-MAC-Ecatepec",
                          "activo": true,
                          "id": "45",
                          "num_asociado": 18
                      },
                      "Nick": "MTCAMPOSB",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Nick": "agenda",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda",
                      "Activo": true,
                      "Nombre": "Agenda Leon",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LEO",
                          "activo": true,
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-León",
                          "hospital": "MAC León",
                          "id": "18"
                      },
                      "Pass": "agenda.leon1"
                  },
                  {
                      "Activo": true,
                      "Pass": "ROMADMIN",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "AROMERO",
                      "Nombre": "ANAYELI ROMERO",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "num_asociado": 10,
                          "mnemonicos": "COR",
                          "activo": true,
                          "id": "9",
                          "hospital": "Corporativo"
                      }
                  },
                  {
                      "Permiso": "administrador",
                      "Nick": "DSANMARCOS",
                      "Nombre": "DAVID SAN MARCOS ROMERO",
                      "Pass": "DSANMARCOS",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "calendar": "Hospital-HMAS-Querétaro",
                          "num_asociado": "0",
                          "mnemonicos": "QRO",
                          "id": "21",
                          "hospital": "HMAS Querétaro"
                      },
                      "Activo": true
                  },
                  {
                      "Permiso": "administrador",
                      "Nombre": "CRISTIAN YAHIR CASTILLO",
                      "UnidadHospitalaria": {
                          "mnemonicos": "QRO",
                          "hospital": "HMAS Querétaro",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "activo": true,
                          "num_asociado": "0",
                          "id": "21"
                      },
                      "Pass": "CCASTILLO",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Nick": "CCASTILLO",
                      "Activo": true
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "Hmac2024*",
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "id": "9",
                          "activo": true,
                          "num_asociado": 9
                      },
                      "Nick": "diana.granados",
                      "Permiso": "calidad",
                      "Activo": false,
                      "Nombre": "diana granados"
                  },
                  {
                      "Nick": "AARUSCHV",
                      "Activo": true,
                      "Permiso": "tablero",
                      "Nombre": "Alma Anahi Rusch Villarreal",
                      "UnidadHospitalaria": {
                          "id": "13",
                          "activo": true,
                          "calendar": "Hospital-MAC-Tampico",
                          "mnemonicos": "TMP",
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "num_asociado": 34,
                          "hospital": "MAC Tampico"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "ServicioQuirofano"
                  },
                  {
                      "Nick": "ORICOGZ",
                      "Permiso": "administrador",
                      "Nombre": "Oscar Rico González",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "mnemonicos": "CUE",
                          "id": "44",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "hospital": "MediMAC Cuemanco",
                          "activo": true
                      },
                      "Activo": true,
                      "Pass": "ORICOGZ"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "num_asociado": 3,
                          "calendar": "Hospital-MAC-Celaya",
                          "id": "1",
                          "hospital": "MAC Celaya",
                          "mnemonicos": "CEL",
                          "activo": true
                      },
                      "Pass": "MCONTINUACLY",
                      "Nombre": "Mejora Continua Celaya",
                      "Nick": "MCONTINUACLY"
                  },
                  {
                      "Nick": "miriam.torres",
                      "Permiso": "credencializacion",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Cuemanco",
                          "mnemonicos": "CUE",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "identificador": "TCQmtGfnIxLLdAVcQwzN",
                          "activo": true,
                          "num_asociado": 0,
                          "id": "44"
                      },
                      "Pass": "mac2024",
                      "Nombre": "Miriam Torrres"
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Boca del Rio",
                          "num_asociado": 23,
                          "mnemonicos": "BDR",
                          "activo": true,
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "calendar": "Hospital-MAC-Boca del Río",
                          "id": "15"
                      },
                      "Pass": "agendaenfermeria4",
                      "Nick": "DCERONR",
                      "Nombre": "Dalia Cerón Roldan",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda"
                  },
                  {
                      "Activo": true,
                      "Nick": "TTOVAR",
                      "Pass": "serviciosagenda3",
                      "Nombre": "Teresita Tovar",
                      "UnidadHospitalaria": {
                          "id": "6",
                          "num_asociado": "0",
                          "mnemonicos": "SMA",
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC San Miguel de Allende"
                      },
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "email_calidad": "",
                          "mnemonicos": "LVG",
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga",
                          "activo": true,
                          "id": "22"
                      },
                      "Nombre": "Administracion La Viga",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": "1",
                              "active": true
                          },
                          {
                              "id": "2",
                              "active": true
                          }
                      ],
                      "Permiso": "administrador",
                      "Nick": "administracion",
                      "Pass": "laviga"
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MAC Aguascalientes Norte",
                          "num_asociado": 1,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "id": "11",
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "mnemonicos": "AGN"
                      },
                      "Activo": true,
                      "Pass": "Lucero.Trejo",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "tablero",
                      "Nombre": "Lucero Arceli Trejo Denicia",
                      "Nick": "LATREJOD"
                  },
                  {
                      "UnidadHospitalaria": {
                          "email_calidad": "credencializacion@hmas.mx",
                          "hospital": "HMAS Los Cabos",
                          "mnemonicos": "LCA",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "num_asociado": "0",
                          "id": "20",
                          "activo": true
                      },
                      "Pass": "AdmisionQuirofano",
                      "Activo": true,
                      "Nick": "NRICOV",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "tablero",
                      "Nombre": "Nayelly Rico Valles"
                  },
                  {
                      "Nombre": "CARLOS JERJES SANCHEZ RAMIREZ",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga",
                          "mnemonicos": "LVG",
                          "num_asociado": 102,
                          "id": "22",
                          "email_calidad": ""
                      },
                      "Pass": "ADMINVIG",
                      "Nick": "CJSANCHEZR",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true
                  },
                  {
                      "Permiso": "tablero",
                      "Nombre": "Jesus Chimeo Garcia",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "15",
                          "num_asociado": 23,
                          "calendar": "Hospital-MAC-Boca del Río",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "hospital": "MediMAC Boca del Rio",
                          "mnemonicos": "BDR"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "serviciosquirofano",
                      "Activo": true,
                      "Nick": "JCHIMEOG"
                  },
                  {
                      "UnidadHospitalaria": {
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "hospital": "Corporativo",
                          "num_asociado": 6,
                          "activo": true,
                          "mnemonicos": "COR",
                          "id": "9"
                      },
                      "Nick": "monica.sanchez",
                      "Activo": true,
                      "Pass": "Hamburguesa95",
                      "Permiso": "superuser",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "Monica Sanchez"
                  },
                  {
                      "Nick": "PATMAC",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "mnemonicos": "LEO",
                          "hospital": "MAC León",
                          "num_asociado": 35,
                          "id": "18",
                          "activo": true,
                          "calendar": "Hospital-MAC-León"
                      },
                      "Pass": "PATMAC.1",
                      "Nombre": "PATOLOGIA MAC"
                  },
                  {
                      "Activo": true,
                      "Pass": "mac2024",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Ecatepec",
                          "calendar": "Hospital-MAC-Ecatepec",
                          "activo": true,
                          "identificador": "dRdskRwuiOWS7NFJumao",
                          "id": "45",
                          "num_asociado": 0,
                          "mnemonicos": "ECA"
                      },
                      "Nombre": "Leonardo Erick Flores Vázquez",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nick": "leonardo.ecatepec",
                      "Permiso": "credencializacion"
                  },
                  {
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "4",
                          "num_asociado": 7,
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "hospital": "MAC Aguascalientes Sur"
                      },
                      "Nick": "ROSA.GONZALEZ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "EnfermeriaSur",
                      "Nombre": "Rosa Gonzalez",
                      "Activo": true
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Karina Rodriguez",
                      "Permiso": "mesa",
                      "Pass": "karinaRdz",
                      "Nick": "karina.rodriguez",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "num_asociado": 8,
                          "id": "9",
                          "activo": true
                      }
                  },
                  {
                      "Nick": "dulce.calidad",
                      "Permiso": "credencializacion",
                      "Pass": "dulce2023",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nombre": "Dulce Maria Reyes",
                      "UnidadHospitalaria": {
                          "identificador": "PV3bUNDCpVnCq9rdGzUd",
                          "mnemonicos": "AGS",
                          "hospital": "MAC Aguascalientes Sur",
                          "num_asociado": 7,
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "id": "4",
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "activo": true
                      },
                      "Activo": false
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 34,
                          "id": "13",
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "hospital": "MAC Tampico",
                          "calendar": "Hospital-MAC-Tampico",
                          "mnemonicos": "TMP",
                          "activo": true
                      },
                      "Permiso": "agenda",
                      "Nombre": "Responsiva Jefe de Enfermería",
                      "Nick": "TAMPICO",
                      "Pass": "QuirofanoAgenda5"
                  },
                  {
                      "Activo": true,
                      "Nick": "JAIME.RICO",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "num_asociado": 1,
                          "mnemonicos": "AGN",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Norte",
                          "id": "11"
                      },
                      "Pass": "AgendaSistemas",
                      "Nombre": "Jaime Rico",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Nick": "AGUERREROZ",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Perisur",
                          "activo": true,
                          "hospital": "MAC Perisur",
                          "num_asociado": 60,
                          "mnemonicos": "PER",
                          "id": "19"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "AdmAgenda2",
                      "Activo": true,
                      "Nombre": "Alejandro Guerrero Zendejas"
                  },
                  {
                      "Pass": "JRBASTARRACHEAR",
                      "Nick": "JRBASTARRACHEAR",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-La Viga",
                          "id": "22",
                          "email_calidad": "",
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "mnemonicos": "LVG",
                          "num_asociado": 51
                      },
                      "Activo": false,
                      "Permiso": "administrador",
                      "Nombre": "JOSE ROBERTO BASTARRACHEA RIVERA",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Pass": "mac2023",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "identificador": "Jae6nIRS5HGXtoVnXEXL",
                          "hospital": "MAC Guadalajara",
                          "id": "3",
                          "mnemonicos": "GDL",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "num_asociado": 43,
                          "calendar": "Hospital-MAC-Guadalajara"
                      },
                      "Nick": "ana.gdl",
                      "Activo": false,
                      "Nombre": "ANA CRISTINA TRUJILLO GODINEZ",
                      "Permiso": "lectura"
                  },
                  {
                      "Nombre": "Vania Fernanda Narváez Leal",
                      "Nick": "VANIA.NARVAEZ",
                      "UnidadHospitalaria": {
                          "num_asociado": 25,
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "activo": true,
                          "hospital": "MediMAC Tlalnepantla",
                          "id": "14",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "mnemonicos": "TLA"
                      },
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "VaniaAdm"
                  },
                  {
                      "Nombre": "Fernando Francisco Aguilar",
                      "UnidadHospitalaria": {
                          "email_calidad": "credencializacion@hmas.mx",
                          "id": "20",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "num_asociado": "0",
                          "mnemonicos": "LCA",
                          "activo": true,
                          "hospital": "HMAS Los Cabos"
                      },
                      "Nick": "FFAGUILAR",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "SistemaQx",
                      "Permiso": "tablero"
                  },
                  {
                      "Nick": "AEGARCIAD",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "tablero",
                      "Nombre": "Aleyda Esther García Díaz",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "id": "13",
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Tampico",
                          "hospital": "MAC Tampico",
                          "activo": true,
                          "mnemonicos": "TMP",
                          "num_asociado": 34
                      },
                      "Pass": "Agendaprogramación3"
                  },
                  {
                      "Nombre": "Blanca Ruth Soto Rocha",
                      "Nick": "BR.SOTOR",
                      "Permiso": "tablero",
                      "Pass": "ServicioAgenda",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 20,
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "activo": true,
                          "mnemonicos": "IRA",
                          "calendar": "Hospital-MAC-Irapuato",
                          "id": "2",
                          "hospital": "MAC Irapuato"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Activo": false,
                      "Pass": "CCARMONA",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "id": "21",
                          "calendar": "Hospital-HMAS-Querétaro",
                          "hospital": "HMAS Querétaro",
                          "activo": true,
                          "mnemonicos": "QRO"
                      },
                      "Nick": "CCARMONA",
                      "Nombre": "María del Carmen Carmona Ornelas"
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "hospital": "MAC Irapuato",
                          "id": "2",
                          "activo": true,
                          "num_asociado": 20,
                          "mnemonicos": "IRA",
                          "calendar": "Hospital-MAC-Irapuato"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "EnfriaIrapuato",
                      "Permiso": "administrador",
                      "Nick": "RERAMIREZF",
                      "Nombre": "Rosa Estefani Ramirez Flores"
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 20,
                          "id": "2",
                          "hospital": "MAC Irapuato",
                          "calendar": "Hospital-MAC-Irapuato",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "IRA",
                          "activo": true
                      },
                      "Nombre": "Aranza Julieta Cordoba Tavares",
                      "Nick": "AJCORDOBAT",
                      "Pass": "IrapuatoAgenda",
                      "Permiso": "tablero"
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "BROSALES",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Cuemanco",
                          "id": "44",
                          "num_asociado": 0,
                          "mnemonicos": "CUE",
                          "activo": true,
                          "hospital": "MediMAC Cuemanco"
                      },
                      "Nombre": "Berenice Rosales",
                      "Nick": "BROSALES"
                  },
                  {
                      "Activo": true,
                      "Permiso": "tablero",
                      "Pass": "SistemaQx5",
                      "Nick": "leonardo.diaz",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Leonardo Diaz",
                      "UnidadHospitalaria": {
                          "mnemonicos": "TLA",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "id": "14",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "num_asociado": 25,
                          "activo": true,
                          "hospital": "MediMAC Tlalnepantla"
                      }
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "agendaquirofano2",
                      "Nombre": "Antonio Balderas Camacho",
                      "Activo": true,
                      "Nick": "ABALDERASC",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "num_asociado": "0",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC San Miguel de Allende",
                          "mnemonicos": "SMA",
                          "id": "6"
                      }
                  },
                  {
                      "Activo": true,
                      "Pass": "recuperacion",
                      "Permiso": "recuperación",
                      "Nick": "capacitacion",
                      "Nombre": "Capacitación Recuperación",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "4",
                          "hospital": "MAC Aguascalientes Sur"
                      }
                  },
                  {
                      "Nombre": "MARÍA DEL CARMEN ARAGÓN HERNÁNDEZ",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "ADMNCORPO",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "num_asociado": 9,
                          "id": "9",
                          "activo": true,
                          "hospital": "Corporativo"
                      },
                      "Nick": "MCARAGONH",
                      "Permiso": "administrador"
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": "0",
                          "mnemonicos": "MEX",
                          "calendar": "Hospital-MAC-Mexicali",
                          "id": "8",
                          "hospital": "MAC Mexicali",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "ALMORENOD",
                      "Pass": "ServicioQuirofano",
                      "Permiso": "tablero",
                      "Nombre": "Alma Lorena Moreno Díaz"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "",
                          "hospital": "MAC La Viga",
                          "num_asociado": 25,
                          "mnemonicos": "LVG",
                          "activo": true,
                          "id": "22",
                          "calendar": "Hospital-MAC-La Viga"
                      },
                      "Permiso": "tablero",
                      "Nick": "CSRAMIREZA",
                      "Pass": "CSRAMIREZA",
                      "Nombre": "CARMINA SORAYDA RAMIREZ ARIZA"
                  },
                  {
                      "UnidadHospitalaria": {
                          "id": "9",
                          "activo": true,
                          "hospital": "Corporativo",
                          "num_asociado": 7,
                          "mnemonicos": "COR"
                      },
                      "Systems": [
                          {
                              "id": "6",
                              "active": true
                          }
                      ],
                      "Pass": "1234",
                      "Email": "monserrat.alba@hospitalesmac.com",
                      "Nick": "monse",
                      "Activo": true,
                      "Nombre": "Monserrat Alba",
                      "Permiso": "ciberseguridad"
                  },
                  {
                      "Activo": true,
                      "Permiso": "superuser",
                      "Pass": "MedicaDir953",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nick": "juan.perez",
                      "Nombre": "Juan Perez",
                      "UnidadHospitalaria": {
                          "num_asociado": 5,
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "activo": true,
                          "identificador": "MolsdOWdaka5W52m8e0I",
                          "id": "9"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "hospital": "MAC Aguascalientes Norte",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "num_asociado": 1,
                          "mnemonicos": "AGN",
                          "id": "11",
                          "activo": true
                      },
                      "Nick": "ACLARAC",
                      "Pass": "AdmisionAgenda",
                      "Activo": true,
                      "Permiso": "tablero",
                      "Nombre": "Andrea Concepción Lara Carreón"
                  },
                  {
                      "Activo": true,
                      "Permiso": "administrador",
                      "Pass": "ADMINCORP",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "hospital": "Corporativo",
                          "activo": true,
                          "num_asociado": 9,
                          "mnemonicos": "COR"
                      },
                      "Nombre": "PABLO MAGAÑA MAINERO",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "PMAGAÑAM"
                  },
                  {
                      "Permiso": "administrador",
                      "Activo": true,
                      "Nick": "EdiDominguez",
                      "Nombre": "Ediberto Domínguez Jiménez",
                      "Pass": "12345",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Aguascalientes Sur",
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "activo": true,
                          "id": "4"
                      }
                  },
                  {
                      "Nick": "MCLEONR",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "EnfProgramacion5",
                      "Activo": true,
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MEX",
                          "num_asociado": "0",
                          "activo": true,
                          "id": "8",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "hospital": "MAC Mexicali",
                          "calendar": "Hospital-MAC-Mexicali"
                      },
                      "Nombre": "Maria del Carmen Leon Ramirez"
                  },
                  {
                      "Permiso": "administrador",
                      "Nombre": "MEJORA CONTINUA",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "activo": true,
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "id": "1",
                          "num_asociado": 3,
                          "mnemonicos": "CEL",
                          "calendar": "Hospital-MAC-Celaya"
                      },
                      "Nick": "MCONTINUACLY",
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Pass": "MCONTINUACLY"
                  },
                  {
                      "Nombre": "Monica Lizeth Pedriz Bustamante",
                      "Nick": "MLPEDRIZB",
                      "Pass": "mexicaliqx3",
                      "UnidadHospitalaria": {
                          "id": "8",
                          "calendar": "Hospital-MAC-Mexicali",
                          "mnemonicos": "MEX",
                          "num_asociado": "0",
                          "hospital": "MAC Mexicali",
                          "activo": true,
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "agenda",
                      "Activo": true
                  },
                  {
                      "Activo": true,
                      "Nombre": "ALEJANDRO GONZALEZ GIL",
                      "Nick": "alejandro.eca",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Ecatepec",
                          "id": "45",
                          "identificador": "dRdskRwuiOWS7NFJumao",
                          "mnemonicos": "ECA",
                          "num_asociado": 25,
                          "calendar": "Hospital-MAC-Ecatepec",
                          "activo": true
                      },
                      "Pass": "mac2023",
                      "Permiso": "lectura",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ]
                  },
                  {
                      "Nombre": "Gabriela Obeso Estrada",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "activo": true,
                          "mnemonicos": "TMP",
                          "hospital": "MAC Tampico",
                          "id": "13",
                          "num_asociado": 34,
                          "calendar": "Hospital-MAC-Tampico"
                      },
                      "Pass": "TampicoQx1",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "GOBESOE"
                  },
                  {
                      "UnidadHospitalaria": {
                          "id": "45",
                          "calendar": "Hospital-MAC-Ecatepec",
                          "activo": true,
                          "mnemonicos": "ECA",
                          "hospital": "MediMAC Ecatepec",
                          "num_asociado": 18
                      },
                      "Nombre": "Uriel Quitero Martinez",
                      "Pass": "EnfermeriaEcatepec5",
                      "Nick": "UQUITEROM",
                      "Activo": true,
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Pass": "mac2024",
                      "Nick": "cesar.puebla",
                      "Activo": true,
                      "Permiso": "credencializacion",
                      "Nombre": "César Arturo Flores Escalona",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "id": "7",
                          "num_asociado": 1,
                          "calendar": "Hospital-MAC-Puebla",
                          "activo": true,
                          "identificador": "NmAzlLj48vbKeGWML08A",
                          "mnemonicos": "PUE",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "hospital": "MAC Puebla"
                      }
                  },
                  {
                      "Activo": true,
                      "Nick": "PATOLOGIA",
                      "Pass": "PATOLOGIA",
                      "UnidadHospitalaria": {
                          "hospital": "HMAS Querétaro",
                          "mnemonicos": "QRO",
                          "id": "21",
                          "activo": true,
                          "calendar": "Hospital-HMAS-Querétaro",
                          "num_asociado": "0"
                      },
                      "Nombre": "PATOLOGIA",
                      "Permiso": "recuperación"
                  },
                  {
                      "Pass": "AgendaTampico3",
                      "Nombre": "Nelly Janette Mar Bautista",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "hospital": "MAC Tampico",
                          "num_asociado": 34,
                          "activo": true,
                          "calendar": "Hospital-MAC-Tampico",
                          "mnemonicos": "TMP",
                          "id": "13"
                      },
                      "Nick": "NJMARB",
                      "Activo": true
                  },
                  {
                      "Nombre": "LUZ ADRIANA ROJO RIZO",
                      "Pass": "ADRIADMINR",
                      "Nick": "LAROJOR",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "activo": true,
                          "hospital": "Corporativo",
                          "num_asociado": 10,
                          "id": "9"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador",
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "activo": true,
                          "calendar": "Hospital-MAC-Los Mochis",
                          "mnemonicos": "MOC",
                          "identificador": "yOvFHZmXst7RPPOefQXT",
                          "id": "17",
                          "num_asociado": 27,
                          "hospital": "MAC Los Mochis"
                      },
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "ROXANA ULLOA URQUIDY",
                      "Nick": "roxana.mochis",
                      "Activo": true,
                      "Permiso": "lectura",
                      "Pass": "mac2023"
                  },
                  {
                      "Permiso": "calidad",
                      "Nombre": "Diego Adayr Corro Hernandez",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Boca del Río",
                          "id": "15",
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "mnemonicos": "BDR",
                          "activo": true,
                          "hospital": "MediMAC Boca del Rio",
                          "num_asociado": 23
                      },
                      "Nick": "DACORROH",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "quirofanocalidadrio"
                  },
                  {
                      "Activo": true,
                      "Permiso": "credencializacion",
                      "Nick": "jose.gdl",
                      "UnidadHospitalaria": {
                          "identificador": "Jae6nIRS5HGXtoVnXEXL",
                          "activo": true,
                          "id": "3",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "num_asociado": "0",
                          "mnemonicos": "GDL",
                          "hospital": "MAC Guadalajara",
                          "calendar": "Hospital-MAC-Guadalajara"
                      },
                      "Pass": "mac2024",
                      "Nombre": "José Enrique Vera Ramírez",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ]
                  },
                  {
                      "Nick": "AHERNANDEZ",
                      "Nombre": "Aracely Hernandez",
                      "Pass": "agenda",
                      "Permiso": "agenda",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          },
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "email_calidad": "jose.campos@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Celaya",
                          "id": "1",
                          "activo": true,
                          "num_asociado": 0,
                          "mnemonicos": "CEL"
                      }
                  },
                  {
                      "Activo": true,
                      "Pass": "mac2023",
                      "Nick": "hector.sma",
                      "Permiso": "credencializacion",
                      "UnidadHospitalaria": {
                          "hospital": "MAC San Miguel de Allende",
                          "num_asociado": "0",
                          "mnemonicos": "SMA",
                          "identificador": "EC6xXUPuxKZx8pEPFezF",
                          "activo": true,
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "id": "6"
                      },
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "HECTOR BERNARDO RASCON PEREZ"
                  },
                  {
                      "Pass": "Cebre.adm",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "VECEBREROSQ",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "mnemonicos": "MOC",
                          "hospital": "MAC Los Mochis",
                          "num_asociado": 27,
                          "calendar": "Hospital-MAC-Los Mochis",
                          "activo": true,
                          "id": "17"
                      },
                      "Activo": false,
                      "Nombre": "Victor Esteban Cebreros Quevedo"
                  },
                  {
                      "Nick": "JAMEXAKE",
                      "Pass": "PerifericoEnf",
                      "Nombre": "Jesus Antonio Mex Ake",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "id": "19",
                          "calendar": "Hospital-MAC-Perisur",
                          "num_asociado": 60,
                          "activo": true,
                          "mnemonicos": "PER"
                      },
                      "Permiso": "tablero",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Merida",
                          "num_asociado": 12,
                          "activo": true,
                          "calendar": "Hospital-MAC-Merida",
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "id": "42",
                          "mnemonicos": "MER"
                      },
                      "Activo": true,
                      "Permiso": "administrador",
                      "Nick": "FTAMAYOP",
                      "Nombre": "Franklin Tamayo Pacho",
                      "Pass": "meridaprogramacion",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "UnidadHospitalaria": {
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "hospital": "MAC Aguascalientes Norte",
                          "activo": true,
                          "mnemonicos": "AGN",
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "num_asociado": 1,
                          "id": "11"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Pass": "AgendaMedico",
                      "Permiso": "administrador",
                      "Nick": "JRARANDA",
                      "Nombre": "Jesús Ricardo Aranda"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Gerardo Jiménez Ayala",
                      "Permiso": "administrador",
                      "Activo": true,
                      "Nick": "GJIMENEZA",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Irapuato",
                          "num_asociado": 20,
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "calendar": "Hospital-MAC-Irapuato",
                          "activo": true,
                          "mnemonicos": "IRA",
                          "id": "2"
                      },
                      "Pass": "MedAgenda"
                  },
                  {
                      "Pass": "calidad",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "id": "999",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "mnemonicos": "IPC",
                          "hospital": "MAC Integración Procesos Clínicos",
                          "activo": true
                      },
                      "Nick": "capacitacion",
                      "Nombre": "Capacitacion Calidad",
                      "Activo": false,
                      "Permiso": "calidad"
                  },
                  {
                      "Activo": true,
                      "Permiso": "administrador",
                      "Nick": "DDENJIROF",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Guadalajara",
                          "mnemonicos": "GDL",
                          "activo": true,
                          "num_asociado": "0",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "id": "3",
                          "calendar": "Hospital-MAC-Guadalajara"
                      },
                      "Nombre": "Diego Denjiro Fukushima",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "guadalajara1"
                  },
                  {
                      "Pass": "123456",
                      "Nick": "RecuperacionCelaya",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Permiso": "recuperación",
                      "Nombre": "Recuperación Enfermería",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Celaya",
                          "id": "1",
                          "activo": true
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "AGN",
                          "num_asociado": 1,
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "activo": true,
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "identificador": "JQ7KGLLcBjLvqg1w1u6X",
                          "hospital": "MAC Aguascalientes Norte",
                          "id": "11"
                      },
                      "Nombre": "Christopher Ariza Acero",
                      "Activo": true,
                      "Permiso": "credencializacion",
                      "Nick": "christopher.ariza",
                      "Pass": "chocolate2024"
                  },
                  {
                      "Pass": "Corp2024+",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "hospital": "Corporativo",
                          "activo": true,
                          "num_asociado": 7,
                          "mnemonicos": "COR"
                      },
                      "Email": "fabian.ake@hospitalesmac.com",
                      "Nombre": "Fabian Jafet Ake Martínez",
                      "Nick": "Fabian",
                      "Permiso": "infraestructura",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": "6"
                          }
                      ]
                  },
                  {
                      "Pass": "mac2023",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Tampico",
                          "activo": true,
                          "hospital": "MAC Tampico",
                          "identificador": "OxSF8yKYFVpJI1tYIu8p",
                          "email_calidad": "carlos.meza@hospitalesmac.com",
                          "mnemonicos": "TMP",
                          "num_asociado": "0",
                          "id": "13"
                      },
                      "Nick": "miriam.tampico",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Nombre": "MIRIAM SUGEY RUEDA ESCAMILLA",
                      "Activo": true,
                      "Permiso": "credencializacion"
                  },
                  {
                      "Nombre": "Ariadna Castañeda",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Ecatepec",
                          "calendar": "Hospital-MAC-Ecatepec",
                          "activo": true,
                          "mnemonicos": "ECA",
                          "num_asociado": 23,
                          "id": "45"
                      },
                      "Pass": "AgendaBiomedica",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nick": "Ariadna.Castañeda"
                  },
                  {
                      "Nombre": "Eduardo Valenzuela",
                      "Permiso": "tablero",
                      "Pass": "agendaquirofano",
                      "UnidadHospitalaria": {
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "activo": true,
                          "mnemonicos": "TLA",
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "id": "14",
                          "hospital": "MediMAC Tlalnepantla",
                          "num_asociado": 25
                      },
                      "Activo": false,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "EVALENZUELA"
                  },
                  {
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Aguascalientes Sur",
                          "mnemonicos": "AGS",
                          "id": "4",
                          "num_asociado": 7,
                          "calendar": "Hospital-MAC-Aguascalientes Sur",
                          "identificador": "PV3bUNDCpVnCq9rdGzUd"
                      },
                      "Pass": "carloscosta",
                      "Nombre": "Carlos Costa",
                      "Nick": "carlos.costa",
                      "Permiso": "lectura",
                      "Activo": true
                  },
                  {
                      "Pass": "MCONTINUALMM",
                      "Nick": "MCONTINUALMM",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          },
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "Nombre": "MEJORA CONTINUA TI",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 21,
                          "hospital": "MAC Los Mochis",
                          "mnemonicos": "MOC",
                          "id": "17",
                          "calendar": "Hospital-MAC-Los Mochis"
                      },
                      "Activo": true,
                      "Permiso": "administrador"
                  },
                  {
                      "Activo": true,
                      "Nick": "DORA.ISLAS",
                      "Nombre": "Dora Celeste Islas Gonzalez",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Perisur",
                          "mnemonicos": "PER",
                          "activo": true,
                          "id": "19",
                          "hospital": "MAC Perisur",
                          "num_asociado": 60
                      },
                      "Pass": "EnfriaDora",
                      "Permiso": "administrador"
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "QxPeriferico",
                      "Activo": true,
                      "Permiso": "agenda",
                      "UnidadHospitalaria": {
                          "id": "19",
                          "mnemonicos": "PER",
                          "num_asociado": 60,
                          "hospital": "MAC Perisur",
                          "activo": true,
                          "calendar": "Hospital-MAC-Perisur"
                      },
                      "Nombre": "Luis Angel Cruz Arcos",
                      "Nick": "LACRUZA"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Diego Alberto Ramirez Flores",
                      "Activo": true,
                      "Pass": "enfermeriaguanajuato1",
                      "Permiso": "agenda",
                      "Nick": "DARAMIREZF",
                      "UnidadHospitalaria": {
                          "num_asociado": 11,
                          "calendar": "Hospital-MAC-Guanajuato",
                          "id": "16",
                          "mnemonicos": "GTO",
                          "hospital": "MediMAC Guanajuato",
                          "activo": true
                      }
                  },
                  {
                      "Nombre": "Idanny Paulina Alomia Catelo",
                      "Activo": true,
                      "Pass": "CalidadQuirofano",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "IPALOMIAC",
                      "Permiso": "calidad",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LCA",
                          "activo": true,
                          "hospital": "HMAS Los Cabos",
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "id": "20",
                          "email_calidad": "credencializacion@hmas.mx"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          },
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "hospital": "MAC Los Mochis",
                          "calendar": "Hospital-MAC-Los Mochis",
                          "id": "17",
                          "mnemonicos": "MOC",
                          "num_asociado": "0",
                          "activo": true
                      },
                      "Nick": "CGGAMAG",
                      "Pass": "Gama.adm",
                      "Nombre": "Carlos Gilberto Gama Gomez",
                      "Permiso": "administrador"
                  },
                  {
                      "Nick": "APEREZA",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "calendar": "Hospital-MAC-Perisur",
                          "id": "19",
                          "num_asociado": 60,
                          "activo": true,
                          "mnemonicos": "PER"
                      },
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Abraham Pérez Aguilar",
                      "Pass": "SistemasQx6"
                  },
                  {
                      "Pass": "QuirofanoAgenda5",
                      "Nick": "JBRAMIREZP",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Permiso": "agenda",
                      "UnidadHospitalaria": {
                          "id": "11",
                          "hospital": "MAC Aguascalientes Norte",
                          "email_calidad": "elisa.garcia@hospitalesmac.com",
                          "activo": true,
                          "mnemonicos": "AGN",
                          "calendar": "Hospital-MAC-Aguascalientes Norte",
                          "num_asociado": 1
                      },
                      "Nombre": "Jessica Biridiana Ramirez Pacheco"
                  },
                  {
                      "Nick": "MCONTINUALEON",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          },
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 35,
                          "calendar": "Hospital-MAC-León",
                          "hospital": "MAC León",
                          "id": "18",
                          "mnemonicos": "LEO"
                      },
                      "Pass": "MCONTINUALEON",
                      "Nombre": "Mejora Continua",
                      "Activo": true
                  },
                  {
                      "Permiso": "agenda",
                      "Pass": "IrapuatoQx5",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MAC Irapuato",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "num_asociado": 20,
                          "calendar": "Hospital-MAC-Irapuato",
                          "mnemonicos": "IRA",
                          "id": "2"
                      },
                      "Nick": "IRAPUATO",
                      "Nombre": "Responsiva firmada por jefatura de enfermería"
                  },
                  {
                      "Nick": "JZORRILLAM",
                      "Pass": "AgendaQX",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Activo": true,
                      "Nombre": "Jazibeth Zorrilla Mendoza",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "activo": true,
                          "email_calidad": "credencializacion@hmas.mx",
                          "hospital": "HMAS Los Cabos",
                          "id": "20",
                          "mnemonicos": "LCA",
                          "calendar": "Hospital-HMAS-Los Cabos"
                      },
                      "Permiso": "agenda"
                  },
                  {
                      "Nick": "MPACHECO",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "45",
                          "num_asociado": 18,
                          "calendar": "Hospital-MAC-Ecatepec",
                          "mnemonicos": "ECA",
                          "hospital": "MediMAC Ecatepec"
                      },
                      "Pass": "SistemaQuirofano5",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Manuel Pacheco"
                  },
                  {
                      "Nick": "perla.ayala",
                      "UnidadHospitalaria": {
                          "mnemonicos": "LVG",
                          "hospital": "MAC La Viga",
                          "num_asociado": 88,
                          "identificador": "vkpxO3SOfZkbsfmWzvte",
                          "activo": true,
                          "email_calidad": "",
                          "calendar": "Hospital-MAC-La Viga",
                          "id": "22"
                      },
                      "Activo": true,
                      "Permiso": "superuser",
                      "Pass": "Guacamole20",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nombre": "Perla Ayala"
                  },
                  {
                      "Nombre": "Argoss Armando Estrella Albavera",
                      "Nick": "ARGOSS.ESTRELLA",
                      "UnidadHospitalaria": {
                          "num_asociado": 60,
                          "activo": true,
                          "calendar": "Hospital-MAC-Perisur",
                          "hospital": "MAC Perisur",
                          "id": "19",
                          "mnemonicos": "PER"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "AgendaSur",
                      "Activo": true,
                      "Permiso": "tablero"
                  },
                  {
                      "Activo": true,
                      "Permiso": "calidad",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Jaquelin Valenzuela Villalobos",
                      "Pass": "calidadagenda3",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Mexicali",
                          "activo": true,
                          "hospital": "MAC Mexicali",
                          "id": "8",
                          "mnemonicos": "MEX",
                          "num_asociado": "0",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com"
                      },
                      "Nick": "JVALENZUELAV"
                  },
                  {
                      "Activo": true,
                      "Pass": "AdmisionQx5",
                      "Nick": "MALVAREZR",
                      "Nombre": "Misael Alvarez Rodriguez",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Irapuato",
                          "mnemonicos": "IRA",
                          "num_asociado": 20,
                          "hospital": "MAC Irapuato",
                          "activo": true,
                          "id": "2",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com"
                      },
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "tablero"
                  },
                  {
                      "UnidadHospitalaria": {
                          "hospital": "Corporativo",
                          "mnemonicos": "COR",
                          "id": "9",
                          "activo": true,
                          "num_asociado": 10
                      },
                      "Nombre": "BRENDA MENDOZA REYES",
                      "Activo": true,
                      "Pass": "ADMINMERE",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador",
                      "Nick": "BMENDOZAR"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "id": "2",
                          "num_asociado": 20,
                          "mnemonicos": "IRA",
                          "calendar": "Hospital-MAC-Irapuato",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "activo": true,
                          "hospital": "MAC Irapuato"
                      },
                      "Activo": true,
                      "Nick": "MAJALFAROR",
                      "Nombre": "MIRIAM ANUARITE JAZMIN ALFARO RODRIGUEZ",
                      "Permiso": "agenda",
                      "Pass": "MAJAGEN"
                  },
                  {
                      "Nombre": "Karina Flores Gonzalez",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 60,
                          "calendar": "Hospital-MAC-Perisur",
                          "hospital": "MAC Perisur",
                          "mnemonicos": "PER",
                          "activo": true,
                          "id": "19"
                      },
                      "Pass": "SuperAgenda",
                      "Permiso": "tablero",
                      "Nick": "KARINA.FLORES"
                  },
                  {
                      "Nombre": "Desarrollo",
                      "Permiso": "superuser",
                      "Activo": true,
                      "Nick": "desarrollo",
                      "Pass": "test",
                      "UnidadHospitalaria": {
                          "id": "9",
                          "hospital": "Corporativo",
                          "activo": true
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": "1"
                          },
                          {
                              "active": true,
                              "id": "2"
                          },
                          {
                              "active": true,
                              "id": "4"
                          },
                          {
                              "id": "5",
                              "active": true
                          },
                          {
                              "active": true,
                              "id": "6"
                          }
                      ]
                  },
                  {
                      "Nick": "jaquelin.mexicali",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Pass": "mac2023",
                      "Nombre": "JAQUELIN VALENZUELA VILLALOBOS",
                      "Permiso": "credencializacion",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "8",
                          "hospital": "MAC Mexicali",
                          "mnemonicos": "MEX",
                          "calendar": "Hospital-MAC-Mexicali",
                          "num_asociado": "0",
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "identificador": "jZ3m3at9GE2p0s8mWV2f"
                      },
                      "Activo": true
                  },
                  {
                      "Activo": true,
                      "Permiso": "calidad",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "hospital": "MAC Perisur",
                          "calendar": "Hospital-MAC-Perisur",
                          "mnemonicos": "PER",
                          "activo": true,
                          "id": "19",
                          "num_asociado": 60
                      },
                      "Nick": "OLIVIA.ROSALES",
                      "Nombre": "Olivia Berenice Rosales",
                      "Pass": "OliviaBerenice"
                  },
                  {
                      "Nick": "julieta.puebla",
                      "Permiso": "lectura",
                      "Pass": "mac2023",
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "num_asociado": 1,
                          "mnemonicos": "PUE",
                          "id": "7",
                          "calendar": "Hospital-MAC-Puebla",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "activo": true,
                          "identificador": "NmAzlLj48vbKeGWML08A",
                          "hospital": "MAC Puebla"
                      },
                      "Nombre": "JULIETA JAQUEZ MARTÍNEZ"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "HBRASCONP",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "hospital": "MAC San Miguel de Allende",
                          "mnemonicos": "SMA",
                          "id": "6",
                          "activo": true,
                          "num_asociado": "0",
                          "calendar": "Hospital-MAC-San Miguel de Allende"
                      },
                      "Pass": "agendacalidadsma",
                      "Permiso": "calidad",
                      "Nombre": "Héctor Bernardo Rascón Pérez"
                  },
                  {
                      "Pass": "EnfriaAgenda",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "activo": true,
                          "num_asociado": "0",
                          "id": "20",
                          "email_calidad": "credencializacion@hmas.mx",
                          "hospital": "HMAS Los Cabos",
                          "mnemonicos": "LCA"
                      },
                      "Activo": true,
                      "Nombre": "Cesar Ramirez Lopez",
                      "Nick": "CRAMIREZL",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Nombre": "Oscar Rico González",
                      "Permiso": "administrador",
                      "Nick": "ORICOG",
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "calendar": "Hospital-MAC-Cuemanco",
                          "id": "44",
                          "activo": true,
                          "hospital": "MediMAC Cuemanco",
                          "mnemonicos": "CUE"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "ORICOG",
                      "Activo": true
                  },
                  {
                      "Activo": true,
                      "Permiso": "agenda",
                      "Nick": "QXCUEMANCO",
                      "Nombre": "QUIROFANO CUEMANCO",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "hospital": "MediMAC Cuemanco",
                          "calendar": "Hospital-MAC-Cuemanco",
                          "id": "44",
                          "mnemonicos": "CUE",
                          "num_asociado": 0
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "QXCUEMANCO"
                  },
                  {
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "id": "1",
                          "hospital": "MAC Celaya"
                      },
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ],
                      "Nombre": "Jefa de Enfermería Araceli Hernández",
                      "Nick": "AraceliHdz",
                      "Permiso": "administrador",
                      "Pass": "78945"
                  },
                  {
                      "Nick": "MCQUERETARO",
                      "Nombre": "MEJORA CONTINUA CORPORATIVO",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "id": "21",
                          "activo": true,
                          "mnemonicos": "QRO",
                          "hospital": "HMAS Querétaro",
                          "num_asociado": "0",
                          "calendar": "Hospital-HMAS-Querétaro"
                      },
                      "Activo": true,
                      "Pass": "MCQUERETARO"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": "5"
                          }
                      ],
                      "Nombre": "Vania Patricia Sánchez Cervantes",
                      "Nick": "vania.sanchez",
                      "Activo": true,
                      "Permiso": "credencializacion",
                      "Pass": "Totopo93",
                      "UnidadHospitalaria": {
                          "hospital": "MediMAC Tlalnepantla",
                          "email_calidad": "idalia.cano@tlalnepantla.medicamac.com",
                          "identificador": "warrEGfxJRVLrzcRZNsR",
                          "mnemonicos": "TLA",
                          "activo": true,
                          "num_asociado": 25,
                          "calendar": "Hospital-MAC-Tlalnepantla",
                          "id": "14"
                      }
                  },
                  {
                      "Nombre": "José Ramon Aguilar",
                      "Pass": "QuirofanoIra",
                      "Nick": "JRAMONA",
                      "Permiso": "administrador",
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "IRA",
                          "id": "2",
                          "hospital": "MAC Irapuato",
                          "activo": true,
                          "calendar": "Hospital-MAC-Irapuato",
                          "num_asociado": 20
                      }
                  },
                  {
                      "Permiso": "agenda",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Haideé Ibarra Dominguez",
                      "Nick": "HIBARRAD",
                      "UnidadHospitalaria": {
                          "email_calidad": "credencializacion@hmas.mx",
                          "activo": true,
                          "calendar": "Hospital-HMAS-Los Cabos",
                          "id": "20",
                          "hospital": "HMAS Los Cabos",
                          "mnemonicos": "LCA",
                          "num_asociado": "0"
                      },
                      "Activo": true,
                      "Pass": "ProgramacionQX"
                  },
                  {
                      "Nombre": "Agenda La Viga",
                      "Pass": "AGENDAENF",
                      "Nick": "AGENDAENF",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Permiso": "agenda",
                      "UnidadHospitalaria": {
                          "id": "22",
                          "hospital": "MAC La Viga",
                          "mnemonicos": "LVG",
                          "calendar": "Hospital-MAC-La Viga",
                          "activo": true,
                          "num_asociado": 25,
                          "email_calidad": ""
                      }
                  },
                  {
                      "Nombre": "Raúl Sánchez Hernández",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "id": "4",
                          "hospital": "MAC Aguascalientes Sur",
                          "email_calidad": "dulce.reyes@hospitalesmac.com",
                          "mnemonicos": "AGS",
                          "num_asociado": 7,
                          "activo": true,
                          "calendar": "Hospital-MAC-Aguascalientes Sur"
                      },
                      "Nick": "RSANCHEZH",
                      "Permiso": "tablero",
                      "Pass": "SistemasQx",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "administrador",
                      "Activo": true,
                      "Nombre": "PAULINA MONSERRAT TALAVERA RAMIREZ",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "COR",
                          "num_asociado": 10,
                          "hospital": "Corporativo",
                          "id": "9"
                      },
                      "Nick": "PMTALAVERAR",
                      "Pass": "ADMINMONRAM"
                  },
                  {
                      "Pass": "mac2023",
                      "Systems": [
                          {
                              "id": "5",
                              "active": true
                          }
                      ],
                      "Permiso": "credencializacion",
                      "Activo": true,
                      "UnidadHospitalaria": {
                          "activo": true,
                          "mnemonicos": "LVG",
                          "num_asociado": "0",
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga",
                          "id": "22",
                          "identificador": "vkpxO3SOfZkbsfmWzvte",
                          "email_calidad": ""
                      },
                      "Nombre": "Liliana Quezada",
                      "Nick": "liliana.laviga"
                  },
                  {
                      "UnidadHospitalaria": {
                          "email_calidad": "angel.adrian@merida.medimac.com",
                          "mnemonicos": "MER",
                          "id": "42",
                          "calendar": "Hospital-MAC-Merida",
                          "num_asociado": 12,
                          "hospital": "MediMAC Merida",
                          "activo": true
                      },
                      "Pass": "admision13",
                      "Permiso": "tablero",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Nombre": "Haidy Cob",
                      "Nick": "HCOB"
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "capacitacion",
                      "Permiso": "agenda",
                      "Activo": false,
                      "UnidadHospitalaria": {
                          "hospital": "MAC Integración Procesos Clínicos",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "activo": true,
                          "mnemonicos": "IPC",
                          "id": "999",
                          "num_asociado": 0
                      },
                      "Pass": "agenda",
                      "Nombre": "Capacitación Agenda"
                  },
                  {
                      "Nick": "capacitacion",
                      "Permiso": "tablero",
                      "UnidadHospitalaria": {
                          "mnemonicos": "IPC",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos",
                          "activo": true,
                          "hospital": "MAC Integración Procesos Clínicos",
                          "num_asociado": 0,
                          "id": "999"
                      },
                      "Activo": false,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Capacitacion Tablero",
                      "Pass": "tablero"
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "admision9",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nombre": "Miguel Angel Dominguez Moral",
                      "Nick": "MADOMINGUEZM",
                      "UnidadHospitalaria": {
                          "email_calidad": "arelis.martinez@bocadelrio.medicamac.com",
                          "mnemonicos": "BDR",
                          "num_asociado": 23,
                          "id": "15",
                          "hospital": "MediMAC Boca del Rio",
                          "calendar": "Hospital-MAC-Boca del Río",
                          "activo": true
                      }
                  },
                  {
                      "UnidadHospitalaria": {
                          "num_asociado": 0,
                          "mnemonicos": "IPC",
                          "id": "999",
                          "activo": true,
                          "hospital": "MAC Integración Procesos Clínicos",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos"
                      },
                      "Nombre": "Capacitación Administracion",
                      "Nick": "capacitacion",
                      "Permiso": "administrador",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Pass": "administracion",
                      "Activo": false
                  },
                  {
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "hospital": "Corporativo",
                          "activo": true,
                          "num_asociado": 10,
                          "id": "9"
                      },
                      "Activo": true,
                      "Nick": "MGOMEZ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Permiso": "administrador",
                      "Pass": "ADMINMIRGO",
                      "Nombre": "MIRIAM GOMEZ"
                  },
                  {
                      "Nombre": "Lizeth Carol Damian",
                      "Permiso": "calidad",
                      "Pass": "MedicAgenda",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Activo": true,
                      "Nick": "LCDAMIAN",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Perisur",
                          "activo": true,
                          "mnemonicos": "PER",
                          "num_asociado": 60,
                          "hospital": "MAC Perisur",
                          "id": "19"
                      }
                  },
                  {
                      "Activo": true,
                      "Nombre": "Denisse Alejandra Santana Campos",
                      "Permiso": "administrador",
                      "Nick": "denisse.santana",
                      "Pass": "dsantana",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "num_asociado": 6,
                          "activo": true,
                          "id": "9",
                          "mnemonicos": "COR",
                          "hospital": "Corporativo"
                      }
                  },
                  {
                      "Permiso": "administrador",
                      "Pass": "agendaenfermeria5",
                      "UnidadHospitalaria": {
                          "calendar": "Hospital-MAC-Puebla",
                          "activo": true,
                          "id": "7",
                          "mnemonicos": "PUE",
                          "hospital": "MAC Puebla",
                          "email_calidad": "julieta.jaquez@hospitalesmac.com",
                          "num_asociado": 1
                      },
                      "Activo": true,
                      "Nick": "JLCABRERAM",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Joana Lizeth Cabrera Macías"
                  },
                  {
                      "Nombre": "MARTHA ANGELES",
                      "Systems": [
                          {
                              "id": 1,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "mnemonicos": "LVG",
                          "activo": true,
                          "id": "22",
                          "hospital": "MAC La Viga",
                          "email_calidad": "",
                          "num_asociado": 83,
                          "calendar": "Hospital-MAC-La Viga"
                      },
                      "Pass": "MANGELES12",
                      "Permiso": "administrador",
                      "Activo": true,
                      "Nick": "MANGELES12"
                  },
                  {
                      "Nick": "jose.vazquez",
                      "Activo": true,
                      "Permiso": "mesa",
                      "Nombre": "Noé Vasquez",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "noeVazquez",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 8,
                          "hospital": "Corporativo",
                          "id": "9",
                          "mnemonicos": "COR"
                      }
                  },
                  {
                      "Nick": "mejora",
                      "Nombre": "Mejora Continua Recuperacion",
                      "Pass": "recuperacion",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "email_calidad": "calidad.irapuato@hospitalesmac.com",
                          "mnemonicos": "IRA",
                          "calendar": "Hospital-MAC-Irapuato",
                          "hospital": "MAC Irapuato",
                          "activo": true,
                          "id": "2"
                      },
                      "Permiso": "recuperación",
                      "Activo": true,
                      "Systems": [
                          {
                              "active": true,
                              "id": 1
                          }
                      ]
                  },
                  {
                      "Permiso": "administrador",
                      "Activo": true,
                      "Pass": "ADMINSILV",
                      "Nick": "SESPINOZAM",
                      "UnidadHospitalaria": {
                          "mnemonicos": "COR",
                          "num_asociado": 9,
                          "activo": true,
                          "id": "9",
                          "hospital": "Corporativo"
                      },
                      "Nombre": "SILVANA DANIELA ESPINOZA MANJARREZ",
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ]
                  },
                  {
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Pass": "agendasma",
                      "Permiso": "administrador",
                      "Nombre": "Ricardo Aguirre",
                      "UnidadHospitalaria": {
                          "num_asociado": "0",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "id": "6",
                          "mnemonicos": "SMA",
                          "activo": true,
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "hospital": "MAC San Miguel de Allende"
                      },
                      "Nick": "RAGUIRRE",
                      "Activo": true
                  },
                  {
                      "Permiso": "tablero",
                      "Pass": "tableroIntegracion",
                      "UnidadHospitalaria": {
                          "activo": true,
                          "num_asociado": 0,
                          "hospital": "MAC Integración Procesos Clínicos",
                          "id": "999",
                          "mnemonicos": "IPC",
                          "calendar": "Hospital-MAC-Integración Procesos Clínicos"
                      },
                      "Activo": true,
                      "Nick": "alexia.reyes",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Nombre": "Alexia Reyes"
                  },
                  {
                      "Pass": "LQGUEVARA",
                      "Nombre": "LILIANA QUEZADA GUEVARA",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "UnidadHospitalaria": {
                          "email_calidad": "",
                          "num_asociado": 25,
                          "mnemonicos": "LVG",
                          "id": "22",
                          "activo": true,
                          "hospital": "MAC La Viga",
                          "calendar": "Hospital-MAC-La Viga"
                      },
                      "Permiso": "calidad",
                      "Nick": "LQGUEVARA",
                      "Activo": true
                  },
                  {
                      "UnidadHospitalaria": {
                          "id": "16",
                          "num_asociado": 11,
                          "calendar": "Hospital-MAC-Guanajuato",
                          "mnemonicos": "GTO",
                          "activo": true,
                          "hospital": "MediMAC Guanajuato"
                      },
                      "Permiso": "agenda",
                      "Pass": "quirofano5",
                      "Nombre": "Responsiva jefe de enfermería",
                      "Activo": true,
                      "Nick": "GUANAJUATO",
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ]
                  },
                  {
                      "Activo": true,
                      "Systems": [
                          {
                              "id": 2,
                              "active": true
                          }
                      ],
                      "Permiso": "administrador",
                      "Nick": "JCIPRIANOF",
                      "Pass": "smaagenda5",
                      "Nombre": "Joel Cipriano Felipe",
                      "UnidadHospitalaria": {
                          "id": "6",
                          "calendar": "Hospital-MAC-San Miguel de Allende",
                          "num_asociado": "0",
                          "activo": true,
                          "mnemonicos": "SMA",
                          "email_calidad": "calidad.sma@hospitalesmac.com",
                          "hospital": "MAC San Miguel de Allende"
                      }
                  },
                  {
                      "Systems": [
                          {
                              "active": true,
                              "id": 2
                          }
                      ],
                      "Nick": "RCRUZG",
                      "Permiso": "administrador",
                      "UnidadHospitalaria": {
                          "hospital": "MAC Mexicali",
                          "calendar": "Hospital-MAC-Mexicali",
                          "mnemonicos": "MEX",
                          "id": "8",
                          "activo": true,
                          "email_calidad": "jaquelin.valenzuela@hospitalesmac.com",
                          "num_asociado": "0"
                      },
                      "Pass": "quirofanomx1",
                      "Nombre": "Roberto Cruz Gallego",
                      "Activo": true
                  }
              ]

                for(let j=0;j<usuarios.length;j++){
                  if(data.solicitado === usuarios[j].Nombre){
                    unidadUsuario = usuarios[j].UnidadHospitalaria.calendar
                  }
                  
                }

                if(unidadEvento !== unidadUsuario){
                  //resultList.push(doc.data())
                  resultList.push({ log_id: doc.id, unidad:unidadEvento})
                  
                }

                
              }
                
            })
            console.log("se tienen que eliminarr")
            console.log(resultList.length)
            console.log(resultList)
        })

        pacientesStore.collection(doctordirectory)
          .onSnapshot(snap =>{
            const users = []
            snap.forEach(doc => {
                let data = doc.data()
                users.push({ 
                  id: doc.id,
                  Descripcion : data.doctorInfo.Descripcion,
                  idTab_DirectorioMedico : data.doctorInfo.idTab_DirectorioMedico,
                  NumeroTelefonico : data.doctorInfo.NumeroTelefonico,
                  NumeroCelular : data.doctorInfo.NumeroCelular,
                  Extension : data.doctorInfo.Extension,
                  SitioWeb: data.doctorInfo.SitioWeb,
                  Especialidad : data.doctorInfo.Especialidad,
                  Nombre : data.doctorInfo.Nombre,
                  Especialidad2 : data.doctorInfo.Especialidad2,
                  Subespecialidad : data.doctorInfo.Subespecialidad,
                  Hospital : data.doctorInfo.Hospital,
                  CorreoElectronico : data.doctorInfo.CorreoElectronico,
                  Consultorio : data.doctorInfo.Consultorio,
                  Cedula : data.doctorInfo.Cedula,
                  RFC : data.doctorInfo.RFC,
                  Validate: data.doctorInfo.Validate,
                  inputCedulaEspecialidad : data.doctorInfo.inputCedulaEspecialidad,
                  inputCedulaSubEspecialidad : data.doctorInfo.inputCedulaSubEspecialidad,
              })
            })

            setMedicos(users)
        })


        getCalendarRequest()

    },[initialDay,finalDay])

    useEffect(()=>{
      if(finalDay){//Se verifica si se ha seleccionado fecha final
          if(moment(initialDay).format('DD-MM-YYYY') === moment(finalDay).format('DD-MM-YYYY')){//Se verifica si la fecha final es igual a la inicial
            //Se muestra solo la fecha inicial seleccionada  
            setRangeMessage(moment(initialDay).format('DD-MM-YYYY'))
          }
          else{//Se muestra el rango de fechas seleccionado
              setRangeMessage(moment(initialDay).format('DD-MM-YYYY') + " - " + moment(finalDay).format('DD-MM-YYYY'))
          }
          
      }
      else{//Se muestra solo la fecha inicial seleccionada
          setRangeMessage(moment(initialDay).format('DD-MM-YYYY'))
      }
  },[initialDay,finalDay])

    useEffect(()=>{
      if (events.length > 0) {//Se verifica si se cuenta con eventos en el rango de fechas requerido

        let eventosEnMemoria = 0
        if(localStorage.getItem("eventos")){
          eventosEnMemoria = JSON.parse(localStorage.getItem('eventos'))
        }
        if(true){
          //Se obtiene el array con los eventos que ya ha visualizado el usuario
          
                

                if(userLevel !== "superuser"){
                  let eventosUnidadHospitalaria = []
                  events.forEach(
                    evento => {
                      let description = evento.description.toString().split('|')
                      let unidadHospitalariaEventoName = ""
                      if(description.length > 1){
                          if(description[8]){
                            const resultado = uhList.find( uh => uh.calendar === description[8].split('=')[1] )
                            if(resultado){
                                unidadHospitalariaEventoName = resultado.hospital
                            }
                            else{
                                unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
  
                            }
                            
                        }
                        else{
                            unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
                        }
                      }
                      else{
                        unidadHospitalariaEventoName = getUhName(evento.organizer.displayName)
                      }
                      if(userNomHospital.toString().toUpperCase() === unidadHospitalariaEventoName.toString().toUpperCase()){
                        eventosUnidadHospitalaria.push(evento)
                      }
                    }
                  )

                  if(eventosUnidadHospitalaria.length !== eventosEnMemoria){
                    const audioEl = document.getElementById('notificacion')
                    audioEl.play()
                    Swal.fire({
                      icon:"warning",
                      title:"Atención!",
                      confirmButtonText: "Aceptar",
                      confirmButtonColor: "#04afaa",
                      text:"Nuevos eventos disponibles.",
                      allowOutsideClick:false,
                    })
                    .then((res)=>{
                      if(res.isConfirmed){
                        //setInitialDay(new Date(evento.start.dateTime))
                        //setFinalDay(new Date(evento.start.dateTime))
                      }
                    })

                    localStorage.setItem("eventos",eventosUnidadHospitalaria.length)
                  }
                }
                else{
                  if(events.length !== eventosEnMemoria){
                    const audioEl = document.getElementById('notificacion')
                    audioEl.play()
                    Swal.fire({
                      icon:"warning",
                      title:"Atención!",
                      confirmButtonText: "Aceptar",
                      confirmButtonColor: "#04afaa",
                      text:"Nuevos eventos disponibles.",
                      allowOutsideClick:false,
                    })
                    .then((res)=>{
                      if(res.isConfirmed){
                        //setInitialDay(new Date(evento.start.dateTime))
                        //setFinalDay(new Date(evento.start.dateTime))
                      }
                    })
                    localStorage.setItem("eventos",events.length)
                  } 
                  
                }
        }
        //Se guarda en memoria la nueva lista de pacientes
        
        setIsLoading(false)
        setIsEmpty(false)
      } else {//Lista de eventos vacía
        setIsEmpty(true)
        setIsLoading(false)
      }

      /**
       * Función que busca el nombre de la unidad hospitalaría a la que pertenece el evento
       * @param {String} organizer Recibe el nombre del organizador en el calendario
       * @returns String Devuelve el nombre de la unidad hospitalaria encontrada
       */
      function getUhName(organizer){
        try{
            //Se toma el nombre del organizador a partir de donde se encuentre la palabra MAC
            let unidadABuscar= organizer.toUpperCase().split('MAC')[1]
            
            //Se realiza la búsqueda en la lista de unidad hospitalarias disponibles
            let busqueda = []
            uhList.forEach(
                uh => {
                    if(uh.hospital.toUpperCase().includes(unidadABuscar.toUpperCase())){
                        busqueda.push(uh.hospital.toUpperCase())
                    }
                }
            )

            if(busqueda.length > 0){//Se verifica si hay resultados en la búsqueda y se devuelve el valor localizado
                return busqueda
            }
            else{//Se devuelve el nombre del organizador del calendario
                return organizer
            }
        }
        catch{//Errores inesperados se devuelve el nombre del organizador del calendario
            return organizer
        }
      }
    },[events,uhList,userLevel,userNomHospital])

    useEffect(()=>{
      if (medicos.length > 0){
        if(localStorage.getItem("medicos")){
          let medicosEnMemoria = JSON.parse(localStorage.getItem('medicos'))
          

          if(userLevel !== "superuser"){
            let medicosUnidadHospitalaria = []
            medicos.forEach(
              medico => {
                let unidadHospitalariaEventoName = getUnidadHospitalariaName(medico.Hospital,uhList)
                if(userNomHospital.toString().toUpperCase() === unidadHospitalariaEventoName.toString().toUpperCase()){
                  medicosUnidadHospitalaria.push({id:medico.id})
                }
              }
            )
            //hadNewEvent(medicosEnMemoria,medicosUnidadHospitalaria)
            if(medicosUnidadHospitalaria.length !== medicosEnMemoria){
              const audioEl = document.getElementById('notificacion')
              audioEl.play()
              localStorage.setItem("medicos",medicosUnidadHospitalaria.length)
                    Swal.fire({
                        icon:"warning",
                        title:"Atención!",
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#04afaa",
                        text:"Nuevos médicos disponibles.",
                        allowOutsideClick:false,
                      })
                      .then((res)=>{
                        if(res.isConfirmed){
                          //setInitialDay(new Date(evento.start.dateTime))
                          //setFinalDay(new Date(evento.start.dateTime))
                        }
                      })
              
            }
          }
          else{
            //hadNewEvent(medicosEnMemoria,medicos)
          }
        }
        else{
          //localStorage.setItem("medicos",JSON.stringify(medicos))
          localStorage.setItem("medicos",medicos.length)
        }
      }

    },[medicos,userLevel,uhList,userNomHospital,isCorporativo])

    useEffect(()=>{
      pacientesStore.collection("users")
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                let data = doc.data()

                resultList.push(doc.data())
            })
        })
    },[])


    return (
        <>
            <audio id="notificacion">
                <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
            </audio>
            {/* <Notifications /> */}
            <div id="seccionCabeceraLogin" className='seccionCabeceraLogin'>
                <Header userLevel={userLevel.toUpperCase()} userNomHospital={userNomHospital} userName={userName} 
                isCorporativo={isCorporativo} setuserNomHospital={setuserNomHospital} 
                setHospitalSeleccionado={setHospitalSeleccionado}
                setIsMostrarIndicadores={setIsMostrarIndicadores} isMostrarIndicadores={isMostrarIndicadores}/>
            </div>
            <br />
            <div className='cuerpoPacientes'>
                <CalendarSection tituloInicial={rangeMessage} 
                    tituloFinal={""} 
                    imgSource={"https://hospitalesmac.com/img/icono_16.png"}
                    isLoading={isLoading}
                    isEmpty={isEmpty}
                    events={events}
                    finalDay={finalDay}
                    initialDay={initialDay}
                    isMostrarRealizado={isMostrarRealizado}
                    setIsMostrarRealizado={setIsMostrarRealizado}
                    isMostrarEnProceso={isMostrarEnProceso}
                    setIsMostrarEnProceso={setIsMostrarEnProceso}
                    isMostrarEnPendiente={isMostrarEnPendiente}
                    setIsMostrarEnPendiente={setIsMostrarEnPendiente}
                    cantidadRealizada={cantidadRealizada}
                    setCantidadRealizada={setCantidadRealizada}
                    cantidadProceso={cantidadProceso}
                    setCantidadProceso={setCantidadProceso}
                    cantidadPendiente={cantidadPendiente}
                    setCantidadPendiente={setCantidadPendiente}
                    uhList={uhList}
                    isMostrarQuirofanos={isMostrarQuirofanos}
                    setIsMostrarQuirofanos={setIsMostrarQuirofanos}
                    isMostrarUsuarios={isMostrarUsuarios}
                    setIsMostrarUsuarios={setIsMostrarUsuarios}
                    userLevel={userLevel}
                    nomHospital={userNomHospital}
                    isMostrarEditar={isMostrarEditar}
                    setIsMostrarEditar={setIsMostrarEditar}
                    userName={userName}
                    cancelEvents={cancelEvents}
                    cantidadCanceladas={cantidadCanceladas}
                    setCantidadCanceladas={setCantidadCanceladas}
                    isMostrarMedicos={isMostrarMedicos}
                    setIsMostrarMedicos={setIsMostrarMedicos}
                    isReadOnly={isReadOnly}
                    setIsMostrarAgendaVista={setIsMostrarAgendaVista} isMostrarAgendaVista={isMostrarAgendaVista}
                    editEvents={editEvents} isCorporativo={isCorporativo} setuserNomHospital={setuserNomHospital}
                    isMostrarAgendar={isMostrarAgendar} setIsMostrarAgendar={setIsMostrarAgendar} hospitalSeleccionado={hospitalSeleccionado}
                    pendientesUnidad={pendientesUnidad} agendadosUnidad={agendadosUnidad}
                    realizadosUnidad={realizadosUnidad} generalUnidad={generalUnidad}
                    pendientesUnidadEventFormat={pendientesUnidadEventFormat}
                    agendadoUnidadEventFormat={agendadoUnidadEventFormat}
                    realizadosUnidadEventFormat={realizadosUnidadEventFormat}
                    canceladosUnidad={canceladosUnidad}
                    isMostrarIndicadores={isMostrarIndicadores} setIsMostrarIndicadores={setIsMostrarIndicadores}
                    editEventsUnidad={editEventsUnidad}/>
                    <DateModal initialDay={initialDay} setInitialDay={setInitialDay} finalDay={finalDay} setFinalDay={setFinalDay}/>
                <Footer />
            </div>
        </>
    )
}

export default CalendarPage;