/**
 * Librerías
 */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"
import moment, { isDuration } from 'moment';
import { BsXSquare } from "react-icons/bs";
import {MdOutlineDoDisturb,MdOutlineEditCalendar} from 'react-icons/md';

/**
 * Styles
 */
import '../styles/tarjetaContenedor.css'
import '../styles/reservar.css'

/**
 * Utilerías
 */
//Objetos y colecciones de la base de datos
import { buildingCollection, pacientesStore, resourcesCollection } from "../utils/firebase";
//Funciones necesarias en la lógica del negocio
import { compararInformación } from "../utils/config";

/**
 * Objects
 */
//Contiene la interacción a la base de datos del objeto de tipo calendarLog
import { createUpdateCalendarLog } from "../objects/calendarLog";

/**
 * Componente que muestra la información actual de un evento y permite su edición
 * @param {Object} param0 Recibe los elementos necesarios para mostrar la información actual del evento
 * @returns Component Devuelve el componente con los elementos y funcionalidad asignada 
 */
const EditarEvento = ({eventoSeleccionado,setIsMostrarEditar,uhList,nomHospital,userLevel,userName,isMostrarEnPendiente}) => {

    //Estado que indica si aun no se ha seleccionado unidad hospitalaria
    const [buildHadError,setBuildHadError] = useState(false)
    //Estado que almacena la lista de unidades hospitalarias disponibles
    const [buildingList, setBuildingList] = useState([])
    //Estado que almacena la unidad hospitalaria seleccionada
    const [buildingSelected ,setBuildingSelected] = useState("-- Seleccione la unidad hospitalaria --")
    //Estado que almacena la cedula profesional del medico tratatente
    const [cedula, setCedula] = useState("")
    //Estado que indica si aun no se ha ingresado la cedula del medico tratante
    const [cedulaHadError, setCedulaHadError] = useState(false)
    //Estado que almacena el doagnostico del paciente
    const [diagnostico, setDiagnostico] = useState("")
    //Estado que almacena la edad del paciente
    const [edad, setEdad] = useState("")
    //Estado que almacena el equipo quirurgico requerido para la cirugía
    const [equipoQuirirgico, setEquipoQuirurgico] = useState("")
    const [equipoEspecial, setEquipoEspecial] = useState("")
    const [equipoEspecialHadError, setEquipoEspecialHadError] = useState(false)
    const [hemoderivados, setHemoderivados] = useState("")
    const [hemoderivadosHadError, setHemoderivadosHadError] = useState(false)
    //Estado que indica si aun no se ha ingresado el equipo quirurgico
    const [equipoQuirirgicoHadError, setEquipoQuirirgicoHadError] = useState(false)
    //Estado que almacena la información actual del evento mostrado
    const [eventActual,setEventActual] = useState({})
    //Estado que almacena los eventos que se han reservado
    const [eventosTotales, setEventosTotales] = useState([])
    //Estado que almacena los estudios transoperatorios
    const [estudios, setEstudios] = useState("")
    //Estado que almacena los requerimientos extras
    const [extras, setExtras] = useState("")
    //Estado que almacena la fecha de nacimiento del paciente
    const [fecha_nacimiento,setFechaNacimiento] = useState("")
    //Estado que almacena la fecha en que se requiere reservar la cirugía
    const [fecha_seleccionada,setFechaSeleccionada] = useState("")
    //Estado que indica si aun no se ha seleccionado hora de finalización para la cirugía
    const [horaFinalHadError, setHoraFinalHadError] = useState(false)
    //Estado que almacena la hora de finalización requerido para la cirugía
    const [horaFinalSelected,setHoraFinalSelected] = useState("-- Seleccione la hora final --")
    //Estado que indica si aun no se ha seleccionado hora inicial para la cirugía
    const [horaInicialHadError,setHoraInicialHadError] = useState(false)
    //Estado que almacena la hora de inicio requerida para la cirugía
    const [horaInicialSelected,setHoraInicialSelected] = useState("-- Seleccione la hora inicial --")
    //Estado que almacena las horas iniciales disponibles para el evento
    const [horasInicialesDisponibles,setHorasInicialesDisponibles] = useState([])
    //Estado que almacena las horas de finalización disponibles
    const [horasFinalArray, setHorasFinalArray] = useState([])
    //Estado que almacena las horas de finalización disponibles para el evento
    const [horasFinalesDisponibles, setHorasFinalesDisponibles] = useState([])
    //Estado que almacena las horas iniciales disponibles
    const [horasInicialArray, setHorasIniciaArray] = useState([])
    //Estado que almacena las horas que ya se han reservado para el quirófano seleccionado
    const [horasReservadas, setHorasReservadas] = useState([])
    //Estado que almacena si se muestra el tooltip
    const [isToolTipVisible, setIsToolTipVisible] = useState(false)
    //Estado que almacena el lado del procedimiento (es un campo opcional)
    const [ladoProcedimiento,setLadoProcedimiento] = useState("")
    //Estado que almacena el mensaje de asistencia para el usuario cuando un parámetro es invalido
    const [messageError,setMessageError] = useState("")
    //Estado que almacena el nombre del anestesiologo
    const [nombreAnestesiologo, setNombreAnestesiologo] = useState("")
    const [tipoAnestesiologo, setTipoAnestesiologo] = useState("")
    //const [isAnestesiologoExterno, setIsAnestesiologoExterno] = useState(false)
    //Estado que almacena el nombre del paciente
    const [nombrePaciente, setNombrePaciente] = useState("")
    //Estado que indica si aun no se ha ingresado el nombre del paciente
    const [nombrePaciennteHadError, setNombrePacienteHadError] = useState(false)
    //Estado que almacena el procedimiento
    const [procedimiento, setProcedimiento] = useState("")
    //Estado que indica si aun no se ha ingresado el procedimiento
    const [procedimientoHadError, setProcedimientoHadError] = useState(false)
    //Estado que indica si aun no se ha seleccionado el quirófano requerido
    const [resourceHadError,setResourceHadError] = useState(false)
    //Estado que almacena la lista de quirófanos disponibles para la unidad hospitalaria seleccionada
    const [resourcesList, setResourcesList] = useState([])
    //Estado que almacena el quirófano requerido para la cirugía
    const [resourcesSelected ,setResourcesSelected] = useState("-- Seleccione el quirófano deseado --")
    //Estado que almacena el sexo del paciente
    const [sexo,setSexo] = useState("")
    //Estado que almacena el télefono de contacto del medico tratante
    const [telefono, setTelefono] = useState("")
    //Estado que indica si aun no se ha ingresado el teléfono de contacto del medico tratante
    const [telefonoHadError, setTelefonoHadError] = useState(false)
    //Estado que almacena el télefono de contacto del paciente
    const [telefonoPaciente, setTelefonoPaciente] = useState("")
    //Estado que indica si aun no se ha ingresado el teléfono de contacto del paciente
    const [telefonoPacienteHadError, setTelefonoPacienteHadError] = useState(false)
    //Estado que almacena el tipo de anestesía
    const [tipoAnestesia, setTipoAnestesia] = useState("")
    //Estado que almacena el tipo de cliente
    const [tipoCliente, setTipoCliente] = useState("")
    const [opcionCliente, setOpcionCliente] = useState("")
    //Estado que almacena el día actual
    const [today,setToday] = useState("")

    const [isMediaHora, setIsMediaHora] = useState(true)
    const [actualResource, setActualResource] = useState("")
    const [isOpcionUno, setIsOpcionUno] = useState(false)
    const [isOpcionDos, setIsOpcionDos] = useState(false)
    const [isOpcionTres, setIsOpcionTres] = useState(false)
    const [isOpcionCuatro, setIsOpcionCuatro] = useState(false)

    const [isAnestsiologoOpcionUno, setIsAnestesiologoOpcionUno] = useState(false)
    const [isAnestesiologoOpcionDos, setIsAnestesiologoOpcionDos] = useState(false)

    const [isUgencia, setIsUrgencia] = useState(false)


    //Estilo asignado al icono de la tarjeta para pacientes en quirófano
    const styleConfirm = { color: "white", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

    useEffect(()=>{
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica que se haya seleccionado una unidad hospitalaria
            if(buildHadError){//Si se habia indicado error en unidad hospitalaria se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setBuildHadError(false)
            if(buildingSelected === "Hospital-MAC-Celaya"){
                setIsMediaHora(true)
            }
            else{
                setIsMediaHora(true)
            }
            /**
             * Se obtiene la lista de quirófanos disponibles en la unidad hospitalaria seleccionada
             */
            pacientesStore.collection(resourcesCollection).where('Building_Id',"==",buildingSelected).where('Activo',"==",true)
            .onSnapshot(snap =>{
                const resultList = []
                snap.forEach(doc => {
                    if(isMostrarEnPendiente || !isMostrarEnPendiente){
                        //Proceso que permite editar
                        resultList.push({ id: doc.id, ...doc.data() })
                    }
                    else{
                        //Proceso que no permite editar
                        let data = doc.data()
                        if(resourcesSelected === data.Resource_Name){
                            resultList.push({ id: doc.id, ...doc.data() })
                        }
                    }
                })
                resultList.sort(compararInformación('Resource_Name'))
                setResourcesList(resultList)
            })
        }
        else{//Se limpia la lista de quirofanos
            setResourcesList([])
            setIsMediaHora(false)
        }
    },[buildingSelected,buildHadError,resourcesSelected,isMostrarEnPendiente])

    useEffect(()=>{
        if(cedula !== ""){
            if(cedulaHadError){//Si se habia indicado error en cedula profesional se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setCedulaHadError(false)
        }
    },[cedula,cedulaHadError])

    useEffect(()=>{
        if(equipoQuirirgico !== ""){
            if(equipoQuirirgicoHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setEquipoQuirirgicoHadError(false)
        }
    },[equipoQuirirgico,equipoQuirirgicoHadError])

    useEffect(()=>{
        if(eventoSeleccionado.description){
            
            let description = eventoSeleccionado.description.toString().split('|')

            if(description.length > 1){
                setBuildingSelected(description[8].split('=')[1])
                setResourcesSelected(description[0].split('=')[1])
                setActualResource(description[0].split('=')[1])
                setCedula(description[1].split('=')[1])
                setTelefono(description[6].split('=')[1])
                setNombrePaciente(description[2].split('=')[1])
                setTelefonoPaciente(description[7].split('=')[1])
                setProcedimiento(description[3].split('=')[1])
                setEquipoQuirurgico(description[4].split('=')[1])
                setExtras(description[5].split('=')[1])
                let fecha = eventoSeleccionado.start.dateTime.split('T')[0]
                setFechaSeleccionada(fecha)
                setLadoProcedimiento(description[10].split('=')[1])
                setNombreAnestesiologo(description[11].split('=')[1])
                setTipoAnestesia(description[12].split('=')[1])
                if(description[13].split('=')[1] === "NA"){
                    setFechaNacimiento("")
                }
                else{
                    setFechaNacimiento(description[13].split('=')[1])
                }
                setEdad(description[14].split('=')[1])
                if(description[15].split('=')[1] === "NA"){
                    setSexo("")
                }
                else{
                    setSexo(description[15].split('=')[1])
                }
                setTipoCliente(description[16].split('=')[1])
                setDiagnostico(description[17].split('=')[1])
                let estudiosActuales = ''
                
                if(description[20]){
                    if(description[20].split('=')[1]){
                        estudiosActuales = description[20].split('=')[1]
                        setEstudios(description[20].split('=')[1])
                    }
                    else{
                        estudiosActuales = 'NA'
                        setEstudios('NA')
                    }
                }
                else{
                    estudiosActuales = 'NA'
                    setEstudios('NA')
                }

                let opcionClienteActual = ''
                if(description[21]){
                    if(description[21].split('=')[1]){
                        opcionClienteActual = description[21].split('=')[1]
                        setOpcionCliente(description[21].split('=')[1])
                    }
                    else{
                        opcionClienteActual = ''
                        setOpcionCliente('')
                    }
                }
                else{
                    opcionClienteActual = ''
                    setOpcionCliente('')
                }

                let tipoAnestesiaActual = ''
                if(description[22]){
                    if(description[22].split('=')[1]){
                        tipoAnestesiaActual = description[22].split('=')[1]
                        setTipoAnestesiologo(description[22].split('=')[1])
                        // if(description[22].split('=')[1] === "Externo"){
                        //     setIsAnestesiologoExterno(true)
                        // }
                        // else{
                        //     setIsAnestesiologoExterno(false)
                        // }
                    }
                    else{
                        tipoAnestesiaActual = ''
                        setTipoAnestesiologo('')
                        //setIsAnestesiologoExterno(false)
                    }
                }
                else{
                    tipoAnestesiaActual = ''
                    setTipoAnestesiologo('')
                    //setIsAnestesiologoExterno(false)
                }

                let equipoEspecialActual = ''
                let hemoderivadosActual = ''

                let concepto = description[19].split('=')[1]
                let result = concepto.includes("Cirugía de urgencia");

                if(description[19].split('=')[1] ==="Cirugía de urgencia"){
                    setIsUrgencia(true)
                }
                else{
                    setIsUrgencia(false)
                }


                if(description.length >= 25){
                    if(description[23]){
                        if(description[23].split('=')[1]){
                            equipoEspecialActual = description[23].split('=')[1]
                            setEquipoEspecial(description[23].split('=')[1])
                        }
                        else{
                            equipoEspecialActual = ''
                            setEquipoEspecial('')
                        }
                    }
                    else{
                        equipoEspecialActual = ''
                        setEquipoEspecial('')
                    }
    
                    
                    if(description[24]){
                        if(description[24].split('=')[1]){
                            hemoderivadosActual = description[24].split('=')[1]
                            setHemoderivados(description[24].split('=')[1])
                        }
                        else{
                            hemoderivadosActual = ''
                            setHemoderivados('')
                        }
                    }
                    else{
                        hemoderivadosActual = ''
                        setHemoderivados('')
                    }
                }
                else{
                    equipoEspecialActual = ''
                    setEquipoEspecial('')
                    hemoderivadosActual = ''
                    setHemoderivados('')
                }
                

                
                let horaInicio = eventoSeleccionado.start.dateTime.split('T')[1].split('-')[0]
                let horaFinal = eventoSeleccionado.end.dateTime.split('T')[1].split('-')[0]

                setHoraInicialSelected(horaInicio)
                setHoraFinalSelected(horaFinal)
                setEventActual({
                    'unidad':description[8].split('=')[1],
                    'quirofano':description[0].split('=')[1],
                    'cedula':description[1].split('=')[1],
                    'telefono':description[6].split('=')[1],
                    'nombrePaciente':description[2].split('=')[1],
                    'telefonoPaciente':description[7].split('=')[1],
                    'equipoQuirurgico':description[4].split('=')[1],
                    'extras':description[5].split('=')[1],
                    'fecha':fecha,
                    'ladoProcedimieto':description[10].split('=')[1],
                    'nombreAnestesiologo':description[11].split('=')[1],
                    'tipoAnestesia':description[12].split('=')[1],
                    'fechaNacimiento':description[13].split('=')[1],
                    'sexo':description[15].split('=')[1],
                    'tipoCliente':description[16].split('=')[1],
                    'diagnostico':description[17].split('=')[1],
                    'horaInicio':horaInicio,
                    'horaFinal':horaFinal,
                    'estudios':estudiosActuales,
                    'TipoClienteOpcion' : opcionClienteActual,
                    'TipoAnestesiologo' : tipoAnestesiaActual,
                    'equipoEspecial' : equipoEspecialActual,
                    'hemoderivados' : hemoderivadosActual
                })
            }
        }
    },[eventoSeleccionado])

    useEffect(()=>{
        let horasOcupadas = []
        eventosTotales.forEach(
                    event => {
                        let dayEvento = new Date(moment(event.start.dateTime).format('MM/DD/YYYY'))
                        let dayInicial =  new Date(moment(fecha_seleccionada).format('MM/DD/YYYY'))

                        if(dayEvento.getFullYear() + "-" + (dayEvento.getMonth()) + "-" + dayEvento.getDate() === dayInicial.getFullYear() + "-" + (dayInicial.getMonth()) + "-" + dayInicial.getDate()){
                            let tiempoInicial = ""
                            //let tiempoInicial = event.start.dateTime.toString().split('T')
                            if(event.start.dateTime){
                                tiempoInicial = event.start.dateTime.toString().split('T')
                            }
                            else{
                                tiempoInicial = event.start.toString().split('T')
                            }
                            let horaInicial = parseInt(tiempoInicial[1].split(':')[0])

                            //let tiempoFinal = event.end.dateTime.toString().split('T')
                            let tiempoFinal = ""
                            if(event.end.dateTime){
                                tiempoFinal = event.end.dateTime.toString().split('T')
                            }
                            else{
                                tiempoFinal = event.end.toString().split('T')
                            }
                            let horaFinal = parseInt(tiempoFinal[1].split(':')[0])
                            let description = event.description.toString().split('|')
                            let AppointmentType = ""
                            if(description.length > 1){
                                AppointmentType = description[0].split('=')[1]
                            }
                            else{
                                AppointmentType = description[0]
                            }

                            let horaReservadaObject = {
                                'Recurso' : AppointmentType,
                                'Hora' : horaInicial,
                                'Identificador':"Inicio",
                                'Unidad' : description[8].split('=')[1]
                            }
                            horasOcupadas.push(horaReservadaObject)
                            for(let i=horaInicial + 1;i<horaFinal;i++){
                                let horaReservadaObject = {
                                    'Recurso' : AppointmentType,
                                    'Hora' : i,
                                    'Identificador':"Intermedio",
                                    'Unidad' : description[8].split('=')[1]
                                }
                                horasOcupadas.push(horaReservadaObject)
                            }
                            horaReservadaObject = {
                                'Recurso' : AppointmentType,
                                'Hora' : horaFinal,
                                'Identificador':"Fin",
                                'Unidad' : description[8].split('=')[1]
                            }
                            horasOcupadas.push(horaReservadaObject)
                        }
                    }
                )
        setHorasReservadas(horasOcupadas)
    },[eventosTotales,fecha_seleccionada,resourcesSelected])

    useEffect(()=>{
        if(!fecha_seleccionada){//Se verifica si la fecha se encuentra vacía
            if(today){//Se verifica si se cuenta con el valor de la fecha actual
                //Se predefine la fecha actual como fecha seleccionada
                setFechaSeleccionada(today)
            }
        }

        //Se solicitan las horas iniciales disponibles
        getTimeLeft()
        /**
         * Función que obtiene las horas disponibles para el inicio de la cirugía
         */
        function getTimeLeft(){
            //Se obtiene el día actual
            let hoy = new Date()

            let dia = ""
            if(hoy.getDate().toString().length < 2){
                dia = "0" + hoy.getDate()
            }
            else{
                dia = hoy.getDate()
            }
            let mes = hoy.getMonth() + 1
            if(mes.toString().length < 2){
                mes = "0" + mes
            }

            pacientesStore.collection("eventos")
          .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                resultList.push({ id_evento: doc.id, ...doc.data() })
            })

            let resultados = []
            for(let i=0;i<resultList.length;i++){
              if(resultList[i].event){
                let prueba = resultList[i].event
                prueba.id_evento = resultList[i].id_evento
                //resultados.push(resultList[i].event)
                resultados.push(prueba)
              }
            }
            

            resultados.sort(function(x, y) {
              var firstDate = new Date(x.start.dateTime),
                SecondDate = new Date(y.start.dateTime);
                
              if (firstDate > SecondDate) return -1;
              if (firstDate < SecondDate) return 1;
              return 0;
            });
            //console.log(resultados)
            setEventosTotales(resultados)
        })


            let horasArray = []
            if(fecha_seleccionada === hoy.getFullYear() + "-" + mes + "-" + dia){//Se  verifica si el día seleccionado corresponde al día actual
                //Se obtiene la hora actual
                let ahora = new Date()
                let hora = ahora.getHours()
                //let minutos = ahora.getMinutes()
                horasArray = []
                // if(minutos < 30){
                //     horasArray.push({
                //         hora : hora,
                //         minutos: "30"
                //     })
                // }
                for(let i=hora + 1;i<24;i++){//Se agregan las horas que aun están disponibles en el día actual considerando tambien medias horas
                    horasArray.push({
                        hora : i,
                        minutos: "00"
                    })
                    horasArray.push({
                        hora : i,
                        minutos: "30"
                    })
                    
                }
            }
            else{//Se ha seleccionado otra fecha por lo que se muestran las 24 horas disponibles considerando medias horas
                if(!isMediaHora){
                    horasArray = [
                        {
                            hora : "00",
                            minutos: "00"
                        },
                        {
                            hora : "00",
                            minutos: "30"
                        },
                        {
                            hora : "01",
                            minutos: "00"
                        },
                        {
                            hora : "01",
                            minutos: "30"
                        },
                        {
                            hora : "02",
                            minutos: "00"
                        },
                        {
                            hora : "02",
                            minutos: "30"
                        },
                        {
                            hora : "03",
                            minutos: "00"
                        },
                        {
                            hora : "03",
                            minutos: "30"
                        },
                        {
                            hora : "04",
                            minutos: "00"
                        },
                        {
                            hora : "04",
                            minutos: "30"
                        },
                        {
                            hora : "05",
                            minutos: "00"
                        },
                        {
                            hora : "05",
                            minutos: "30"
                        },
                        {
                            hora : "06",
                            minutos: "00"
                        },
                        {
                            hora : "06",
                            minutos: "30"
                        },
                        {
                            hora : "07",
                            minutos: "00"
                        },
                        {
                            hora : "07",
                            minutos: "30"
                        },
                        {
                            hora : "08",
                            minutos: "00"
                        },
                        {
                            hora : "08",
                            minutos: "30"
                        },
                        {
                            hora : "09",
                            minutos: "00"
                        },
                        {
                            hora : "09",
                            minutos: "30"
                        },
                        {
                            hora : "10",
                            minutos: "00"
                        },
                        {
                            hora : "10",
                            minutos: "30"
                        },
                        {
                            hora : "11",
                            minutos: "00"
                        },
                        {
                            hora : "11",
                            minutos: "30"
                        },
                        {
                            hora : "12",
                            minutos: "00"
                        },
                        {
                            hora : "12",
                            minutos: "30"
                        },
                        {
                            hora : "13",
                            minutos: "00"
                        },
                        {
                            hora : "13",
                            minutos: "30"
                        },
                        {
                            hora : "14",
                            minutos: "00"
                        },
                        {
                            hora : "14",
                            minutos: "30"
                        },
                        {
                            hora : "15",
                            minutos: "00"
                        },
                        {
                            hora : "15",
                            minutos: "30"
                        },
                        {
                            hora : "16",
                            minutos: "00"
                        },
                        {
                            hora : "16",
                            minutos: "30"
                        },
                        {
                            hora : "17",
                            minutos: "00"
                        },
                        {
                            hora : "17",
                            minutos: "30"
                        },
                        {
                            hora : "18",
                            minutos: "00"
                        },
                        {
                            hora : "18",
                            minutos: "30"
                        },
                        {
                            hora : "19",
                            minutos: "00"
                        },
                        {
                            hora : "19",
                            minutos: "30"
                        },
                        {
                            hora : "20",
                            minutos: "00"
                        },
                        {
                            hora : "20",
                            minutos: "30"
                        },
                        {
                            hora : "21",
                            minutos: "00"
                        },
                        {
                            hora : "21",
                            minutos: "30"
                        },
                        {
                            hora : "22",
                            minutos: "00"
                        },
                        {
                            hora : "22",
                            minutos: "30"
                        },
                        {
                            hora : "23",
                            minutos: "00"
                        },
                        {
                            hora : "23",
                            minutos: "30"
                        }
                    ]
                }
                else{
                    horasArray = [
                        {
                            hora : "00",
                            minutos: "00"
                        },
                        {
                            hora : "00",
                            minutos: "30"
                        },
                        {
                            hora : "01",
                            minutos: "00"
                        },
                        {
                            hora : "01",
                            minutos: "30"
                        },
                        {
                            hora : "02",
                            minutos: "00"
                        },
                        {
                            hora : "02",
                            minutos: "30"
                        },
                        {
                            hora : "03",
                            minutos: "00"
                        },
                        {
                            hora : "03",
                            minutos: "30"
                        },
                        {
                            hora : "04",
                            minutos: "00"
                        },
                        {
                            hora : "04",
                            minutos: "30"
                        },
                        {
                            hora : "05",
                            minutos: "00"
                        },
                        {
                            hora : "05",
                            minutos: "30"
                        },
                        {
                            hora : "06",
                            minutos: "00"
                        },
                        {
                            hora : "06",
                            minutos: "30"
                        },
                        {
                            hora : "07",
                            minutos: "00"
                        },
                        {
                            hora : "07",
                            minutos: "30"
                        },
                        {
                            hora : "08",
                            minutos: "00"
                        },
                        {
                            hora : "08",
                            minutos: "30"
                        },
                        {
                            hora : "09",
                            minutos: "00"
                        },
                        {
                            hora : "09",
                            minutos: "30"
                        },
                        {
                            hora : "10",
                            minutos: "00"
                        },
                        {
                            hora : "10",
                            minutos: "30"
                        },
                        {
                            hora : "11",
                            minutos: "00"
                        },
                        {
                            hora : "11",
                            minutos: "30"
                        },
                        {
                            hora : "12",
                            minutos: "00"
                        },
                        {
                            hora : "12",
                            minutos: "30"
                        },
                        {
                            hora : "13",
                            minutos: "00"
                        },
                        {
                            hora : "13",
                            minutos: "30"
                        },
                        {
                            hora : "14",
                            minutos: "00"
                        },
                        {
                            hora : "14",
                            minutos: "30"
                        },
                        {
                            hora : "15",
                            minutos: "00"
                        },
                        {
                            hora : "15",
                            minutos: "30"
                        },
                        {
                            hora : "16",
                            minutos: "00"
                        },
                        {
                            hora : "16",
                            minutos: "30"
                        },
                        {
                            hora : "17",
                            minutos: "00"
                        },
                        {
                            hora : "17",
                            minutos: "30"
                        },
                        {
                            hora : "18",
                            minutos: "00"
                        },
                        {
                            hora : "18",
                            minutos: "30"
                        },
                        {
                            hora : "19",
                            minutos: "00"
                        },
                        {
                            hora : "19",
                            minutos: "30"
                        },
                        {
                            hora : "20",
                            minutos: "00"
                        },
                        {
                            hora : "20",
                            minutos: "30"
                        },
                        {
                            hora : "21",
                            minutos: "00"
                        },
                        {
                            hora : "21",
                            minutos: "30"
                        },
                        {
                            hora : "22",
                            minutos: "00"
                        },
                        {
                            hora : "22",
                            minutos: "30"
                        },
                        {
                            hora : "23",
                            minutos: "00"
                        },
                        {
                            hora : "23",
                            minutos: "30"
                        }
                    ]
                }
                
            }

            //Se asignan las horas iniciales disponibles
            setHorasIniciaArray(horasArray)
            //Se solicitan las horas finales disponibles
            //getFinalTimeLeft()
        }
    },[fecha_seleccionada,today,isMediaHora])

    useEffect(()=>{
        if(isToolTipVisible){
            document.getElementById('telefonoToolTip').style.display = 'block'
        }
        else{
            document.getElementById('telefonoToolTip').style.display = 'none'
        }
    },[isToolTipVisible])

    useEffect(()=>{
        //Se solicitan las horas de finalización disponibles
        getFinalTimeLeft()
        /**
         * Función que obtiene las horas disponibles para finalización de la cirugía
         */
        function getFinalTimeLeft(){
            //Se obtiene la hora inicial seleccionada
            let hora = horaInicialSelected.toString().split(':')[0]
            let horasArray = []
            let contador = 0

            for(let i=parseInt(hora) + 1;i<24;i++){//Se agregan las horas disponibles en el día seleccionado considerando también medias horas
                let hora = i
                if(hora.toString().length < 2){
                    hora = "0" + hora
                }
                horasArray.push({
                    hora : hora,
                    minutos: "00"
                })
                if(isMediaHora){
                    horasArray.push({
                        hora : hora,
                        minutos: "30"
                    })
                }
                
                contador = contador + 1
            }

            //Se obtienen la cantidad de horas faltantes para completar esquema de 24 horas
            let faltantes = 24 - contador

            for(let i=0;i < faltantes - 1;i++){//Se agregan las horas faltantes considerando también medias horas (las horas agregadas corresponde al día siguiente del seleccionado)
                let hora = i
                if(hora.toString().length < 2){
                    hora = "0" + hora
                }
                horasArray.push({
                    hora : hora,
                    minutos: "00"
                })
                if(isMediaHora){
                    horasArray.push({
                        hora : hora,
                        minutos: "30"
                    })
                }
                
            }

            //Se asignan las horas finales disponibles
            setHorasFinalArray(horasArray)
        }
    },[horaInicialSelected,isMediaHora])

    useEffect(()=>{
        let horasValidas = []
        if(horasReservadas.length > 0){
            let fechaFinal = eventoSeleccionado.end.dateTime.split('T')[1]
            let fechaFinalSinAgregar = ""
            if(fechaFinal.split(":")[1] === "30"){
                fechaFinalSinAgregar = fechaFinal.split(":")[0] + ":00"
            }
            else{
                let nuevahora = parseInt(fechaFinal.split(":")[0]) - 1
                fechaFinalSinAgregar = nuevahora.toString().padStart(2,'0')+ ":30"
            }

            for(let i=0;i<horasFinalArray.length;i++){
                let encontrado = false
                
                for(let j=0;j<horasReservadas.length;j++){
                    if(parseInt(horasFinalArray[i].hora) === parseInt(horasReservadas[j].Hora) 
                        && horasReservadas[j].Recurso === resourcesSelected
                        && horasReservadas[j].Unidad === buildingSelected){
                        if(horasReservadas[j].Identificador !== "Inicio"){
                            encontrado = true
                        }
                        else{
                            if(parseInt(horasReservadas[j].Hora) > parseInt(horaInicialSelected.split(':')[0])){
                                horasValidas.push(horasFinalArray[i])
                                i = horasFinalArray.length
                            }
                        }
                        j = horasReservadas.length
                    }
                }

                if(!encontrado && horasFinalArray[i]){
                    horasValidas.push(horasFinalArray[i])
                }
                else{
                    if(horasFinalArray[i]){
                        if(fechaFinal.split(':')[0] + ":" + fechaFinal.split(':')[1] === horasFinalArray[i].hora + ":" + horasFinalArray[i].minutos
                            || fechaFinalSinAgregar === horasFinalArray[i].hora + ":" + horasFinalArray[i].minutos){
                                horasValidas.push(horasFinalArray[i])
                        }
                    }
                }
            }
            
            //setHoraFinalSelected(fechaFinal.split(':')[0] + ":" + fechaFinal.split(':')[1])
            setHoraFinalSelected(fechaFinalSinAgregar)
            
            if(isMostrarEnPendiente || !isMostrarEnPendiente){
                //Proceso de editar
                //setHoraFinalSelected(horasValidas[0].hora +':'+ horasValidas[0].minutos)
                setHorasFinalesDisponibles(horasValidas)
            }
            else{
                //Proceso de bloqueo
                let validas = []
                validas.push({
                    "hora": fechaFinal.split(':')[0],
                    "minutos": fechaFinal.split(':')[1]
                })
                setHorasFinalesDisponibles(validas)
            }
        }
        else{
            if(horasFinalArray.length > 0){
                //setHoraFinalSelected(horasFinalArray[0].hora + ":" + horasFinalArray[0].minutos)
                let fechaFinal = eventoSeleccionado.end.dateTime.split('T')[1]
                setHoraFinalSelected(fechaFinal.split(':')[0] + ":" + fechaFinal.split(':')[1])
            }
            setHorasFinalesDisponibles(horasFinalArray)
        }
    },[horasReservadas,horasFinalArray,resourcesSelected,horaInicialSelected,eventoSeleccionado.end.dateTime,isMostrarEnPendiente,buildingSelected])

    useEffect(()=>{
        let horasValidas = []
        let horaInicio = eventoSeleccionado.start.dateTime.split('T')[1].split('-')[0]
        let horaInicioSinAgregar = ""
        
        if(horaInicio.split(':')[1] === "30"){
            let nuevahora = parseInt(horaInicio.split(":")[0]) + 1
            horaInicioSinAgregar = nuevahora.toString().padStart(2,'0')+ ":00"
        }
        else{
            horaInicioSinAgregar = horaInicio.split(":")[0]+ ":30"
        }

        horasValidas.push({
            "hora": horaInicio.split(':')[0],
            "minutos": horaInicio.split(':')[1]
        })

        horasValidas.push({
            "hora": horaInicioSinAgregar.split(':')[0],
            "minutos": horaInicioSinAgregar.split(':')[1]
        })

        if(horasReservadas.length > 0){
            for(let i=0;i<horasInicialArray.length;i++){
                let encontrado = false
                for(let j=0;j<horasReservadas.length;j++){
                    if(parseInt(horasInicialArray[i].hora) === parseInt(horasReservadas[j].Hora) 
                        && horasReservadas[j].Recurso === resourcesSelected
                        && horasReservadas[j].Unidad === buildingSelected){
                        encontrado = true
                    }
                }
                if(!encontrado && horasInicialArray[i]){
                    horasValidas.push(horasInicialArray[i])
                }
            }
            //setHoraInicialSelected(horaInicio.split(':')[0] + ":" + horaInicio.split(':')[1])
            setHoraInicialSelected(horaInicioSinAgregar)
            if(isMostrarEnPendiente || !isMostrarEnPendiente){
                //Para editar
                setHorasInicialesDisponibles(horasValidas)
            }
            else{
                //Para bloqueo
                let validas = []
                validas.push(horasValidas[0])
                setHorasInicialesDisponibles(validas)
            }
        }
        else{
            if(horasInicialArray.length > 0){
                setHoraInicialSelected(horasValidas[0].hora + ":" + horasValidas[0].minutos)
            }
            setHorasInicialesDisponibles(horasInicialArray)
        }
    },[horasReservadas,horasInicialArray,resourcesSelected,eventoSeleccionado.start.dateTime,isMostrarEnPendiente,buildingSelected])

    useEffect(()=>{
        if(nombrePaciente !== ""){
            if(nombrePaciennteHadError){//Si se habia indicado error en el nombre del paciente se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setNombrePacienteHadError(false)
        }
    },[nombrePaciente,nombrePaciennteHadError])

    useEffect(()=>{
        /**
         * Se obtiene la fecha actual y se predefine en la selección de fechas con formato yyyy-mm-dd
         */
        let hoy = new Date()
        let dia = ""
        if(hoy.getDate().toString().length < 2){
            dia = "0" + hoy.getDate()
        }
        else{
            dia = hoy.getDate()
        }
        let mes = hoy.getMonth() + 1
        if(mes.toString().length < 2){
            mes = "0" + mes
        }
        setToday(hoy.getFullYear() + "-" + mes + "-" + dia)
        //setFechaSeleccionada(hoy.getFullYear() + "-" + mes + "-" + dia)

        /**
         * Se obtiene la lista de unidades hospitalarias
         */
        pacientesStore.collection(buildingCollection)
        .onSnapshot(snap =>{
            const resultList = []
            snap.forEach(doc => {
                if(userLevel !== "superuser"){
                    let data = doc.data()
                    const resultado = uhList.find( uh => uh.calendar === data.Building_Id );
                    if(resultado){
                        if(nomHospital === resultado.hospital){
                            resultList.push({ id: doc.id, ...doc.data() })
                        }
                    }
                }
                else{
                    resultList.push({ id: doc.id, ...doc.data() })
                }
            })
            resultList.sort(compararInformación('Building_Id'))
            setBuildingList(resultList)
        },(error)=>{ 
            setBuildingList([]) 
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error,
            })
          })
    },[nomHospital,uhList,userLevel])

    useEffect(()=>{
        if(procedimiento !== ""){
            if(procedimientoHadError){//Si se habia indicado error en el procedimiento a realizar se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setProcedimientoHadError(false)
        }
    },[procedimiento,procedimientoHadError])

    useEffect(()=>{
        if(resourcesSelected !== "-- Seleccione el quirófano deseado --"){//Se verifica si se ha seleccionado un quirófano valido
            if(resourceHadError){//Si se habia indicado error en quirófano se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setResourceHadError(false)
        }
    },[resourcesSelected,resourceHadError])

    useEffect(()=>{
        if(telefono.length === 10){
            if(telefonoHadError){//Si se habia indicado error en teléfono de contacto del medico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setTelefonoHadError(false)
        }
    },[telefono,telefonoHadError])

    useEffect(()=>{
        if(telefonoPaciente.length === 10){
            if(telefonoPacienteHadError){//Si se habia indicado error en teléfono de contacto del paciente se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setTelefonoPacienteHadError(false)
        }
    },[telefonoPaciente,telefonoPacienteHadError])

    /**
     * Función que cambia el estado del modal a oculto
     */
     const closeModal = () => {
        setIsMostrarEditar(false)
    } 

    /**
     * Función que permite el registro de un evento
     */
     const getReserve = async (motivo) =>{
        try{
            /**
             * Se da formato a los parametros correspondientes a las fechas (actual,inical y final)
             */
            //let date = new Date()
            //let today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
            let result = resourcesList.find( resource => resource.Resource_Name === resourcesSelected && resource.Building_Id === buildingSelected)
            let horaInicialSeparada = horaInicialSelected.toString().split(':')
            let horaFinalSeparada = horaFinalSelected.toString().split(':')
            
            /* Para obtener el texto */
            var combo = document.getElementById("horaFinalSelec");
            var selected = combo.options[combo.selectedIndex].text;
            horaFinalSeparada = selected.toString().split(':')
            // let horaFinal = ""
            // if(horaFinalSeparada[0] === eventActual.horaFinal.toString().split(':')[0]){
            //     horaFinal = parseInt(horaFinalSeparada[0]-1) + ":00"
            // }
            // else{
            //     horaFinal = horaFinalSelected
            // }
           
            let pruebaHoraInicial  = ""
            if(horaInicialSeparada[1] === "00"){
                pruebaHoraInicial = (parseInt(horaInicialSeparada[0]) - 1).toString().padStart(2,"0") + ":30"
            }
            else{
                pruebaHoraInicial = (parseInt(horaInicialSeparada[0])).toString().padStart(2,"0") + ":00"
            }

            let pruebaHoraFinal  = ""

            if(horaFinalSeparada[1] === "00"){
                pruebaHoraFinal = (parseInt(horaFinalSeparada[0])).toString().padStart(2,"0") + ":30"
            }
            else{
                pruebaHoraFinal = (parseInt(horaFinalSeparada[0]) + 1).toString().padStart(2,"0") + ":00"
            }
            let fechaFinal = fecha_seleccionada + "T" + pruebaHoraFinal

            if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                let fechaSeparada = fecha_seleccionada.split('-')
                let diaNuevo = parseInt(fechaSeparada[2]) + 1
                fechaFinal = fechaSeparada[0] + "-" + fechaSeparada[1] + "-" + diaNuevo + "T" + pruebaHoraFinal
            }

            //Solicitud de registro de un evento
            let solicitudBody = "AppointmentType=" + result.Resource_Name
            solicitudBody += "|Medico=" + cedula
            solicitudBody += "|Paciente=" + nombrePaciente
            solicitudBody += "|Procedimiento=" + procedimiento
            solicitudBody += "|Equipo Quirúrgico=" + equipoQuirirgico
            if(extras){
                solicitudBody += "|Extras=" + extras
            }
            else{
                solicitudBody += "|Extras=NA"
            }
            solicitudBody += "|Telefono Medico=" + telefono
            solicitudBody += "|Telefono Paciente=" + telefonoPaciente
            solicitudBody += "|Unidad Hospitalaroa=" + buildingSelected

            let duracion = parseInt(horaFinalSeparada[0]) - parseInt(horaInicialSeparada[0])
            if(duracion < 0){
                duracion = duracion + 24
            }
            solicitudBody += "|Duracion=" + duracion
            if(ladoProcedimiento){
                solicitudBody += "|LadoProcedimiento=" + ladoProcedimiento
            }
            else{
                solicitudBody += "|LadoProcedimiento=NA"
            }
            if(nombreAnestesiologo){
                solicitudBody += "|Anestesiologo=" + nombreAnestesiologo
            }
            else{
                solicitudBody += "|Anestesiologo=NA"
            }
            if(tipoAnestesia){
                solicitudBody += "|TipoAnestesia=" + tipoAnestesia
            }
            else{
                solicitudBody += "|TipoAnestesia=NA"
            }
            if(fecha_nacimiento){
                solicitudBody += "|FechaNacimientoPaciente=" + fecha_nacimiento
            }
            else{
                solicitudBody += "|FechaNacimientoPaciente=NA"
            }
            if(edad){
                solicitudBody += "|EdadPaciente=" + edad
            }
            else{
                solicitudBody += "|EdadPaciente=NA"
            }
            if(sexo){
                solicitudBody += "|SexoPaciente=" + sexo
            }
            else{
                solicitudBody += "|SexoPaciente=NA"
            }
            if(tipoCliente){
                solicitudBody += "|TipoCliente=" + tipoCliente
            }
            else{
                solicitudBody += "|TipoCliente=NA"
            }
            if(diagnostico){
                solicitudBody += "|Diagnostico=" + diagnostico
            }
            else{
                solicitudBody += "|Diagnostico=NA"
            }
            solicitudBody += "|Solicitado=" + userName
            solicitudBody += "|Concepto=" + motivo 
            solicitudBody += "|EstudiosTransoperatorios=" + estudios
            solicitudBody += "|TipoClienteOpcion=" + opcionCliente
            if(tipoAnestesiologo){
                solicitudBody += "|TipoAnestesiologo=" + tipoAnestesiologo
            }
            else{
                solicitudBody += "|TipoAnestesiologo=NA"
            }
            solicitudBody += "|EquipoEspecial=" + equipoEspecial
            solicitudBody += "|Hemoderivados=" + hemoderivados
            let date = new Date()
            
            if(fecha_seleccionada === eventActual.fecha){
                let horaUno = ""
                if(eventActual.horaInicio.split(":")[1] === "30"){
                    horaUno = (parseInt(eventActual.horaInicio.split(":")[0]) + 1).toString().padStart(2,"0") + ":00"
                }
                else{
                    horaUno = parseInt(eventActual.horaInicio.split(":")[0]) + ":30"
                }

                let horaDos = ""
                if(eventActual.horaFinal.split(":")[1] !== "30"){
                    horaDos = (parseInt(eventActual.horaFinal.split(":")[0]) - 1).toString().padStart(2,"0") + ":30"
                }
                else{
                    horaDos = parseInt(eventActual.horaFinal.split(":")[0]) + ":00"
                }

                if(horaUno === horaInicialSelected && horaDos === horaFinalSelected){
                    solicitudBody += "|TipoCambio=Misma fecha y horario"
                }
                else{
                    solicitudBody += "|TipoCambio=Misma fecha otra hora"
                }
            }
            else{
                solicitudBody += "|TipoCambio=Reprogramación a otra fecha"
            }

            solicitarEvento()

            /**
             * Función asyncrona que solicita la actualización de un evento
             */
            async function solicitarEvento(){
                const response = await createUpdateCalendarLog(
                    eventoSeleccionado.id,
                    "update",
                    "confirm",
                    eventoSeleccionado,
                    solicitudBody,
                    fecha_seleccionada + "T" + pruebaHoraInicial,
                    fechaFinal,
                    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                    date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":"+date.getMilliseconds(),
                    eventoSeleccionado.id_evento,
                    eventActual.fecha,
                    eventActual.horaInicio,
                    eventActual.horaFinal
                )

                if(response === "success"){//Registro de evento exitoso
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualización de evento',
                        text: 'Actualización de reservación de quirófano exitosa',
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#04afaa",
                      }).then((result) => {
                        if (result.isConfirmed) {
                            //Se solicita el registro del evento
                            setCedula("")
                            setTelefono("")
                            setNombrePaciente("")
                            setTelefonoPaciente("")
                            setDiagnostico("")
                            setProcedimiento("")
                            setEquipoQuirurgico("")
                            setExtras("")
                            setEstudios("")
                            setIsMostrarEditar(false)
                        }
                    })
                }
                else{//Error en el registro de un evento
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response,
                      })
                }
            }
            
        }
        catch(error){//Error inesperado
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                button: "Aceptar",
            });
        }
    }

    /**
     * Funcón que atrapa la selección de una unidad hospitalaria
     * @param {String} value Recibe el valor del objeto seleccionado
     */
    const handleBuildChange = (value) => {
        setBuildingSelected(value)
    };

    /**
     * Función que atrapa el evento click de cancelar y dispara el cierre del modal
     * @returns Devuelve el estado del objeto
     */
    const handleCancelarClick = () => (event) => {
        event.preventDefault()
        closeModal()
    }

    /**
     * Función que atrapa la selección de la hora inicial de la cirugía 
     * @param {String} value Recibe el valor de la hora seleccionada
     */
    const handleHoraChange = (value) => {
        //Se limpiar la ayuda al usuario si se contaba con error de hora inicial requerida
        if(horaInicialHadError){
            setMessageError("")
        }
        setHoraInicialHadError(false)

        //Se asigna la hora inicial y se oculta la lista de horas iniciales disponibles
        setHoraInicialSelected(value)
    };

    /**
     * Función que atrapa la selección de la hora final de la cirugía
     * @param {String} value Recibe el valor de la hora seleccionada
     */
    const handleHoraFinalChange = (value) => {
        //Se limpiar la ayuda al usuario si se contaba con error de hora inicial requerida
        if(horaFinalHadError){
            setMessageError("")
        }
        setHoraFinalHadError(false)
        //Se asigna la hora inicial y se oculta la lista de horas iniciales disponibles
        setHoraFinalSelected(value)
    };

    /**
     * Función que atrapa el evento clic para solicitar el registro de un evento
     * @returns Devuelve el estado del objeto
     */
     const handleReservaClick = () => (event) => {
        event.preventDefault()
        if(buildingSelected !== "-- Seleccione la unidad hospitalaria --"){//Se verifica si se ha seleccionado una unidad hospitalaria valida
            setBuildHadError(false)
            if(resourcesSelected !== "-- Seleccione el quirófano deseado --"){//Se verifica si se ha seleccionado un quirófano valido
                setResourceHadError(false)
                if(fecha_seleccionada){//Se verifica si se ha seleccionado una fecha valida
                    let encontrado = false
                    let horaInicialValida = false
                        let uno = horaInicialSelected.toString().split(':')
                        for(let j=0;j<horasReservadas.length;j++){
                            if(parseInt(uno[0]) === parseInt(horasReservadas[j].Hora) 
                                && horasReservadas[j].Recurso === resourcesSelected
                                && horasReservadas[j].Unidad === buildingSelected){
                                encontrado = true
                            }
                        }
                        if(encontrado){
                            if(resourcesSelected !== actualResource){
                                horaInicialValida = false
                            }
                            else{
                                horaInicialValida = true
                            }
                        }
                        else{
                            horaInicialValida = true
                        }
                    if(horaInicialSelected !== "-- Seleccione la hora inicial --"){//Se verifica si se ha seleccionado una hora inicial valida
                        if(horaInicialValida){
                            encontrado = false
                            let horaFinalValida = false
                            let dos = horaFinalSelected.toString().split(':')
                            for(let j=0;j<horasReservadas.length;j++){
                                if(parseInt(dos[0]) === parseInt(horasReservadas[j].Hora) 
                                    && horasReservadas[j].Recurso === resourcesSelected
                                    && horasReservadas[j].Unidad === buildingSelected){
                                    if(horasReservadas[j].Identificador !== "Inicio"){
                                        encontrado = true
                                    }
                                    else{
                                        if(parseInt(horasReservadas[j].Hora) > parseInt(horaInicialSelected.split(':')[0])){
                                            // horasValidas.push(horasFinalArray[i])
                                            // i = horasFinalArray.length
                                        }
                                    }
                                    //j = horasReservadas.length
                                }
                            }
                            if(encontrado){
                                if(resourcesSelected !== actualResource){
                                    horaFinalValida = false
                                }
                                else{
                                    horaFinalValida = true
                                }
                            }
                            else{
                                horaFinalValida = true
                            }
                            setHoraInicialHadError(false)
                            if(horaFinalSelected !== "-- Seleccione la hora final --"){//Se verifica si se ha seleccionado una hora final valida
                                if(horaFinalValida){
                                    setHoraFinalHadError(false)
                                    if(cedula !== ""){
                                        setCedulaHadError(false)
                                        if(nombrePaciente !== ""){
                                            setNombrePacienteHadError(false)
                                            if(procedimiento !== ""){
                                                setProcedimientoHadError(false)
                                                if(equipoQuirirgico !== ""){
                                                    setEquipoQuirirgicoHadError(false)
                                                    if(telefono.length === 10 || isUgencia){
                                                        setTelefonoHadError(false)
                                                        if(telefonoPaciente.length === 10 || true){
                                                            setMessageError("")
                                                            //Se muestra dialogo de confirmación con los detalles de la reservación
                                                            let fechaInicial = fecha_seleccionada.toString().split('-')
                                                            
                                                            let horaInicialSeparada = horaInicialSelected.toString().split(':')
                                                            let horaFinalSeparada = horaFinalSelected.toString().split(':')
                                                            let fechaFinal = fecha_seleccionada + "T" + horaFinalSelected
                                                            
                                                            let mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " a " + horaFinalSelected + " con fecha " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0]
                                                            mensaje += " para el paciente " + nombrePaciente

                                                            if(parseInt(horaFinalSeparada[0]) < parseInt(horaInicialSeparada[0])){//En caso de que la hora final sea menor que la inicial se agrega un día para indicar que se trata del día siguiente
                                                                let fechaSeparada = fecha_seleccionada.split('-')
                                                                let diaNuevo = parseInt(fechaSeparada[2]) + 1
                                                                fechaFinal = diaNuevo + "-" + fechaSeparada[1] + "-" + fechaSeparada[0]
                                                                mensaje = buildingSelected + " en " + resourcesSelected + " de " + horaInicialSelected + " del " + fechaInicial[2] + "-" + fechaInicial[1] + "-" + fechaInicial[0] + " a " + horaFinalSelected + " del " + fechaFinal
                                                                mensaje += " para el paciente " + nombrePaciente
                                                            }
                                                            
                                                            Swal.fire({
                                                                title: "Se actualizará la reservación de quirófano para:",
                                                                text: mensaje,
                                                                showDenyButton: false,
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#14988f',
                                                                confirmButtonText: 'Aceptar',
                                                                denyButtonText: `Cancelar`,
                                                                cancelButtonText:`Cancelar`,
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    let cambiaronLasFechas = false
                                                                    if(fecha_seleccionada === eventActual.fecha){
                                                                        let horaUno = ""
                                                                        if(eventActual.horaInicio.split(":")[1] === "30"){
                                                                            horaUno = (parseInt(eventActual.horaInicio.split(":")[0]) + 1).toString().padStart(2,"0") + ":00"
                                                                        }
                                                                        else{
                                                                            horaUno = parseInt(eventActual.horaInicio.split(":")[0]) + ":30"
                                                                        }
                                                        
                                                                        let horaDos = ""
                                                                        if(eventActual.horaFinal.split(":")[1] !== "30"){
                                                                            horaDos = (parseInt(eventActual.horaFinal.split(":")[0]) - 1).toString().padStart(2,"0") + ":30"
                                                                        }
                                                                        else{
                                                                            horaDos = parseInt(eventActual.horaFinal.split(":")[0]) + ":00"
                                                                        }
                                                        
                                                                        if(horaUno === horaInicialSelected && horaDos === horaFinalSelected){
                                                                            cambiaronLasFechas = false
                                                                        }
                                                                        else{
                                                                            cambiaronLasFechas = true
                                                                        }
                                                                    }
                                                                    else{
                                                                        cambiaronLasFechas = true
                                                                    }



                                                                    //Se solicita el registro del evento
                                                                    if(buildingSelected === eventActual.unidad &&
                                                                        resourcesSelected === eventActual.quirofano &&
                                                                        !cambiaronLasFechas){
                                                                            if(isUgencia){
                                                                                getReserve("Cirugía de urgencia: Complementar información")
                                                                                
                                                                            }
                                                                            else{
                                                                                getReserve("Complementar información")
                                                                                
                                                                            }
                                                                            
                                                                    }
                                                                    else{
                                                                        let motivoOpciones = ""
                                                                        motivoOpciones+="<option value='cero' selected>Seleccione el motivo de la reasignación</option>"
                                                                        motivoOpciones+="<option value='Agenda Limitada'>Agenda Limitada</option>"
                                                                        motivoOpciones+="<option value='Solicitud Paciente'>Solicitud Paciente</option>"
                                                                        motivoOpciones+="<option value='Solicitud Médica'>Solicitud Médica</option>"
                                                                        motivoOpciones+="<option value='Aseguradora'>Aseguradora</option>"
                                                                        motivoOpciones+="<option value='Motivos internos'>Motivos internos</option>"
                                                                        Swal.fire({
                                                                            icon: "warning",
                                                                            text: "Favor de ingresar el motivo de la reasignación",
                                                                            html:`<div class="form_wrapper">
                                                                                    <div class="form_container">
                                                                                        <div class="row clearfix">
                                                                                            <div class="">
                                                                                                <form autocomplete="off" method="post">
                                                                                                    <h2 class="modalTitle">Motivo de la reasignación</h2>
                                                                                                    <br />
                                                                                                    <div class="input_field select_option">
                                                                                                        <select id="selectMotivo">
                                                                                                            `+motivoOpciones+`
                                                                                                        </select>
                                                                                                        <div class="select_arrow"></div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>`,
                                                                            showCancelButton: true,
                                                                            confirmButtonText: "Guardar",
                                                                            cancelButtonText: "Cancelar",
                                                                            confirmButtonColor: "#04afaa",
                                                                            allowOutsideClick:false,
                                                                            width:'500px',
                                                                            heightAuto: false,
                                                                            focusConfirm: false,
                                                                            customClass: 'swal-confirm',
                                                                            preConfirm: () => {
                                                                                if(document.getElementById('selectMotivo').value !== "cero"){
                                                                                    if(document.getElementById('selectMotivo').value !== "Motivos internos"){
                                                                                        if(isUgencia){
                                                                                            
                                                                                            getReserve("Cirugía de urgencia: Reasignación por " + document.getElementById('selectMotivo').value)
                                                                                        }
                                                                                        else{
                                                                                            
                                                                                            getReserve("Reasignación por " + document.getElementById('selectMotivo').value)
                                                                                        }
                                                                                        
                                                                                    }
                                                                                    else{
                                                                                        let modalBody = ""
                                                                                        modalBody += "<h2 class=info_itemconfirmTitle>Favor de ingresar el motivo de la reasignación interna:</h2>"
                                                                                        Swal.fire({
                                                                                            html:modalBody,
                                                                                            //title: "Favor de ingresar el número de atención del paciente:",
                                                                                            text: "",
                                                                                            input: "text",
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: "Guardar",
                                                                                            cancelButtonText: "Cancelar",
                                                                                            confirmButtonColor: "#04afaa",
                                                                                            allowOutsideClick:false,
                                                                                            customClass: 'swal-confirm',
                                                                                            preConfirm: (text) => {
                                                                                              return new Promise((resolve) => {
                                                                                                if (!text) {
                                                                                                  Swal.showValidationMessage('No ha proporcionado el motivo de reasignación interna')
                                                                                                }
                                                                                                resolve()
                                                                                              })
                                                                                            },
                                                                                          })
                                                                                          .then(resultado => {
                                                                                            if (resultado.value) {
                                                                                                if(isUgencia){
                                                                                                    
                                                                                                    getReserve("Cirugía de urgencia: Reasignación por Motivos internos: " + resultado.value)
                                                                                                }
                                                                                                else{
                                                                                                    
                                                                                                    getReserve("Reasignación por Motivos internos: " + resultado.value)
                                                                                                }
                                                                                                
                                                                                            }
                                                                                          });
                                                                                    }
                                                                                    
                                                                                }
                                                                                else{
                                                                                    Swal.showValidationMessage('Seleccione el motivo de la reasignación')   
                                                                                } 
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        else{
                                                            setTelefonoPacienteHadError(false)
                                                            setMessageError("Favor de ingresar el teléfono de contacto del paciente, está información se utilizará para contactar al paciente y realizar el pre-registro, con el objetivo de tener un ingreso ágil")
                                                        }
                                                    }
                                                    else{
                                                        setTelefonoHadError(true)
                                                        setMessageError("Favor de ingresar el teléfono de contacto del médico tratante")
                                                    }
                                                }
                                                else{
                                                    setEquipoQuirirgicoHadError(true)
                                                    setMessageError("Favor de ingresar el equipo quirúrgico requerido")
                                                }
                                            }
                                            else{
                                                setProcedimientoHadError(true)
                                                setMessageError("Favor de ingresar el procedimiento a realizar")
                                            }
                                        }
                                        else{
                                            setNombrePacienteHadError(true)
                                            setMessageError("Favor de ingresar el nombre del paciente")
                                        }
                                    }
                                    else{
                                        setCedulaHadError(true)
                                        setMessageError("Favor de ingresar la cedula profesional del médico tratante")
                                    }
                                }
                                else{
                                    setHoraFinalHadError(true)
                                    setMessageError("La hora final de la cirugía se encuentra reservada")
                                }
                            }//aqui termina el if de la hora final
                            else{//Se muestra el error de hora final requerida
                                setHoraFinalHadError(true)
                                setMessageError("Favor de seleccionar la hora final de la cirugía")
                            }
                        }
                        else{
                            setHoraInicialHadError(true)
                            setMessageError("La hora inicial de la cirugía se encuentra reservada")
                        }
                    }
                    else{//Se muestra el error de hora inicial requerida
                        setHoraInicialHadError(true)
                        setMessageError("Favor de seleccionar la hora inicial de la cirugía")
                    }
                }
                else{//Se muestra el error de fecha requerida
                    setMessageError("Favor de seleccionar la fecha de la cirugía")
                }
            }
            else{//Se muestra el error de quirófano requerido
                setResourceHadError(true)
                setMessageError("Favor de seleccionar el Quirófano deseado")
            }
        }
        else{//Se muestra el error de unidad hospitalaria requerida
            setBuildHadError(true)
            setMessageError("Favor de seleccionar la Unidad Hospitalaria")
        }
    }

    /**
     * Función que atrapa cuando se ha seleccionado una sala de cirugía
     * @param {String} value Recibe el valor del objeto seleccionado
     */
    const handleResourceChange = (value) => {
        setResourcesSelected(value)
    };

    /**
     * Función que atrapa cuando se pierde la selección en el campo teléfono y oculta el tooltip
     */
    const onBlurTelefono = () => {
        setIsToolTipVisible(false)
    };

    /**
     * Función que atrapa el ingreso de la cedula profesional
     * @param {Event} cedula Recibe el evento disparado en la intruducción de texto
     */
     const onChangeCedula = (cedula) => {
        setCedula(cedula.target.value.toString().toUpperCase())
    };

    /**
     * Función que atrapa la selección de la fecha de cirugía
     * @param {Event} date Recibe el evento disparado en la selección de fecha
     */
     const onChangeDate = (date) => {
        setFechaSeleccionada(date.target.value)
    };

    /**
     * Función que atrapa el ingreso del diagnostico del cliente
     * @param {Event}diagnostico Recibe el evento disparado en la intruducción de texto
     */
     const onChangeDiagnostico = (diagnostico) => {
        setDiagnostico(diagnostico.target.value)
    };

    /**
     * Función que atrapa el ingreso de la edad del paciente
     * @param {Event} edad Recibe el evento disparado en la intruducción de texto
     */
     const onChangeEdad = (edad) => {
        setEdad(edad.target.value)
    };

    /**
     * Función que atrapa el ingreso del equipo quirurgico necesario
     * @param {Event} equipo Recibe el evento disparado en la intruducción de texto
     */
     const onChangeEquipoQuirurgico = (equipo) => {
        setEquipoQuirurgico(equipo.target.value)
    };

    /**
     * Función que atrapa el ingreso de requerimientos extras
     * @param {Event} extras Recibe el evento disparado en la intruducción de texto
     */
     const onChangeExtras = (extras) => {
        setExtras(extras.target.value)
    };

    const onChangeEstudios = (extras) => {
        setEstudios(extras.target.value)
    };

    /**
     * Función que atrapa el ingreso del lado del procedimiento a realizar
     * @param {Event} ladoProcedimiento Recibe el evento disparado en la intruducción de texto
     */
     const onChangeLadoProcedimiento = (ladoProcedimiento) => {
        setLadoProcedimiento(ladoProcedimiento.target.value)
    };

    /**
     * Función que atrapa la selección de la fecha de cirugía
     * @param {Event} date Recibe el evento disparado en la selección de fecha
     */
     const onChangeNacimientoDate = (date) => {
        setFechaNacimiento(date.target.value)
    };

    /**
     * Función que atrapa el ingreso del nombre del anestesiólogo
     * @param {Event} nombre Recibe el evento disparado en la intruducción de texto
     */
     const onChangeNombreAnestesiologo = (nombre) => {
        setNombreAnestesiologo(nombre.target.value)
    };

    /**
     * Función que atrapa el ingreso del nombre del paciente
     * @param {Event} nombre Recibe el evento disparado en la intruducción de texto
     */
     const onChangeNombrePaciente = (cedula) => {
        setNombrePaciente(cedula.target.value)
    };

    /**
     * Función que atrapa el ingreso del procedimiento a realizar
     * @param {Event} procedimiento Recibe el evento disparado en la intruducción de texto
     */
     const onChangeProcedimiento = (procedimiento) => {
        setProcedimiento(procedimiento.target.value)
    };

    /**
     * Función que atrapa la selección de un genero
     * @param {Event} sexo Recibe el evento disparado en la selección
     */
    const onChangeSexo = (sexo) => {
        setSexo(sexo.target.value)
    };

    /**
     * Función que atrapa el ingreso de la cedula profesional
     * @param {Event} telefono Recibe el evento disparado en la intruducción de texto
     */
     const onChangeTelefono = (telefono) => {
        setTelefono(telefono.target.value)
    };

    /**
     * Función que atrapa el ingreso de la cedula profesional
     * @param {Event} telefono Recibe el evento disparado en la intruducción de texto
     */
     const onChangeTelefonoPaciente = (telefono) => {
        setTelefonoPaciente(telefono.target.value)
    };

    /**
     * Función que atrapa el ingreso del tipo de anestesía
     * @param {Event} tipo Recibe el evento disparado en la intruducción de texto
     */
     const onChangeTipoAnestesia = (tipo) => {
        setTipoAnestesia(tipo.target.value)
    };

    /**
     * Función que atrapa el ingreso del tipo de cliente
     * @param {Event} tipo Recibe el evento disparado en la intruducción de texto
     */
     const onChangeTipoCliente = (tipo) => {
        setTipoCliente(tipo.target.value)
    };

    /**
     * Función que atrapa cuando se tiene seleccionado el campo teléfono y muestra el tooltip
     */
    const onFocusTelefono = () => {
        setIsToolTipVisible(true)
    };

    const onChangeEquipoEspecial = (equipo) => {
        setEquipoEspecial(equipo.target.value)
    };

    useEffect(()=>{
        if(equipoEspecial !== ""){
            if(equipoEspecialHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setEquipoEspecialHadError(false)
        }
    },[equipoEspecial,equipoEspecialHadError])

    const onChangeHemoderivados = (equipo) => {
        setHemoderivados(equipo.target.value)
    };

    useEffect(()=>{
        if(hemoderivados !== ""){
            if(hemoderivadosHadError){//Si se habia indicado error en el equipo quirúrgico se limpia el mensaje de ayuda y el respectivo indicador
                setMessageError("")
            }
            setHemoderivadosHadError(false)
        }
    },[hemoderivados,hemoderivadosHadError])

    useEffect(()=>{
        if(opcionCliente){
            if(opcionCliente === "Gobierno"){
                setIsOpcionUno(true)
                setIsOpcionDos(false)
                setIsOpcionTres(false)
                setIsOpcionCuatro(false)
            }
            else{
                if(opcionCliente === "Privado"){
                    setIsOpcionUno(false)
                    setIsOpcionDos(true)
                    setIsOpcionTres(false)
                    setIsOpcionCuatro(false)
                }
                else{
                    if(opcionCliente === "Aseguradora"){
                        setIsOpcionUno(false)
                        setIsOpcionDos(false)
                        setIsOpcionTres(true)
                        setIsOpcionCuatro(false)
                    }
                    else{
                        if(opcionCliente === "Empresa"){
                            setIsOpcionUno(false)
                            setIsOpcionDos(false)
                            setIsOpcionTres(false)
                            setIsOpcionCuatro(true)
                        }
                        else{
                            setIsOpcionUno(false)
                            setIsOpcionDos(false)
                            setIsOpcionTres(false)
                            setIsOpcionCuatro(false)
                        }
                    }
                }
            }
        }
        else{
            setIsOpcionUno(false)
            setIsOpcionDos(false)
            setIsOpcionTres(false)
            setIsOpcionCuatro(false)
        }
    },[opcionCliente])

    useEffect(()=>{
        if(tipoAnestesiologo){
            if(tipoAnestesiologo === "Externo"){
                setIsAnestesiologoOpcionUno(false)
                setIsAnestesiologoOpcionDos(true)
            }
            else{
                if(tipoAnestesiologo === "Staff"){
                    setIsAnestesiologoOpcionUno(true)
                    setIsAnestesiologoOpcionDos(false)
                }
                else{
                    setIsAnestesiologoOpcionUno(false)
                    setIsAnestesiologoOpcionDos(false)
                }
            }
        }
        else{
            setIsAnestesiologoOpcionUno(false)
            setIsAnestesiologoOpcionDos(false)
        }
    },[tipoAnestesiologo])

    const onChangeTypeCliente = (sexo) => {
        setOpcionCliente(sexo.target.value)

    };

    const onChangeTipoAnestesiologo = (sexo) => {
        setTipoAnestesiologo(sexo.target.value)
    };

    return(
        <div className="reserve_form">
            <p className="reserve_title">Editar Cirugía Programada  <span onClick={handleCancelarClick()}><BsXSquare /></span></p>
                    <div className={buildHadError ? "contenedorError":"contenedor"} id="contenedorBuilding">
                        <div className="contenedorEncabezado">
                            ¿Dónde quiere reservar?<span className="requerido">*</span>
                        </div>
                        <div className="contenido">
                            <select value={buildingSelected} 
                                onChange={(e)=>handleBuildChange(e.target.value)} 
                                className="datos">
                                {
                                    buildingList.map(
                                        build => <option key={build.id} value={build.Building_Id}>{build.Building_Id}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className={resourceHadError ? "contenedorError":"contenedor"} id="contenedorResources">
                        <div className="contenedorEncabezado">
                            En el quirófano<span className="requerido">*</span>
                        </div>
                        <div className="contenido">
                            <select value={resourcesSelected} onChange={(e)=>handleResourceChange(e.target.value)} className="datos">
                                {
                                    resourcesList.map(
                                        resource => <option key={resource.id} value={resource.Resource_Name}>{resource.Resource_Name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className={cedulaHadError ? "contenedorError":"contenedor"} id="contenedorMedico">
                        <div className="contenedorEncabezado">
                            Cédula ó RFC del médico tratante<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="cedula" 
                            name="cedula" 
                            className="datos"
                            value={cedula}
                            onChange={onChangeCedula}
                            readOnly={true}/>
                    </div>
                    {
                        !isUgencia
                            ?
                                <div className={telefonoHadError ? "contenedorError":"contenedor"} id="contenedorTelefonoMedico">
                                    <div className="contenedorEncabezado">
                                        Teléfono de contacto del médico tratante<span className="requerido">*</span>
                                    </div>
                                    <input 
                                        type={"text"} 
                                        id="telefono" 
                                        name="telefono" 
                                        className="datos"
                                        value={telefono}
                                        onChange={onChangeTelefono}
                                        readOnly={true}/>
                                </div>
                            :
                                null
                    }
                    
                    <div className={nombrePaciennteHadError ? "contenedorError":"contenedor"} id="contenedorNombrePaciente">
                        <div className="contenedorEncabezado">
                            Nombre del paciente<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="nombrePaciente" 
                            name="nombrePaciente" 
                            className="datos"
                            value={nombrePaciente}
                            onChange={onChangeNombrePaciente}
                            readOnly={true}/>
                    </div>
                    <div className="tooltip-one" id="telefonoToolTip">Está información se utilizará para contactar al paciente y realizar el pre-registro, con el objetivo de tener un ingreso ágil</div>
                    {
                        !isUgencia
                            ?
                                <div className={telefonoPacienteHadError ? "contenedorError":"contenedor"} id="contenedorTelefonoPaciente">
                                    <div className="contenedorEncabezado">
                                        Teléfono de contacto del paciente<span className="requerido">*</span>
                                    </div>
                                    <input 
                                        type={"text"} 
                                        id="telefonoPaciente" 
                                        name="telefonoPaciente" 
                                        className="datos"
                                        value={telefonoPaciente}
                                        onChange={onChangeTelefonoPaciente}
                                        onFocus={onFocusTelefono}
                                        onBlur={onBlurTelefono}
                                        readOnly={true}/>
                                </div>
                            :
                                null
                    }
                    <div className="contenedor" id="contenedorFechaNacimiento">
                        <div className="contenedorEncabezado">
                            Fecha de nacimiento
                        </div>
                        <input 
                            type={"date"} 
                            id="fecha_nacimiento" 
                            name="fecha_nacimiento" 
                            className="fecha_seleccionada"
                            value={fecha_nacimiento}
                            max={today}
                            onChange={onChangeNacimientoDate}/>
                    </div>
                    <div className="contenedor" id="contenedorEdad">
                        <div className="contenedorEncabezado">
                            Edad del paciente
                        </div>
                        <input 
                            type={"text"} 
                            id="edad" 
                            name="edad" 
                            className="datos"
                            value={edad}
                            onChange={onChangeEdad}/>
                    </div>
                    <div className="contenedor" id="contenedorSexo">
                        <div className="contenedorEncabezado">
                            Sexo del paciente
                        </div>
                        <div className="datos">
                            <input type="radio" value="Masculino" name="gender" checked={sexo === "Masculino"} onChange={onChangeSexo}/> Masculino
                            <input type="radio" value="Femenino" name="gender" checked={sexo === "Femenino"} onChange={onChangeSexo}/> Femenino
                        </div>
                    </div>
                    <div className="contenedor" id="contenedorTipoCliente">
                        <div className="contenedorEncabezado">
                            Tipo de cliente
                        </div>
                        <div onChange={onChangeTypeCliente} className="datos">
                            <input type="radio" value="Gobierno" name="clienteType" checked={isOpcionUno}/> Gobierno
                            <input type="radio" value="Privado" name="clienteType" checked={isOpcionDos}/> Privado
                            <br/>
                            <input type="radio" value="Aseguradora" name="clienteType" checked={isOpcionTres}/> Aseguradora
                            <input type="radio" value="Empresa" name="clienteType" checked={isOpcionCuatro}/> Empresa
                        </div>
                        <input 
                            type={"text"} 
                            id="tipoCliente" 
                            name="tipoCliente" 
                            className="datos"
                            value={tipoCliente}
                            onChange={onChangeTipoCliente}/>
                    </div>
                    {
                        !isUgencia
                            ?
                                <div className="contenedor" id="contenedorDiagnostico">
                                    <div className="contenedorEncabezado">
                                        Diagnóstico del paciente
                                    </div>
                                    <input 
                                        type={"text"} 
                                        id="diagnostico" 
                                        name="diagnostico" 
                                        className="datos"
                                        value={diagnostico}
                                        onChange={onChangeDiagnostico}/>
                                </div>
                            :
                                null
                    }
                    <div className={procedimientoHadError ? "contenedorError":"contenedor"} id="contenedorProcedimiento">
                        <div className="contenedorEncabezado">
                            Procedimiento a realizar<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"text"} 
                            id="procedimiento" 
                            name="procedimiento" 
                            className="datos"
                            value={procedimiento}
                            onChange={onChangeProcedimiento}
                            readOnly={true}/>
                    </div>
                    <div className="contenedor" id="contenedorLadoProcedimiento">
                        <div className="contenedorEncabezado">
                            Lado del procedimiento (si aplica)
                        </div>
                        <input 
                            type={"text"} 
                            id="ladoProcedimiento" 
                            name="ladoProcedimiento" 
                            className="datos"
                            value={ladoProcedimiento}
                            onChange={onChangeLadoProcedimiento}/>
                    </div>
                    <div className="contenedor" id="contenedorNombreAnestesiologo">
                        <div onChange={onChangeTipoAnestesiologo} className="datos">
                            <input type="radio" value="Staff" name="anestesiologo" checked={isAnestsiologoOpcionUno}/> Anestesiólogo Staff
                            <br/>
                            <input type="radio" value="Externo" name="anestesiologo" checked={isAnestesiologoOpcionDos}/> Anestesiólogo Externo
                        </div>
                        <div className="contenedorEncabezado">
                            Nombre del anestesiólogo
                        </div>
                        <input 
                            type={"text"} 
                            id="nombreAnestesiologo" 
                            name="nombreAnestesiologo" 
                            className="datos"
                            value={nombreAnestesiologo}
                            onChange={onChangeNombreAnestesiologo}/>
                    </div>
                    <div className="contenedor" id="contenedorTipoAnestesia">
                        <div className="contenedorEncabezado">
                            Tipo de anestesia
                        </div>
                        <input 
                            type={"text"} 
                            id="tipoAnestesia" 
                            name="tipoAnestesia" 
                            className="datos"
                            value={tipoAnestesia}
                            onChange={onChangeTipoAnestesia}/>
                    </div>
                    <div className="contenedor" id="contenedorFecha">
                        <div className="contenedorEncabezado">
                            Día de la cirugía<span className="requerido">*</span>
                        </div>
                        <input 
                            type={"date"} 
                            id="fecha_seleccionada" 
                            name="fecha_seleccionada" 
                            className="fecha_seleccionada"
                            value={fecha_seleccionada}
                            min={today}
                            onChange={onChangeDate}/>
                    </div>
                    <div className={horaInicialHadError ? "contenedorError":"contenedor"} id="contenedorHoraInicial">
                        <div className="contenedorEncabezado">
                            Hora inicial<span className="requerido">*</span>
                        </div>
                        <div className="contenido">
                            <select value={horaInicialSelected} onChange={(e)=>handleHoraChange(e.target.value)} className="datos">
                                {
                                    horasInicialesDisponibles.map(
                                        hora => <option key={hora.hora + ":" + hora.minutos} value={hora.hora + ":" + hora.minutos}>{hora.hora + ":" + hora.minutos}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className={horaFinalHadError ? "contenedorError":"contenedor"} id="contenedorHoraFinal">
                        <div className="contenedorEncabezado">
                            Hora Final<span className="requerido">*</span>
                        </div>
                        <div className="contenido">
                            <select value={horaFinalSelected} onChange={(e)=>handleHoraFinalChange(e.target.value)} className="datos" id="horaFinalSelec">
                                {
                                    horasFinalesDisponibles.map(
                                        hora => <option key={hora.hora + ":" + hora.minutos} value={hora.hora + ":" + hora.minutos}>{hora.hora + ":" + hora.minutos}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>        
                    {
                        !isUgencia
                            ?
                                <>
                                    <div className={equipoQuirirgicoHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                                        <div className="contenedorEncabezado">
                                            Materiales y medicamentos<span className="requerido">*</span>
                                        </div>
                                        <textarea
                                            className="datos"
                                            id="equipoQuirirgico" 
                                            name="equipoQuirirgico"
                                            value={equipoQuirirgico}
                                            onChange={onChangeEquipoQuirurgico}
                                        />
                                    </div>
                                    <div className={equipoEspecialHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                                        <div className="contenedorEncabezado">
                                            Equipo especial<span className="requerido">*</span>
                                        </div>
                                        <textarea
                                            className="datos"
                                            id="equipoEspecial" 
                                            name="equipoEspecial"
                                            value={equipoEspecial}
                                            onChange={onChangeEquipoEspecial}
                                            rows="4"
                                        />
                                    </div>
                                    <div className={hemoderivadosHadError ? "contenedorError":"contenedor"} id="contenedorEquipoQuirigico">
                                        <div className="contenedorEncabezado">
                                            Hemoderivados<span className="requerido">*</span>
                                        </div>
                                        <textarea
                                            className="datos"
                                            id="hemoderivados" 
                                            name="hemoderivados"
                                            value={hemoderivados}
                                            onChange={onChangeHemoderivados}
                                            rows="4"
                                        />
                                    </div>
                                    <div className={"contenedor"} id="contenedorExtras">
                                        <div className="contenedorEncabezado">
                                            Estudios transoperatorios
                                        </div>
                                        <textarea
                                                        className="datos"
                                                        id="estudios" 
                                                        name="estudios" 
                                                        value={estudios}
                                                        onChange={onChangeEstudios}
                                                    />
                                    </div>
                                    <div className={"contenedor"} id="contenedorExtras">
                                        <div className="contenedorEncabezado">
                                            ¿Requerimientos extras?
                                        </div>
                                        <textarea
                                                        className="datos"
                                                        id="extras" 
                                                        name="extras" 
                                                        value={extras}
                                                        onChange={onChangeExtras}
                                                    />
                                    </div>
                                </>
                            :
                                null
                    }
                    
                    <p className='fail' id="fail">{messageError}</p>
                    {/* <p className='boton_uno' id='enviar' onClick={handleReservaClick()}>Actualizar</p> */}
                    {/* <p className='boton_uno' id='enviar' onClick={handleCancelarClick()}>Cancelar</p> */}
                    <p className='boton_actualizar' onClick={handleReservaClick()}><MdOutlineEditCalendar style={styleConfirm}/> Actualizar</p>
                    <p className='boton_cancelarEdicion' onClick={handleCancelarClick()}><MdOutlineDoDisturb style={styleConfirm}/> Cerrar</p>
        </div>
    )
}

export default EditarEvento