//Importación SDK firebase
import firebase from 'firebase/compat/app';
//Importación del servicio de firestore
import 'firebase/compat/firestore';

import {getAuth, signInWithEmailAndPassword  } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAI,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};
  
//Inicialización del servicio de firebase
firebase.initializeApp(firebaseConfig);

const auth = getAuth();

signInWithEmailAndPassword(auth, process.env.REACT_APP_FBACCOUNT, process.env.REACT_APP_FBPASS)
  .then((userCredential) => {
    // Signed in
    //const user = userCredential.user;

    //console.log(user)
    // ...
  })
  .catch((error) => {
    //const errorCode = error.code;
    //console.log(errorCode)
    //const errorMessage = error.message;
    //console.log(errorMessage)
  });

//Exportar el espacio de almacenamiento de base de datos firestore
export const pacientesStore = firebase.firestore();

//Exportar el identificador de la colección de soliitudes de calendario events
export const surgerycalendarrequest = 'surgerycalendarrequest'

//Exportar el identificador de la colección de eventos de calendario
export const surgerycalendarIemsCollection = 'surgerycalendaritems'

//Exportar el identificador de la colección de unidades hospitalarias
export const uhCollection = 'unidadesservicios'

//Exportar el identificador de la colección de building
export const buildingCollection = 'building'

//Exportar el identificador de la colección de resources
export const resourcesCollection = 'resources'

//Exportar el identificador de la colección de solicitud de actualización de evento
export const calendarLog = 'calendarlog'

//Exportar el identificador de la colección de usuarios
export const usersCollection = 'users'
//export const usersCollection = 'userstest'

//Exportar el identificador de la colección de médicos
export const doctordirectory = 'doctordirectory'

//Exportar el identificador de la colección de sesiones
export const sessions = 'sessions'

//Exportar el identificador de la coleccion de systemas
export const systems = 'systems'

//Exportar el identificador de la colección de ips
export const ipwhitelist = 'ipwhitelist'