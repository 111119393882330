/**
 * Librerías
 */
import React, { useEffect, useState } from "react";
import { IoCalendarSharp } from "react-icons/io5";
import { HiSave } from "react-icons/hi";
import { CSVLink } from "react-csv";
import { CgCalendarNext } from "react-icons/cg";
//CgCalendarNext

/**
 * Styles
 */
import '../styles/encabezadoSeccion.css'
import '../styles/userTop.css'

/**
 * Componente que muestra el encabezado de la sección
 * @param {Object} param0 Recibe los elementos requeridos para el muestro de información del encabezado
 * @returns Component Devuelve el componente con los elementos requeridos
 */
const TituloSection = ({tituloInicial,tituloFinal,cantidad,imgSource,
    isMostrarRealizado,setIsMostrarRealizado,isMostrarEnProceso,setIsMostrarEnProceso,isMostrarEnPendiente,setIsMostrarEnPendiente,
    cantidadRealizada,cantidadProceso,cantidadPendiente,isMostrarQuirofanos,setIsMostrarQuirofanos,
    setIsMostrarUsuarios,userLevel,setIsMostrarEditar,exportedEventList,nomHospital,isMostrarUsuarios,
    cantidadCanceladas,setIsMostrarMedicos,isMostrarMedicos,tipoReporte,setIsMostrarAgendaVista,isMostrarAgendaVista,
    eventosEditados,setuserNomHospital,isCorporativo,hospitalesDisponibles,setIsMostrarAgendar,isMostrarAgendar,
    setIsMostrarIndicadores,isMostrarIndicadores}) => {

    //Estado que indica si el usuario logeado tiene permisos de administrador
    const [isAdmin,setIsAdmin] = useState(false)
    const [isAgendar, setIsAgendar] = useState(false)
    //Estado que indica si se puede exportar la agenda
    const [hadExport, setHadExport] = useState(false)
    const [hadEditExport, setHadEditExport] = useState(false)

    const styleActive = { color: "white", margin: "5px auto 5px", height: "25px", fontSize:"3.0em"}

    //Encabezado de las columnas que contendrá el reporte exportado
    const headers = [
        { label: "Fecha", key: "Fecha"},
        { label: "Inicio", key: "HoraInicio"},
        { label: "Fin", key: "HoraFin"},
        { label: "Duración", key: "Duracion"},
        { label: "Unidad Hospitalaria", key: "UnidadHospitalaria"},
        { label: "Sala", key: "Sala"},
        { label: "Paciente", key: "Paciente"},
        { label: "Teléfono Paciente", key: "TelefonoPaciente"},
        { label: "Sexo", key: "Sexo"},
        { label: "Cirujano", key: "Medico"},
        { label: "Teléfono Cirujano", key: "TelefonoMedico"},
        { label: "Estatus", key: "Estatus" },
        { label: "Procedimiento", key: "Procedimiento"},
        { label: "Lado", key: "LadoProcedimiento"},
        { label: "Anestesiólogo", key: "Anestesiologo"},
        { label: "Convenio", key: "TipoCliente"},
        { label: "Anestesia", key: "TipoAnestesia"},
        { label: "Edad", key: "Edad"},
        { label: "Fecha de nac.", key: "FechaNacimiento"},
        { label: "Diagnostico", key: "Diagnostico"},
        { label: "Materiales y medicamentos", key: "Materiales"},
        { label: "Materiales Especiales", key: "MaterialesEspeciales"},
        { label: "Hemoderivados", key: "Hemoderivados"},
        { label: "Requerimientos Extras", key: "Extras"},
        { label: "Ultima Solicitud", key: "UltimaSolicitud"},
        { label: "Ultima Solicitud", key: "UltimaSolicitud"},
        { label: "Tipo Reasignación", key: "TipoReasignacion"},
      ];

    const headersEventosEditados = [
        { label: "Fecha", key: "Fecha"},
        { label: "Inicio", key: "HoraInicio"},
        { label: "Fin", key: "HoraFin"},
        { label: "Duración", key: "Duracion"},
        { label: "Unidad Hospitalaria", key: "UnidadHospitalaria"},
        { label: "Sala", key: "Sala"},
        { label: "Paciente", key: "Paciente"},
        { label: "Teléfono Paciente", key: "TelefonoPaciente"},
        { label: "Sexo", key: "Sexo"},
        { label: "Cirujano", key: "Medico"},
        { label: "Teléfono Cirujano", key: "TelefonoMedico"},
        { label: "Estatus", key: "Estatus" },
        { label: "Procedimiento", key: "Procedimiento"},
        { label: "Lado", key: "LadoProcedimiento"},
        { label: "Anestesiólogo", key: "Anestesiologo"},
        { label: "Convenio", key: "TipoCliente"},
        { label: "Anestesia", key: "TipoAnestesia"},
        { label: "Edad", key: "Edad"},
        { label: "Fecha de nac.", key: "FechaNacimiento"},
        { label: "Diagnostico", key: "Diagnostico"},
        { label: "Materiales y medicamentos", key: "Materiales"},
        { label: "Materiales Especiales", key: "MaterialesEspeciales"},
        { label: "Hemoderivados", key: "Hemoderivados"},
        { label: "Requerimientos Extras", key: "Extras"},
        { label: "Ultima Solicitud", key: "UltimaSolicitud"},
        { label: "Ultima Solicitud", key: "UltimaSolicitud"},
        { label: "Tipo Reasignación", key: "TipoReasignacion"},
        { label: "Editado El", key: "fechaEdicionMostrar"},
        { label: "Fecha Anterior", key: "fechaAnterior"},
        { label: "Hora Inicia Anterior", key: "horaInicialAnterior"},
        { label: "Hora Final Anterior", key: "horaFinalAnterior"},
      ];
    
    //Definición del contenido del reporte a exportar en CSV
    const csvreport = {
        data: exportedEventList,
        headers: headers,
        filename: 'Agenda de quirófano ' + tipoReporte +' Hospital ' + nomHospital + ' ' + tituloInicial + '.csv',
    };

    const csveditreport = {
        data: eventosEditados,
        headers: headersEventosEditados,
        filename: 'Agenda de quirófano Eventos Editados Hospital ' + nomHospital + ' ' + tituloInicial + '.csv',
    };


    useEffect(()=>{
        if((cantidadPendiente + cantidadProceso + cantidadRealizada + cantidadCanceladas) > 0){
            if(isMostrarRealizado){
                if((cantidadRealizada + cantidadCanceladas) > 0){
                    setHadExport(true)
                }
                else{
                    setHadExport(false)
                }
            }
            else{
                if(isMostrarEnProceso){
                    if(cantidadProceso > 0){
                        setHadExport(true)
                    }
                    else{
                        if(exportedEventList.length > 0){
                            setHadExport(true)
                        }
                        else{
                            setHadExport(false)
                        }
                        
                    }
                }
                else{
                    if(isMostrarEnPendiente){
                        if(cantidadPendiente > 0){
                            setHadExport(true)
                        }
                        else{
                            setHadExport(false)
                        }
                    }
                    else{
                        setHadExport(true)
                    }
                }
            }
        }
        else{
            setHadExport(false)
        }

        if(exportedEventList.length > 0){
            setHadExport(true)
        }
        else{
            setHadExport(false)
        }
    },[cantidadPendiente,cantidadProceso,cantidadRealizada,cantidadCanceladas,isMostrarEnPendiente,isMostrarEnProceso,isMostrarRealizado,exportedEventList])

    useEffect(()=>{
        if(userLevel === "administrador" || userLevel === "superuser" || userLevel === "mesa"){//Se verifica si el usuario logeadoi es tipo admin o superuser
            setIsAdmin(true)
        }
        else{
            setIsAdmin(false)
        }

        if(userLevel === "agenda"){
            setIsAgendar(true)
        }
        else{
            setIsAgendar(false)
        }
    },[userLevel])

    useEffect(()=>{
        if(eventosEditados){
            if(eventosEditados.length > 0){
                setHadEditExport(true)
            }
            else{
                setHadEditExport(false)
            }
        }
        else{
            setHadEditExport(false)
        }
    },[eventosEditados])
    
    /**
     * Función que atrapa el evento click de la selección de menu
     * @param {Int16Array} opcion Recibe el número de opción seleccionado por el usuario
     * @returns Devuelve el estado del objeto
     */
     const handleMenuClick = (opcion) => (event) => {
        event.preventDefault()
        switch(opcion){
            case 1:
                sessionStorage.setItem('active','realizado')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(true)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 2:
                sessionStorage.setItem('active','proceso')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(true)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 3:
                sessionStorage.setItem('active','pendientes')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(true)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 4:
                sessionStorage.setItem('active','quirofanos')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(true)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 5:
                sessionStorage.setItem('active','usuarios')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(true)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 6:
                sessionStorage.setItem('active','medicos')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(true)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 7:
                sessionStorage.setItem('active','medicosvalidate')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
            case 8:
                sessionStorage.setItem('active','agendavista')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(true)
                setIsMostrarIndicadores(false)
                break
            case 9:
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(false)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(true)
                break
            default:
                sessionStorage.setItem('active','proceso')
                setIsMostrarEditar(false)
                setIsMostrarRealizado(false)
                setIsMostrarEnProceso(true)
                setIsMostrarEnPendiente(false)
                setIsMostrarQuirofanos(false)
                setIsMostrarUsuarios(false)
                setIsMostrarMedicos(false)
                setIsMostrarAgendaVista(false)
                setIsMostrarIndicadores(false)
                break
                
        }
    }

    /**
     * Función que atrapa el evento clic de la selección de fechas
     * @returns Devuelve el estado del objeto
     */
    const handleSelectDateClick = () => (event) => {
        event.preventDefault()
        document.getElementById('dateModal').style.display='block'
    }

    const handleAgregar = () => (event) => {
        event.preventDefault()
        setIsMostrarAgendaVista(false)
        setIsMostrarEditar(false)
        setIsMostrarEnPendiente(false)
        setIsMostrarEnProceso(false)
        setIsMostrarMedicos(false)
        setIsMostrarQuirofanos(false)
        setIsMostrarRealizado(false)
        setIsMostrarUsuarios(false)
        setIsMostrarAgendar(true)
    }

    return(
        <>
            <h2 className='titulo_verde'><div onClick={handleSelectDateClick()}>{tituloInicial} 
                <span>{tituloFinal}</span> <span className='icono_calendar'>  <IoCalendarSharp />
                                                
                </span>
                </div>
            </h2>
            
            <div className='icono_titulo'>
                <img src={imgSource} title="Mac" alt="Mac" height="35px" />
            </div>
            <br />
            <div className='menu_top'>
                <div className='maxwidth'>
                    {
                        isMostrarRealizado 
                            ?
                                <>
                                    <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                    <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                    <div className='opc_menuSeleccionada' onClick={handleMenuClick(1)} style={{ color: '#0582a9' }}>Histórico ({cantidadRealizada})</div>
                                    {
                                        isCorporativo
                                            ?
                                                <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                
                                            :
                                                null
                                    }
                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                    {
                                        isAdmin
                                            ?
                                                <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                            :
                                                null
                                    }
                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                    <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                    {
                                        hadExport
                                            ?
                                                <CSVLink {...csvreport} className="btn-flotanteExport" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
                                            :
                                                null
                                    }
                                    {
                                        hadEditExport
                                            ?
                                                <CSVLink {...csveditreport} className="btn-flotanteExportMedicos" title="Exportar a CSV Eventos Editados" alt="Exportar"><CgCalendarNext style={styleActive}/></CSVLink>
                                            :
                                                null
                                    }
                                    {
                                        !isAgendar
                                            ?
                                                null
                                            :
                                                <button className="btn-Agendar" title="Agendar" alt="Agendar" onClick={handleAgregar()}>Agendar</button>
                                    }
                                    
                                </>
                            :
                                isMostrarEnProceso
                                    ?
                                        <>
                                            <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                            <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                            <div className='opc_menuSeleccionada' onClick={handleMenuClick(2)} style={{ color: '#0582a9' }}>Agendadas ({cantidadProceso})</div>
                                            <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                            {
                                                isCorporativo
                                                    ?
                                                        <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                        
                                                    :
                                                        null
                                            }
                                            <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                            {
                                                isAdmin
                                                    ?
                                                        <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                    :
                                                        null
                                            }
                                            <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                            <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                            {
                                                hadExport
                                                ?
                                                    <CSVLink {...csvreport} className="btn-flotanteExport" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
                                                :
                                                    null
                                            }
                                            {
                                                hadEditExport
                                                ?
                                                    <CSVLink {...csveditreport} className="btn-flotanteExportMedicos" title="Exportar a CSV Eventos Editado" alt="Exportar"><CgCalendarNext style={styleActive}/></CSVLink>
                                                :
                                                    null
                                            }
                                        </>
                                    :
                                        isMostrarEnPendiente
                                            ?
                                                <>
                                                    <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                    <div className='opc_menuSeleccionada' onClick={handleMenuClick(3)} style={{ color: '#0582a9' }}>Pendientes ({cantidadPendiente})</div>
                                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                    <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                    {
                                                        isCorporativo
                                                            ?
                                                                <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                
                                                            :
                                                                null
                                                    }
                                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                    {
                                                        isAdmin
                                                            ?
                                                                <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                            :
                                                                null
                                                    }
                                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                    <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                                    {
                                                        hadExport
                                                        ?
                                                            <CSVLink {...csvreport} className="btn-flotanteExport" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
                                                        :
                                                            null
                                                    }
                                                    {
                                                        hadEditExport
                                                        ?
                                                            <CSVLink {...csveditreport} className="btn-flotanteExportMedicos" title="Exportar a CSV Eventos Editados" alt="Exportar"><CgCalendarNext style={styleActive}/></CSVLink>
                                                        :
                                                            null
                                                    }
                                                </>
                                            :
                                                isMostrarQuirofanos
                                                    ?
                                                        <>
                                                            <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                            <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                            <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                            <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                            {
                                                                isCorporativo
                                                                    ?
                                                                        <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                        
                                                                    :
                                                                        null
                                                            }
                                                            <div className='opc_menuSeleccionada' onClick={handleMenuClick(4)} style={{ color: '#0582a9' }}>Quirófanos</div>
                                                            {
                                                                isAdmin
                                                                    ?
                                                                        <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                    :
                                                                        null
                                                            }
                                                            <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                            <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                                            {
                                                                hadExport
                                                                ?
                                                                    <CSVLink {...csvreport} className="btn-flotanteExport" title="Exportar a CSV" alt="Exportar"><HiSave style={styleActive}/></CSVLink>
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                hadEditExport
                                                                ?
                                                                    <CSVLink {...csveditreport} className="btn-flotanteExportMedicos" title="Exportar a CSV Eventos Editados" alt="Exportar"><CgCalendarNext style={styleActive}/></CSVLink>
                                                                :
                                                                    null
                                                            }
                                                        </>
                                                    :
                                                        isMostrarUsuarios
                                                            ?
                                                                <>
                                                                    <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                    <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                    <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                    {
                                                                        isCorporativo
                                                                            ?
                                                                                <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                
                                                                            :
                                                                                null
                                                                    }
                                                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                    {
                                                                        isAdmin
                                                                            ?
                                                                                <div className='opc_menuSeleccionada' onClick={handleMenuClick(5)} style={{ color: '#0582a9' }}>Usuarios</div>
                                                                            :
                                                                                null
                                                                    }
                                                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                                    <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                                                </>
                                                            :
                                                                isMostrarMedicos
                                                                    ?
                                                                        <>
                                                                            <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                            <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                            <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                            <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                            {
                                                                                isCorporativo
                                                                                    ?
                                                                                        <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                        
                                                                                    :
                                                                                        null
                                                                            }
                                                                            <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                            {
                                                                                isAdmin
                                                                                    ?
                                                                                        <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                                    :
                                                                                        null
                                                                            }
                                                                            <div className='opc_menuSeleccionada' onClick={handleMenuClick(6)} style={{ color: '#0582a9' }}>Médicos</div>
                                                                            <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#282828' }}>Médicos Sin Credencializar</div>
                                                                        </>
                                                                    :
                                                                        isMostrarAgendaVista
                                                                            ?
                                                                                <>
                                                                                    <div className='opc_menuSeleccionada' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                                    <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                                    <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                                    {
                                                                                        isCorporativo
                                                                                            ?
                                                                                                <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                                
                                                                                            :
                                                                                                null
                                                                                    }
                                                                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                                    {
                                                                                        isAdmin
                                                                                            ?
                                                                                                <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                                            :
                                                                                                null
                                                                                    }
                                                                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                                                    <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#0582a9' }}>Médicos Sin Credencializar</div>
                                                                                </>
                                                                            :
                                                                                isMostrarAgendar
                                                                                    ?
                                                                                        <>
                                                                                            <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                                            <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                                            <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                                            <div className='opc_menuSeleccionada' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                                            {
                                                                                                isCorporativo
                                                                                                    ?
                                                                                                        <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                                        
                                                                                                    :
                                                                                                        null
                                                                                            }
                                                                                            <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                                            {
                                                                                                isAdmin
                                                                                                    ?
                                                                                                        <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                                                    :
                                                                                                        null
                                                                                            }
                                                                                            <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                                                            <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#0582a9' }}>Médicos Sin Credencializar</div>
                                                                                        </>
                                                                                    :
                                                                                        isMostrarIndicadores
                                                                                            ?
                                                                                                <>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                                                    {
                                                                                                        isCorporativo
                                                                                                            ?
                                                                                                                <div className='opc_menuSeleccionada' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                                                
                                                                                                            :
                                                                                                                null
                                                                                                    }
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                                                    {
                                                                                                        isAdmin
                                                                                                            ?
                                                                                                                <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                                                            :
                                                                                                                null
                                                                                                    }
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(7)} style={{ color: '#0582a9' }}>Médicos Sin Credencializar</div>
                                                                                                </>
                                                                                            :
                                                                                                <>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(8)} style={{ color: '#282828' }}>Agenda</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(3)} style={{ color: '#282828' }}>Pendientes ({cantidadPendiente})</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(2)} style={{ color: '#282828' }}>Agendadas ({cantidadProceso})</div>
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(1)} style={{ color: '#282828' }}>Histórico ({cantidadRealizada})</div>
                                                                                                    {
                                                                                                        isCorporativo
                                                                                                            ?
                                                                                                                <div className='opc_menu' onClick={handleMenuClick(9)} style={{ color: '#282828' }}>Indicadores</div>
                                                                                                                
                                                                                                            :
                                                                                                                null
                                                                                                    }
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(4)} style={{ color: '#282828' }}>Quirófanos</div>
                                                                                                    {
                                                                                                        isAdmin
                                                                                                            ?
                                                                                                                <div className='opc_menu' onClick={handleMenuClick(5)} style={{ color: '#282828' }}>Usuarios</div>
                                                                                                            :
                                                                                                                null
                                                                                                    }
                                                                                                    <div className='opc_menu' onClick={handleMenuClick(6)} style={{ color: '#282828' }}>Médicos</div>
                                                                                                    <div className='opc_menuSeleccionada' onClick={handleMenuClick(7)} style={{ color: '#0582a9' }}>Médicos Sin Credencializar</div>
                                                                                                </>
                    }
                </div>
            </div>
        </>
    )
}

export default TituloSection;